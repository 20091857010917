import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { IAssignment } from "../../interface/assignment";
import { IClient, IMatrixRisk, IMatrixSeverity } from "../../interface/client";
import { IInstallation } from "../../interface/installation";

interface IRiskTable {
    assignment?: IAssignment;
    installation?: IInstallation;
    client: IClient;
    query?: any;
    setQuery?: (q: any, add: boolean) => void
}

const RiskTable = ({ installation, client, query, assignment, setQuery }: IRiskTable) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const doCellCss = (riskNo: number, severityNo: number): React.CSSProperties => {
        const severityRisk = client.matrix.severityRisk;
        const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
        //    console.log(index);
        const thing = severityRisk[index];


        const level = client.matrix.levels[thing.thing]




        return { "backgroundColor": level.color };


    }
    const doCellTheme = (riskNo: number, severityNo: number): string => {
        if (client !== undefined) {
            const severityRisk = client.matrix.severityRisk;
            const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
            //    console.log(index);
            const thing = severityRisk[index];



            //const level = client.matrix.levels[thing.thing]




            // return { "backgroundColor": level.color };


            // if (thing.thing === 'Low') {
            //     return `${inQuery(severityNo, riskNo) ? 'bg-success' : 'bg-light-success'} tableHover`;
            // } else if (thing.thing === 'Medium') {
            //     return `${inQuery(severityNo, riskNo) ? 'bg-warning' : 'bg-light-warning'} tableHover`;
            // } else if (thing.thing === 'High') {
            //     return `${inQuery(severityNo, riskNo) ? 'bg-danger' : 'bg-light-danger'} tableHover`;
            // } else {
            return `tableHover`;
            // }
        } else {
            return '';
        }


    }
    const doCellText = (riskNo: number, severityNo: number): string => {

        if (client !== undefined) {

            const severityRisk = client.matrix.severityRisk;
            const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
            //   console.log(index);
            const thing = severityRisk[index];





            const level = client.matrix.levels[thing.thing]

            //  console.log(level)


            return level.level

        } else {
            return '';
        }
    }


    const getCount = (Severity: number, Likelihood: number) => {

        let count: number = -1;
        if (assignment) {
            count = assignment.forms.filter(form => {
                return (form.Severity === Severity && form.Likelihood === Likelihood)
            }).length;
        }
        if (installation) {
            //pipes
            //  console.log(installation)
            if (installation.pipes) {
                count = installation.pipes.filter(form => {
                    return (form.combinedConsequence === Severity && form.likelihood === Likelihood)
                }).length;
            }
            if (installation.equipments) {
                count = count + installation.equipments.filter(form => {
                    return (form.combinedConsequence === Severity && form.likelihood === Likelihood)
                }).length;
            }

        }
        if (count !== -1) { return `(${count})` } else {
            return '(0)';
        }
    }

    const inQuery = (Severity: number, Likelihood: number) => {

        let SeverityTrue: boolean = false;
        let LikelihoodTrue: boolean = false;
        if (assignment) {
            if (query && Array.isArray(query.Severity)) {
                if (query.Severity.includes(Severity)) {
                    SeverityTrue = true;
                }
            } else {
                if (query && query.Severity === Severity) {
                    SeverityTrue = true;
                }
            }

            if (query && Array.isArray(query.Likelihood)) {
                if (query && query.Likelihood.includes(Likelihood)) {
                    LikelihoodTrue = true;
                }
            } else {
                if (query && query.Likelihood === Likelihood) {
                    LikelihoodTrue = true;
                }
            }
        }
        if (installation) {
            if (query && Array.isArray(query.combinedConsequence)) {
                if (query && query.combinedConsequence.includes(Severity)) {
                    SeverityTrue = true;
                }
            } else {
                if (query && query.combinedConsequence === Severity) {
                    SeverityTrue = true;
                }
            }

            if (query && Array.isArray(query.likelihood)) {
                if (query && query.likelihood.includes(Likelihood)) {
                    LikelihoodTrue = true;
                }
            } else {
                if (query && query.likelihood === Likelihood) {
                    LikelihoodTrue = true;
                }
            }
        }

        if (SeverityTrue === true && LikelihoodTrue === true) {
            return true;
        } else {
            return false;
        }
    }


    return <><div ref={componentRef}>
        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header bsPrefix="debug" closeButton>
                <Modal.Title>Detailed Risk Table</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-left">

                    <table className="riskTable center w-100">
                        <tbody>
                            <tr>


                                <td colSpan={5}></td>


                                <td></td>

                                <td colSpan={client && client.matrix.risk.length}>      <h4>Risk profile</h4></td>
                            </tr>
                            <tr>


                                <td colSpan={5}></td>



                                <td></td>

                                <td colSpan={client && client.matrix.risk.length}><b>Likelihood</b></td>
                            </tr>
                            <tr>


                                <td>People Impact</td>
                                <td>Asset Impact</td>
                                <td>Environmental Impact</td>
                                <td>Financial Impact</td>
                                <td>Reputation Impact</td>

                                <td><b>Severity</b></td>
                                {client && client.matrix.risk.map((risk: IMatrixRisk, riskIndex: number) => {
                                    return <td key={`${riskIndex}`} className="tableHover" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        setQuery && setQuery({ Likelihood: risk.riskNo }, false)
                                    }}>

                                        <p>{risk.riskDescription}</p>
                                    </td>
                                })}
                            </tr>

                            {client && client.matrix.severity.map((severity: IMatrixSeverity, severityIndex: number) => {
                                const actSeverity = severity.severityNumber
                                return <tr key={severityIndex}>


                                    <td>{severity.peopleImpact}</td>
                                    <td>{severity.assetImpact}</td>
                                    <td>{severity.environmentalImpact}</td>
                                    <td>{severity.financialImpact}</td>
                                    <td>{severity.reputationImpact}</td>

                                    <td className="tableHover" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        setQuery && setQuery({ Severity: actSeverity }, false)
                                    }}>


                                        <p>{severity.severityDescription}</p>
                                    </td>
                                    {client && client.matrix.risk.map((risk: IMatrixRisk, riskIndex: number) => {
                                        const actRisk = risk.riskNo;
                                        return <td key={`${riskIndex}`} onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            console.log({ Severity: actSeverity, Likelihood: actRisk })
                                            if (assignment) {
                                                setQuery && setQuery({ Severity: actSeverity, Likelihood: actRisk }, false)
                                            }
                                            if (installation) {
                                                setQuery && setQuery({ combinedConsequence: actSeverity, likelihood: actRisk }, false)
                                            }
                                        }} style={doCellCss(actRisk, actSeverity)} className={doCellTheme(actRisk, actSeverity)}>{doCellText(actRisk, actSeverity)}<br />{getCount(actSeverity, actRisk)}</td>
                                    })}

                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>


        <table className="riskTable center w-100">
            <tbody>
                <tr>

                    <td></td>

                    <td colSpan={client && client.matrix.risk.length}></td>
                </tr>
                <tr>

                    <td></td>

                    <td colSpan={client && client.matrix.risk.length}><b>Likelihood</b></td>
                </tr>
                <tr>

                    <td><b>Severity</b></td>
                    {client && client.matrix.risk.map((risk: IMatrixRisk, riskIndex: number) => {
                        return <td key={`${riskIndex}`} className="tableHover" onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            setQuery && setQuery({ Likelihood: risk.riskNo }, false)
                        }}>
                            <b>{risk.risk}</b>
                        </td>
                    })}
                </tr>

                {client && client.matrix.severity.map((severity: IMatrixSeverity, severityIndex: number) => {
                    const actSeverity = severity.severityNumber
                    return <tr key={severityIndex}>


                        <td className="tableHover" onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            setQuery && setQuery({ Severity: actSeverity }, false)
                        }}>
                            <br />
                            <b>{severity.severity}</b>
                        </td>
                        {client && client.matrix.risk.map((risk: IMatrixRisk, riskIndex: number) => {
                            const actRisk = risk.riskNo;
                            return <td key={`${riskIndex}`} onClick={(ev: React.MouseEvent) => {
                                ev.preventDefault();
                                //console.log({ Severity: actSeverity, Likelihood: actRisk })
                                if (assignment) {
                                    setQuery && setQuery({ Severity: actSeverity, Likelihood: actRisk }, false)
                                }
                                if (installation) {
                                    setQuery && setQuery({ combinedConsequence: actSeverity, likelihood: actRisk }, false)
                                }
                            }} style={doCellCss(actRisk, actSeverity)} className={doCellTheme(actRisk, actSeverity)}>{doCellText(actRisk, actSeverity)}<br />{getCount(actSeverity, actRisk)}</td>
                        })}

                    </tr>
                })}
            </tbody>
        </table>
    </div>

        <div className="btn-group">
            <button type="button" name="" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                setShow(true);
            }} className="btn btn-success">View Full Risk Profile</button>

            {handlePrint && <button type="button" className="btn btn-info" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                handlePrint();
            }}>
                <FontAwesomeIcon icon={faPrint} />
            </button>}

        </div>
    </>;
}

export default RiskTable;