import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RequestGetInspectionTypes } from '../../api/inspection-type-requests';
import DataTable from '../../components/system/DataTable';
import DateDisplay from '../../components/system/DateDisplay';
import DebugButton from '../../components/system/DebugButton';
import Loading from '../../components/system/loading';
import { useNotifications } from '../../context/notifications';
import { IEquipment } from '../../interface/equipment';
import { IInspectionType } from '../../interface/inspection-type';
import { IInstallation } from '../../interface/installation';
import { IInstallationLoop } from '../../interface/installation-loop';
import { NoResults } from './Installation';


interface IISchedule {
    data: IInstallation,
    loops: IInstallationLoop[];
    equipment: IEquipment[];
}
interface IISYear {
    year: number;
    loops: any[];
}
const InstallationDeferrals = ({ data, loops, equipment }: IISchedule) => {
    const detailDisplayTable = false;
    const [inspectionTypes, setInspectionTypes] = useState<IInspectionType[]>();
    const { addNotification } = useNotifications()!;
    const [startedLoading, setStartedLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        console.log("get reports");
        // const params: any = match.params;
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            // const report = (): Promise<any> => {
            if (startedLoading === false) {
                setStartedLoading(true)
                RequestGetInspectionTypes(addNotification).then(iTypes => {
                    setInspectionTypes(iTypes)
                    setLoading(false);
                })

            }



        }
    });


    if (loading) {
        return <Loading />
    } else {


        let things: any[] = [];
        // equipment.map((loop, loopIndex) => {
        //     console.log(loop)
        //     return loop.inspectionSchedule && loop.inspectionSchedule.map((sthing, sthingindex) => {
        //         things.push({ refId: loop.refId, systemId: loop.systemId, system: loop.system, risk: loop.risk, riskAZ: loop.riskAZ, id: loop.id, name: loop.name, ttype: 'equipment', ...sthing });
        //     })
        // })

        ///loop.inspectionSchedule.filter(ddd=>ddd.isDeferred===true)



        loops.map((loop, loopIndex) => {
            console.log("loop", loop.id, loop.inspectionSchedule)
            return loop.inspectionSchedule && loop.inspectionSchedule.filter && loop.inspectionSchedule.filter(ddd => ddd.isDeferred === true).map((sthing, sthingindex) => {
                //  console.log("YOU COCK SUCKER", sthing)


                const aThing: any = { ...sthing, installId: data.id };
                aThing.ttype = 'loop';
                if (aThing.isDeferred === true) {
                    const startTime = moment(aThing.inspectionDue);
                    const endTime = moment(aThing.deferredDate);
                    const duration = moment.duration(endTime.diff(startTime));
                    // inspectionType.defMonths = duration.asMonths();
                    aThing.deferralMonths = duration.asMonths();
                }




                things.push({
                    ...aThing
                });
            })
        })


        equipment.map((loop, loopIndex) => {
            console.log("loop", loop.id, loop.inspectionSchedule)
            return loop.inspectionSchedule && loop.inspectionSchedule.filter && loop.inspectionSchedule.filter(ddd => ddd.isDeferred === true).map((sthing, sthingindex) => {
                //  console.log("YOU COCK SUCKER", sthing)


                const aThing: any = { ...sthing, installId: data.id };
                aThing.ttype = 'equipment';
                if (aThing.isDeferred === true) {
                    const startTime = moment(aThing.inspectionDue);
                    const endTime = moment(aThing.deferredDate);
                    const duration = moment.duration(endTime.diff(startTime));
                    // inspectionType.defMonths = duration.asMonths();
                    aThing.deferralMonths = duration.asMonths();
                }




                things.push({
                    ...aThing
                });
            })
        })


        return <div className="row">

            {things && things.length === 0 ? <NoResults /> : <>
                <DataTable

                    hideIndexCol={true}
                    rowClass="deferrals"
                    colHeadings={[
                        { name: "ID", field: "refId", sortable: true },
                        { name: "Type", field: "type", sortable: true },
                        {
                            name: "Last Inspected", field: "id", r: (value: any, index) => {
                                return <div>
                                    {value.lastInspected && value.lastInspected.date && <DateDisplay format="YYYY-MM-DD" date={value.lastInspected.date} />}
                                </div>;
                            }, sortable: false
                        },
                        {
                            name: "Inspection Scheduled", field: "id", r: (value: any, index) => {
                                return <div>
                                    {value.inspectionDue && <DateDisplay format="YYYY-MM-DD" date={value.inspectionDue} />}
                                </div>;
                            }, sortable: false
                        }, {
                            name: "Inspection Deferred Until", field: "deferredDate", r: (value: any, index) => {
                                return <div>
                                    {value.deferredDate && <DateDisplay format="YYYY-MM-DD" date={value.deferredDate} />}
                                </div>;
                            }, sortable: false
                        }, {
                            name: "Deferred For (months)", field: "deferralMonths", r: (value: any, index) => {
                                return <div>
                                    {value.deferralMonths && value.deferralMonths.toFixed(1)}
                                </div>;
                            }, sortable: false
                        },
                        {
                            name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                return <div className="btn-group">
                                    {value.ttype === 'loop' && <Link className="btn btn-primary" to={`/installations/${value.installId}/loop/${value.id}`}>Loop Details</Link>}
                                    {value.ttype === 'equipment' && <Link className="btn btn-primary" to={`/installations/${value.installId}/equipment/${value.id}`}>Equipment Details</Link>}
                                    <DebugButton data={value} />

                                </div>;
                            }, sortable: false
                        },

                    ]}

                    data={things}
                /></>}
        </div>;
    }
}

export default InstallationDeferrals;