export const validateFormInput = (thingddcdd: any, fields: any[]) => {
    const validationErrors: string[] = [];
    fields.map(field => {
        console.log(field);
        if (field.required) {
            if (field.targetField) {
                const fName = field.targetField;

                if (!thingddcdd[fName]) {

                    validationErrors.push(field.field);
                }
            } else if (field.field) {
                const fName = field.field;

                if (!thingddcdd[fName]) {
                    validationErrors.push(fName);
                }
            }
        }
    });
    return validationErrors;
}
