import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Helmet } from "react-helmet";
import { Link, Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestGetClients } from "../../../api/client-requests";
import { RequestGetLogs, RequestNewLog } from "../../../api/log-requests";
import { RequestGetSysNotificationTypes } from "../../../api/sys-notification-type-requests";
import { RequestGetSysPermissionss } from "../../../api/sys-permissions-requests";
import { RequestGetUser, RequestNewUser, RequestUpdateUser, RequestUserFunction } from "../../../api/user-requests";
import { composeValidators, required } from "../../../components/FormStuff/validators";
import DataTable from "../../../components/system/DataTable";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { IClient } from "../../../interface/client";
import { ELogType, ESeverity, ILog } from "../../../interface/log";
import { ISysNotificationType } from "../../../interface/sys-notification-type";
import { ISysPermissions } from "../../../interface/sys-permissions";
import { IUser } from "../../../interface/user";



const AdminUser = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IUser>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    // eslint-disable-next-line
    const [clients, setClients] = useState<IClient[]>();
    const [logs, setLogs] = useState<ILog[]>([])
    const [nType, setNType] = useState<ISysNotificationType[]>();
    const [permissions, setPermissions] = useState<ISysPermissions[]>([])

    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/users/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }
                const prodCall = () => {

                    return RequestGetUser(addNotification, id).then(user => {
                        setThing(user);
                    })
                }

                const gPerm = (): Promise<any> => {
                    return RequestGetSysPermissionss(addNotification).then(clients => {
                        setPermissions(clients);
                    })
                }
                const gNt = (): Promise<any> => {
                    return RequestGetSysNotificationTypes(addNotification).then(clients => {
                        setNType(clients);
                    })
                }
                const gClients = (): Promise<any> => {
                    return RequestGetClients(addNotification).then(clients => {
                        setClients(clients);
                    })
                }
                if (id === 'new') {

                    Promise.allSettled([gClients(), gPerm(), gNt()]).then(() => {
                        setThing({ id: 'new', clientId: '', notifications: [], username: '', password: '', email: '', mobile: '', type: '', firstName: '', lastName: '', permissions: [], config: {} })

                        setLoading(false)
                    });

                } else {

                    // const getStores = () => {
                    //     return fetch(`/api/admin/stores`)
                    //         .then((response) => response.json())
                    //         .then((json: IStore[]) => {
                    //             console.log(json);
                    //             setStores(json);
                    //         })
                    // }


                    Promise.allSettled([gClients(), prodCall(), gPerm(), gNt()]).then(() => {
                        setLoading(false)
                    });
                }
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - User - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IUser>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'User Created!';
                ppp = RequestNewUser(addNotification, data);
            } else {
                notificationText = 'User Saved!';
                ppp = RequestUpdateUser(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - User - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();
    const getUserActivity = useCallback((userId: string) => {
        RequestGetLogs(addNotification, { userId: userId }).then(logs => {
            setLogs(logs)
        })
    }, [addNotification, setLogs])

    const sendWelcomeEmail = useCallback((userId: string) => {
        RequestUserFunction(addNotification, 'send-welcome-email', userId).then(logs => {

        })
    }, [addNotification])


    const sendPassword = useCallback((userId: string) => {
        RequestUserFunction(addNotification, 'admin-lost-password', userId).then(logs => {

        })
    }, [addNotification])
    const severityObj = Object.values(ESeverity);
    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }


    const terribleList: string[] = ['register_user', 'contact', 'request', 'checkout', 'daily_sales', 'daily_errors']

    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../users" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>User / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 3 -->*/}

            <Form
                onSubmit={onSubmit}
                initialValues={thing}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                {thing.id !== 'new' && <div className="btn-group">
                                    <button className="btn btn-primary" type="button" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        thing.id && sendWelcomeEmail(thing.id);
                                    }}>Resend Welcome Email</button>
                                    <button className="btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        thing.id && sendPassword(thing.id);

                                    }}>Send Password Reset Email</button>
                                    <button className="btn btn-warning" type="button" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();

                                        thing.id && getUserActivity(thing.id)
                                    }}>View Activity</button> <DebugButton data={values} />
                                </div>}


                                <Field name="username" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Username</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                {values.id === 'new' && <><Field name="password" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Password</label>
                                            <input type="password" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                    <Field name="password2" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Re - Enter Password</label>
                                                <input type="password" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field></>}
                                <Field name="type" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>User Type</label>
                                            <select className="form-control" {...input}>
                                                <option></option>
                                                <option value="admin">Admin</option>
                                                <option value="client">Client</option>
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                {values.type === 'client' && <Field name="clientId">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Client</label>
                                            <select className="form-control" {...input} >
                                                <option></option>
                                                {clients && clients.map((client, index) => {
                                                    return <option key={index} value={client.id}>{client.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>}
                                {/* {values.type === 'client' && <Field name="clientId">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Client</label>
                                            <select className="form-control" {...input} disabled={values.id !== 'new'}>
                                                <option></option>
                                                {clients && clients.map((client, index) => {
                                                    return <option key={index} value={client.id}>{client.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>} */}


                                <Field name="firstName" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>First Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="lastName" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Last Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <hr />
                                <Field name="email" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Email</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="mobile" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Mobile</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>


                                <div className="row">
                                    <h2>Notifications</h2>
                                    {nType && nType.map((ss, key) => {
                                        const exists: boolean = values.notifications.includes(ss.id)
                                        console.log("dsd", ss.userTypes.includes(auth.type))
                                        if (ss.userTypes.includes(values.type))
                                            return <div className="col-3" key={key}>
                                                {ss.name}
                                                <br />
                                                {ss.description}
                                                <br />
                                                <div className="btn-group">
                                                    {exists ? <button type="button" className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                        ev.preventDefault()
                                                        console.log("sss", ss)
                                                        const p = [...thing.notifications];
                                                        const index = p.findIndex(dd => dd === ss.id)
                                                        if (index !== -1) {
                                                            p.splice(index, 1)
                                                        }
                                                        form.change("notifications", p)
                                                    }}><FontAwesomeIcon icon={faTrash} /></button> : <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                                        ev.preventDefault()
                                                        const p = [...thing.notifications];
                                                        p.push(ss.id)
                                                        form.change("notifications", p)
                                                    }}><FontAwesomeIcon icon={faPlus} /></button>}
                                                </div>
                                            </div>
                                    })}
                                </div>


                                <div className="row">
                                    <h2>Permissions</h2>
                                    {permissions && permissions.map((ss, key) => {
                                        const exists: boolean = values.permissions.includes(ss.id)
                                        console.log(exists)
                                        if (ss.visible === true && ss.userTypes.includes(values.type))
                                            return <div className="col-3" key={key}>
                                                {ss.name}
                                                <br />
                                                {ss.description}
                                                <br />
                                                <div className="btn-group">
                                                    {exists ? <button type="button" className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                        ev.preventDefault()
                                                        console.log("sss", ss)
                                                        const p = [...thing.permissions];
                                                        const index = p.findIndex(dd => dd === ss.id)
                                                        if (index !== -1) {
                                                            p.splice(index, 1)
                                                        }
                                                        form.change("permissions", p)
                                                    }}><FontAwesomeIcon icon={faTrash} /></button> : <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                                        ev.preventDefault()
                                                        const p = [...thing.permissions];
                                                        p.push(ss.id)
                                                        form.change("permissions", p)
                                                    }}><FontAwesomeIcon icon={faPlus} /></button>}
                                                </div>
                                            </div>
                                    })}
                                </div>

                                {values.id === 'new' && <><Field
                                    name="sendWelcome"
                                    component="input"
                                    type="checkbox"
                                    // value={true}
                                    className=""
                                    id="sendWelcome"
                                />
                                    <label htmlFor="agree">Send Welcome Email</label></>}
                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </form>

                }} />
            {logs && logs.length > 0 && <><h2>User log</h2><DataTable rowStyleFn={(value) => {
                // console.log('ss', value)
                switch (value.severity) {
                    case 0:
                        return { backgroundColor: "lightgreen" };
                    case 1:
                        return { backgroundColor: "lightblue" };
                    case 2:
                        return { backgroundColor: "#d342ff" };
                    case 3:
                        return { backgroundColor: "orange" };
                    case 4:
                        return { backgroundColor: "red" };
                    default:
                        return {};
                }

            }}

                colHeadings={[
                    {
                        name: "Severity", field: "severity", sortable: true, r: (value: any, index) => {
                            return <>{value.severity}</>;
                        }
                    },
                    {
                        name: "Type", field: "type", sortable: true
                    },
                    {
                        name: "Text", field: "text", sortable: false
                    },
                    {
                        name: "Severity", field: "severity", sortable: true, r: (value: any, index) => {
                            const severity: number = value.severity;
                            let d = severityObj[severity]
                            console.log(d)
                            return <>{d}</>
                        }
                    },
                    {
                        name: "Created", field: "created", r: (value: any, index) => {
                            return <>{value.created}</>;
                        }, sortable: true
                    },
                    {
                        name: "Debug", field: "id", r: (value: any, index) => {
                            return <><DebugButton data={value} /><Link className="btn btn-primary" to={`${match.url}/${value._id && value._id}`}>Edit</Link></>;
                        }, sortable: false
                    },
                ]} data={logs} /></>}
            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminUser;