import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useRouteMatch } from "react-router-dom";
import { RequestGetAnomalys } from "../../api/anomaly-requests";
import { downloadFile } from "../../api/file-download-request";
import { RequestGetInspection } from "../../api/inspection-requests";
import DataTable from "../../components/system/DataTable";
import DateDisplay from "../../components/system/DateDisplay";
import DebugButton from "../../components/system/DebugButton";
import { useAuth } from "../../context/auth";
import { useNotifications } from "../../context/notifications";
import { IAnomaly } from "../../interface/anomaly";
import { IInspection } from "../../interface/inspection";


const Inspection = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [thing, setThing] = useState<IInspection>();
    const [anomalys, setAnomalys] = useState<IAnomaly[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const topRef = useRef<any>()
    const { auth } = useAuth()!;
    useEffect(() => {
        console.log("get reports");
        const params: any = match.params;
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            // const report = (): Promise<any> => {
            console.log("get reports", params.id2);
            //   setId(params.id2)
            const prodCall = () => {
                RequestGetInspection(addNotification, params.id2)
                    .then((json: IInspection) => {
                        console.log(json);
                        setThing(json);
                        setLoading(false);
                    })
            }

            const anomaly = () => {
                RequestGetAnomalys(addNotification, { inspectionId: params.id })
                    .then((json: IAnomaly[]) => {
                        console.log(json);
                        setAnomalys(json);

                    })
            }
            Promise.allSettled([prodCall(), anomaly()]).then(() => {
                setLoading(false)
            });


        }
    }, [])



    return (<>
        {(loading === false && thing && thing !== null) && <>
            <span className="e2eClientInspection" style={{ display: 'none' }}>TestOk</span>
            <Helmet>
                <title>{thing.refId} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <div className="col-12" ref={topRef}>
                <div className="d-flex justify-content-between">
                    <div className="text-left">
                        <Link to={`/installations/${thing.installId}/home`}>{thing.installation && thing.installation.name}</Link>
                        {' >'} Inspection : {thing.refId}
                    </div>

                    {auth.type === 'admin' && <div className="text-left">
                        <Link to={`/admin/inspections/${thing.id}`}>EDIT INSPECTION</Link>

                    </div>}
                </div>



                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="Home">

                        <DebugButton data={thing} />

                        <div className="row">
                            <h2>Details</h2>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Subject</b>
                                    <p id="e2e_Subject">{thing.subject}</p>
                                </div>
                            </div>

                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>File</b>

                                    {thing.file && <p id="e2e_FileName">{thing.file.fileName} :
                                        <button className="btn btn-primary" type="button" onClick={() => {
                                            thing.file && downloadFile(addNotification, thing.file);
                                        }}><FontAwesomeIcon icon={faFileDownload} /></button>

                                    </p>}
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Reference Id</b>
                                    <p id="e2e_Refid">{thing.refId}</p>
                                </div>
                            </div>
                            <h2>Inspected By</h2>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Inspector</b>
                                    <p id="e2e_inspectedByName">{thing.inspectionByName}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Company</b>
                                    <p id="e2e_inspectedByCompany">{thing.inspectionByCompany}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Date</b>
                                    <p id="e2e_inspectedDate"><DateDisplay format="YYYY-MM-DD" date={thing.inspectionDate} /></p>
                                </div>
                            </div>


                            <div className="col-12 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Summary Of Findings</b>
                                    <p id="e2e_summaryOfFindings">{thing.summaryOfFindings}</p>
                                </div>
                            </div>

                            <div className="col-12 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Closing</b>
                                    <p id="e2e_closing">{thing.closing}</p>
                                </div>
                            </div>

                            <h2>Assessed By</h2>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Name</b>
                                    <p id="e2e_assessedByName">{thing.assessedByName}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Company</b>
                                    <p id="e2e_assessedByCompany">{thing.assessedByCompany}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Date</b>
                                    <p id="e2e_assessedDate"><DateDisplay format="YYYY-MM-DD" date={thing.assessedDate} /></p>
                                </div>
                            </div>
                            {thing.approvedByUser && <>
                                <h2>Approved By</h2>
                                <div className="col-6 border border-start">
                                    <div className="d-flex justify-content-between">
                                        <b>Name</b>
                                        <p id="e2e_Name">{thing.approvedByUser.firstName} {thing.approvedByUser.lastName}</p>
                                    </div>
                                </div>
                                <div className="col-6 border border-start">
                                    <div className="d-flex justify-content-between">
                                        <b>Date</b>
                                        <p id="e2e_approvedByDate"><DateDisplay format="YYYY-MM-DD" date={thing.approvedByDate} /></p>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </Tab>
                    <Tab eventKey="inspection" title="Inspection">
                        <>
                            <h2>Inspections</h2>

                            <div className="col-12">
                                <DataTable colHeadings={[
                                    {
                                        name: "Image", field: "imageId", r: (value: any, index: number) => {
                                            return <div>
                                                {value.image && <div className="col-6 offset-2">
                                                    <img src={value.image.croppedSrcData ? value.image.croppedSrcData : value.image.c1600} className="img-fluid" />
                                                </div>}
                                            </div>;
                                        }, sortable: false
                                    },
                                    { name: 'Type', field: 'typeId', sortable: true },
                                    { name: 'Tag No', field: 'tagNo', sortable: true },
                                    {
                                        name: 'Confidence', field: 'confidence', sortable: false, r: (value: any, index: number) => {
                                            return <div className="btn-group">
                                                {value.confidence === 0 && <>Low</>}
                                                {value.confidence === 1 && <>Medium</>}
                                                {value.confidence === 2 && <>High</>}
                                            </div>;
                                        }
                                    },
                                    { name: 'Findings', field: 'findings', sortable: false },
                                    {
                                        name: "Pipe / Equipment", field: "actions", r: (value: any, index: number) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                {value.loop && <Link className={`btn btn-outline-primary e2e_Loop_${index - 1}`} to={`/installations/${thing.installId}/loop/${value.loopId}`}>Loop {value.loop.refId}</Link>}
                                                {value.pipe && <Link className={`btn btn-outline-primary e2e_Pipe_${index - 1}`} to={`/installations/${thing.installId}/pipe/${value.pipeId}`}>Pipe {value.pipe.refId}</Link>}
                                                {value.equipment && <Link className={`btn btn-outline-primary e2e_Equipment_${index - 1}`} to={`/installations/${thing.installId}/equipment/${value.equipmentId}`}>Equipment {value.equipment.refId}</Link>}

                                            </div>;
                                        }, sortable: false
                                    }
                                ]} data={thing.reported} />
                            </div>
                        </>
                    </Tab>
                    <Tab eventKey="anomalies" title="Anomalies">
                        <h2>Anomalies</h2>
                        {anomalys && <DataTable

                            hideIndexCol={true}
                            colHeadings={[
                                { name: "ID", field: "refId", sortable: true },
                                { name: "Subject", field: "subject", sortable: false },
                                { name: "Status", field: "status", sortable: false },
                                {
                                    name: "Date", field: "date", r: (value: any, index) => {
                                        return <DateDisplay format="YYYY-MM-DD" date={value.date} />;
                                    }, sortable: true
                                },
                                {
                                    name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                        return <div className="btn-group">
                                            <DebugButton data={value} />
                                            {value.pipe && <Link className="btn btn-outline-primary e2ePipe" to={`/installations/${value.installId}/pipe/${value.pipeId}`}>{value.pipe.refId}</Link>}
                                            {value.equipment && <Link className="btn btn-outline-primary e2eEquipment" to={`/installations/${value.installId}/equipment/${value.equipmentId}`}> {value.equipment.refId}</Link>}


                                        </div>;
                                    }, sortable: false
                                },

                            ]}

                            data={anomalys}
                        />}
                    </Tab>

                </Tabs>

            </div>



        </>}

    </>
    );
}

export default Inspection;