import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Helmet } from 'react-helmet';
import { Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import { RequestNewLog } from '../../../api/log-requests';
import { RequestGetPipe, RequestNewPipe, RequestUpdatePipe } from '../../../api/pipe-requests';
import DebugButton from '../../../components/system/DebugButton';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { ELogType, ESeverity } from '../../../interface/log';
import { IPipe } from '../../../interface/pipe';



const AdminPipe = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IPipe>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/pipes/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
                    setThing({
                        name: '', description: '', loopId: '', loopIdStr: '', systemId: '', materialId: '', likelihood: 0, risk: 0, loopIdNumber: 0, systemIdStr: '',
                        flammableConsequence: 0, toxicConsequence: 0, combinedConsequence: 0, fromEquipmentId: '', toEquipmentId: '', fromPipeId: '', toPipeId: '', pressureRelief: '',
                        originalWallThickness: 0, id: 'new', clientId: '', installId: '', size: "", refId: "", spec: "", fromStr: "", toStr: "", operatingTemperatureMin: "",
                        testPoints: [], operatingTemperatureMax: "", operatingPressureMin: "", operatingPressureMax: "", designTemperatureMin: "", designTemperatureMax: "", designPressureMin: "", designPressureMax: "", testingBarg: "", testingMedium: "", insulationType: "", insulationThk: '', paintCode: "", circuitDescription: "",
                    })
                    Promise.allSettled([]).then(() => {
                        setLoading(false)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetPipe(addNotification, id).then(thing => {
                            setThing(thing);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Pipe - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IPipe>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Pipe Created!';
                ppp = RequestNewPipe(addNotification, data);
            } else {
                notificationText = 'Pipe Saved!';
                ppp = RequestUpdatePipe(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Pipe - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../pipes" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Pipe / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}

            <Form <IPipe>
                onSubmit={onSubmit}
                initialValues={thing}
                mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                        console.log(state, field, value)
                        changeValue(state, field, () => value)
                    }
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    //do if altered browse notifcation
                    //  console.log(submitting, pristine)
                    setFormChanged(!pristine)
                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />




                                <Field name={`originalWallThickness`} type="number" parse={(value, name) => {
                                    //  console.log("parse", value, name)
                                    return parseFloat(value);
                                }}
                                    format={(value, name) => {
                                        //  console.log("format", value, name)

                                        return parseFloat(value);
                                    }}>
                                    {({ input, meta }) => (
                                        <div className="">

                                            <label>Original Thickness</label>
                                            <input className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name={`risk`}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Risk #[{values.risk}]</label>
                                            <select className="form-control" {...input}>
                                                {thing.client && thing.client.matrix.levels && thing.client.matrix.levels.map((lev, index) => {
                                                    return <option key={index} value={index}>{lev.level}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>


                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </form>

                }} />

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminPipe;