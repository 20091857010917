
export const getRisk = (likelihoodNo: number, severityNo: number, values: any): number => {

    console.log("getRisk", likelihoodNo, severityNo, values.client.matrix.severityRisk, values.client)
    if (values.client !== undefined) {
        const severityRisk = values.client.matrix.severityRisk;
        const index = severityRisk.findIndex((ddd: any) => ddd.riskNo === likelihoodNo && ddd.severityNo === severityNo)

        // console.log(index);
        const things = severityRisk[index];
        if (things) {
            return things.thing;
        } else {
            return -1;
        }
    } else {
        console.log("NO CLIENT")
        return -1;
    }
}
