
import { useEffect } from "react";
import { Nav } from "react-bootstrap";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { usePageTitle } from "../../context/pageTitle";
import MyProfileHome from "./MyProfileHome";


export interface IProfileSubpageProp {
    //  user: IUserSellerProfile;

}


const MyProfile = () => {
    let match = useRouteMatch();

    // let [loading, setLoading] = useState<boolean>(false);
    // let [user, setUser] = useState<IUserSellerProfile>();

    // useEffect(() => {
    //     //   console.log("get product types");
    //     //  const query = qs.parse(props.location.search.substring(1));
    //     //setQuery({ name: query.cat });

    //     if (loading === true) {



    //     }
    // }, [match])

    const { setPageTitle } = usePageTitle()!;

    const location = useLocation();



    useEffect(() => {



        setPageTitle && setPageTitle('My Profile');

    }, [location])

    return (<div>


        <Nav>
            <Nav.Item>
                <Nav.Link to={`${match.url}/`} as={Link}>Profile</Nav.Link>
            </Nav.Item>

        </Nav>

        <Switch>
            <Route path={`${match.path}`} exact>
                <MyProfileHome />
            </Route>

        </Switch>
    </div>);
}

export default MyProfile;