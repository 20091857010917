import axios from "axios";
import {ILog} from "../interface/log";
import axiosInstance from "./instance";
import queryString from "query-string";
import { useNotifications } from "../context/notifications";
export const RequestGetLogs =  (addNotification:any,data?:any): Promise<ILog[]> => {
    return new Promise<ILog[]>((resolve, reject) => {
        let url = '/logs';
        if(data){
          url+="?";
          for (let [key, value] of Object.entries(data)) {
            url+=key + '=' + value+"&";
          }
        }
        axiosInstance(addNotification).get(url).then(response => {
            return response.data;
        }).then(response => {
          //  console.log(response);
            resolve(response)
        }).catch(exc => {
            //console.log(exc);
            reject(exc);
        });
    })
}
export const RequestGetLog =  (addNotification:any,id: string): Promise<ILog> => {
    return new Promise<ILog>((resolve, reject) => {

        axiosInstance(addNotification).get(`/logs/${id}`).then(response => {
            //   console.log(response);
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}




// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

export const RequestNewLog = (addNotification:any, user:ILog): Promise<ILog> => {
    return new Promise<ILog>((resolve, reject) => {

        axiosInstance(addNotification).post(`/logs`, user).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
