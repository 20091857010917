import { faBug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import JSONPretty from 'react-json-prettify';

interface IDebugButon {
  data: any;
  alwaysDisplay?: boolean
}
const DebugButton = ({ data, alwaysDisplay }: IDebugButon) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  let isTest = false;
  if (process.env.REACT_APP_MODE !== 'live') {
    isTest = true;
  }



  if (isTest || alwaysDisplay === true) {
    return (<>
      <button type="button" name="e2eDebugButton" onClick={(ev: React.MouseEvent) => {
        ev.preventDefault();
        setShow(true);
      }} className="btn btn-success e2eDebugButton"><FontAwesomeIcon icon={faBug} /></button>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header bsPrefix="debug" closeButton>
          <Modal.Title>Debug</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-left">
            <JSONPretty json={data} />

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>);
  } else {
    return <></>
  }

}

export default DebugButton;