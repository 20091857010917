import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { usePageTitle } from "../../context/pageTitle";
import { useSocket } from "../../context/socket";
//const socket = io();
const att: any[] = [];
const AdminDebug = ({ types }: any) => {
    let match = useRouteMatch();
    let params = useParams();
    const { setPageTitle } = usePageTitle()!;
    useEffect(() => {

        setPageTitle && setPageTitle('Debug', 'admin');

    }, [])



    // if (socket) {
    //     console.log(socket.socket);
    // }

    const [debugs, setDebugs] = useState<any[]>([]);

    const dddd = (something: any) => {
        console.log('something', something, att)
        debugs.push(something)
        att.push(something)
        setDebugs([...debugs]);
    }
    const socket = useSocket();
    useEffect(() => {
        // as soon as the component is mounted, do the following tasks:

        // emit USER_ONLINE event
        //     socket && socket.emit("USER_ONLINE", userId);

        // subscribe to socket events
        if (socket !== undefined) {

            socket.socket && socket.socket.on("request", dddd);
        }
        return () => {
            // before the component is destroyed
            // unbind all event handlers used in this component
            //   socket.off("JOIN_REQUEST_ACCEPTED", handleInviteAccepted);

            if (socket !== undefined) {

                socket.socket && socket.socket.off("request", dddd);
            }
        };
    }, [socket]);
    return (<>

        {debugs && debugs.map((db, index) => {
            return <div key={index} className="col-12">

                {JSON.stringify(db)}
            </div>
        })}

    </>
    );
}






export default AdminDebug;