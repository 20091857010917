
export const pipeFields = [
    { field: 'id', name: 'id', type: 'id' },
    { field: 'name', name: 'name', type: 'text', required: true },
    { field: 'description', name: 'description', type: 'text' },
    { field: 'refId', name: 'refId', type: 'text', required: true },
    { field: 'loop_refId', name: 'loop_refId', required: true, type: 'loop', targetField: 'loopId' },
    // { field: 'systemId', name: 'systemId', required: true,  },    
    { field: 'drawings', name: 'drawings', type: 'text' },
    { field: 'size', name: 'size', type: 'number' },
    { field: 'toStr', name: 'toStr', type: 'toStr' },
    { field: 'fromStr', name: 'fromStr', type: 'fromStr' },
    { field: 'spec', name: 'spec', type: 'text' },
    { field: 'criticalityRating', name: 'criticalityRating', type: 'text' },

    { field: 'operatingTemperatureMin', name: 'operatingTemperatureMin', type: 'number' },
    { field: 'operatingTemperatureMax', name: 'operatingTemperatureMax', type: 'number' },
    { field: 'operatingPressureMin', name: 'operatingPressureMin', type: 'number' },
    { field: 'operatingPressureMax', name: 'operatingPressureMax', type: 'number' },
    { field: 'designPressureMin', name: 'designPressureMin', type: 'number' },
    { field: 'designPressureMax', name: 'designPressureMax', type: 'number' },

    { field: 'designTemperatureMin', name: 'designTemperatureMin', type: 'number' },
    { field: 'designTemperatureMax', name: 'designTemperatureMax', type: 'number' },

    { field: 'materialSpecification', name: 'materialSpecification', type: 'text' },
    { field: 'materialGrade', name: 'materialGrade', type: 'text' },
    { field: 'diameter', name: 'diameter', type: 'number' },
    { field: 'originalWallThickness', name: 'originalWallThickness', type: 'number' },
    { field: 'testingBarg', name: 'testingBarg', type: 'number' },
    { field: 'testingMedium', name: 'testingMedium', type: 'text' },
    { field: 'insulationThk', name: 'insulationThk', type: 'number' },
    { field: 'insulationType', name: 'insulationType', type: 'text' },
    { field: 'paintCode', name: 'paintCode', type: 'text' },
    { field: 'circuitDescription', name: 'circuitDescription', type: 'text' },
    { field: 'pressureRelief', name: 'pressureRelief', type: 'text' },
]

