import arrayMutators from "final-form-arrays";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { RequestGetCollections, RequestNewCollectionBackup } from "../../../api/collections-requests";
import { RequestGetInstallations } from "../../../api/installation-requests";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { useNotifications } from "../../../context/notifications";
import { usePageTitle } from "../../../context/pageTitle";
import { IInstallation } from "../../../interface/installation";




const att: any[] = [];
// const formatBytes = (bytes: number, decimals = 2) => {
//     if (bytes === 0) return '0 Bytes';
//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
//     const i = Math.floor(Math.log(bytes) / Math.log(k));
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
// }

const InstallationExport = ({ types }: any) => {

    const [started, setStarted] = useState(false);
    const { addNotification } = useNotifications()!;

    const [loading, setLoading] = useState<boolean>(true);
    // const [at, setAt] = useState<string>();
    const [things, setThings] = useState<any[]>();
    const [installs, setInstalls] = useState<IInstallation[]>();
    const [startTime, setStart] = useState<Date>();
    const [endTime, setEnd] = useState<Date>();
    const [imports, setImports] = useState<any[]>();
    const { auth } = useAuth()!;
    const { setPageTitle } = usePageTitle()!;
    useEffect(() => {

        setPageTitle && setPageTitle('System', 'admin');

    }, [])

    const [result, setResult] = useState<any>();
    useEffect(() => {
        if (started === false) {
            setStarted(true);

            RequestGetCollections(addNotification, {}).then((collections: any) => {
                console.log(collections);
                setThings(collections.collections.map((sss: any) => {
                    let object = { name: sss.name, backup: true };
                    if (sss.name === 'logs' || sss.name === 'agendaJobs') {
                        object.backup = false;
                    }
                    return object;
                }));

                setImports(collections.import)
                RequestGetInstallations(addNotification).then(thing => {
                    //   console.log(stores)
                    setInstalls(thing);
                    setLoading(false);
                })
            })
        }
    }, [])
    const doBackup = (dd: any) => {



        const ddd = dd.d.map((some: any, inde: number) => {

            if (some.name === 'images') {
                some.query = { assignToType: 'installation', assignToId: dd.installId }
            } else if (some.name === 'installations') {
                some.query = { id: dd.installId }
            } else if (some.name === 'clients') {
                some.query = { id: dd.clientId }
            } else if (some.name === 'files') {
                some.query = { assignToType: 'installation', assignToId: dd.installId }
            } else {
                some.query = { installId: dd.installId }
            }



            return some;
        })

        console.log(ddd);

        RequestNewCollectionBackup(addNotification, { backup: ddd, title: dd.title, id: uuid() }).then(s => {
            console.log("doingbackup", dd);
            setResult(s)
            setStart(new Date(Date.now()))
        })
    }

    // const [debugs, setDebugs] = useState<any[]>([]);

    // const dddd = (something: any) => {
    //     console.log('something', something, att)
    //     //   debugs.push(something)
    //     setAt(something.at);
    //     if (something.at === 'complete') {
    //         setEnd(new Date(Date.now()))
    //     }
    //     const d = { ...result }
    //     if (something.at === 'gzCommand') {
    //         d.gzCommand = something.gzCommand;
    //         setResult(d)
    //     }
    //     att.push(something)
    //     //setDebugs([...debugs]);
    // }


    return (<>

        {(loading === false && things) ? <>
            <Helmet>
                <title>Pipe / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>


            {result === undefined ? <>
                <h2>Export</h2><Form <any>
                    onSubmit={doBackup}
                    initialValues={{ d: things }}
                    mutators={{
                        ...arrayMutators,
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        //do if altered browse notifcation
                        //  console.log(submitting, pristine)

                        return <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <button type="button" className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        const d = values.d.map((ddd: any) => {
                                            ddd.backup = false;
                                            return ddd;
                                        })

                                        form.change('d', d)
                                    }}>Deselect All</button>

                                    <DebugButton data={values} />

                                    <Field name="title">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Title</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name={`installId`}>
                                        {({ input, meta }) => (
                                            <div>

                                                <select className="form-control" {...input}>
                                                    <option>*</option>
                                                    {installs && installs.map((inst, inde) => {
                                                        return <option value={inst.id} key={inde}>{inst.name}</option>
                                                    })}
                                                </select>
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <OnChange name="installId">
                                        {(value, previous) => {
                                            // do something

                                            if (installs && values.installId && value !== null) {
                                                const install = installs.find(dd => dd.id === values.installId)
                                                form.change('installation', install)
                                                form.change('clientId', install && install.clientId)
                                            }
                                        }}
                                    </OnChange>
                                    <button className="btn btn-primary" type="submit">Save</button>
                                </div>

                            </div>

                        </form>

                    }} />

            </> : <div className="text-left row">
                <Redirect to="/admin/backupMonitor" />
            </div>}

        </> : <Loading />}
    </>
    );
}


const InstallationExportPage = ({ }: any) => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            {/* <Route path={`${match.path}/:id`}>
                <AdminLog />
            </Route> */}
            <Route path={`${match.path}`} exact>
                <InstallationExport />
            </Route>

        </Switch>
    </>
    );
}





export default InstallationExportPage;





