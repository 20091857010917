import { IContact } from "../interface/contact";
import axiosInstance from "./instance";

export const RequestGetContacts = (addNotification: any, data?: any): Promise<IContact[]> => {
    return new Promise<IContact[]>((resolve, reject) => {
        let url = `/contacts/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetContact = (addNotification: any, id: string): Promise<IContact> => {
    return new Promise<IContact>((resolve, reject) => {

        axiosInstance(addNotification).get(`/contacts/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewContact = (addNotification: any, thing: IContact): Promise<IContact> => {
    return new Promise<IContact>((resolve, reject) => {

        axiosInstance(addNotification).post(`/contacts/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteContact = (addNotification: any, thing: IContact): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/contacts/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateContact = (addNotification: any, user: IContact): Promise<IContact> => {
    return new Promise<IContact>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/contacts/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestContactFunction = (addNotification: any, func:string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/contacts/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }
    export const RequestNewContactPublic = (addNotification: any, thing: IContact): Promise<IContact> => {
        return new Promise<IContact>((resolve, reject) => {
    
            axiosInstance(addNotification).post(`/contacts/public`, thing).then(response => {
                //   console.log(response);
                if (response === undefined) reject();
                return response.data;
            }).then(response => {
                console.log(response);
                resolve(response)
    
            }).catch(exc => {
                console.log(exc);
                reject(exc);
            });
        })
    }
    
        // eslint-disable-next-line
        {/*<!-- OPTIONAL END -->*/ }
    
    
