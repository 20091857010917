import { faFilePdf, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Nav, Tab, Tabs } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import { Helmet } from 'react-helmet';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { RequestGetAnomalys } from '../../api/anomaly-requests';
import { RequestEquipmentFunction, RequestGetEquipments } from '../../api/equipment-requests';
import { RequestGetInspections } from '../../api/inspection-requests';
import { RequestGetInstallationLoops, RequestInstallationLoopFunction } from '../../api/installation-loop-requests';
import { RequestGetInstallation } from '../../api/installation-requests';
import { RequestGetInstallationSystems } from '../../api/installation-system-requests';
import { RequestGetPipes } from '../../api/pipe-requests';
import { RefreshPage } from '../../components/RefreshPage';
import DataTable from '../../components/system/DataTable';
import DateDisplay from '../../components/system/DateDisplay';
import DebugButton from '../../components/system/DebugButton';
import Loading from '../../components/system/loading';
import { useAuth } from '../../context/auth';
import { useNotifications } from '../../context/notifications';
import { usePageTitle } from '../../context/pageTitle';
import { IAnomaly } from '../../interface/anomaly';
import { IEquipment } from '../../interface/equipment';
import { IInspection } from '../../interface/inspection';
import { IInstallation } from '../../interface/installation';
import { IInstallationLoop } from '../../interface/installation-loop';
import { IInstallationSystem } from '../../interface/installation-system';
import { IPipe } from '../../interface/pipe';
import InstallationDeferrals from './ InstallationDeferrals';
import Anomaly from './Anomaly';
import Equipment from './Equipment';
import Inspection from './Inspection';
import InstalaltionInspectionSchedule from './InstalaltionInspectionSchedule';
import InstallationHome from './InstallationHome';
import Loop from './Loop';
import Pipe from './Pipe';
import System from './System';

export const NoResults = () => {
    return <>No Results</>
}
const Installation = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [thing, setThing] = useState<IInstallation>();

    const [inspections, setInspections] = useState<IInspection[]>();
    const [loops, setLoops] = useState<IInstallationLoop[]>();
    const [pipes, setPipes] = useState<IPipe[]>();
    const [equipment, setEquipment] = useState<IEquipment[]>();
    const [systems, setSystems] = useState<IInstallationSystem[]>();
    const [anomalys, setAnomalys] = useState<IAnomaly[]>();

    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const topRef = useRef<any>()

    const { auth } = useAuth()!;
    const [tab, setTab] = useState('');


    const { setPageTitle } = usePageTitle()!;






    useEffect(() => {
        const params: any = match.params;
        console.log("useParams", params);
        setTab(params.page)



    }, [match])


    useEffect(() => {

        const params: any = match.params;
        console.log("get reports", params);
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            // const report = (): Promise<any> => {
            const prodCall = () => {
                RequestGetInstallation(addNotification, params.id)
                    .then((json: IInstallation) => {
                        console.log(json);
                        setThing(json);
                        setPageTitle && setPageTitle(json.name);

                    })
            }
            const inspect = () => {
                RequestGetInspections(addNotification, { installId: params.id })
                    .then((json: IInspection[]) => {
                        console.log(json);
                        setInspections(json);

                    })
            }
            const loops = () => {
                RequestGetInstallationLoops(addNotification, { installId: params.id })
                    .then((json: IInstallationLoop[]) => {
                        console.log(json);
                        setLoops(json);

                    })
            }
            const pipes = () => {
                RequestGetPipes(addNotification, { installId: params.id })
                    .then((json: IPipe[]) => {
                        console.log(json);
                        setPipes(json);

                    }).catch(ex => {
                        console.log("PIPES", ex)
                    })
            }
            const equip = () => {
                RequestGetEquipments(addNotification, { installId: params.id })
                    .then((json: IEquipment[]) => {
                        console.log(json);
                        setEquipment(json);

                    })
            }
            const systems = () => {
                RequestGetInstallationSystems(addNotification, { installId: params.id })
                    .then((json: IInstallationSystem[]) => {
                        console.log(json);
                        setSystems(json);

                    })
            }
            // const drawings = () => {
            //     RequestGetDrawings(addNotification, { installationId: params.id })
            //         .then((json: IDrawing[]) => {
            //             console.log(json);
            //             setDrawings(json);

            //         })
            // }
            const anomaly = () => {
                RequestGetAnomalys(addNotification, { installId: params.id })
                    .then((json: IAnomaly[]) => {
                        console.log(json);
                        setAnomalys(json);

                    })
            }
            Promise.allSettled([prodCall(), inspect(), loops(), pipes(), equip(), systems(), anomaly()]).then(() => {
                setLoading(false)
            });


        }
    }, [])

    return (<>

        {thing && thing !== null && <Helmet>
            <title>{thing.name} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
        </Helmet>}
        <div className="col-12" ref={topRef}>



            {(loading === false && thing && thing !== null) ? <Switch>
                <Route path={`${match.path}/:id2`} exact>
                    {tab === 'system' && <System />}
                    {tab === 'pipe' && <Pipe />}
                    {tab === 'loop' && <Loop />}
                    {tab === 'equipment' && <Equipment />}
                    {tab === 'inspection' && <Inspection />}
                    {tab === 'anomaly' && <Anomaly />}

                </Route>
                <Route path={`${match.path}`} exact>
                    <div className="col-12 d-flex justify-content-between"> <RefreshPage />
                        <Nav className="mr-auto installationNav">

                            <Nav.Link as={Link} id="e2eTabHome" className={`btn btn-link ${tab === 'homne' && 'active'}`} to={`/installations/${thing.id}/home`}>Dashboard</Nav.Link>
                            <Nav.Link as={Link} id="e2eTabSystems" className={`btn btn-link ${tab === 'systems' && 'active'}`} to={`/installations/${thing.id}/systems`}>Systems</Nav.Link>
                            <Nav.Link as={Link} id="e2eTabLoops" className={`btn btn-link ${tab === 'loops' && 'active'}`} to={`/installations/${thing.id}/loops`}>Loops</Nav.Link>
                            <Nav.Link as={Link} id="e2eTabPipes" className={`btn btn-link ${tab === 'pipes' && 'active'}`} to={`/installations/${thing.id}/pipes`}>Pipes</Nav.Link>
                            <Nav.Link as={Link} id="e2eTabEquipment" className={`btn btn-link ${tab === 'equipment' && 'active'}`} to={`/installations/${thing.id}/equipment`}>Equipment</Nav.Link>
                            <Nav.Link as={Link} id="e2eTabInspections" className={`btn btn-link ${tab === 'inspections' && 'active'}`} to={`/installations/${thing.id}/inspections`}>Inspections</Nav.Link>


                            {/* 
                            
                            <Nav.Link as={Link} className={`btn btn-link ${tab === 'inspectionScheduleSystem' && 'active'}`} to={`/installations/${thing.id}/inspectionScheduleSystem`}>Schedule System</Nav.Link>
                            <Nav.Link as={Link} className={`btn btn-link ${tab === 'inspectionScheduleLoops' && 'active'}`} to={`/installations/${thing.id}/inspectionScheduleLoops`}>Schedule Loops</Nav.Link>
                            */}
                            <Nav.Link as={Link} className={`btn btn-link ${tab === 'anomalies' && 'active'}`} to={`/installations/${thing.id}/anomalies`}>Anomalies</Nav.Link>

                            <Nav.Link as={Link} className={`btn btn-link ${tab === 'inspectionSchedule' && 'active'}`} to={`/installations/${thing.id}/inspectionSchedule`}>Schedule</Nav.Link>        <Nav.Link as={Link} className={`btn btn-link ${tab === 'deferrals' && 'active'}`} to={`/installations/${thing.id}/deferrals`}>Inspection Deferrals</Nav.Link>
                        </Nav>
                    </div>
                    <Tabs className="specialCase" defaultActiveKey="home" activeKey={tab} onSelect={(dd) => {
                        //   history.push(`../${thing.id}/${tab}`)
                    }} id="uncontrolled-tab-example">
                        <Tab eventKey="home" title="Dashboard">
                            <h2>Dashboard</h2>
                            <DebugButton data={thing} />
                            {pipes && equipment && anomalys && <InstallationHome anomalys={anomalys} data={thing} pipesAll={pipes} equipmentsAll={equipment} />}
                        </Tab>
                        <Tab eventKey="systems" title="Systems">
                            <h2>Systems</h2>
                            {systems && systems.length === 0 ? <NoResults /> : <> {systems && <DataTable
                                defaultSort="refId"
                                rowClass="systems"
                                hideIndexCol={true}
                                colHeadings={[
                                    // { name: "ID", field: "refId", sortable: true },
                                    { name: "Name", field: "name", sortable: true },
                                    {
                                        name: "Contains", field: "id", r: (value: any, index) => {
                                            return <>Pipes: {value.pipes && value.pipes.length} / Equipments: {value.equipment && value.equipment.length}</>;
                                        }, sortable: false
                                    },
                                    {
                                        name: "Anomalies", field: "id", r: (value: any, index) => {
                                            return <> {value.anomalys.length > 0 ? <> Open: {value.anomalys.filter((sss: any) => sss.type = 'new').length} / Closed: {value.anomalys.filter((sss: any) => sss.type = 'closed').length}</> : <>Open: 0 / Closed: 0</>}</>;
                                        }, sortable: false
                                    },
                                    {
                                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                <Link className="btn btn-primary" to={`/installations/${value.installId}/system/${value.id}`}>Details</Link>


                                            </div>;
                                        }, sortable: false
                                    },

                                ]}

                                data={systems}
                            />}</>}
                        </Tab>
                        <Tab eventKey="loops" title="Loops">
                            <h2>Loops</h2>
                            <span className="e2eClientInstallationLoops" style={{ display: 'none' }}>TestOk</span>
                            {loops && loops.length === 0 ? <NoResults /> : <> {loops && <DataTable
                                defaultSort="refId"
                                rowClass="loops"
                                hideIndexCol={true}
                                colHeadings={[
                                    // { name: "ID", field: "refId", sortable: true },
                                    { name: "Name", field: "name", sortable: true },
                                    {
                                        name: "System", field: "systemId", sortable: true, r: (value: any, index) => {
                                            return <>{value.system && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/system/${value.systemId}`}>{value.system.refId}</Link>}</>;
                                        }
                                    },

                                    {
                                        name: "Contains", field: "id", r: (value: any, index) => {
                                            return <>Pipes: {value.pipes && value.pipes.length} </>;
                                        }, sortable: false
                                    },
                                    {
                                        name: "Anomalies", field: "id", r: (value: any, index) => {
                                            return <> {value.anomalys.length > 0 ? <> Open: {value.anomalys.filter((sss: any) => sss.type = 'new').length} / Closed: {value.anomalys.filter((sss: any) => sss.type = 'closed').length}</> : <>Open: 0 / Closed: 0</>}</>;
                                        }, sortable: false
                                    },
                                    { name: "Likelihood", field: "likelihoodAZ", sortable: false },
                                    { name: "Consequence", field: "combinedConsequenceAZ", sortable: false },
                                    {
                                        name: "Risk", field: "riskAZ", sortable: true,
                                    },
                                    {
                                        name: "Fluid", field: "serviceFluidName", sortable: true,
                                    },
                                    {
                                        name: "Material", field: "materialName", sortable: true,
                                    },
                                    {
                                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                <button className="btn btn-info" id={`e2e${index - 1}WSE`} type="button" onClick={(ev: React.MouseEvent) => {
                                                    ev.preventDefault();
                                                    RequestInstallationLoopFunction(addNotification, 'pdf', value.id).then(something => {
                                                        //       console.log("jhk__________", something.payload);
                                                        const linkSource = `data:application/pdf;base64,${something.payload}`;
                                                        const downloadLink = document.createElement("a");


                                                        downloadLink.href = linkSource;
                                                        downloadLink.download = "loop.pdf";
                                                        downloadLink.click();
                                                    }).catch(Errr => {
                                                        console.log("err", Errr)
                                                    })
                                                }}><FontAwesomeIcon icon={faFilePdf} /></button>
                                                <Link className="btn btn-primary" id={`e2e${index - 1}LoopDetails`} to={`/installations/${value.installId}/loop/${value.id}`}>Details</Link>


                                            </div>;
                                        }, sortable: false
                                    },

                                ]}

                                data={loops}
                            />}</>}
                        </Tab>

                        <Tab eventKey="pipes" title="Pipes">
                            <h2>Pipes</h2>
                            {pipes && pipes.length === 0 ? <NoResults /> : <> {pipes && <DataTable

                                rowClass="pipes"
                                defaultSort="refId"
                                hideIndexCol={true}
                                colHeadings={[

                                    // { name: "ID", field: "refId", sortable: true },
                                    { name: "Name", field: "name", sortable: true },
                                    { name: "Description", field: "description", sortable: true },
                                    {
                                        name: "System", field: "systemIdNumber2", sortable: true, r: (value: any, index) => {
                                            return <>{value.system && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/system/${value.systemId}`}>{value.system.refId}</Link>}</>;
                                        }
                                    },

                                    {
                                        name: "Loop", field: "loopIdNumber", r: (value: any, index) => {
                                            return <>{value.loop && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/loop/${value.loopId}`}>{value.loop.refId}</Link>}</>;
                                        }, sortable: true
                                    },


                                    {
                                        name: "Risk", field: "riskAZ", sortable: true,
                                    },
                                    {
                                        name: "Fluid", field: "serviceFluidName", sortable: true,
                                    },
                                    {
                                        name: "Material", field: "materialName", sortable: true,
                                    },
                                    { name: "Size", field: "size", sortable: true, queryable: true },

                                    {
                                        name: "From", field: "fromStr", r: (value: any, index, addToQuery) => {
                                            return <>{value.fromStr}
                                                {value.fromEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.fromEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                                {value.fromPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.fromPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                            </>
                                        }, sortable: true
                                    },
                                    {
                                        name: "To", field: "toStr", r: (value: any, index, addToQuery) => {
                                            return <>{value.toStr}
                                                {value.toEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.toEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                                {value.toPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.toPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}

                                            </>

                                        }, sortable: true
                                    },
                                    {
                                        name: "Anomalies", field: "newAnomalies", r: (value: any, index) => {
                                            return <>New {value.newAnomalies} / Closed: {value.closedAnomalies}</>;
                                        }, sortable: true
                                    },

                                    {
                                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                <Link className="btn btn-primary" id={`e2e${index - 1}PipeDetails`} to={`/installations/${thing.id}/pipe/${value.id}`}>Details</Link>

                                            </div>;
                                        }, sortable: false
                                    },

                                ]}

                                data={pipes}
                            />}</>}
                        </Tab>
                        <Tab eventKey="equipment" title="Equipment">
                            <h2>Equipment</h2>
                            {equipment && equipment.length === 0 ? <NoResults /> : <> {equipment && <DataTable
                                defaultSort="refId"
                                rowClass="equipment"
                                hideIndexCol={true}
                                colHeadings={[
                                    { name: "Name", field: "name", sortable: true },
                                    // { name: "ID", field: "refId", sortable: true },
                                    {
                                        name: "System", field: "systemIdNumber2", sortable: true, r: (value: any, index) => {
                                            return <>{value.system && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/system/${value.systemId}`}>{value.system.refId}</Link>}</>;
                                        }
                                    },


                                    { name: "Type", field: "typeName", sortable: true },
                                    { name: "Fluid", field: "serviceFluidName", sortable: true, queryable: true },
                                    {
                                        name: "Material", field: "materialName", sortable: true,
                                    },

                                    {
                                        name: "Anomalies", field: "id", r: (value: any, index) => {
                                            return <> {value.anomalys.length > 0 ? <> Open: {value.anomalys.filter((sss: any) => sss.type === 'new').length} / Closed: {value.anomalys.filter((sss: any) => sss.type === 'closed').length}</> : <>Open: 0 / Closed: 0</>}</>;
                                        }, sortable: true
                                    },

                                    { name: "Likelihood", field: "likelihoodAZ", sortable: false },
                                    { name: "Consequence", field: "combinedConsequenceAZ", sortable: false },
                                    {
                                        name: "Risk", field: "riskAZ", sortable: true,
                                    },
                                    {
                                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                <button className="btn btn-info" type="button" onClick={(ev: React.MouseEvent) => {
                                                    ev.preventDefault();
                                                    RequestEquipmentFunction(addNotification, 'pdf', value.id).then(something => {
                                                        //      console.log("jhk__________", something);



                                                        const linkSource = `data:application/pdf;base64,${something.payload}`;
                                                        const downloadLink = document.createElement("a");


                                                        downloadLink.href = linkSource;
                                                        downloadLink.download = "equipment.pdf";
                                                        downloadLink.click();
                                                    }).catch(Errr => {
                                                        console.log("err", Errr)
                                                    })
                                                }}><FontAwesomeIcon icon={faFilePdf} /></button>
                                                <Link className="btn btn-primary" id={`e2e${index - 1}EquipmentDetails`} to={`/installations/${value.installId}/equipment/${value.id}`}>Details</Link>

                                            </div>;
                                        }, sortable: false
                                    },

                                ]}

                                data={equipment}
                            />}</>}
                        </Tab>
                        <Tab eventKey="inspections" title="Inspections">
                            <h2>Inspections</h2>
                            {inspections && inspections.length === 0 ? <NoResults /> : <>  {inspections && <DataTable
                                defaultSort="refId"
                                rowClass="inspections"
                                hideIndexCol={true}
                                colHeadings={[
                                    // { name: "ID", field: "refId", sortable: true },
                                    { name: "Subject", field: "subject", sortable: true },
                                    {
                                        name: "Date", field: "dateOfReport", r: (value: any, index) => {
                                            return <DateDisplay format="YYYY-MM-DD" date={value.dateOfReport} />;
                                        }, sortable: true
                                    },
                                    {
                                        name: "Items", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">

                                                {value.reported && value.reported.map((rep: any, index: number) => <>
                                                    {rep.loop && <Link className="btn btn-outline-primary" to={`/installations/${thing.id}/equipment/${rep.loopId}`}>Loop: {rep.loop.refId}</Link>}


                                                    {rep.pipe && <Link className="btn btn-outline-primary" to={`/installations/${thing.id}/pipe/${rep.pipeId}`}>Pipe: {rep.pipe.refId}</Link>}
                                                    {rep.equipment && <Link className="btn btn-outline-primary" to={`/installations/${thing.id}/equipment/${rep.equipmentId}`}>Equipment: {rep.equipment.refId}</Link>}


                                                </>)}


                                            </div>;
                                        }, sortable: true
                                    },
                                    {
                                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                <Link className="btn btn-primary" to={`/installations/${value.installId}/inspection/${value.id}`}>Details</Link>


                                            </div>;
                                        }, sortable: false
                                    },

                                ]}

                                data={inspections}
                            />}</>}

                        </Tab>
                        <Tab eventKey="inspectionSchedule" title="inspectionSchedule">
                            <h2>Inspection Schedule</h2>
                            {loops && equipment && <InstalaltionInspectionSchedule data={thing} loops={loops} equipment={equipment} />}
                        </Tab>
                        <Tab eventKey="deferrals" title="deferrals">
                            <h2>Inspection Deferrals</h2>
                            {loops && equipment && <InstallationDeferrals data={thing} loops={loops} equipment={equipment} />}
                        </Tab>
                        {/* 
                        <Tab eventKey="inspectionScheduleEquipment" title="inspectionScheduleEquipment">
                            <h2>Inspection Schedule Equipment</h2>
                            {equipment && <InstalaltionInspectionScheduleEquipment data={thing} equipment={equipment} />}
                        </Tab>
                        <Tab eventKey="inspectionScheduleSystem" title="inspectionScheduleSystem">
                            <h2>Inspection Schedule Systems</h2>
                            {systems && <InstalaltionInspectionScheduleSystem data={thing} systems={systems} />}
                        </Tab> */}
                        <Tab eventKey="anomalies" title="Anomalies">
                            <h2>Anomalies</h2>
                            {anomalys && anomalys.length === 0 ? <NoResults /> : <>{anomalys && <DataTable

                                hideIndexCol={true}
                                colHeadings={[
                                    // { name: "ID", field: "refId", sortable: true },
                                    { name: "Subject", field: "subject", sortable: true },
                                    { name: "Status", field: "status", sortable: true },
                                    {
                                        name: "Date", field: "date", r: (value: any, index) => {
                                            return <DateDisplay format="YYYY-MM-DD" date={value.date} />;
                                        }, sortable: true
                                    },
                                    {
                                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                <Link className="btn btn-primary" to={`/installations/${value.installId}/anomaly/${value.id}`}>Details</Link>
                                                {value.pipe && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.pipeId}`}>Pipe: {value.pipe.refId}</Link>}
                                                {value.equipment && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.equipmentId}`}>Equipment: {value.equipment.refId}</Link>}



                                            </div>;
                                        }, sortable: false
                                    },

                                ]}

                                data={anomalys}
                            />}</>}
                        </Tab>

                    </Tabs>
                </Route>
            </Switch> : <Loading />}



        </div>





    </>
    );
}

export default Installation;