import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";
import DebugButton from "../../../components/system/DebugButton";
import { useAuth } from "../../../context/auth";
import { useNotifications } from "../../../context/notifications";
import { usePageTitle } from "../../../context/pageTitle";
import { useSocket } from "../../../context/socket";
import { ILog } from "../../../interface/log";




const att: any[] = [];
let humm: IBackupInstance[] = [];


interface IBackupInstance {
    id: string;
    state: string;
    log: any[];
    started: Date;
    finished?: Date;
}

const AdminCollectionStatus = ({ types }: any) => {
    let match = useRouteMatch();
    let params = useParams();
    const [started, setStarted] = useState(false);
    const { addNotification } = useNotifications()!;
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);
    const [logs, setLogs] = useState<ILog[]>();
    const [at, setAt] = useState<string>();
    const [things, setThings] = useState<any[]>();
    const [startTime, setStart] = useState<Date>();
    const [endTime, setEnd] = useState<Date>();
    const [imports, setImports] = useState<any[]>();
    const { auth } = useAuth()!;
    const { setPageTitle } = usePageTitle()!;
    useEffect(() => {

        setPageTitle && setPageTitle('System', 'admin');

    }, [])

    const [result, setResult] = useState<any>();

    const [processs, setProcesss] = useState<IBackupInstance[]>([]);




    const [debugs, setDebugs] = useState<any[]>([]);

    const dddd = (something: any) => {
        console.log('something', something)
        //   debugs.push(something)
        ///  setAt(something.at);
        if (something.at === 'complete') {
            setEnd(new Date(Date.now()))
        }
        const d = { ...result }
        if (something.at === 'gzCommand') {
            d.gzCommand = something.gzCommand;
            setResult(d)
        }
        att.push(something)
        //setDebugs([...debugs]);

        console.log(humm)
        const array: IBackupInstance[] = [...humm]
        const processIndex = array.findIndex(dd => dd.id === something.id)

        if (processIndex === -1) {
            array.push({ id: something.id, log: [{ ...something }], state: something.at, started: new Date(Date.now()) })
        } else {

            array[processIndex].state = something.at;
            array[processIndex].log.push(something)
            if (something.at === 'complete') {
                array[processIndex].finished = new Date(Date.now())
            }
        }



        humm = array;

        console.log(processIndex)


    }


    const socket = useSocket();
    useEffect(() => {
        // as soon as the component is mounted, do the following tasks:

        // emit USER_ONLINE event
        //     socket && socket.emit("USER_ONLINE", userId);

        // subscribe to socket events
        if (socket !== undefined) {

            socket.socket && socket.socket.on("syscollection_progress", dddd);
        }
        return () => {
            // before the component is destroyed
            // unbind all event handlers used in this component
            //   socket.off("JOIN_REQUEST_ACCEPTED", handleInviteAccepted);

            if (socket !== undefined) {

                socket.socket && socket.socket.off("syscollection_progress", dddd);
            }
        };
    }, [socket]);

    let duration = '';
    if (endTime && startTime) {
        duration = moment.duration(moment(endTime).diff(moment(startTime))).humanize()
    }





    return (<>


        <Helmet>
            <title>Backup Process Monitor / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
        </Helmet>
        <div className="row">
            {humm.map((pro, index) => {
                return <div className="col-12" key={index}>
                    <div className="d-flex justify-content-between">
                        <h2>{pro.id}</h2>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div> {pro.state}</div>


                        <div> {pro.state === 'complete' ? <> ,
                            {moment.duration(moment(pro.finished).diff(moment(pro.started))).humanize()}
                        </> : <>
                            {moment.duration(moment().diff(moment(pro.started))).humanize()}
                        </>}</div>


                        <div>{pro.log.length} <DebugButton alwaysDisplay={true} data={pro.log} /></div>
                    </div>



                </div>
            })}

        </div>




    </>

    );
}


const AdminCollectionStatusPage = ({ }: any) => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            {/* <Route path={`${match.path}/:id`}>
                <AdminLog />
            </Route> */}
            <Route path={`${match.path}`} exact>
                <AdminCollectionStatus />
            </Route>

        </Switch>
    </>
    );
}





export default AdminCollectionStatusPage;





