import { IInstallationSystem } from "../interface/installation-system";
import axiosInstance from "./instance";

export const RequestGetInstallationSystems = (addNotification: any, data?: any): Promise<IInstallationSystem[]> => {
    return new Promise<IInstallationSystem[]>((resolve, reject) => {
        let url = `/installationSystems/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetInstallationSystem = (addNotification: any, id: string): Promise<IInstallationSystem> => {
    return new Promise<IInstallationSystem>((resolve, reject) => {

        axiosInstance(addNotification).get(`/installationSystems/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewInstallationSystem = (addNotification: any, thing: IInstallationSystem): Promise<IInstallationSystem> => {
    return new Promise<IInstallationSystem>((resolve, reject) => {

        axiosInstance(addNotification).post(`/installationSystems/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteInstallationSystem = (addNotification: any, thing: IInstallationSystem): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/installationSystems/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateInstallationSystem = (addNotification: any, user: IInstallationSystem): Promise<IInstallationSystem> => {
    return new Promise<IInstallationSystem>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/installationSystems/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestInstallationSystemFunction = (addNotification: any, func: string, id: string): Promise<any> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/installationSystems/_func/${func}/${id}`;

        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
