import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMutators from 'final-form-arrays';
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from "react-final-form-listeners";
import { Helmet } from "react-helmet";
import { Link, useRouteMatch } from "react-router-dom";
import { RequestGetAnomalys } from "../../api/anomaly-requests";
import { RequestGetInspections } from "../../api/inspection-requests";
import { RequestGetInstallationLoop, RequestNewInstallationLoop, RequestUpdateInstallationLoop } from "../../api/installation-loop-requests";
import { RequestNewLog } from "../../api/log-requests";
import { RequestGetPipes } from "../../api/pipe-requests";
import DateField from "../../components/FormStuff/date";
import DataTable from "../../components/system/DataTable";
import DateDisplay from "../../components/system/DateDisplay";
import DebugButton from "../../components/system/DebugButton";
import { useAuth } from "../../context/auth";
import { ENotificationType, useNotifications } from "../../context/notifications";
import { IAnomaly } from "../../interface/anomaly";
import { IInspection } from "../../interface/inspection";
import { IInstallationLoop } from "../../interface/installation-loop";
import { ELogType, ESeverity } from "../../interface/log";
import { IPipe } from "../../interface/pipe";
import { AttributeBlob } from "./_AttributeBlob";
import { DisplayInspectionType } from "./_DisplayInspecitonType";
import { InspectionDisplay } from "./_InspectionsDisplay";
const Loop = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [thing, setThing] = useState<IInstallationLoop>();
    const [anomalys, setAnomalys] = useState<IAnomaly[]>();

    const [inspections, setInspections] = useState<IInspection[]>();
    const [pipes, setPipes] = useState<IPipe[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const topRef = useRef<any>()

    const { auth } = useAuth()!;
    useEffect(() => {
        console.log("get reports");
        const params: any = match.params;
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            // const report = (): Promise<any> => {
            console.log("get reports", params);
            // setId(params.id2)
            const prodCall = () => {
                RequestGetInstallationLoop(addNotification, params.id2)
                    .then((json: IInstallationLoop) => {
                        console.log(json);
                        setThing(json);
                        setLoading(false);
                    })
            }
            const inspect = () => {
                RequestGetInspections(addNotification, { loopId: params.id2 })
                    .then((json: IInspection[]) => {
                        console.log(json);
                        setInspections(json);
                        setLoading(false);
                    })
            }
            const anomaly = () => {
                RequestGetAnomalys(addNotification, { loopId: params.id2 })
                    .then((json: IAnomaly[]) => {
                        console.log(json);
                        setAnomalys(json);

                    })
            }
            const pipe = () => {
                RequestGetPipes(addNotification, { loopId: params.id2 })
                    .then((json: IPipe[]) => {
                        console.log(json);
                        setPipes(json);

                    })
            }
            Promise.allSettled([prodCall(), inspect(), anomaly(), pipe()]).then(() => {
                setLoading(false)
            });


        }
    }, [])

    const onSubmit = (data: any) => {
        try {
            //  setLoading(true)
            //   setFormChanged(false)
            let ppp: Promise<IInstallationLoop>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'InstallationLoop Created!';
                ppp = RequestNewInstallationLoop(addNotification, data);
            } else {
                notificationText = 'InstallationLoop Saved!';
                ppp = RequestUpdateInstallationLoop(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);

                setLoading(false)

                window.location.reload();
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - InstallationLoop - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    return (<>
        {(loading === false && thing && thing !== null) && <>
            <Helmet>
                <title>{thing.refId} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <div className="col-12" ref={topRef}>
                <span className="e2eClientLoop" style={{ display: 'none' }}>TestOk</span>
                <div className="text-left">
                    <Link to={`/installations/${thing.installId}/home`}>{thing.installation && thing.installation.name}</Link>
                    {' >'} Loop : {thing.refId}
                </div>
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="Description">
                        <span className="e2eClientLoopDescription" style={{ display: 'none' }}>TestOk</span>
                        <DebugButton data={thing} />
                        <div className="row">
                            <h2>Description</h2>

                            <AttributeBlob data={thing} title="Ref ID" field="refId" />
                            <AttributeBlob data={thing} title="Name" field="name" />
                            <AttributeBlob data={thing} title="Description" field="description" />
                            <AttributeBlob data={thing} title="Commission Date" field="commissionedDate" special="date" />
                            <AttributeBlob data={thing} title="Material" field="materialName" />
                            <AttributeBlob data={thing} title="Service Fluid" field="serviceFluidName" />
                            <AttributeBlob data={thing} title="External Coating" field="externalCoating" />
                            <AttributeBlob data={thing} title="Risk" field="riskAZ" />
                            <AttributeBlob data={thing} title="Likelihood" field="likelihoodAZ" />
                            <AttributeBlob data={thing} title="Combined Consequence" field="combinedConsequenceAZ" />
                            <AttributeBlob data={thing} title="Required Inspection Types" field="inspectionTypeIdsStr" />

                            <div className="col-3 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Insulated</b>
                                    <p className="e2e_insulation">{thing.insulation === true ? 'Yes' : 'No'}</p>
                                </div>
                            </div>
                            <AttributeBlob data={thing} title="Corrosion Allowance" field="corrosionAllowance" />
                            <AttributeBlob data={thing} title="Design Code" field="designCode" />


                        </div>
                    </Tab>
                    <Tab eventKey="LOF" title="Integrity Threat Assessment">
                        <span className="e2eClientLoopDescription" style={{ display: 'none' }}>TestOk</span>
                        <DebugButton data={thing} />
                        <div className="row">
                            <h2>Internal</h2>
                            <AttributeBlob data={thing} title="Co2 LOF" field="itaIntCo2LOF" />
                            <AttributeBlob data={thing} title="Co2 Comments" field="itaIntCo2Comments" />
                            <AttributeBlob data={thing} title="PWC LOF" field="itaIntPWCLOF" />
                            <AttributeBlob data={thing} title="PWC Comments" field="itaIntPWCComments" />
                            <AttributeBlob data={thing} title="Erosion LOF" field="itaIntErosionLOF" />
                            <AttributeBlob data={thing} title="Erosion Comments" field="itaIntErosionComments" />
                            <AttributeBlob data={thing} title="Crevice Corrosion LOF" field="itaIntCreviceCorrosionLOF" />
                            <AttributeBlob data={thing} title="Crevice Corrosion Comments" field="itaIntCreviceCorrosionComments" />
                            <AttributeBlob data={thing} title="Under Deposit Corrosion LOF" field="itaIntUnderDepositCorrosionLOF" />
                            <AttributeBlob data={thing} title="Under Deposit Corrosion Comments" field="itaIntUnderDepositCorrosionComments" />
                            <AttributeBlob data={thing} title="MIC LOF" field="itaIntMICLOF" />
                            <AttributeBlob data={thing} title="MIC Comments" field="itaIntMICComments" />

                            <h2>External</h2>
                            <AttributeBlob data={thing} title="AtmosphericCorrosion LOF" field="itaExtAtmosphericCorrosionLOF" />
                            <AttributeBlob data={thing} title="Atmospheric Corrosion Comments" field="itaExtAtmosphericCorrosionComments" />
                            <AttributeBlob data={thing} title="CUI LOF" field="itaExtCUILOF" />
                            <AttributeBlob data={thing} title="CUI Comments" field="itaExtCUIComments" />
                            <AttributeBlob data={thing} title="Corrosion Under Support LOF" field="itaExtCorrosionUnderSupportLOF" />
                            <AttributeBlob data={thing} title="Corrosion Under Support Comments" field="itaExtCorrosionUnderSupportComments" />





                        </div>
                    </Tab>
                    <Tab eventKey="inspectionTypes" title="Inspection Types">

                        <div className="row">
                            <h2>Inspection Types</h2>
                            <span className="e2eClientLoopInspectionType" style={{ display: 'none' }}>TestOk</span>
                            <Form
                                onSubmit={onSubmit}
                                initialValues={thing}
                                mutators={{
                                    // setValue: ([field, value], state, { changeValue }) => {
                                    //     console.log(state, field, value)
                                    //     changeValue(state, field, () => value)
                                    // },
                                    ...arrayMutators
                                }}
                                render={({ handleSubmit, form, submitting, pristine, values }) => {
                                    //do if altered browse notifcation
                                    //  console.log(submitting, pristine)

                                    return <form onSubmit={handleSubmit}>
                                        <DebugButton data={values} />
                                        <div className="row">

                                            <FieldArray name="inspectionTypesPlannedDates">
                                                {({ fields }) => {
                                                    return <div className="col-12 row">
                                                        {fields.map((name, index) => {
                                                            const type = values.inspectionTypesPlannedDates[index];
                                                            return <div key={index} className="col-4">
                                                                <DisplayInspectionType type={type} />
                                                                <h4>Set Planned Date : </h4>
                                                                <OnChange name={`${name}.formDate`}>
                                                                    {(value, previous) => {
                                                                        // do something
                                                                        //  console.log(value);
                                                                        const ddd = [...values.inspectionTypesPlannedDates];

                                                                        const myMomentObject: Date = moment(value, 'DD-MM-YYYY').toDate();
                                                                        ddd[index].Date = myMomentObject.toString();

                                                                        console.log(value, myMomentObject, ddd);
                                                                        form.change(`inspectionTypesPlannedDates`, ddd);

                                                                        const inspectionDeferrals = [...values.inspectionDeferrals]
                                                                        const difIndex = inspectionDeferrals.findIndex((dd: any) => dd.id === type.id)
                                                                        if (difIndex !== -1) {
                                                                            //update
                                                                            inspectionDeferrals[difIndex].Date = myMomentObject.toString();
                                                                            inspectionDeferrals[difIndex].formDate = value;
                                                                        } else {
                                                                            ddd[index].Date = { ...myMomentObject };
                                                                            const thing = {
                                                                                id: type.id,
                                                                                Date: myMomentObject.toString(),
                                                                                formDate: value
                                                                            };
                                                                            inspectionDeferrals.push(thing)
                                                                        }

                                                                        form.change(`inspectionDeferrals`, inspectionDeferrals);
                                                                    }}
                                                                </OnChange>
                                                                <Field name={`${name}.formDate`} label="Deferred To Date" component={DateField} />
                                                            </div>
                                                        })}

                                                    </div>
                                                }}
                                            </FieldArray>



                                        </div>
                                        <button className="btn btn-primary e2eSubmit" type="submit">Save Deferred Dates</button>
                                    </form>
                                }} />


                        </div>
                    </Tab>
                    <Tab eventKey="pipes" title="Pipes">
                        <DebugButton data={thing} />
                        <div className="row">
                            <h2>Pipes</h2>
                            {pipes && <DataTable

                                hideIndexCol={true}
                                colHeadings={[
                                    { name: "Name", field: "name", sortable: true },
                                    { name: "Description", field: "description", sortable: true },
                                    {
                                        name: "System", field: "systemIdNumber2", sortable: true, r: (value: any, index) => {
                                            return <>{value.system && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/system/${value.systemId}`}>{value.system.refId}</Link>}</>;
                                        }
                                    },
                                    {
                                        name: "Risk", field: "riskAZ", sortable: true,
                                    },
                                    {
                                        name: "Fluid", field: "fluidName", sortable: true,
                                    },
                                    {
                                        name: "Material", field: "materialName", sortable: true,
                                    },
                                    { name: "Size", field: "size", sortable: true, queryable: true },

                                    {
                                        name: "From", field: "fromStr", r: (value: any, index, addToQuery) => {
                                            return <>{value.fromStr}
                                                {value.fromEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.fromEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                                {value.fromPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.fromPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                            </>
                                        }, sortable: true
                                    },
                                    {
                                        name: "To", field: "toStr", r: (value: any, index, addToQuery) => {
                                            return <>{value.toStr}
                                                {value.toEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.toEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                                {value.toPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.toPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}

                                            </>

                                        }, sortable: true
                                    },
                                    {
                                        name: "Anomalies", field: "newAnomalies", r: (value: any, index) => {
                                            return <>New {value.newAnomalies} / Closed: {value.closedAnomalies}</>;
                                        }, sortable: true
                                    },

                                    {
                                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                <Link className="btn btn-primary" id={`e2e${index - 1}PipeDetails`} to={`/installations/${thing.id}/pipe/${value.id}`}>Details</Link>

                                            </div>;
                                        }, sortable: false
                                    },

                                ]}

                                data={pipes}
                            />
                            }
                        </div>
                    </Tab>
                    <Tab eventKey="inspection" title="Inspection">

                        {inspections && <InspectionDisplay inspections={inspections} loopId={thing.id} />}

                    </Tab>
                    <Tab eventKey="anomalies" title="Anomalies">
                        <h2>Anomalies</h2>
                        {anomalys && <DataTable

                            hideIndexCol={true}
                            colHeadings={[
                                { name: "ID", field: "refId", sortable: true },
                                { name: "Subject", field: "subject", sortable: false },
                                { name: "Status", field: "status", sortable: false },
                                {
                                    name: "System", field: "systemId", r: (value: any, index) => {
                                        return <>{value.system && <Link className="btn btn-outline-primary" to={`${value.installId}/system/${value.systemId}`}>{value.system.refId}</Link>}</>;
                                    }, sortable: false
                                },
                                {
                                    name: "Date", field: "date", r: (value: any, index) => {
                                        return <DateDisplay format="YYYY-MM-DD" date={value.date} />;
                                    }, sortable: true
                                },
                                {
                                    name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                        return <div className="btn-group">
                                            <DebugButton data={value} />
                                            <Link className="btn btn-primary" to={`/installations/${value.installId}/anomaly/${value.id}`}>Details</Link>

                                            {value.pipe && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.pipeId}`}>Pipe: {value.pipe.refId}</Link>}
                                            {value.equipment && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.equipmentId}`}>Equipment: {value.equipment.refId}</Link>}

                                        </div>;
                                    }, sortable: false
                                },

                            ]}

                            data={anomalys}
                        />}
                    </Tab>


                </Tabs>

            </div>



        </>}

    </>
    );
}

export default Loop;