import axios, { AxiosInstance } from "axios";
import { ENotificationType } from "../context/notifications";
import { ISession } from "../interface/session";

export const RequestSystem = (addNotification: any, token: string): Promise<ISession> => {

    const specialAxiosInstance = (newNote: any): AxiosInstance => {
        // const localStorageStr = localStorage.getItem('auth');
        console.log("specialAxiosInstance", token)
        // let token: string = '';
        // if (localStorageStr) {
        //     token = JSON.parse(localStorageStr).token;
        // }
        const instance: AxiosInstance = axios.create({
            baseURL: '/api',
            timeout: 20000,
            headers: { 'X-Custom-Header': token }
        });
        instance.interceptors.response.use((resp) => resp, (error) => {
            // whatever you want to do with the error
            newNote && newNote('Error', 'Api Error', ENotificationType.Danger)
        });

        return instance;
    };




    return new Promise<ISession>((resolve, reject) => {
        let url = '/system';

        specialAxiosInstance(addNotification).get(url).then(response => {
            if (response === undefined) {
                reject();
            } else {
                return response.data;
            }

        }).then(response => {
            //  console.log(response);
            resolve(response)
        }).catch(exc => {
            //console.log(exc);
            reject(exc);
        });
    })
}