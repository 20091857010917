import Downshift from 'downshift'
import { matchSorter } from 'match-sorter'
import React, { useState } from 'react'

const DownshiftInput = ({ input, meta, placeholder, items, label, keys, OUTPUT, keyString, ...rest }: any) => {
  console.log(OUTPUT)

  let keyyy = 'id';
  if (keyString !== undefined) {
    keyyy = keyString;
  }

  let itemToString = (item: any) => (item ? item[keyyy] : '')

  const [ss, set] = useState('');
  return <Downshift
    {...input}

    onSelect={(selectedItem: any) => {
      console.log("onSelect", selectedItem)
    }}
    itemToString={itemToString}
  // selectedItem={input.value}
  >
    {({
      getInputProps,
      getRootProps,
      getItemProps,
      getLabelProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem
    }) => {

      //  const filteredItem=items; 
      const sddddd = inputValue !== null ? inputValue : ''
      console.log(inputValue, items, isOpen)

      const filteredItem = matchSorter(items, sddddd, { keys: keys });

      return (<div className="row pb-2 pt-2">
        <div className="col-3"><label>{label}</label></div>
        <div className="col-8">
          <div className="downshift" style={{ position: 'relative' }}>

            <input
              className="form-control"
              {...getInputProps({
                name: input.name,
                placeholder
              })}
            />

            {isOpen &&
              !!filteredItem.length && (
                <div
                  className="downshift-options"
                  style={{
                    // background: 'white',
                    // position: 'absolute',
                    // top: '100%',
                    // left: 15,
                    // right: 0,
                    // zIndex: 4
                  }}
                >
                  {filteredItem.map(({ id, fluidId, refId }: any, index: number) => {
                    console.log(id, fluidId, refId)
                    return <div
                      {...getItemProps({
                        key: id,
                        index,
                        item: id,
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? 'lightgray' : 'white',
                          fontWeight: selectedItem === id ? 'bold' : 'normal'
                        }
                      })}
                    >
                      {OUTPUT ? OUTPUT.map((out: any, iOut: number) => {
                        return <span key={iOut}>

                          {out} : {items[index] && items[index][out]}</span>
                      }) : <>{JSON.stringify(items[index])} {id}</>}
                    </div>
                  })}
                </div>
              )}
          </div>
        </div>
        <div className="col-2">
          {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
        </div>

      </div>
      )
    }}
  </Downshift>
}

export default DownshiftInput
