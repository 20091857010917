import moment from 'moment';
import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import DateField from '../../../components/FormStuff/date';
import { composeValidators, required } from '../../../components/FormStuff/validators';
import { IInstallationContractor, IInstallationContractorPerson } from '../../../interface/installation';

const capitalizeFirst = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}




export const anythingBy = (text: string, fieldPrefix: string, form: any, values: any) => {
    const capitalizedFirst = capitalizeFirst(fieldPrefix);
    const formDateField = `form${capitalizedFirst}Date`;
    const dateField = `${fieldPrefix}Date`;
    const byCompany = `${fieldPrefix}ByCompany`;
    const byCompanyFind = `${fieldPrefix}ByCompanyFind`;
    const byCompanyObj = `${fieldPrefix}ByCompanyObj`;
    const byName = `${fieldPrefix}ByName`;
    const byNameFind = `${fieldPrefix}ByNameFind`;
    const byNameObj = `${fieldPrefix}ByObj`;
    return <div>

        <h2>{text} By</h2>
        <div className="col-12">
            <OnChange name={formDateField}>
                {(value, previous) => {
                    // do something
                    const myMomentObject = moment(value, 'DD-MM-YYYY').toDate();
                    console.log(value, myMomentObject);
                    form.change(dateField, myMomentObject.toString());
                }}
            </OnChange>
            {/* {formDateField} */}
            <Field name={formDateField} label={`${text} Date`} component={DateField} validate={composeValidators(required)} />
        </div>
        <div className="col-12">
            Company: {values[byCompany]}<br />
            {/* {byCompanyFind} {byCompany} */}
            {values[byCompanyFind] === '' && <>
                {values[byCompany] === '' &&
                    <Field name={byCompanyFind}>
                        {({ input, meta }) => (
                            <><div className="input-group">
                                <label>{text}  Company</label>
                                <select className="form-control" {...input}>
                                    <option></option>
                                    {values.installation && values.installation.contractors && values.installation.contractors.map((client: any, index: number) => {
                                        return <option key={index} value={client.id}>{client.name}</option>;
                                    })}
                                </select>
                                <button className="btn btn-success" type="button" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    form.change(byCompanyFind, 'newId');
                                }}>+ NEW</button>

                            </div>
                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}

                            </>
                        )}
                    </Field>}






                {values[byCompany] !== '' && <button className="btn btn-outline-danger" type="button" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    form.change(byCompany, '');
                    form.change(byName, '');

                }}>Reset Company</button>}
            </>}
            <OnChange name={byCompanyFind}>
                {(value, previous) => {
                    // do something
                    if (value === 'newId') {
                        form.change('newContractor', '');
                    } else if (value !== '') {
                        ///set inspectionByCompanyObj?: any;
                        form.change(byCompany, value);
                        form.change(byCompanyFind, '');
                        if (values.installation) {
                            const index = values.installation.contractors.findIndex((ddd: any) => ddd.id === value);
                            if (index !== -1) {
                                form.change(byCompanyObj, values.installation.contractors[index]);
                            }
                        }
                    }

                    // const myMomentObject = moment(value, 'DD-MM-YYYY').toDate()
                    console.log(value);
                    // form.change('inspectionDate', myMomentObject.toString())
                }}
            </OnChange>
            {values[byCompanyFind] === 'newId' && <div className="input-group">
                <Field name="newContractor">
                    {({ input, meta }) => (
                        <>
                            <span className="input-group-text" id="basic-addon3">New Company</span>
                            <input type="text" className="form-control" {...input} placeholder="...." />
                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                        </>
                    )}
                </Field>
                <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    console.log(values.newContractor);
                    if (values.newContractor && values.installation) {
                        const contractor: IInstallationContractor = { id: values.newContractor, name: values.newContractor, people: [] };
                        form.change(byCompany, values.newContractor);
                        form.change(byCompanyObj, contractor);
                        form.change(byCompanyFind, '');
                        form.change('newContractor', '');
                        const nInst = { ...values.installation };
                        if (!nInst.contractors) {
                            nInst.contractors = [];
                        }
                        nInst.contractors.push(contractor);
                        nInst._reqSave = true;
                        form.change('installation', nInst);
                    }
                }}>Done</button>
            </div>}
        </div>

        {values[byCompany] !== '' && <div className="col-12">



            Person: {values[byName]} <br />
            {values[byNameFind] === '' && <>
                {values[byName] === '' ? <>



                    <Field name={byNameFind}>
                        {({ input, meta }) => (
                            <><div className="input-group">
                                <label>{text}  Person</label>
                                <select className="form-control" {...input}>
                                    <option></option>
                                    {values[byCompanyObj] && values[byCompanyObj].people && values[byCompanyObj].people.map((client: any, index: number) => {
                                        return <option key={index} value={client.id}>{client.name}</option>;
                                    })}
                                </select>
                                <button className="btn btn-success" type="button" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    form.change(byNameFind, 'newId');

                                }}>+</button>

                            </div>
                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}

                            </>
                        )}
                    </Field></> : <button type="button" className="btn btn-outline-danger" onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        form.change(byNameFind, '');
                        form.change(byName, '');
                    }}>Reset Person</button>}
            </>}
            <OnChange name={byNameFind}>
                {(value, previous) => {
                    // do something
                    console.log(value);
                    if (value === 'newId') {
                        form.change('newContractorPerson', '');
                    } else if (value !== '') {
                        ///set inspectionByCompanyObj?: any;
                        form.change(byCompanyFind, '');
                        form.change(byName, value);
                        form.change(byNameFind, '');
                    }

                    // const myMomentObject = moment(value, 'DD-MM-YYYY').toDate()
                    console.log(value);
                    // form.change('inspectionDate', myMomentObject.toString())
                }}
            </OnChange>
            {values[byNameFind] === 'newId' && <div className="input-group">
                <Field name="newContractorPerson">
                    {({ input, meta }) => (
                        <>
                            <span className="input-group-text" id="basic-addon3">New  Person</span>
                            <input type="text" className="form-control" {...input} placeholder="...." />
                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                        </>
                    )}
                </Field>
                <button type="button" className="btn btn-outline-secondary" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    console.log(values.newContractorPerson);
                    if (values.newContractorPerson && values.installation) {
                        const stringFU = values.newContractorPerson;
                        const contractorPerson: IInstallationContractorPerson = { id: stringFU, name: stringFU };

                        form.change(byNameObj, contractorPerson);


                        const nInst = { ...values.installation };

                        const contIndex = nInst.contractors.findIndex((ds: any) => ds.id === values[byCompany]);
                        if (contIndex !== -1) {
                            nInst.contractors[contIndex].people.push(contractorPerson);
                        }

                        nInst._reqSave = true;
                        form.change('newContractorPerson', '');
                        form.change('installation', nInst);
                        form.change(byNameFind, '');
                        form.change(byName, stringFU);
                    }

                }}>Done</button>
            </div>}


        </div>}
    </div>;
}