
import React, { useEffect, useState } from 'react';
import InputRange from 'react-input-range'; // https://www.npmjs.com/package/react-input-range
import { AreaSeries, HorizontalGridLines, MarkSeries, VerticalGridLines, XAxis, XYPlot, YAxis } from 'react-vis';
import { RequestCalcEvaluate } from '../../api/calculator-requests';
import DataTable from '../../components/system/DataTable';
import DebugButton from '../../components/system/DebugButton';
import { useNotifications } from '../../context/notifications';
import { usePageTitle } from '../../context/pageTitle';

const CalcBase = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [temperature, setTemp] = useState(73);
    const [temperatureF, setTempF] = useState((73 * 9 / 5) + 32);
    const [pressure, setPressure] = useState(323);
    const [pressureB, setPressureB] = useState(parseFloat((323 * 14.504).toFixed(2)));
    const [h2s, setH2s] = useState(21);
    const [h2sP, setH2sP] = useState(21 * 10000);
    const [co2, setCo2] = useState(21);
    const [co2P, setCo2P] = useState(21 * 10000);
    const [cl, setCl] = useState(134000);
    const [bic, setHco] = useState(100);
    const [designLife, setLife] = useState(1);
    const [corrosion, setCorrosion] = useState(0);
    const { addNotification } = useNotifications()!;
    const [response, setResponse] = useState<any>();

    const { setPageTitle } = usePageTitle()!;

    useEffect(() => {
        setPageTitle && setPageTitle('Alloyselect');
    }, [])

    const calculate = () => {
        const requstPayload: any = { temperature, pressure, h2s, co2, cl, bic, designLife, corrosion };
        console.log(requstPayload)

        RequestCalcEvaluate(addNotification, requstPayload).then(dd => {
            setResponse(dd)
        })
    }



    return <div className="col-12">
        <form onSubmit={(ev) => {
            ev.preventDefault();
            calculate()
        }}>
            <div className="row mt-3 pt-3 mt-3 pb-3">
                <div className="col-2">
                    Temperature
                </div>
                <div className="col-2 input-group">
                    <span className="input-group-text">C</span>
                    <input id="tempC" type="number" className="form-control" value={temperature} onChange={value => {
                        console.log(value.target.value)
                        const c = parseFloat(value.target.value.toString())
                        setTemp(c)
                        setTempF((c * 9 / 5) + 32)
                    }} />
                </div>
                <div className="col-2 input-group">
                    <span className="input-group-text">F</span>
                    <input id="tempF" className="form-control" type="number" value={temperatureF} onChange={value => {
                        console.log(value.target.value);
                        const degf = parseFloat(value.target.value.toString());
                        setTempF(degf)


                        setTemp(parseFloat(((degf - 32) * 5 / 9).toFixed(2)))
                    }} />
                </div>
                <div className="col-6">
                    <InputRange
                        maxValue={125}
                        minValue={0}
                        value={temperature}
                        onChange={value => {


                            const c = parseFloat(value.toString())
                            setTemp(c)
                            setTempF((c * 9 / 5) + 32)


                        }} />
                </div>
            </div>




            <div className="row mt-3 pt-3 mt-3 pb-3">
                <div className="col-2">
                    Pressure
                </div>
                <div className="col-2 input-group">
                    <span className="input-group-text">Bar</span>
                    <input id="pressure" type="number" className="form-control" value={pressure} onChange={value => {
                        console.log(value.target.value)
                        const c = parseFloat(value.target.value.toString())
                        setPressure(parseFloat(c.toFixed(2)))
                        setPressureB(parseFloat((c * 14.504).toFixed(2)))
                        // setTempF((c * 9 / 5) + 32)
                    }} />
                </div>
                <div className="col-2 input-group">
                    <span className="input-group-text">PSI</span>
                    <input id="pressureB" type="number" className="form-control" value={pressureB} onChange={value => {
                        console.log(value.target.value)
                        const c = parseFloat(value.target.value.toString())
                        //setPressureB(c)
                        setPressureB(parseFloat(c.toFixed(2)))
                        setPressure(parseFloat((c / 14.504).toFixed(2)))

                    }} />
                </div>
                <div className="col-6">
                    <InputRange
                        maxValue={1034}
                        minValue={0}
                        value={pressure}
                        onChange={value => {
                            const c = parseFloat(value.toString())

                            setPressure(parseFloat(c.toFixed(2)))
                            setPressureB(parseFloat((c * 14.504).toFixed(2)))


                        }} />
                </div>
            </div>





            <div className="row mt-3 pt-3 mt-3 pb-3">
                <div className="col-2">
                    H2S%
                </div>
                <div className="col-2 input-group">
                    <span className="input-group-text">%</span>
                    <input id="pressure" type="number" className="form-control" value={h2s} onChange={value => {
                        console.log(value.target.value)
                        const c = parseFloat(value.target.value.toString())
                        setH2s(c)
                        setH2sP(parseFloat((c * 10000).toFixed(2)))
                        // setTempF((c * 9 / 5) + 32)
                    }} />
                </div>
                <div className="col-2 input-group">
                    <span className="input-group-text">PPM</span>
                    <input id="pressure" type="number" className="form-control" value={h2sP} onChange={value => {
                        console.log(value.target.value)
                        const c = parseFloat(value.target.value.toString())
                        setH2sP(c)
                        setH2s(parseFloat((c / 10000).toFixed(2)))

                        // setTempF((c * 9 / 5) + 32)
                    }} />
                </div>
                <div className="col-6">
                    <InputRange
                        maxValue={100}
                        minValue={0}
                        value={h2s}
                        onChange={value => {
                            const c = parseFloat(value.toString())

                            setH2s(parseFloat(c.toFixed(2)))
                            setH2sP(parseFloat((c * 10000).toFixed(2)))

                        }} />
                </div>
            </div>





            <div className="row mt-3 pt-3 mt-3 pb-3">
                <div className="col-2">
                    CO2%
                </div>
                <div className="col-2 input-group">
                    <span className="input-group-text">%</span>
                    <input id="pressure" type="number" className="form-control" value={co2} onChange={value => {
                        console.log(value.target.value)
                        // const c = parseFloat(value.target.value.toString())
                        // setCo2(c)

                        const c = parseFloat(value.target.value.toString())
                        setCo2(c)
                        setCo2P(parseFloat((c * 10000).toFixed(2)))
                        // setTempF((c * 9 / 5) + 32)
                    }} />
                </div>
                <div className="col-2 input-group">
                    <span className="input-group-text">PPM</span>
                    <input id="pressure" type="number" className="form-control" value={co2P} onChange={value => {
                        console.log(value.target.value)

                        const c = parseFloat(value.target.value.toString())
                        setCo2P(c)
                        setCo2(parseFloat((c / 10000).toFixed(2)))

                        // setTempF((c * 9 / 5) + 32)
                    }} />
                </div>
                <div className="col-6">
                    <InputRange
                        maxValue={100}
                        minValue={0}
                        value={co2}
                        onChange={value => {
                            const c = parseFloat(value.toString())

                            setCo2(parseFloat(c.toFixed(2)))
                            setCo2P(parseFloat((c * 10000).toFixed(2)))
                        }} />
                </div>
            </div>



            <div className="row mt-3 pt-3 mt-3 pb-3">
                <div className="col-2">
                    CL conc
                </div>
                <div className="col-4">
                    <input id="pressure" type="number" className="form-control" value={cl} onChange={value => {
                        console.log(value.target.value)
                        const c = parseFloat(value.target.value.toString())
                        setCl(c)
                        // setTempF((c * 9 / 5) + 32)
                    }} />
                </div>

                <div className="col-6">
                    <InputRange
                        maxValue={300000}
                        minValue={0}
                        value={cl}
                        onChange={value => {

                            setCl(parseFloat(value.toString()))
                        }} />
                </div>
            </div>





            <div className="row mt-3 pt-3 mt-3 pb-3">
                <div className="col-2">
                    HCO3 conc
                </div>
                <div className="col-4">
                    <input id="pressure" type="number" className="form-control" value={bic} onChange={value => {
                        console.log(value.target.value)
                        const c = parseFloat(value.target.value.toString())
                        setHco(c)
                        // setTempF((c * 9 / 5) + 32)
                    }} />
                </div>
                <div className="col-6">
                    <InputRange
                        maxValue={3000}
                        minValue={0}
                        value={bic}
                        onChange={value => {
                            console.log(value)
                            setHco(parseFloat(value.toString()))
                        }} />
                </div>
            </div>











            <div className="row mt-3 pt-3 mt-3 pb-3">
                <div className="col-2">
                    Design Life
                </div>
                <div className="col-4">
                    <input id="pressure" type="number" className="form-control" value={designLife} onChange={value => {
                        console.log(value.target.value)
                        const c = parseFloat(value.target.value.toString())
                        setLife(c)
                        // setTempF((c * 9 / 5) + 32)
                    }} />
                </div>
                <div className="col-6">
                    Years - Temp for now
                    <InputRange
                        maxValue={100}
                        minValue={0}
                        value={designLife}
                        onChange={value => {

                            setLife(parseFloat(value.toString()))
                        }} />
                </div>
            </div>








            <div className="row mt-3 pt-3 mt-3 pb-3">
                <div className="col-3">
                    Corrosion Inhibitor Availability
                </div>
                <div className="col-3">
                    field
                </div>
                <div className="col-6">
                    <select value={corrosion} onChange={value => {
                        setCorrosion(parseFloat(value.target.value.toString()))
                    }}>
                        <option value="0">0 %</option>
                        <option value="90">90 %</option>
                        <option value="95">95 %</option>
                    </select>
                </div>
            </div>
            <button className="btn btn-success" type="submit">Go
            </button>
        </form>

        {response && <div className="row">
            <div className="col-12">
                message {response.message}<br />
                pH {response.pH} <br />
                CR {response.CR} <br />
                CRI {response.CRI} <br />
                CA  {response.CA} <br />
                pph2s {response.pph2s} <br />
                <DebugButton data={response} />








                <XYPlot
                    width={1300}
                    height={600}
                    xDomain={[0.1, response.pph2s + 100]}
                    yDomain={[2.5, 6.5]}
                    xType={"log"}
                >

                    <VerticalGridLines />
                    <HorizontalGridLines />
                    {/* <DecorativeAxis
    axisStart={{x: 1000, y: 10}}
    axisEnd={{x: -1, y: 10}}
    axisDomain={[-10, 100]}
    /> */}
                    <AreaSeries
                        style={{ strokeDasharray: "2 2" }}
                        data={[
                            { x: 0.01, y: 3.5 },
                            { x: 0.1, y: 3.5, },
                            { x: 1, y: 3.5, },
                            { x: 100, y: 5.5, },
                            { x: 1000, y: 5.5, },
                            { x: 1000, y: 6.5, },
                            { x: 100, y: 6.5, },
                            { x: 0.1, y: 3.5, },
                        ]} />
                    <MarkSeries
                        data={[
                            { x: response.pph2s, y: response.pH },

                        ]} />
                    <XAxis title="PP2S" />
                    <YAxis title="PH" />
                </XYPlot>

            </div>

            <div>

            </div>

            <div className="col-12 text-left">

                {response.matt && <DataTable

                    colHeadings={[
                        { name: "Equipment", field: "equip", sortable: false, queryable: false },
                        { name: "Material name", field: "matterialName", sortable: false, queryable: true },
                        { name: "Notes", field: "notes", sortable: false, queryable: false },
                        {
                            name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                return <div className="btn-group">
                                    <DebugButton alwaysDisplay={true} data={value} />

                                </div>;
                            }, sortable: false
                        },

                    ]}

                    data={response.matt}
                />}  <DebugButton alwaysDisplay={true} data={response} />
            </div>
        </div>}
    </div>

}


export default CalcBase