import { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";

const rainbow = (numOfSteps: number, step: number) => {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    let r = 0, g = 0, b = 0;
    let h = step / numOfSteps;
    let i = ~~(h * 6);
    let f = h * 6 - i;
    let q = 1 - f;
    switch (i % 6) {
        case 0: r = 1; g = f; b = 0; break;
        case 1: r = q; g = 1; b = 0; break;
        case 2: r = 0; g = 1; b = f; break;
        case 3: r = 0; g = q; b = 1; break;
        case 4: r = f; g = 0; b = 1; break;
        case 5: r = 1; g = 0; b = q; break;
    }
    let c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
    return (c);
}
const SvgComponent = (props: any) => {
    const [steps, setSteps] = useState<number>(1);
    const [fill, setFill] = useState<string>('purple');

    const svgRef = useRef(null);
    const [hovered, setHovered] = useState<boolean>(false);
    let interval: any;
    const handleMouseEnter = () => {
        //setFill(rainbow(5, steps))
        //setSteps(steps+1);
        setHovered(true);
        interval = setInterval(() => {
            setSteps(steps + 1);
            setFill(rainbow(5, steps))
            //  console.log("interval")
        }, 1000)
    }
    const handleMouseLeave = () => {
        setHovered(false);
        clearInterval(interval);
    }
    return (
        <a href="" target="_new"> <Overlay target={svgRef.current} show={hovered} placement="right">
            {(props) => (
                <Tooltip id="overlay-example" {...props}>
                    Powered by Photonsurge
                </Tooltip>
            )}
        </Overlay><svg
            ref={svgRef}
            xmlns="http://www.w3.org/2000/svg"
            width={50}
            height={46.3425}
            viewBox="0 0 132.292 122.614"
            id="prefix__svg8"
            {...props}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
                <defs id="prefix__defs2">
                    <marker
                        id="prefix__Arrow1Send"
                        refX={0}
                        refY={0}
                        orient="auto"
                        overflow="visible"
                    >
                        <path
                            d="M-1.2 0l-1 1 3.5-1-3.5-1 1 1z"
                            id="prefix__path1076"
                            fillRule="evenodd"
                            stroke="#000"
                            strokeWidth=".2pt"
                            strokeOpacity={1}
                            fill="#000"
                            fillOpacity={0}
                        />
                    </marker>
                    <marker
                        id="prefix__Arrow1Lend"
                        refX={0}
                        refY={0}
                        orient="auto"
                        overflow="visible"
                    >
                        <path
                            d="M-10 0l-4 4L0 0l-14-4 4 4z"
                            id="prefix__path1064"
                            fillRule="evenodd"
                            stroke="#000"
                            strokeWidth=".8pt"
                            strokeOpacity={1}
                            fill="#000"
                            fillOpacity={0}
                        />
                    </marker>
                    <marker
                        id="prefix__Arrow2Lstart"
                        refX={0}
                        refY={0}
                        orient="auto"
                        overflow="visible"
                    >
                        <path
                            d="M10.69 4.437L-1.328.017 10.69-4.401c-1.92 2.61-1.909 6.18 0 8.84z"
                            id="prefix__path1079"
                            fillRule="evenodd"
                            strokeWidth={0.6875}
                            strokeLinejoin="round"
                            stroke="#000"
                            strokeOpacity={1}
                            fill="#000"
                            fillOpacity={0}
                        />
                    </marker>
                    <marker
                        id="prefix__Tail"
                        refX={0}
                        refY={0}
                        orient="auto"
                        overflow="visible"
                    >
                        <g
                            transform="scale(-1.2)"
                            id="prefix__g1109"
                            stroke="#000"
                            strokeOpacity={1}
                            fill="#000"
                            fillOpacity={0}
                            fillRule="evenodd"
                            strokeWidth={0.8}
                            strokeLinecap="round"
                        >
                            <path d="M-3.805-3.959L.544 0" id="prefix__path1097" />
                            <path d="M-1.287-3.959L3.062 0" id="prefix__path1099" />
                            <path d="M1.305-3.959L5.654 0" id="prefix__path1101" />
                            <path d="M-3.805 4.178L.544.22" id="prefix__path1103" />
                            <path d="M-1.287 4.178L3.062.22" id="prefix__path1105" />
                            <path d="M1.305 4.178L5.654.22" id="prefix__path1107" />
                        </g>
                    </marker>
                    <style id="style945" />
                    <style id="style969" />
                    <style id="style1022" />
                    <filter
                        id="prefix__filter1049"
                        x={0}
                        width={1}
                        y={0}
                        height={1}
                        colorInterpolationFilters="sRGB"
                    >
                        <feGaussianBlur stdDeviation={0} id="prefix__feGaussianBlur1051" />
                    </filter>
                </defs>
                <g id="prefix__layer2" display="inline">
                    <path

                        style={{
                            lineHeight: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantPosition: "normal",
                            fontVariantCaps: "normal",
                            fontVariantNumeric: "normal",
                            fontVariantAlternates: "normal",
                            fontVariantEastAsian: "normal",
                            fontFeatureSettings: "normal",
                            fontVariationSettings: "normal",
                            textIndent: 0,
                            textAlign: "start",
                            textDecorationLine: "none",
                            textDecorationStyle: "solid",
                            textDecorationColor: "#000",
                            textTransform: "none",
                            textOrientation: "mixed",
                            whiteSpace: "normal",
                            shapeMargin: 0,
                            inlineSize: 0,
                            isolation: "auto",
                            mixBlendMode: "normal",
                        }}
                        d="M74.594 14.637c-23.93 0-43.373 19.442-43.373 43.373 0 23.93 19.442 43.373 43.373 43.373 23.93 0 43.37-19.443 43.37-43.373 0-23.93-19.44-43.373-43.37-43.373zm0 3.968c21.785 0 39.402 17.619 39.402 39.405S96.38 97.414 74.594 97.414c-21.786 0-39.405-17.618-39.405-39.404 0-21.786 17.619-39.405 39.405-39.405z"
                        id="prefix__path1035"
                        color="#000"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight={400}
                        fontStretch="normal"
                        fontSize="medium"
                        fontFamily="sans-serif"
                        textDecoration="none"
                        letterSpacing="normal"
                        wordSpacing="normal"
                        writingMode="lr-tb"
                        direction="ltr"
                        dominantBaseline="auto"
                        baselineShift="baseline"
                        textAnchor="start"
                        clipRule="nonzero"
                        display="inline"
                        overflow="visible"
                        visibility="visible"
                        colorInterpolation="sRGB"
                        colorInterpolationFilters="linearRGB"
                        vectorEffect="none"
                        fill={hovered ? fill : 'black'}
                        fillOpacity={1}
                        fillRule="nonzero"
                        stroke="none"
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        strokeDasharray="none"
                        strokeDashoffset={0}
                        strokeOpacity={1}
                        paintOrder="normal"
                        colorRendering="auto"
                        imageRendering="auto"
                        shapeRendering="auto"
                        textRendering="auto"
                        enableBackground="accumulate"
                        stopColor="#000"
                        stopOpacity={1}
                        opacity={1}
                    />
                </g>
                <g
                    id="prefix__layer1"
                    transform="translate(-37.778 -50.98)"
                    filter="url(#prefix__filter1049)"
                >
                    <path
                        id="prefix__PhotonsurgeBig"
                        style={{
                            mixBlendMode: "normal",
                        }}
                        d="M140.76 52.87c-2.345.058-22.893 44.517-27.138 45.843-4.279 1.337-18.093-38.646-19.825-36.782-1.733 1.864 14.984 35.836 11.329 38.48-3.656 2.643-19.366-7.25-20.392-5.66-1.042 1.615 13.624 6.748 16.93 12.532-20.37.196-42.21.767-62.04.907-.766.005-.717 1.442-.023 1.456 9.64.188 44.915 1.755 61.689 2.375-3.584 5.231-15.848 9.395-15.046 10.93.791 1.516 13.765-5.568 18.882-2.735 2.505 3.065-14.785 51.106-13.595 51.495 1.19.389 18.28-50.192 22.658-50.363 4.378-.17 19.516 32.736 20.958 32.255 1.589-.463-14.28-32.9-12.462-36.216 2.421-4.418 44.876 13.12 45.316 11.883.44-1.236-43.51-17.444-43.05-20.937.46-3.493 38.461-19.276 37.952-20.372-.51-1.095-39.998 17.328-43.05 14.713-3.051-2.615 23.031-49.145 20.959-49.797a.159.159 0 00-.052-.007z"
                        display="inline"
                        fill={hovered ? fill : 'black'}
                        fillOpacity={1}
                        stroke="#fffefe"
                        strokeWidth={0.265}
                        strokeMiterlimit={4}
                        strokeDasharray="none"
                        strokeOpacity={0}
                        paintOrder="fill markers stroke"
                    />
                </g>
            </svg></a>
    )
}



const Photonsurge = () => {
    return <div className="photonsurge">
        <SvgComponent />
    </div>
}


export default Photonsurge;