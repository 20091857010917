import { useEffect } from "react";
import { Link } from "react-router-dom";
import ComingSoon from "../components/system/ComingSoon";
import { useAuth } from "../context/auth";
import { usePageTitle } from "../context/pageTitle";


const Home = () => {
  const { auth } = useAuth()!;
  const { setPageTitle } = usePageTitle()!;
  useEffect(() => {
    setPageTitle && setPageTitle('Home', 'normal');
  }, [])


  return <>
    <span className="e2ePageHome" style={{ display: 'none' }}>TestOk</span>
    {auth.type === 'guest' ? <>

      <h2>Login required</h2>
      you need to login to use this service
      <Link to="/login">Login</Link>
    </> : <ComingSoon />}

  </>;
}

export default Home;