import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import arrayMutators from "final-form-arrays";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import { Helmet } from "react-helmet";
import { Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestAnomalyFunction, RequestAnomalyFunctionDos, RequestGetAnomaly, RequestNewAnomaly, RequestUpdateAnomaly } from "../../../api/anomaly-requests";
import { downloadFile } from "../../../api/file-download-request";
import { RequestGetInspectionTypes } from "../../../api/inspection-type-requests";
import { RequestGetInstallationLoops } from "../../../api/installation-loop-requests";
import { RequestGetInstallations } from "../../../api/installation-requests";
import { RequestGetInstallationSystems } from "../../../api/installation-system-requests";
import { RequestNewLog } from "../../../api/log-requests";
import DateField from "../../../components/FormStuff/date";
import { composeValidators, required } from "../../../components/FormStuff/validators";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import UploadFiles from "../../../components/system/UploadFiles";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { usePageTitle } from "../../../context/pageTitle";
import { IAnomaly } from "../../../interface/anomaly";
import { IInspectionType } from "../../../interface/inspection-type";
import { IInstallation } from "../../../interface/installation";
import { IInstallationLoop } from "../../../interface/installation-loop";
import { IInstallationSystem } from "../../../interface/installation-system";
import { ELogType, ESeverity } from "../../../interface/log";
import { anythingBy } from "../Admin.Inspections/anythingBy";
import { selectEquipmentA } from "./addon";

const AdminAnomaly = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IAnomaly>();
    const { addNotification } = useNotifications()!;
    const { auth, hasPermission } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();

    const [installations, setInstallations] = useState<IInstallation[]>();
    const [systems, setSystems] = useState<IInstallationSystem[]>();
    const [inspectionTypes, setInspectionTypes] = useState<IInspectionType[]>()
    const [loops, setLoops] = useState<IInstallationLoop[]>()

    // 


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/anomalys/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    const { setPageTitle } = usePageTitle()!;

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                RequestGetInspectionTypes(addNotification).then(iTypes => {
                    setInspectionTypes(iTypes)




                    RequestGetInstallations(addNotification).then(installations => {


                        setInstallations(installations);
                        if (id === 'new') {
                            setThing({
                                mitigationMeasures: '', evaluatedByCompanyFind: '', evaluatedByNameFind: '',
                                clientId: '', typeId: '', status: 'new', pipeId: '', equipmentId: '', inspectionId: '', systemId: '', tagNo: '',
                                loopId: '', findings: '', results: [], engineering: '', materialId: '',
                                refId: '', action: '', actionByName: '', actionByCompany: '', actionByNameFind: '', actionByCompanyFind: '', actionByDate: '', formActionByDate: '', work: '',
                                workByCompany: '', workByDate: '', workByName: '', completed: '', completedByDate: '', completedByName: '', completedByCompany: '', completedByCompanyFind: '', completedByNameFind: '',
                                formEvaluatedDate: '', id: 'new', fileId: '', inspectionTypeId: 'inspection', workByCompanyFind: '', workByNameFind: '',
                                rasesByCompany: '', rasesByName: '', rasesByCompanyFind: '', rasesByNameFind: '', rasesDate: '', formRasesDate: '',
                                evaluatedByName: '', evaluatedByCompany: '', evaluatedByDate: '', approvedByName: '', approvedByUserId: '', subject: '', summaryOfFindings: '', installId: '', formDate: format(new Date(Date.now()), "dd-MM-yyyy"),
                                date: new Date(Date.now()).toString()
                            })
                            setPageTitle && setPageTitle('Retriever / Anomaly / New', 'admin');

                            setLoading(false)
                        } else {
                            RequestGetAnomaly(addNotification, id).then(thing => {
                                if (thing.rasesDate) {
                                    thing.formRasesDate = format(new Date(thing.rasesDate), "dd-MM-yyyy")
                                }

                                if (thing.evaluatedByDate) {
                                    thing.formEvaluatedDate = format(new Date(thing.evaluatedByDate), "dd-MM-yyyy")
                                }
                                if (thing.actionByDate) {
                                    thing.formActionByDate = format(new Date(thing.actionByDate), "dd-MM-yyyy")
                                }
                                if (thing.date) {
                                    thing.formDate = format(new Date(thing.date), "dd-MM-yyyy")
                                }

                                if (thing.date) {
                                    thing.formCompletedByDate = format(new Date(thing.completedByDate), "dd-MM-yyyy")
                                }


                                thing.rasesByCompanyFind = '';
                                thing.rasesByNameFind = '';

                                thing.evaluatedByNameFind = '';
                                thing.evaluatedByCompanyFind = '';

                                thing.actionByCompanyFind = '';
                                thing.actionByNameFind = '';

                                thing.workByCompanyFind = '';
                                thing.workByNameFind = '';

                                thing.completedByCompanyFind = '';
                                thing.completedByNameFind = '';
                                setThing(thing);

                                setPageTitle && setPageTitle('Retriever / Anomaly / ' + thing.refId, 'admin');
                                setLoading(false)
                            })
                        }

                    });
                });

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Anomaly - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IAnomaly>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Anomaly Created!';
                ppp = RequestNewAnomaly(addNotification, data);
            } else {
                notificationText = 'Anomaly Saved!';
                ppp = RequestUpdateAnomaly(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                // setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Anomaly - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }

    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../anomalys" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Anomaly / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}

            Status: {thing.status}
            {thing.id !== 'new' && <div className="btn-group">
                {thing.status === 'pending' && <button className="btn btn-primary" type="button" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    if (thing.fileId !== '') {
                        RequestAnomalyFunctionDos(addNotification, 'statusNew', thing.id).then((data: any) => {
                            console.log(data)

                            data.id && setThing(data)
                        })
                    } else {
                        alert('please finish this anomaly')
                    }

                }}>Set New or Current</button>}

                {thing.status === 'new' && <button className="btn btn-primary" type="button" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    if (thing.completedByDate !== '') {
                        RequestAnomalyFunctionDos(addNotification, 'statusClose', thing.id).then((data: any) => {
                            console.log(data)
                            data.id && setThing(data)
                        })
                    } else {
                        alert('please finish this anomaly - Close it!')
                    }

                }}>Set Closed</button>}


                {thing.status === 'new' && <button className="btn btn-primary" type="button" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    if (thing.completedByDate !== '') {
                        RequestAnomalyFunctionDos(addNotification, 'statusOpen', thing.id).then((data: any) => {
                            console.log(data)
                            data.id && setThing(data)
                        })
                    } else {
                        alert('please finish this anomaly - Close it!')
                    }

                }}>Re-Open</button>}
            </div>}


            <Form <IAnomaly>
                onSubmit={onSubmit}
                initialValues={thing}
                mutators={{
                    ...arrayMutators,
                    setValue: ([field, value], state, { changeValue }) => {
                        console.log(state, field, value)
                        changeValue(state, field, () => value)
                    }
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    //do if altered browse notifcation
                    //  console.log(submitting, pristine)
                    //                    setFormChanged(!pristine)

                    const equipment = values.equipmentId;
                    const pipe = values.pipeId;
                    const display = equipment === '' && pipe === '';
                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />
                                <Field name="installId">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Installation</label>
                                            <select className="form-control" {...input} onChange={(ev: React.FormEvent) => {
                                                ev.preventDefault();
                                                input.onChange(ev);
                                                console.log(values.installId)

                                            }}>
                                                <option></option>
                                                {installations && installations.map((client, index) => {
                                                    return <option key={index} value={client.id}>{client.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <OnChange name="installId">
                                    {(value, previous) => {
                                        // do something

                                        if (installations && values.installId && value !== null) {
                                            const install = installations.find(dd => dd.id === values.installId)
                                            form.change('installation', install)
                                            form.change('clientId', install && install.clientId)

                                            RequestGetInstallationSystems(addNotification, { installId: value })
                                                .then(loooy => {
                                                    //    console.log("WWWWWW", loooy)
                                                    setSystems(loooy)
                                                }).catch(ds => {
                                                    console.log("WWWWWW_err", ds)
                                                })

                                        }
                                    }}
                                </OnChange>
                            </div>

                            {values.installation && <>
                                <div className="col-12">
                                    <Field name={`typeId`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Type</label>
                                                <select className="form-control" {...input}>
                                                    <option></option>
                                                    {inspectionTypes && inspectionTypes.map((vv, index) => {
                                                        return <option key={index} value={vv.id}>{vv.name} - {vv.type}</option>
                                                    })}
                                                </select>
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <OnChange name={`typeId`}>
                                        {(value, previous) => {
                                            // do something


                                            //find the equipment obj
                                            if (inspectionTypes) {
                                                const ssss = inspectionTypes.find((ddd => ddd.id === value));
                                                console.log("sdfsdfd", ssss);

                                                form.change('inspectionType', ssss);
                                            }

                                        }}
                                    </OnChange>
                                </div>
                                <div className="col-12">
                                    Installation Details - location
                                </div>
                                <div className="col-6">
                                    <Field name="refId" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Ref - From Source</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-6">
                                    <label>File</label><br />
                                    {values.file && <>{values.file.fileName}
                                        <button className="btn btn-primary" type="button" onClick={() => {
                                            values.file && downloadFile(addNotification, values.file);
                                        }}><FontAwesomeIcon icon={faFileDownload} /></button>

                                    </>}
                                    <Field name="file" validate={composeValidators(required)} component={UploadFiles} onChanged={(event: any) => {
                                        //   fields.push(event)
                                        form.change('fileId', event.id)
                                        console.log(event)
                                    }}>


                                    </Field>
                                </div>

                                <div className="col-6">
                                    <Field name="subject" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Subject</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-6">
                                    <OnChange name={`formDate`}>
                                        {(value, previous) => {
                                            // do something


                                            const myMomentObject = moment(value, 'DD-MM-YYYY').toDate()
                                            console.log(value, myMomentObject)
                                            form.change('date', myMomentObject.toString())
                                        }}
                                    </OnChange>
                                    <Field name="formDate" validate={composeValidators(required)} component={DateField} />
                                </div>

                                <div className="col-12">
                                    <Field name="summaryOfFindings" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Summary of Findings</label>
                                                <textarea spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div className="col-12">
                                    <Field name="mitigationMeasures" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Mitigation Measures</label>
                                                <textarea spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                {anythingBy("Raised", "rases", form, values)}

                                {anythingBy("Evaluated", "evaluated", form, values)}


                                <div className="col-12"><h2>Affected Equipment / Pipe</h2></div>
                                <div className="col-12">

                                    <Field name={`systemId`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>System</label>
                                                <select className="form-control" {...input}>OnChange
                                                    <option></option>
                                                    {systems && systems.map((l, li) => {
                                                        return <option value={l.id}>{l.refId}</option>
                                                    })}
                                                </select>
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <OnChange name="systemId">
                                        {(value, previous) => {
                                            if (value !== '' && systems) {
                                                RequestGetInstallationLoops(addNotification, { systemId: value }).then(loooy => {
                                                    console.log("WWWWWW", loooy)
                                                    setLoops(loooy)
                                                }).catch(ds => {
                                                    console.log("WWWWWW_err", ds)
                                                })
                                                const index = systems.findIndex(d => d.id === value);

                                                const loop = systems[index];
                                                form.change('system', loop)
                                            }
                                        }}
                                    </OnChange>
                                    {selectEquipmentA(display, equipment, pipe, '', values, loops, 0, form)}


                                </div>
                                <div className="col-12">




                                    {(values.inspectionType && values.inspectionType.isMeasurement === true) && <FieldArray name={`results`}>

                                        {(resultsFrm: any) => {

                                            return <>
                                                <h4>Wall Thickness Readings</h4>
                                                {resultsFrm.fields.map((name2: string, index2: number) => {
                                                    const thing = values.results[index2];
                                                    return <div key={index2} className="row">
                                                        <div className="col-4">
                                                            <Field name={`${name2}.point`}>
                                                                {({ input, meta }) => (
                                                                    <div>
                                                                        <label>Point</label>
                                                                        <input type="text" className="form-control" {...input} placeholder="...." />
                                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-4">
                                                            <Field name={`${name2}.value`}
                                                                parse={(value, name) => {
                                                                    //  console.log("parse", value, name)
                                                                    if (value) return parseFloat(value);
                                                                }}
                                                                format={(value, name) => {
                                                                    //  console.log("format", value, name)

                                                                    if (value) return parseFloat(value);
                                                                }}>
                                                                {({ input, meta }) => (
                                                                    <div>
                                                                        <label>AVE</label>
                                                                        <input type="number" className="form-control" {...input} placeholder="...." />
                                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-4">
                                                            <Field name={`${name2}.min`}
                                                                parse={(value, name) => {
                                                                    //  console.log("parse", value, name)
                                                                    if (value) return parseFloat(value);
                                                                }}
                                                                format={(value, name) => {
                                                                    //  console.log("format", value, name)

                                                                    if (value) return parseFloat(value);
                                                                }}>
                                                                {({ input, meta }) => (
                                                                    <div>
                                                                        <label>MIN</label>
                                                                        <input type="number" className="form-control" {...input} placeholder="...." />
                                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <OnChange name={`${name2}.value`}>
                                                            {(value, previous) => {
                                                                // do something
                                                                if (value) {
                                                                    console.log(value, thing)

                                                                    if (thing.original && value > thing.original) {
                                                                        //  window.alert('Thats above the original thinkness')
                                                                    }
                                                                }
                                                            }}
                                                        </OnChange>

                                                        <div className="col-4">
                                                            <button className="btn btn-outline-danger" onClick={() => resultsFrm.fields.remove(index2)} type="button" >Remove Result</button>
                                                        </div>
                                                    </div>
                                                })}

                                                <button
                                                    className="btn btn-success"
                                                    type="button"
                                                    onClick={() => {

                                                        resultsFrm.fields.push({})
                                                    }}
                                                >Add Result</button>
                                            </>;

                                        }}
                                    </FieldArray>}


                                    <Field name={`tagNo`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Tag No</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name={`findings`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Inspection Findings</label>
                                                <textarea spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name={`engineering`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Engineering Assessment</label>
                                                <textarea spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name={`materialId`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Material</label>
                                                <select className="form-control" {...input}>
                                                    <option></option>
                                                    {values.installation && values.installation.matterials && values.installation.matterials.map((mat, mIndex) => {
                                                        return <option value={mat.value}>{mat.text}</option>
                                                    })}
                                                </select>
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>









                                    {anythingBy("Action", "action", form, values)}

                                    <Field name="action">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Action Summary</label>
                                                <textarea spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>


                                    {anythingBy("Work", "work", form, values)}
                                    <Field name="work">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Work Summary</label>
                                                <textarea spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>


                                    {anythingBy("Completed", "completed", form, values)}

                                    <Field name="completed">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Completed Summary</label>
                                                <textarea spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    {values.id !== 'new' && <div className="col-12">
                                        <h2>Approval</h2>
                                        {values.approvedByUser ? <>Approved by : {values.approvedByUser.firstName} {values.approvedByUser.lastName}</> : <>
                                            {hasPermission && hasPermission(['approveStuff']) && <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                                ev.preventDefault();
                                                setLoading(true);

                                                RequestAnomalyFunction(addNotification, 'approve', thing.id).then(() => {
                                                    RequestGetAnomaly(addNotification, thing.id).then(ddd => {
                                                        console.log("ddd", ddd)
                                                        setThing(ddd)
                                                        setLoading(false);
                                                    });
                                                })
                                            }}>Approve</button>}
                                        </>}
                                    </div>}
                                    {thing.status !== 'closed' && <div className="col-12"><button className="btn btn-primary" type="submit">Save</button></div>}

                                </div>
                            </>}
                        </div>

                    </form>

                }} />

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminAnomaly;