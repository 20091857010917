import arrayMutators from 'final-form-arrays';
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from "react-final-form-listeners";
import { Helmet } from "react-helmet";
import { Link, useRouteMatch } from "react-router-dom";
import { RequestGetAnomalys } from "../../api/anomaly-requests";
import { RequestGetEquipment, RequestNewEquipment, RequestUpdateEquipment } from "../../api/equipment-requests";
import { RequestGetInspections } from "../../api/inspection-requests";
import { RequestNewLog } from "../../api/log-requests";
import DateField from "../../components/FormStuff/date";
import DataTable from "../../components/system/DataTable";
import DateDisplay from "../../components/system/DateDisplay";
import DebugButton from "../../components/system/DebugButton";
import { useAuth } from "../../context/auth";
import { ENotificationType, useNotifications } from "../../context/notifications";
import { IAnomaly } from "../../interface/anomaly";
import { IEquipment } from "../../interface/equipment";
import { IInspection } from "../../interface/inspection";
import { ELogType, ESeverity } from "../../interface/log";
import { AttributeBlob } from './_AttributeBlob';
import { DisplayInspectionType } from './_DisplayInspecitonType';
import { InspectionDisplay } from "./_InspectionsDisplay";

const Equipment = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [thing, setThing] = useState<IEquipment>();
    const [inspections, setInspections] = useState<IInspection[]>();
    const [anomalys, setAnomalys] = useState<IAnomaly[]>();
    const [id, setId] = useState<string>('');

    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const topRef = useRef<any>()

    const { auth } = useAuth()!;
    useEffect(() => {
        console.log("get reports");
        const params: any = match.params;
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            // const report = (): Promise<any> => {
            console.log("get reports", params.id2);
            setId(params.id2);
            const prodCall = () => {
                RequestGetEquipment(addNotification, params.id2)
                    .then((json: IEquipment) => {
                        console.log(json);
                        setThing(json);
                        setLoading(false);
                    })
            }
            const inspect = () => {
                RequestGetInspections(addNotification, { equipmentId: params.id2 })
                    .then((json: IInspection[]) => {
                        console.log(json);
                        setInspections(json);
                        setLoading(false);
                    })
            }
            const anomaly = () => {
                RequestGetAnomalys(addNotification, { equipmentId: params.id2 })
                    .then((json: IAnomaly[]) => {
                        console.log(json);
                        setAnomalys(json);

                    })
            }
            Promise.allSettled([prodCall(), inspect(), anomaly()]).then(() => {
                setLoading(false)
            });


        }
    }, [])

    const onSubmit = (data: any) => {
        try {
            //  setLoading(true)
            //   setFormChanged(false)
            let ppp: Promise<IEquipment>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'InstallationLoop Created!';
                ppp = RequestNewEquipment(addNotification, data);
            } else {
                notificationText = 'InstallationLoop Saved!';
                ppp = RequestUpdateEquipment(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);

                setLoading(false)
                window.location.reload();
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - InstallationLoop - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }

    return (<>
        {(loading === false && thing && thing !== null) && <>
            <Helmet>
                <title>{thing.refId} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            <div className="col-12" ref={topRef}>
                <span className="e2eClientEquipment" style={{ display: 'none' }}>TestOk</span>
                <div className="text-left">
                    <Link to={`/installations/${thing.installId}/home`}>{thing.installation && thing.installation.name}</Link>
                    {' >'} Equipment : {thing.refId}-{thing.type}
                </div>
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="Description">

                        <DebugButton data={thing} />

                        <div className="row">
                            <h2>Details</h2>
                            {thing.system && <div className="col-3 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>System</b>
                                    <p className="e2e_System"><Link className="btn btn-outline-primary" to={`/installations/${thing.installId}/system/${thing.systemId}`}>{thing.system.refId}</Link></p>
                                </div>
                            </div>}
                            <AttributeBlob data={thing} title="Name" field="name" />
                            <AttributeBlob data={thing} title="Description" field="description" />

                            <AttributeBlob data={thing} title="RefId" field="refId" />
                            <AttributeBlob data={thing} title="Risk" field="riskAZ" />
                            <AttributeBlob data={thing} title="Likelihood" field="likelihoodAZ" />
                            <AttributeBlob data={thing} title="Combined Consequence" field="combinedConsequenceAZ" />
                            <AttributeBlob data={thing} title="Material" field="materialName" />
                            <AttributeBlob data={thing} title="Service Fluid" field="serviceFluidName" />
                            <AttributeBlob data={thing} title="Component Type" field="typeName" />
                            <AttributeBlob data={thing} title="Commission Date" field="commissionedDate" special="date" />
                            <AttributeBlob data={thing} title="Required Inspection Types" field="inspectionTypeIdsStr" />

                            <div className="col-3 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Drawings</b>
                                    <p className="e2e_circuitDescription">{thing.circuitDescription} [direct links]</p>
                                </div>
                            </div>

                        </div>
                    </Tab>
                    <Tab eventKey="detail" title="Detailed Description">

                        <div className="row">

                            <h2>Operating Conditions</h2>






                            <AttributeBlob data={thing} title="Min Temp" field="operatingTemperatureMin" />
                            <AttributeBlob data={thing} title="Max Temp" field="operatingTemperatureMax" />
                            <AttributeBlob data={thing} title="Min Pressure" field="operatingPressureMin" />
                            <AttributeBlob data={thing} title="Max Pressure" field="operatingPressureMax" />
                            <h2>Design Conditions</h2>

                            <AttributeBlob data={thing} title="Min Temp" field="designTemperatureMin" />
                            <AttributeBlob data={thing} title="Max Temp" field="designTemperatureMax" />
                            <AttributeBlob data={thing} title="Min Pressure" field="designPressureMin" />
                            <AttributeBlob data={thing} title="Max Pressure" field="designPressureMax" />


                            <h2>Testing</h2>
                            <AttributeBlob data={thing} title="Pressure" field="testingBarg" />
                            <AttributeBlob data={thing} title="Medium" field="testingMedium" />

                            <AttributeBlob data={thing} title="Criticality Rating" field="criticalityRating" />
                            <AttributeBlob data={thing} title="Flammable Consequence" field="flammableConsequence" />
                            <AttributeBlob data={thing} title="Toxic Consequence" field="toxicConsequence" />
                            <AttributeBlob data={thing} title="Combined Consequence" field="combinedConsequence" />


                            <AttributeBlob data={thing} title="Material Spec" field="materialSpecification" />
                            <AttributeBlob data={thing} title="Material Grade" field="materialSpecification" />
                            <AttributeBlob data={thing} title="Material Diameter" field="diameter" />
                            <AttributeBlob data={thing} title="Original Wall Thickness" field="originalWallThickness" />


                            <AttributeBlob data={thing} title="Corrosion Type" field="corrosionType" />
                            <AttributeBlob data={thing} title="Int Corrosion Rate" field="intCorrosionRate" />
                            <AttributeBlob data={thing} title="Int Corrosion Probability" field="intCorrosionProbability" />
                            <AttributeBlob data={thing} title="Ext Corrosion Rate" field="extCorrosionRate" />


                            <AttributeBlob data={thing} title="Ext Corrosion Probability" field="externalCorrosionProbability" />
                            <AttributeBlob data={thing} title="Other Damage Mechanism" field="otherDamageMechanisms" />
                            <AttributeBlob data={thing} title="Other Damage  Mechanism Probability" field="otherDamageMechanismProbability" />
                            <AttributeBlob data={thing} title="Combined Probability" field="combinedProbability" />
                            <AttributeBlob data={thing} title="Int Corrosion Inspection Priority" field="intCorrosionInspectionPriority" />

                            <AttributeBlob data={thing} title="Ext Corrosion Inspection Priority" field="extCorrosionInspectionPriority" />
                            <AttributeBlob data={thing} title="Other Damage Mechanism Inspection Priority" field="otherDamageMechanismInspectionPriority" />

                            <AttributeBlob data={thing} title="Environmental Cracking Inspection Priority" field="environmentalCrackingInspectionPriority" />
                            <AttributeBlob data={thing} title="Combined Inspection Priority" field="combinedInspectionPriority" />




                        </div>
                    </Tab>
                    <Tab eventKey="LOF" title="Integrity Threat Assessment">
                        <span className="e2eClientLoopDescription" style={{ display: 'none' }}>TestOk</span>
                        <DebugButton data={thing} />
                        <div className="row">
                            <h2>Internal</h2>
                            <AttributeBlob data={thing} title="Co2 LOF" field="itaIntCo2LOF" />
                            <AttributeBlob data={thing} title="Co2 Comments" field="itaIntCo2Comments" />
                            <AttributeBlob data={thing} title="PWC LOF" field="itaIntPWCLOF" />
                            <AttributeBlob data={thing} title="PWC Comments" field="itaIntPWCComments" />
                            <AttributeBlob data={thing} title="Erosion LOF" field="itaIntErosionLOF" />
                            <AttributeBlob data={thing} title="Erosion Comments" field="itaIntErosionComments" />
                            <AttributeBlob data={thing} title="Crevice Corrosion LOF" field="itaIntCreviceCorrosionLOF" />
                            <AttributeBlob data={thing} title="Crevice Corrosion Comments" field="itaIntCreviceCorrosionComments" />
                            <AttributeBlob data={thing} title="Under Deposit Corrosion LOF" field="itaIntUnderDepositCorrosionLOF" />
                            <AttributeBlob data={thing} title="Under Deposit Corrosion Comments" field="itaIntUnderDepositCorrosionComments" />
                            <AttributeBlob data={thing} title="MIC LOF" field="itaIntMICLOF" />
                            <AttributeBlob data={thing} title="MIC Comments" field="itaIntMICComments" />
                            <h2>External</h2>
                            <AttributeBlob data={thing} title="AtmosphericCorrosion LOF" field="itaExtAtmosphericCorrosionLOF" />
                            <AttributeBlob data={thing} title="Atmospheric Corrosion Comments" field="itaExtAtmosphericCorrosionComments" />
                            <AttributeBlob data={thing} title="CUI LOF" field="itaExtCUILOF" />
                            <AttributeBlob data={thing} title="CUI Comments" field="itaExtCUIComments" />
                            <AttributeBlob data={thing} title="Corrosion Under Support LOF" field="itaExtCorrosionUnderSupportLOF" />
                            <AttributeBlob data={thing} title="Corrosion Under Support Comments" field="itaExtCorrosionUnderSupportComments" />














                        </div>
                    </Tab>
                    <Tab eventKey="inspectionTypes" title="Inspection Types">

                        <div className="row">
                            <h2>Inspection Types</h2>
                            <span className="e2eClientLoopInspectionType" style={{ display: 'none' }}>TestOk</span>
                            <Form
                                onSubmit={onSubmit}
                                initialValues={thing}
                                mutators={{
                                    // setValue: ([field, value], state, { changeValue }) => {
                                    //     console.log(state, field, value)
                                    //     changeValue(state, field, () => value)
                                    // },
                                    ...arrayMutators
                                }}
                                render={({ handleSubmit, form, submitting, pristine, values }) => {
                                    //do if altered browse notifcation
                                    //  console.log(submitting, pristine)

                                    return <form onSubmit={handleSubmit}>
                                        <DebugButton data={values} />
                                        <div className="row">

                                            <FieldArray name="inspectionTypesPlannedDates">
                                                {({ fields }) => {
                                                    return <div className="col-12 row">
                                                        {fields.map((name, index) => {
                                                            const type = values.inspectionTypesPlannedDates[index];
                                                            return <div key={index} className="col-4">
                                                                <DisplayInspectionType type={type} />
                                                                <h4>Set Planned Date : </h4>
                                                                <OnChange name={`${name}.formDate`}>
                                                                    {(value, previous) => {
                                                                        // do something
                                                                        //  console.log(value);
                                                                        const ddd = [...values.inspectionTypesPlannedDates];

                                                                        const myMomentObject: Date = moment(value, 'DD-MM-YYYY').toDate();
                                                                        ddd[index].Date = myMomentObject.toString();

                                                                        console.log(value, myMomentObject, ddd);
                                                                        form.change(`inspectionTypesPlannedDates`, ddd);

                                                                        const inspectionDeferrals = [...values.inspectionDeferrals]
                                                                        const difIndex = inspectionDeferrals.findIndex((dd: any) => dd.i === type.id)
                                                                        if (difIndex !== -1) {
                                                                            //update
                                                                            inspectionDeferrals[difIndex].Date = myMomentObject.toString();
                                                                            inspectionDeferrals[difIndex].formDate = value;
                                                                        } else {
                                                                            ddd[index].Date = { ...myMomentObject };
                                                                            const thing = {
                                                                                id: type.id,
                                                                                Date: myMomentObject.toString(),
                                                                                formDate: value
                                                                            };
                                                                            inspectionDeferrals.push(thing)
                                                                        }

                                                                        form.change(`inspectionDeferrals`, inspectionDeferrals);
                                                                    }}
                                                                </OnChange>
                                                                <Field name={`${name}.formDate`} label="Inspection Date" component={DateField} />
                                                            </div>
                                                        })}

                                                    </div>
                                                }}
                                            </FieldArray>



                                        </div>
                                        <button className="btn btn-primary" type="submit">Save</button>z
                                    </form>
                                }} />


                        </div>
                    </Tab>
                    <Tab eventKey="inspection" title="Inspections">
                        {inspections && <InspectionDisplay inspections={inspections} equipmentId={thing.id} />}
                    </Tab>
                    <Tab eventKey="schedule" title="Inspection Schedule">

                        <div className="row">
                            <h2>Inspection Schedule</h2>

                            {thing.inspectionSchedule && thing.inspectionSchedule.map((s, sindex) => {
                                return <div key={sindex} className="col-6">
                                    <h2>{s.type}</h2>
                                    <p className={`${s.overdue ? 'bg-danger' : 'bg-primary'}`}>DUE {s.inspectionYear} <br />
                                        <DateDisplay format="YYYY-MM-DD" date={s.inspectionDue} /></p>
                                    {s.lastInspected && <div className="w-100">
                                        Last Inspected :

                                        <DateDisplay format="YYYY-MM-DD" date={s.lastInspected.date} /><br />
                                        Confidence {s.inspectedConfidence}



                                        {s.inspectedConfidence === 0 && <>Low</>}
                                        {s.inspectedConfidence === 1 && <>Medium</>}
                                        {s.inspectedConfidence === 2 && <>High</>}

                                    </div>}

                                    <DebugButton data={s} />
                                </div>
                            })}

                        </div>
                    </Tab>
                    <Tab eventKey="anomalies" title="Anomalies">
                        <h2>Anomalies</h2>
                        {anomalys && <DataTable

                            hideIndexCol={true}
                            colHeadings={[
                                { name: "ID", field: "refId", sortable: true },
                                { name: "Subject", field: "subject", sortable: false },
                                { name: "Status", field: "status", sortable: false },
                                {
                                    name: "Date", field: "date", r: (value: any, index) => {
                                        return <DateDisplay format="YYYY-MM-DD" date={value.date} />;
                                    }, sortable: true
                                },
                                {
                                    name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                        return <div className="btn-group">
                                            <DebugButton data={value} />
                                            <Link className="btn btn-primary" to={`/installations/${value.installId}/anomaly/${value.id}`}>Details</Link>
                                            {value.pipe && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.pipeId}`}>Pipe: {value.pipe.refId}</Link>}
                                            {value.equipment && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.equipmentId}`}>Equipment: {value.equipment.refId}</Link>}


                                        </div>;
                                    }, sortable: false
                                },

                            ]}

                            data={anomalys}
                        />}
                    </Tab>
                </Tabs>

            </div>



        </>}

    </>
    );


}
export default Equipment;