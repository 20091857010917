import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { RequestGetInstallations } from "../../api/installation-requests";
import { RefreshPage } from "../../components/RefreshPage";
import DataTable from "../../components/system/DataTable";
import DebugButton from "../../components/system/DebugButton";
import { useAuth } from "../../context/auth";
import { useNotifications } from "../../context/notifications";
import { usePageTitle } from "../../context/pageTitle";
import { IInstallation } from "../../interface/installation";
import Anomaly from "./Anomaly";
import Equipment from "./Equipment";
import Inspection from "./Inspection";
import Installation from "./Installation";
import Loop from "./Loop";
import Pipe from './Pipe';
import System from "./System";
const Installations = () => {
    // const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IInstallation[]>();
    const { addNotification } = useNotifications()!;
    let match = useRouteMatch();
    const { auth } = useAuth()!;

    useEffect(() => {
        console.log("get Installation");
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            RequestGetInstallations(addNotification)
                .then(assignments => {
                    setThings(assignments)
                    setLoading(false);

                })

        }
    }, [])
    const { setPageTitle } = usePageTitle()!;

    const location = useLocation();



    useEffect(() => {



        setPageTitle && setPageTitle('My Retriever');

    }, [location])

    let ColHead: any[] = [
        { name: 'Name', field: 'name', sortable: true, align: 'left' },


    ]


    if (auth.type === 'admin') {

        ColHead.push({
            name: "Client", field: "client", sortable: false, r: (value: any, inde: number) => {
                return <>{value.client !== null && value.client.name}</>
            }
        })
    }

    ColHead.push({
        name: "Actions", align: "right", field: "id", r: (value: any, index: number) => {
            return <div className="btn-group">
                <DebugButton data={value} />
                {auth.type === 'admin' && <Link className="btn btn-warning" to={`/admin/installations/${value.id}/home`}>Edit Installation</Link>}
                <Link className="btn btn-primary" to={`${match.url}/${value.id}/home`}>View Details</Link>

            </div>;
        }, sortable: false
    })
    return (<>
        {things && <>
            <Helmet>
                <title>My Installations / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <Switch>
                <Route path={`${match.path}`} exact>
                    <div className="col-12">
                        <RefreshPage />
                        <DataTable colHeadings={ColHead} data={things} />
                    </div>
                </Route>
                <Route path={`${match.path}/:id/:page`}>
                    <Installation />
                </Route>
                <Route path={`${match.path}/:id/pipe/:pipeId`}>
                    <Pipe />
                </Route>
                <Route path={`${match.path}/:id/equipment/:equipmentId`}>
                    <Equipment />
                </Route>
                <Route path={`${match.path}/:id/loop/:loopId`}>
                    <Loop />
                </Route>
                <Route path={`${match.path}/:id/anomaly/:anomalyId`}>
                    <Anomaly />
                </Route>
                <Route path={`${match.path}/:id/inspection/:inspectionId`}>
                    <Inspection />
                </Route>
                <Route path={`${match.path}/:id/system/:systemId`}>
                    <System />
                </Route>
            </Switch>

        </>}

    </>
    );
}

export default Installations;