import { useEffect, useState } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestDeletePage, RequestGetPages } from '../../../api/page-requests';
import DataTable from "../../../components/system/DataTable";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { usePageTitle } from "../../../context/pageTitle";
import { ELogType, ESeverity } from "../../../interface/log";
import { IPage } from "../../../interface/page";
import AdminPage from './Admin.Page';


const AdminPagesPge = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IPage[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;




    const { setPageTitle } = usePageTitle()!;
    useEffect(() => {
        // 
        setPageTitle && setPageTitle('Pages', 'admin');

    }, [])



    useEffect(() => {
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react stores onunload'
        });
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }

    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        try {
            if (loading === true) {
                // eslint-disable-next-line
                {/*<!-- OPTIONAL 2 -->*/ }
                RequestGetPages(addNotification).then(thing => {
                    //   console.log(stores)
                    setThings(thing);
                    setLoading(false);
                })
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Pages - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading, addNotification]);

    const onDelete = (value: IPage) => {
        try {

            const conf = window.confirm("Delete?");
            if (conf) {
                setLoading(true);
                RequestDeletePage(addNotification, value).then(res => {
                    //  console.log(res);
                    let thingsD: IPage[] = [];
                    if (things) {
                        thingsD = [...things];
                    }
                    const index = thingsD.findIndex(thing => thing._id === value._id);
                    thingsD.splice(index, 1);

                    setThings(thingsD);
                    setLoading(false);
                    addNotification && addNotification('Success', 'Page Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Pages - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    return (<>
        {loading === true && <Loading />}
        {things && <div className="col-12">
            <h2>Pages Admin</h2>

            <Link to="./pages/new">New</Link>
            {/*<!-- OPTIONAL 3 -->*/}
            <DataTable

                colHeadings={[
                    { name: "Name", field: "name", sortable: false },
                    { name: "Slug", field: "slug", sortable: false },
                    {
                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} />
                                <Link className="btn btn-secondary" to={`/p/${value.slug}`}>Go</Link>
                                <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    onDelete(value);
                                }}>Delete</button>
                            </div>;
                        }, sortable: false
                    },

                ]}

                data={things}
            />

            {/*<!-- OPTIONAL END -->*/}
        </div>}
    </>
    );
};
const AdminPages = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminPage />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminPagesPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminPages;