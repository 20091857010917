import { faAddressCard, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useRouteMatch } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { RequestGetAnomalys } from "../../api/anomaly-requests";
import { RequestGetEquipments } from "../../api/equipment-requests";
import { RequestGetInspections } from "../../api/inspection-requests";
import { RequestGetInstallationSystem } from "../../api/installation-system-requests";
import { RequestGetPipes } from "../../api/pipe-requests";
import DataTable from "../../components/system/DataTable";
import DateDisplay from "../../components/system/DateDisplay";
import DebugButton from "../../components/system/DebugButton";
import TextDisplay from "../../components/system/TextDisplay";
import { useAuth } from "../../context/auth";
import { useNotifications } from "../../context/notifications";
import { IAnomaly } from "../../interface/anomaly";
import { IEquipment } from "../../interface/equipment";
import { IInspection, IInspectionReported } from "../../interface/inspection";
import { IInstallationSystem } from "../../interface/installation-system";
import { IPipe } from "../../interface/pipe";
const System = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [resultsCount, setResultsCount] = useState<number>(0);
    const [thing, setThing] = useState<IInstallationSystem>();
    const [inspections, setInspections] = useState<IInspection[]>();
    const [query, setQueryObj] = useState<any>({});
    const [id, setId] = useState<string>('');
    const [anomalys, setAnomalys] = useState<IAnomaly[]>();
    const [pipes, setPipes] = useState<IPipe[]>();
    const [equipment, setEquipment] = useState<IEquipment[]>();

    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const topRef = useRef<any>()
    const tableRef = useRef<any>()
    const [detailDisplayTable, setDetailDisplayTable] = useState<boolean>(false)
    const { auth } = useAuth()!;
    useEffect(() => {
        console.log("get reports");
        const params: any = match.params;
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            setId(params.systemId)
            // const report = (): Promise<any> => {
            console.log("get reports", params);
            const prodCall = () => {
                RequestGetInstallationSystem(addNotification, params.id2)
                    .then(json => {
                        console.log(json);
                        setThing(json);

                    })
            }
            const inspect = () => {
                RequestGetInspections(addNotification, { systemId: params.id2 })
                    .then((json: IInspection[]) => {
                        console.log(json);
                        setInspections(json);

                    })
            }
            const pipes = () => {
                RequestGetPipes(addNotification, { systemId: params.id2 })
                    .then((json: IPipe[]) => {
                        console.log(json);
                        setPipes(json);

                    })
            }
            const equip = () => {
                RequestGetEquipments(addNotification, { systemId: params.id2 })
                    .then((json: IEquipment[]) => {
                        console.log(json);
                        setEquipment(json);

                    })
            }
            const anomaly = () => {
                RequestGetAnomalys(addNotification, { systemId: params.id2 })
                    .then((json: IAnomaly[]) => {
                        console.log(json);
                        setAnomalys(json);

                    })
            }
            Promise.allSettled([prodCall(), pipes(), equip(), inspect(), anomaly()]).then(() => {
                setLoading(false)
            });


        }
    }, [])

    return (<>
        {(loading === false && thing && thing !== null) && <>
            <Helmet>
                <title>{thing.refId} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <div className="col-12" ref={topRef}>
                <div className="text-left">
                    <Link to={`/installations/${thing.installId}/home`}>{thing.installation && thing.installation.name}</Link>
                    {' >'} System : {thing.refId}
                </div>
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="Description">
                        <DebugButton data={thing} />
                        <div className="row">
                            <h2>Description</h2>
                            <div className="col-3 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Name</b>
                                    <p>{thing.name}</p>
                                </div>
                            </div>
                            <div className="col-3 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Ref Id</b>
                                    <p>{thing.refId}</p>
                                </div>
                            </div>
                            <div className="col-12 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Description</b>
                                    <p>{thing.description}</p>
                                </div>
                            </div>

                        </div>
                    </Tab>
                    <Tab eventKey="pipes" title="Pipes">
                        <DebugButton data={thing} />
                        <div className="row">
                            <h2>Pipes</h2>
                            {pipes && <DataTable
                                hideIndexCol={true}
                                colHeadings={[
                                    { name: "Name", field: "name", sortable: true },
                                    { name: "Description", field: "description", sortable: true },


                                    {
                                        name: "Loop", field: "loopIdNumber", r: (value: any, index) => {
                                            return <>{value.loop && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/loop/${value.loopId}`}>{value.loop.refId}</Link>}</>;
                                        }, sortable: true
                                    },


                                    {
                                        name: "Risk", field: "riskAZ", sortable: true,
                                    },
                                    {
                                        name: "Fluid", field: "fluidName", sortable: true,
                                    },
                                    {
                                        name: "Material", field: "materialName", sortable: true,
                                    },
                                    { name: "Size", field: "size", sortable: true, queryable: true },

                                    {
                                        name: "From", field: "fromStr", r: (value: any, index, addToQuery) => {
                                            return <>{value.fromStr}
                                                {value.fromEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.fromEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                                {value.fromPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.fromPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                            </>
                                        }, sortable: true
                                    },
                                    {
                                        name: "To", field: "toStr", r: (value: any, index, addToQuery) => {
                                            return <>{value.toStr}
                                                {value.toEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.toEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                                {value.toPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.toPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}

                                            </>

                                        }, sortable: true
                                    },
                                    {
                                        name: "Anomalies", field: "newAnomalies", r: (value: any, index) => {
                                            return <>New {value.newAnomalies} / Closed: {value.closedAnomalies}</>;
                                        }, sortable: true
                                    },

                                    {
                                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                <Link className="btn btn-primary" id={`e2e${index - 1}PipeDetails`} to={`/installations/${thing.id}/pipe/${value.id}`}>Details</Link>

                                            </div>;
                                        }, sortable: false
                                    },

                                ]}

                                data={pipes}
                            />
                            }
                        </div>
                    </Tab>
                    <Tab eventKey="equipment" title="Equipment">
                        <DebugButton data={thing} />
                        <div className="row">
                            <h2>Equipment</h2>
                            {equipment && <DataTable
                                hideIndexCol={true}
                                colHeadings={[
                                    { name: "Name", field: "name", sortable: false },
                                    { name: "Description", field: "description", sortable: true },

                                    { name: "Fluid", field: "fluidId", sortable: true, queryable: true },
                                    { name: "Type", field: "type", sortable: false },

                                    { name: "Risk", field: "riskAZ", sortable: true },
                                    {
                                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                            return <div className="btn-group">
                                                <DebugButton data={value} />
                                                <Link className="btn btn-primary" to={`/installations/${value.installId}/equipment/${value.id}`}>Details</Link>

                                            </div>;
                                        }, sortable: false
                                    },

                                ]}

                                data={equipment}
                            />
                            }
                        </div>
                    </Tab>
                    <Tab eventKey="inspection" title="Inspection">
                        <>
                            <h2>Inspection</h2>
                            <VerticalTimeline >

                                {inspections && inspections.map(insp => {
                                    const inspected: IInspectionReported[] = insp.reported.filter(dd => dd.systemId === id)
                                    return <VerticalTimelineElement
                                        dateClassName={'text-dark'}
                                        className="vertical-timeline-element--work"
                                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                        date={format(new Date(insp.dateOfReport), "dd-MM-yyyy")}
                                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                        icon={<FontAwesomeIcon icon={faAddressCard} />}
                                    >
                                        {/* <h3 className="vertical-timeline-element-title">{insp.typeId}</h3> */}

                                        {inspected && inspected.map((ins, index) => {
                                            return <><h4 className="vertical-timeline-element-subtitle">{index + 1} : {ins.typeId} {ins && ins.tagNo} {ins.loop && <>LOOP {ins.loop.refId}</>}</h4><TextDisplay text={ins.findings} /></>
                                        })}


                                        <p>

                                            <Link className="btn btn-primary" to={`/installations/${insp.installId}/inspection/${insp.id}`}>Details</Link>

                                        </p>
                                    </VerticalTimelineElement>
                                })}


                            </VerticalTimeline>

                        </>
                    </Tab>
                    <Tab eventKey="anomalies" title="Anomalies">
                        <h2>Anomalies</h2>
                        {anomalys && <DataTable

                            hideIndexCol={true}
                            colHeadings={[
                                { name: "ID", field: "refId", sortable: true },
                                { name: "Subject", field: "subject", sortable: false },
                                { name: "Status", field: "status", sortable: false },
                                {
                                    name: "Loop", field: "loopId", r: (value: any, index) => {
                                        return <>{value.loop && <Link className="btn btn-outline-primary" to={`${value.installId}/loop/${value.loopId}`}>{value.loop.refId}</Link>}</>;
                                    }, sortable: false
                                },
                                {
                                    name: "Date", field: "date", r: (value: any, index) => {
                                        return <DateDisplay format="YYYY-MM-DD" date={value.date} />;
                                    }, sortable: true
                                },
                                {
                                    name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                        return <div className="btn-group">
                                            <DebugButton data={value} />
                                            <Link className="btn btn-primary" to={`/installations/${value.installId}/anomaly/${value.id}`}>Details</Link>
                                            {value.pipe && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.pipeId}`}>Pipe: {value.pipe.refId}</Link>}
                                            {value.equipment && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.equipmentId}`}>Equipment: {value.equipment.refId}</Link>}


                                        </div>;
                                    }, sortable: false
                                },

                            ]}

                            data={anomalys}
                        />}
                    </Tab>


                </Tabs>

            </div>



        </>}

    </>
    );
}

export default System;