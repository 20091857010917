import DateDisplay from "../../components/system/DateDisplay"

export const DisplayInspectionType = ({ type }: any) => {

    return <div className="e2eInspectionType"><h2 className={`e2eInspectionTyp_name e2e_${type.id}_name`}>{type.name}</h2>
        <p>
            No Of Inspections {type.noOfInspections}<br />
            Last Inspected <span className={`e2e_${type.id}_LastInspectedDate`}><DateDisplay format="YYYY-MM-DD" date={type.lastInspectedDate} /></span><br />
            Scheduled For <span className={`e2e_${type.id}_Due`}><DateDisplay format="YYYY-MM-DD" date={type.inspectionDue} /></span><br />
            Inspection Interval (Months)<span className={`e2e_${type.id}_Interval`}>{type.inspectionMonths}</span><br />
            Last Inspection Confidence  <span className={`e2e_${type.id}_Confidence`}>
                {type.inspectedConfidence === 0 && <>Low</>}
                {type.inspectedConfidence === 1 && <>Medium</>}
                {type.inspectedConfidence === 2 && <>High</>}

            </span>
        </p>
    </div>

}