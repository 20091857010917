const simpleMemoize = (fn: any) => {
    let lastArg: any;
    let lastResult: any;
    return (arg: any) => {
        if (arg !== lastArg) {
            lastArg = arg;
            lastResult = fn(arg);
        }
        return lastResult;
    };
};

export default simpleMemoize;