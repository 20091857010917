import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useRouteMatch } from 'react-router-dom';
import { RequestGetAnomalys } from '../../api/anomaly-requests';
import { RequestGetDrawings } from '../../api/drawing-requests';
import { RequestGetInspections } from '../../api/inspection-requests';
import { RequestGetPipe } from '../../api/pipe-requests';
import DataTable from '../../components/system/DataTable';
import DateDisplay from '../../components/system/DateDisplay';
import DebugButton from '../../components/system/DebugButton';
import PopupImage from '../../components/system/PopupImage';
import { useAuth } from '../../context/auth';
import { useNotifications } from '../../context/notifications';
import { IAnomaly } from '../../interface/anomaly';
import { IDrawing } from '../../interface/drawing';
import { IInspection } from '../../interface/inspection';
import { IPipe } from '../../interface/pipe';
import { AttributeBlob } from './_AttributeBlob';
import { InspectionDisplay } from './_InspectionsDisplay';


const Pipe = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [thing, setThing] = useState<IPipe>();
    const [inspections, setInspections] = useState<IInspection[]>();
    const [drawings, setDrawings] = useState<IDrawing[]>();
    const [anomalys, setAnomalys] = useState<IAnomaly[]>();
    const [id, setId] = useState<string>('');
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const topRef = useRef<any>()
    const tableRef = useRef<any>()
    const [detailDisplayTable, setDetailDisplayTable] = useState<boolean>(false)
    const { auth } = useAuth()!;
    useEffect(() => {
        console.log("get reports");
        const params: any = match.params;
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            // const report = (): Promise<any> => {
            console.log("get reports", params);
            setId(params.pipeId);
            const prodCall = () => {
                RequestGetPipe(addNotification, params.id2)
                    .then((json: IPipe) => {
                        console.log(json);
                        setThing(json);
                        setLoading(false);
                    })
            }
            const inspect = () => {
                RequestGetInspections(addNotification, { pipeId: params.id2 })
                    .then((json: IInspection[]) => {
                        console.log(json);
                        setInspections(json.reverse());
                        setLoading(false);
                    })
            }


            const anomaly = () => {
                RequestGetAnomalys(addNotification, { pipeId: params.id2 })
                    .then((json: IAnomaly[]) => {
                        console.log(json);
                        setAnomalys(json);

                    })
            }
            const drawings = () => {
                console.log({ pipeId: params.pipid2eId })
                RequestGetDrawings(addNotification, { pipeId: params.id2 })
                    .then((json: IDrawing[]) => {
                        console.log(json);
                        setDrawings(json);

                    })
            }
            Promise.allSettled([prodCall(), inspect(), anomaly(), drawings()]).then(() => {
                setLoading(false)
            });


        }
    }, [])
    let lowestThickness: number = 0;

    let InspectionResults: any = [];
    if (thing && thing.inspected) {
        lowestThickness = thing.originalWallThickness;
        InspectionResults = thing.inspected.map((value, index) => {
            console.log(value);
            return {
                date: value.date, results: value.results.map((res, ires) => {
                    if (res.value <= lowestThickness) {
                        lowestThickness = res.value;
                    }
                    return res;
                })
            }
        })

        InspectionResults.reverse();
    }

    return (<>
        {(loading === false && thing && thing !== null) && <>
            <Helmet>
                <title>{thing.refId} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <div className="col-12" ref={topRef}>
                <span className="e2eClientPipe" style={{ display: 'none' }}>TestOk</span>
                <div className="text-left">
                    <Link to={`/installations/${thing.installId}/home`}>{thing.installation && thing.installation.name}</Link>
                    {' >'} Pipe : {thing.size}-{thing.serviceFluidId}-{thing.refId}
                </div>
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="Description">
                        <span className="e2eClientPipeHome" style={{ display: 'none' }}>TestOk</span>
                        <div className="row">
                            <h2>Description</h2>
                            {thing.loop && <div className="col-3 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Loop</b>
                                    <p id="e2e_loop"><Link className="btn btn-outline-primary" to={`/installations/${thing.installId}/loop/${thing.loopId}`}>{thing.loop.refId}</Link></p>
                                </div>
                            </div>}
                            {thing.system && <div className="col-3 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>System</b>
                                    <p id="e2e_system"><Link className="btn btn-outline-primary" to={`/installations/${thing.installId}/system/${thing.systemId}`}>{thing.system.refId}</Link></p>
                                </div>
                            </div>}



                            <AttributeBlob data={thing} title="Name" field="name" />
                            <AttributeBlob data={thing} title="Description" field="description" />
                            <AttributeBlob data={thing} title="Circuit Description" field="circuitDescription" />
                            <AttributeBlob data={thing} title="Risk" field="riskAZ" />
                            <AttributeBlob data={thing} title="Likelihood" field="likelihoodAZ" />
                            <AttributeBlob data={thing} title="Combined Consequence" field="combinedConsequenceAZ" />
                            <AttributeBlob data={thing} title="Spec" field="spec" />
                            <AttributeBlob data={thing} title="RefId" field="refId" />
                            <AttributeBlob data={thing} title="Fluid" field="fluidName" />
                            <AttributeBlob data={thing} title="Service Fluid" field="serviceFluidName" />
                            <AttributeBlob data={thing} title="Material" field="materialName" />
                            <AttributeBlob data={thing} title="Commission Date" field="commissionedDate" special="date" />
                            <AttributeBlob data={thing} title="criticality Rating" field="criticalityRating" />
                            <AttributeBlob data={thing} title="Material Specification" field="materialSpecification" />
                            <AttributeBlob data={thing} title="Material Grade" field="materialGrade" />

                            <AttributeBlob data={thing} title="Size" field="size" />
                            <AttributeBlob data={thing} title="Diameter" field="diameter" />
                            <AttributeBlob data={thing} title="Pressure Relief" field="pressureRelief" />
                            {/* {JSON.stringify(drawings)} */}
                            {drawings && drawings.length > 0 && <div className="col-12 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Drawings</b>

                                </div>
                                <div className="row">
                                    {drawings && drawings.map((draw, index: number) => {
                                        return <div className="col-4" key={index}>
                                            {draw.image && <><img className="img-fluid" src={draw.image.c400} /><br /><PopupImage data={draw.image} /></>}
                                        </div>
                                    })}
                                </div>
                            </div>}

                            <h2>Location</h2>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>From</b>
                                    <p id="e2e_fromStr">

                                        {thing.fromStr} {thing.fromEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${thing.installId}/equipment/${thing.fromEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}

                                    </p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>To</b>
                                    <p id="e2e_toStr">

                                        {thing.toStr} {thing.toEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${thing.installId}/equipment/${thing.toEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}

                                    </p>
                                </div>
                            </div>
                        </div>

                        <DebugButton data={thing} />
                    </Tab>
                    <Tab eventKey="detail" title="Detailed Description">

                        <div className="row">
                            <h2>Wall Thickness</h2>

                            <AttributeBlob data={thing} title="Original" field="originalWallThickness" />



                            <div className="col-3 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Current</b>
                                    <p id="e2e_currentThickness">{lowestThickness === 0 ? thing.originalWallThickness : lowestThickness}</p>
                                </div>
                            </div>

                            <div className="col-12 border border-start">
                                {InspectionResults.length > 0 && <><table className="table"><thead>
                                    <tr><th>Date</th> {thing.testPoints.map((point, pointI) => {
                                        return <th key={pointI}>{point}</th>
                                    })}</tr>
                                </thead>

                                    <tbody>
                                        {InspectionResults.map((thing: any, iindex: number) => {
                                            return <tr><td><DateDisplay format="YYYY-MM-DD" date={thing.date} /></td>
                                                {thing.results.map((thinR: any, rindex: number) => {
                                                    return <td>{thinR.value}</td>
                                                })}

                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                                </>}

                            </div>
                            <h2>Operating Conditions</h2>






                            <AttributeBlob data={thing} title="Min Temp" field="operatingTemperatureMin" />
                            <AttributeBlob data={thing} title="Max Temp" field="operatingTemperatureMax" />
                            <AttributeBlob data={thing} title="Min Pressure" field="operatingPressureMin" />
                            <AttributeBlob data={thing} title="Max Pressure" field="operatingPressureMax" />
                            <h2>Design Conditions</h2>

                            <AttributeBlob data={thing} title="Min Temp" field="designTemperatureMin" />
                            <AttributeBlob data={thing} title="Max Temp" field="designTemperatureMax" />
                            <AttributeBlob data={thing} title="Min Pressure" field="designPressureMin" />
                            <AttributeBlob data={thing} title="Max Pressure" field="designPressureMax" />


                            <h2>Testing</h2>
                            <AttributeBlob data={thing} title="Pressure" field="testingBarg" />
                            <AttributeBlob data={thing} title="Medium" field="testingMedium" />
                            <h2>Piping</h2>
                            <AttributeBlob data={thing} title="Insulation Type" field="insulationType" />
                            <AttributeBlob data={thing} title="Insulation Thickness" field="insulationThk" />

                            <AttributeBlob data={thing} title="Paint Code" field="paintCode" />





                        </div>
                    </Tab>
                    <Tab eventKey="inspection" title="Inspections">
                        {inspections && <InspectionDisplay inspections={inspections} pipeId={thing.id} />}
                    </Tab>
                    <Tab eventKey="anomalies" title="Anomalies">
                        <h2>Anomalies</h2>
                        {anomalys && <DataTable

                            hideIndexCol={false}
                            colHeadings={[
                                { name: "ID", field: "refId", sortable: true },
                                { name: "Subject", field: "subject", sortable: false },
                                { name: "Status", field: "status", sortable: false },
                                {
                                    name: "Date", field: "date", r: (value: any, index) => {
                                        return <DateDisplay format="YYYY-MM-DD" date={value.date} />;
                                    }, sortable: true
                                },
                                {
                                    name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                        return <div className="btn-group">
                                            <DebugButton data={value} />
                                            <Link className="btn btn-primary" to={`/installations/${value.installId}/anomaly/${value.id}`}>Details</Link>


                                        </div>;
                                    }, sortable: false
                                },

                            ]}

                            data={anomalys}
                        />}
                    </Tab>


                </Tabs>

            </div>



        </>}

    </>
    );
}

export default Pipe;