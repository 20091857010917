import { IClient } from "./client";
import { IFile } from "./file";
import { IImage } from "./image";
import { IImpurity } from "./impurity";
import { IAll } from "./_modelAll";

export interface IAssignment extends IAll {
    id: string;
    name: string;
    clientId: string;
    client?: IClient;
    description: string;
    parentId: string;
    assets: string[];
    assetsDetailed: IAssignmentSubThing[];
    modes: string[];
    modesDetailed: IAssignmentSubThing[];
    additional: any[];
    impurities: string[];
    impuritiesDetailed?: IImpurity[];
    damageMechanisms: string[];
    riskMatrix: any[];
    forms: IAssignmentForm[];
    image?: IImage;
    revision: number;
    state: EAssignmentSate;
}

export interface IAssignmentSubThing {
    id: string,
    name: string,
}
export interface IAssignmentForm {
    Additional: string[];
    AssetSection: string[];
    OperatingMode: string[];
    Impurities: string[];
    DamageMechanism: string[];
    ExistingSafeguards: string;
    RiskDescription: string;
    QuantMethod: string;
    Severity: number;
    Likelihood: number;
    Risk: string;

    ResSeverity: number;
    ResLikelihood: number;
    ResRisk: string;
    SpecificActions: string;
    fileIds: string[];
    files: IFile[];
    aproved: boolean;
    aprovedTs: Date;
    aprovedBy: string;

}


export enum EAssignmentSate {
    "Draft" = "draft",
    "Complete" = "complete"
}
