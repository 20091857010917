import moment from 'moment';
import { parseFields } from './_parseFields';


export const parseCSV = (data: any[], targetArray: string, fields: any[], endTargetArray: string, values: any, form: any, doRisk: boolean) => {
    console.log("PARSEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE", data, targetArray, fields, endTargetArray)
    const uplaodD = data.map(ddd => {
        const possibleUpdate: any = {
            object: { ...ddd },
            error: false,
            errorTxt: '',
            isNew: (ddd.id === '' || ddd.id === null) ? true : false,
            update: (ddd.id === '' || ddd.id === null) ? false : true,
            missingFields: false,
            action: false,
            missingArr: [],
            foundId: '',
            fieldComparison: [],
        }


        if (possibleUpdate.isNew !== true) {
            const valuesIndex = values[targetArray].findIndex((dd: any) => dd.id === ddd.id)
            console.log(valuesIndex)
            if (valuesIndex !== -1) {
                const current: any = values[targetArray][valuesIndex];
                possibleUpdate.foundId = current.id;
                possibleUpdate.original = { ...current }

                for (let index = 0; index < fields.length; index++) {
                    parseFields(fields, index, ddd, current, possibleUpdate, values);
                }


                //lof cRAP

                possibleUpdate.object = { ...ddd }

            } else {
                possibleUpdate.errorTxt = 'id lookup failed'
                possibleUpdate.error = true;
            }

        } else {
            for (let index = 0; index < fields.length; index++) {
                const field: any = { ...fields[index] };
                const fname = field.field;
                const update = ddd[fname];
                const ftype = field.type;
                const validation = {
                    ok: true, msg: '', echo: '',
                }

                console.log("ffs", field)
                //  field.currentValue = current[fname];
                // field.updateValue = ddd[fname]

                if (update !== undefined) {

                    if (field.required) {
                        if (update === '') {
                            validation.ok = false;
                            validation.msg = 'Required'
                        }
                    }
                    switch (ftype) {
                        case 'inspection-types':
                            const targetField = field.targetField;
                            const newArr: string[] = [];
                            const split: string[] = update.split(',');
                            split.map(str => {
                                if (newArr.findIndex(s => s === str) === -1) {
                                    newArr.push(str)
                                }
                            })
                            ddd[targetField] = newArr;
                            break;
                        case 'date':
                            const splitOne = update.split('-');
                            const splitTwo = update.split('/');
                            console.log("SSS", splitOne, splitTwo)
                            if (splitOne.length === 3) {
                                const momented = moment(update, 'DD-MM-YYYY')
                                const targetField = field.targetField;
                                if (momented) {
                                    ddd[targetField] = momented.toDate();
                                    possibleUpdate.fieldComparison.push({ fname: targetField, current: null, update: momented.toDate().toString(), validation: { ok: true, msg: '' } })
                                } else {
                                    //  validation.ok = false;
                                    validation.msg = 'Parse failed (1)'
                                }
                            } else if (splitTwo.length === 3) {
                                const momented = moment(update, 'DD-MM-YYYY')
                                const targetField = field.targetField;
                                if (momented) {
                                    ddd[targetField] = momented.toDate();
                                    possibleUpdate.fieldComparison.push({ fname: targetField, current: null, update: momented.toDate().toString(), validation: { ok: true, msg: '' } })
                                } else {
                                    //  validation.ok = false;
                                    validation.msg = 'Parse failed (2)'
                                }
                            } else {
                                validation.msg = 'Parse failed (*)'
                            }

                            break;
                        case 'equipmentType':
                            // eq = (current[fname] === update)
                            const equipIndex = values.equipmentTypes.findIndex((dd: any) => dd.value === update.toString())
                            if (equipIndex === -1) {
                                validation.ok = false;
                                validation.msg = 'Failed to find equipment type that matches'
                            }
                            break;
                        case 'fluid':
                            // eq = (current[fname] === update)
                            const fluidIndex = values.fluids.findIndex((dd: any) => dd.value === update.toString())
                            if (fluidIndex === -1) {
                                validation.ok = false;
                                validation.msg = 'Failed to find fluid that matches'
                            }
                            break;
                        case 'matterial':
                            // eq = (current[fname] === update)
                            const matterialIndex = values.matterials.findIndex((dd: any) => dd.value === update.toString())
                            console.log("MATTERIAL", matterialIndex)
                            if (matterialIndex === -1) {
                                validation.ok = false;
                                validation.msg = 'Failed to find matterial that matches'
                            }
                            break;
                        case 'text':
                            // eq = (current[fname] === update)
                            break;
                        case 'boolean':
                            if (update.toLowerCase() === 'true') {
                                ddd[fname] = true;
                            } else if (update.toLowerCase() === '1') {
                                ddd[fname] = true;
                            } else if (update.toLowerCase() === 'yes') {
                                ddd[fname] = true;
                            } else if (update.toLowerCase() === 'false') {
                                ddd[fname] = false;
                            } else if (update.toLowerCase() === '0') {
                                ddd[fname] = false;
                            } else if (update.toLowerCase() === 'no') {
                                ddd[fname] = false;
                            } else {
                                ddd[fname] = false;
                            }
                            break;
                        case 'int':
                            if (isNaN(Number(update))) {
                                validation.ok = false;
                                validation.msg = 'Not a number';

                                // eq = (current[fname] === update)
                            }
                            break;
                        case 'number':
                            if (isNaN(Number(update))) {
                                validation.ok = false;
                                validation.msg = 'Not a number';

                                // eq = (current[fname] === update)
                            }
                            break;
                        case 'float':
                            if (isNaN(Number(update))) {
                                validation.ok = false;
                                validation.msg = 'Not a number';

                                // eq = (current[fname] === update)
                            }
                            break;
                        case 'system':
                            //   eq = (current[fname] === ddd[fname])
                            const systemIndex = values.systems.findIndex((dd: any) => dd.refId === update.toString())
                            if (systemIndex === -1) {
                                validation.ok = false;
                                validation.msg = 'Failed to find system with that refId'
                            } else {
                                const id = values.systems[systemIndex].id;
                                ddd.systemId = id;
                                possibleUpdate.fieldComparison.push({ fname: 'systemId', current: null, update: id, validation: { ok: true, msg: '' } })
                            }

                            break;
                        case 'loop':
                            //   eq = (current[fname] === ddd[fname])
                            const loopIndex = values.loops.findIndex((dd: any) => dd.refId === update.toString())
                            if (loopIndex === -1) {
                                validation.ok = false;
                                validation.msg = 'Failed to find loop with that refId'
                            } else {
                                const id = values.loops[loopIndex].id;
                                ddd.loopId = id;
                                ddd.systemId = values.loops[loopIndex].systemId;
                                possibleUpdate.fieldComparison.push({ fname: 'loopId', current: null, update: id, validation: { ok: true, msg: '' } })
                            }

                            break;
                        case 'AZ':
                            //  eq = (current[fname] === update)

                            console.log(fname, update)
                            if (values.client.matrix && values.client.matrix.risk) {
                                const index = values.client.matrix.risk.findIndex((s: any) => s.risk === update.toUpperCase())
                                if (index !== -1) {
                                    const targetField = field.targetField;
                                    const int = parseInt(values.client.matrix.risk[index].riskNo);
                                    ddd[targetField] = int;
                                    possibleUpdate.fieldComparison.push({ fname: targetField, current: null, update: int, validation: { ok: true, msg: '' } })
                                } else {
                                    validation.echo = '[cant match]'
                                }
                            } else {
                                validation.echo = '[no client matrix]'
                            }
                            break;
                        case 'AZ_LIKE':
                            //  eq = (current[fname] === update)

                            console.log(fname, update)
                            if (values.client.matrix && values.client.matrix.risk) {
                                if (update.toUpperCase() !== '') {
                                    const index = values.client.matrix.risk.findIndex((s: any) => s.risk === update.toUpperCase())
                                    if (index !== -1) {
                                        const targetField = field.targetField;
                                        const int = parseInt(values.client.matrix.risk[index].riskNo);
                                        ddd[targetField] = int;
                                        possibleUpdate.fieldComparison.push({ fname: targetField, current: null, update: int, validation: { ok: true, msg: '' } })
                                    } else {
                                        validation.echo = '[cant match]'
                                    }
                                }
                            } else {
                                validation.echo = '[no client matrix]'
                            }
                            break;

                        case 'AZ_CC':
                            //  eq = (current[fname] === update)

                            //     console.log(fname, update)
                            if (values.client.matrix && values.client.matrix.severity) {
                                const index = values.client.matrix.severity.findIndex((s: any) => s.severity === update.toUpperCase())
                                if (index !== -1) {
                                    const targetField = field.targetField;
                                    const int = parseInt(values.client.matrix.severity[index].severityNumber);
                                    ddd[targetField] = int;
                                    possibleUpdate.fieldComparison.push({ fname: targetField, current: null, update: int, validation: { ok: true, msg: '' } })
                                } else {
                                    validation.echo = '[cant match]'
                                }
                            } else {
                                validation.echo = '[no client matrix]'
                            }
                            break;
                        case 'LOF':

                            if (isNaN(Number(update)) || update === 'N/A') {
                                if (update === 'N/A') {

                                } else {
                                    const parsed = parseInt(update);
                                    if ((parsed > 0) && (parsed <= values.client.matrixMax)) {
                                        validation.ok = false;
                                        validation.msg = `N/A or 1-${values.client.matrixMax}`;
                                    }

                                }


                                // eq = (current[fname] === update)
                            }
                            break;
                    }
                    possibleUpdate.fieldComparison.push({ fname, current: null, update: ddd[fname], validation })
                }

            }
        }

        //if (doRisk) {
        //  const risk = getRisk(ddd['likelihood'], ddd['combinedConsequence'], values)
        // ddd.risk = risk;
        //  possibleUpdate.fieldComparison.push({ fname: 'risk', current: "", update: risk, validation: { ok: true, msg: '' } })
        // }


        possibleUpdate.object = { ...ddd }

        const validationErrors = possibleUpdate.fieldComparison.filter((dd: any) => dd.validation.ok === false)
        console.log(validationErrors)
        if (validationErrors.length > 0) {
            possibleUpdate.errorTxt = 'validation errors'
            possibleUpdate.error = true;
        }
        //   console.log(possibleUpdate)


        return possibleUpdate
    })
    form.change(endTargetArray, uplaodD)
}
