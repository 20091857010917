import { ISysNotificationType } from "../interface/sys-notification-type";
import axiosInstance from "./instance";

export const RequestGetSysNotificationTypes = (addNotification: any, data?: any): Promise<ISysNotificationType[]> => {
    return new Promise<ISysNotificationType[]>((resolve, reject) => {
        let url = `/sysNotificationTypes/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetSysNotificationType = (addNotification: any, id: string): Promise<ISysNotificationType> => {
    return new Promise<ISysNotificationType>((resolve, reject) => {

        axiosInstance(addNotification).get(`/sysNotificationTypes/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewSysNotificationType = (addNotification: any, thing: ISysNotificationType): Promise<ISysNotificationType> => {
    return new Promise<ISysNotificationType>((resolve, reject) => {

        axiosInstance(addNotification).post(`/sysNotificationTypes/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteSysNotificationType = (addNotification: any, thing: ISysNotificationType): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/sysNotificationTypes/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateSysNotificationType = (addNotification: any, user: ISysNotificationType): Promise<ISysNotificationType> => {
    return new Promise<ISysNotificationType>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/sysNotificationTypes/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestSysNotificationTypeFunction = (addNotification: any, func:string, id: string): Promise<any> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/sysNotificationTypes/_func/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
