import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import {
    RequestGetInspection,
    RequestInspectionFunction
} from '../../../api/inspection-requests';
import { ENotificationType } from '../../../context/notifications';
import { IInspection, IInspectionReported } from '../../../interface/inspection';
import { IInstallationLoop } from '../../../interface/installation-loop';








export const internalApproval = (values: IInspection, hasPermission: ((roles: string[]) => boolean) | undefined, setLoading: React.Dispatch<React.SetStateAction<boolean>>, addNotification: ((title: string, payload: string, type: ENotificationType) => void) | undefined, thing: IInspection, setThing: React.Dispatch<React.SetStateAction<IInspection | undefined>>) => {
    return <>
        {values.id !== 'new' && <div className="col-12">
            <h2>Approval</h2>
            {values.approvedByUser ? <>Approved by: {values.approvedByUser.firstName} {values.approvedByUser.lastName}</> : <>
                {hasPermission && hasPermission(['approveStuff']) && <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    setLoading(true);
                    RequestInspectionFunction(addNotification, 'approve', thing.id).then(() => {

                        RequestGetInspection(addNotification, thing.id).then(ddd => {
                            console.log("ddd", ddd);
                            setThing(ddd);
                            setLoading(false);
                        });
                    });
                }}>Approve</button>}</>}
        </div>}
    </>;
}





export const selectEquipment = (bool: boolean, equipment: any, pipe: any, name: string, values: IInspection, loops: IInstallationLoop[] | undefined, index: number, reported: IInspectionReported, form: any) => {
    return <div className="row">
        {bool === true ? <>
            <div className="col-6">
                <Field name={`${name}.equipmentId`}>
                    {({ input, meta }) => (
                        <div>
                            <label>Equipment</label>
                            <select id={`inp_${index}equipmentId`} className="form-control" {...input}>
                                <option></option>
                                {values.system && values.system.equipment && values.system.equipment.map((p, pi) => {

                                    return <option value={p.id} key={pi}>{p.refId}</option>;
                                })}
                            </select>
                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                        </div>
                    )}
                </Field>
            </div>
            <div className="col-6">

                <Field name={`${name}.loopId`}>
                    {({ input, meta }) => (
                        <div>
                            <label>Loop</label>
                            <select id={`inp_${index}loopId`} className="form-control" {...input}>
                                <option></option>

                                {loops && loops.map((l, li) => {
                                    return <option value={l.id}>{l.refId}</option>;
                                })}
                            </select>
                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                        </div>
                    )}
                </Field>

                {reported.loop && <>
                    <Field name={`${name}.pipeId`}>
                        {({ input, meta }) => (
                            <div>
                                <label>Pipe</label>
                                <select id={`inp_${index}pipeId`} className="form-control" {...input}>
                                    <option></option>
                                    <option value="_ALL">all</option>
                                    {reported.loop && reported.loop.pipes && reported.loop.pipes.map((p, pi) => {

                                        return <option value={p.id} key={pi}>{p.refId}</option>;
                                    })}
                                </select>
                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                            </div>
                        )}
                    </Field>
                </>}

            </div>




        </> : <div className="col-12">
            {reported.equipmentId ? <p>Equipment: {reported.equipment?.refId} - {reported.equipment?.name} </p> : <p>

                {reported.loopId && <>Loop: {reported.loop?.refId} -  {reported.loop?.name}</>}
                /
                {reported.pipeId && <>Pipe: {reported.pipe?.refId} -  {reported.loop?.name}</>}
            </p>}


            <button className="btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                const rrr = [...values.reported];

                delete rrr[index].pipe;
                delete rrr[index].equipment;
                delete rrr[index].loop;

                rrr[index].pipeId = '';
                rrr[index].equipmentId = '';
                rrr[index].loopId = '';
                rrr[index].results = [];
                form.change('reported', rrr);
            }}>Clear Selection</button>
        </div>}
        <OnChange name={`${name}.loopId`}>
            {(value, previous) => {
                if (value !== '' && loops) {
                    // /reported
                    const loopIndex = loops.findIndex(d => d.id === value);
                    const lst = [...values.reported];
                    const loop = loops[loopIndex];

                    const thisRep = lst[index];
                    console.log(thisRep);

                    const ddd = { ...reported };
                    thisRep.loop = loop;
                    thisRep.materialId = loop.matterialId;
                    lst[index] = thisRep;
                    form.change('reported', lst);
                }
            }}
        </OnChange>
        <OnChange name={`${name}.equipmentId`}>
            {(value, previous) => {
                // do something
                console.log("sdfsdfd", values.reported[index]);
                const rrr = [...values.reported];
                //find the equipment obj
                if (values.system) {
                    const ssss = values.system && values.system.equipment && values.system.equipment.find((ddd => ddd.id === value));
                    console.log("sdfsdfd", ssss);

                    if (ssss) {
                        rrr[index].equipment = ssss;
                        //  rrr[index].loopId = ssss.loopId;
                        rrr[index].systemId = ssss.systemId;
                        rrr[index].materialId = ssss.materialId;
                        rrr[index].results = [];
                        //   rrr[index].originalWallThickness = ssss.originalWallThickness;
                    }
                    console.log(value, rrr);
                    form.change('reported', rrr);
                }
            }}
        </OnChange>


        <OnChange name={`${name}.pipeId`}>
            {(value, previous) => {
                // do something
                console.log("sdfsdfd", values.reported[index]);
                const rrr = [...values.reported];
                //find the equipment obj

                const thisRR = rrr[index];

                if (thisRR !== undefined) {
                    const ssss = thisRR.loop !== undefined && thisRR.loop.pipes !== undefined && thisRR.loop.pipes.find((ddd => ddd.id === value));
                    console.log("sdfsdfd", ssss);

                    if (ssss) {
                        rrr[index].pipe = ssss;
                        rrr[index].loopId = ssss.loopId;
                        rrr[index].systemId = ssss.systemId;

                        rrr[index].results = [];

                    }
                    console.log(value, rrr);
                    form.change('reported', rrr);
                }
            }}
        </OnChange>

    </div>;
}


