import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import JSONPretty from 'react-json-prettify';
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { RequestDeleteDrawing, RequestGetDrawings } from '../../../api/drawing-requests';
import { RequestNewLog } from "../../../api/log-requests";
import DataTable from "../../../components/system/DataTable";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import PopupImage from "../../../components/system/PopupImage";
import TextDisplay from "../../../components/system/TextDisplay";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { usePageTitle } from "../../../context/pageTitle";
import { IDrawing } from "../../../interface/drawing";
import { ELogType, ESeverity } from "../../../interface/log";
import AdminDrawing from './Admin.Drawing';


const AdminDrawingsPge = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IDrawing[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;



    const { setPageTitle } = usePageTitle()!;
    useEffect(() => {
        // 
        setPageTitle && setPageTitle('Drawings', 'admin');

    }, [])



    useEffect(() => {
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react stores onunload'
        });
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }

    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        try {
            if (loading === true) {
                // eslint-disable-next-line
                {/*<!-- OPTIONAL 2 -->*/ }
                RequestGetDrawings(addNotification).then(thing => {
                    //   console.log(stores)
                    setThings(thing);
                    setLoading(false);
                })
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Drawings - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading, addNotification]);

    const onDelete = (value: IDrawing) => {
        try {

            const conf = window.confirm("Delete?");
            if (conf) {
                setLoading(true);
                RequestDeleteDrawing(addNotification, value).then(res => {
                    //  console.log(res);
                    let thingsD: IDrawing[] = [];
                    if (things) {
                        thingsD = [...things];
                    }
                    const index = thingsD.findIndex(thing => thing._id === value._id);
                    thingsD.splice(index, 1);

                    setThings(thingsD);
                    setLoading(false);
                    addNotification && addNotification('Success', 'Drawing Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Drawings - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    return (<>
        {loading === true && <Loading />}
        {things && <div className="col-12">
            <h2>Drawings Admin</h2>
            <Helmet>
                <title>Drawings / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            <Link to="./drawings/new">New</Link>
            {/*<!-- OPTIONAL 3 -->*/}
            <DataTable

                colHeadings={[

                    { name: "PipeId", field: "pipeId", sortable: true, },
                    {
                        name: "Image", field: "image", r: (value: any, index) => {
                            return <>{value.image && <><img className="img-fluid" src={value.image.c400} /><br />{value.image.name}<br /><PopupImage data={value.image} /></>} </>;
                        }, sortable: false
                    },
                    {
                        name: "Desc", field: "description", r: (value: any, index) => {
                            return <p className="text-left"><TextDisplay text={value.description} /><br />
                                <JSONPretty json={value.classify[0]} />
                            </p>;
                        }, sortable: false
                    },


                    {
                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} alwaysDisplay={true} />
                                <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    onDelete(value);
                                }}>Delete</button>
                            </div>;
                        }, sortable: false
                    },

                ]}

                data={things}
            />

            {/*<!-- OPTIONAL END -->*/}
        </div>}
    </>
    );
};
const AdminDrawings = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminDrawing />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminDrawingsPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminDrawings;