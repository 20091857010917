import queryString from "query-string";
import { useEffect, useState } from "react";
import { Link, Route, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { RequestGetLogs } from "../../api/log-requests";
import DataTable from "../../components/system/DataTable";
import DebugButton from "../../components/system/DebugButton";
import { useNotifications } from "../../context/notifications";
import { usePageTitle } from "../../context/pageTitle";
import { ESeverity, ILog } from "../../interface/log";
import AdminLog from "./Admin.Log";


const AdminLogsList = ({ types }: any) => {
    let match = useRouteMatch();
    let params = useParams();
    const { setPageTitle } = usePageTitle()!;
    useEffect(() => {

        setPageTitle && setPageTitle('Logs', 'admin');

    }, [])


    const { addNotification } = useNotifications()!;
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);
    const [logs, setLogs] = useState<ILog[]>();
    const [showAll, setShowAll] = useState(false);
    useEffect(() => {
        // const searchParams = new URLSearchParams(location.search);
        const parsed = queryString.parse(location.search);
        //console.log(parsed);


        if (loading === true) {
            console.log("get product types", parsed);
            //  const query = qs.parse(props.location.search.substring(1));
            //setQuery({ name: query.cat });
            let q: any = {};
            q.notSeverity = 0;
            if (parsed.requestId) {
                q.requestId = parsed.requestId;
            }

            if (showAll) {
                q.all = true
            }

            //  setSL(true);
            RequestGetLogs(addNotification, q).then(logs => {
                console.log(logs)
                setLogs(logs.reverse());
                setLoading(false);
            })

        }
    }, [match, params, showAll])
    const severityObj = Object.values(ESeverity);

    //console.log(severityObj);
    return (<>
        {logs && <div className="col-12">
            <button className="btn btn-secondary" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                setShowAll(!showAll);
                setLogs([]);
                setLoading(true);
            }}>{showAll ? 'Show Less' : 'Show All'}</button>
            <DataTable
                rowStyleFn={(value) => {
                    // console.log('ss', value)
                    switch (value.severity) {
                        case 0:
                            return { backgroundColor: "lightgreen" };
                        case 1:
                            return { backgroundColor: "lightblue" };
                        case 2:
                            return { backgroundColor: "#d342ff" };
                        case 3:
                            return { backgroundColor: "orange" };
                        case 4:
                            return { backgroundColor: "red" };
                        default:
                            return {};
                    }

                }}

                colHeadings={[
                    {
                        name: "Severity", field: "severity", sortable: true, r: (value: any, index) => {
                            return <>{value.severity}</>;
                        }
                    },
                    {
                        name: "Type", field: "type", sortable: true
                    },
                    {
                        name: "Text", field: "text", sortable: false
                    },
                    {
                        name: "Severity", field: "severity", sortable: true, r: (value: any, index) => {
                            const severity: number = value.severity;
                            let d = severityObj[severity]
                            console.log(d)
                            return <>{d}</>
                        }
                    },
                    {
                        name: "Created", field: "created", r: (value: any, index) => {
                            return <>{value.created}</>;
                        }, sortable: true
                    },
                    {
                        name: "Debug", field: "id", r: (value: any, index) => {
                            return <><DebugButton data={value} /><Link className="btn btn-primary" to={`${match.url}/${value._id && value._id}`}>Edit</Link></>;
                        }, sortable: false
                    },
                ]}

                data={logs}
            />
        </div>}

    </>
    );
}


const AdminLogs = ({ }: any) => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminLog />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminLogsList />
            </Route>

        </Switch>
    </>
    );
}





export default AdminLogs;