import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useAuth } from "../context/auth";
import { usePageTitle } from "../context/pageTitle";
import HelpButton from "./system/HelpButton";
import Notifications from "./system/Notifications";

interface INavProp {

}
const Navigation = ({ }: INavProp) => {
  const { d } = usePageTitle()!;
  const { auth, logout, hasPermission } = useAuth()!;

  let isTest = false;
  //if(auth && auth.metaData.)
  //console.log(location)
  if (auth.metaData.MODE === 'SOMETHING' || auth.metaData.MODE === 'TEST' || auth.metaData.MODE === 'docker') {
    isTest = true;
  }
  return (<><span className="e2eNavigation" style={{ display: 'none' }}>TestOk</span>
    {isTest && <div className="notLiveBar text-center">TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST</div>}

    <header className="header">
      <div className="container-fluid">

        <div className="header__wrapper">

          <Link to="/" id="e2eHome" className="logo2"><img src={`/android-chrome-75x75.png`} alt="Risk Retriever" /></Link>[BETA]
          <nav className="nav">
            <ul className="nav__menu" id="header-main-nav">

              {(auth.type === 'client' || auth.type === 'admin') && <li><Link id="e2eMyDRA" className="" to="/reports">My DRA</Link></li>}
              {(auth.type === 'client' || auth.type === 'admin') && <li><Link id="e2eMyRetriever" className="" to="/installations">My Retriever</Link></li>}
              {(auth.type === 'admin' || hasPermission && hasPermission(['calculate'])) && <li><Link id="e2eAlloySelect" className="" to="/calculator">Alloyselect</Link></li>}
              {auth.type === 'admin' && <li><Link id="e2eSteeks" className="" to="/steels">S355</Link></li>}


              {auth.type !== 'guest' ? <li className="nav__arrow">
                <a href="#" target="">{auth.user && auth.user.firstName} {auth.user && auth.user.lastName}<svg
                  className="svg-inline--fa fa-chevron-down fa-w-14" aria-hidden="true" focusable="false"
                  data-prefix="fas" data-icon="chevron-down" role="img"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                  <path fill="currentColor"
                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z">
                  </path>
                </svg> <i className="fas fa-chevron-down"></i></a>
                <ul className="nav__sub-menu">

                  <li><button id="e2eLogout" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    logout && logout();
                  }}>Logout</button>
                  </li>
                  <li><Link id="e2eProfile" className="" to="/profile">Profile</Link></li>
                </ul>
              </li> : <li><Link id="e2eLogin" className="" to="/login">Login</Link></li>}
            </ul>

          </nav>
          <div className="header-right">
            <div className="list-feedback">
              <ul>

                {auth.type === 'admin' && <li>
                  <Link to="/admin/home"><FontAwesomeIcon icon={faCogs} size="2x" /></Link>

                </li>}
                <li>


                  <a href="mailto:office@ogcenergy.com">

                  </a>
                </li>



              </ul>
            </div>

            {auth.client && auth.client.logoUrl && <>
              <img className="img-fluid" src={auth.client.logoUrl} alt={auth.client.name} />
            </>}

          </div>

        </div>
      </div>
    </header>

    <section className={`page-nace sector page_${d.class}`} id="nace-intro-block_610124cf7a9ad">
      <div className="sector__wrapper">
        <div className="sector__content">
          <div className="d-flex justify-content-between">
            <div className="w-100">

              <div className="title pl-4">
                <div>{d.title !== '' && <h1>{d.title}</h1>}</div>
              </div>

            </div>
            <div className="title w-100 text-right pr-5">
              <HelpButton />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Notifications />
  </>);
}

export default Navigation;