import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestGetInstallationVersion, RequestNewInstallationVersion, RequestUpdateInstallationVersion } from "../../../api/installation-version-requests";
import { RequestNewLog } from "../../../api/log-requests";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { IInstallationVersion } from "../../../interface/installation-version";
import { ELogType, ESeverity } from "../../../interface/log";
import SpreadSheetEditor from "./SpreadSheetEditor";



const AdminInstallationVersion = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IInstallationVersion>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }


    const match: any = useRouteMatch("/admin/installationVersions/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }
                /*
                
                id:string;
                    name:string;
                    installationId:string;
                    installation?:IInstallation;
                    pipes:any[];
                    equipment:any[];
                
                    */


                if (id === 'new') {
                    setThing({ id: 'new', revision: 0, installationId: '', pipes: [], equipment: [], pipeHeadings: [], equipmentHeadings: [] })
                    Promise.allSettled([]).then(() => {
                        setLoading(false)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetInstallationVersion(addNotification, id).then(thing => {
                            setThing(thing);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - InstallationVersion - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IInstallationVersion>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'InstallationVersion Created!';
                ppp = RequestNewInstallationVersion(addNotification, data);
            } else {
                notificationText = 'InstallationVersion Saved!';
                ppp = RequestUpdateInstallationVersion(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - InstallationVersion - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../installationVersions" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Installation Version / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}

            <div className="row">
                <div className="col-12">
                    x
                    <SpreadSheetEditor onSave={(pipes, equipment) => {
                        const aThing = { ...thing };
                        aThing.pipes = pipes;
                        aThing.equipment = equipment;
                        RequestUpdateInstallationVersion(addNotification, aThing).then(saved => {
                            setThing(saved)
                        }).catch(ex => {

                        })
                    }} pipeData={thing.pipes} equipmentData={thing.equipment} pipeFields={thing.pipeHeadings} equipmentFields={thing.equipmentHeadings} />
                </div>
            </div>
            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminInstallationVersion;