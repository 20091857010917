
import { useEffect, useRef, useState } from "react";
import Spreadsheet from "x-data-spreadsheet";
import { ISpreadSheetField } from "../../../interface/installation-version";



interface IDSpreadSheetEditor {
    pipeData: any[];
    pipeFields: ISpreadSheetField[];
    equipmentData: any[];
    equipmentFields: ISpreadSheetField[];
    onSave: (pipeData: any[], equipmentData: any[]) => void
}
let s: Spreadsheet;
const SpreadSheetEditor: React.FunctionComponent<IDSpreadSheetEditor> = props => {
    const [things, setThings] = useState<any[]>([]);


    const [pipeDataNew, setPipeDataNew] = useState<any[]>();
    const [equipmentDataNew, setEquipmentDataNew] = useState<any[]>();


    const Ref = useRef<HTMLDivElement>(null);
    // data validation
    //s.validate()

    const check = () => {

    }
    const stox = () => {


        const out: any[] = [];
        var p: any = { name: "Pipes", rows: {} };
        const hcells: any = {};

        for (let indexh = 0; indexh < props.pipeFields.length; indexh++) {
            const heading = props.pipeFields[indexh];
            hcells[indexh] = { text: heading.fieldName };
        }
        p.rows[0] = { cells: hcells, freeze: true }

        for (let indexP = 0; indexP < props.pipeData.length; indexP++) {
            const pipe: any = props.pipeData[indexP];
            //    if
            if (pipe && pipe !== null) {
                const cells: any = {};
                for (let index2 = 0; index2 < props.pipeFields.length; index2++) {
                    const heading = props.pipeFields[index2];
                    if (pipe[heading.fieldName]) {
                        cells[index2] = { text: pipe[heading.fieldName] };
                    } else {
                        cells[index2] = { text: '' };
                    }


                }
                p.rows[indexP + 1] = { cells: cells }
            }



            console.log(indexP);
        }

        out.push(p);

        var e: any = { name: "Equipment", rows: {} };
        const hcells2: any = {};
        for (let indexh = 0; indexh < props.equipmentFields.length; indexh++) {
            const heading = props.equipmentFields[indexh];
            hcells2[indexh] = { text: heading.fieldName };

        }
        e.rows[0] = { cells: hcells2, freeze: true }


        for (let indexP = 0; indexP < props.pipeData.length; indexP++) {
            const pipe: any = props.equipmentData[indexP];
            if (pipe) {
                const cells: any = {};
                for (let index2 = 0; index2 < props.equipmentFields.length; index2++) {
                    const heading = props.equipmentFields[index2];
                    if (pipe[heading.fieldName]) {
                        cells[index2] = { text: pipe[heading.fieldName] };
                    } else {
                        cells[index2] = { text: '' };
                    }
                }
                e.rows[indexP + 1] = { cells: cells }

            }

            console.log(indexP);
        }

        out.push(e);
        return out;
    }




    useEffect(() => {
        console.log("useEffect")


        console.log(props)
        if (Ref.current !== null) {
            setPipeDataNew(props.pipeData)
            setEquipmentDataNew(props.equipmentData)
            let maxLength = 0;
            let maxWidth = 0;

            maxWidth = props.pipeFields.length;
            if (props.pipeFields.length > maxLength) {
                maxWidth = props.pipeFields.length;
            }

            maxLength = props.pipeData.length;
            if (props.equipmentData.length > maxLength) {
                maxLength = props.equipmentData.length;
            }
            maxWidth = maxWidth + 5;
            maxLength = maxLength + 100;


            s = new Spreadsheet(Ref.current, {
                mode: 'edit', // edit | read
                showToolbar: true,
                showGrid: true,
                showContextmenu: false,
                view: {
                    height: () => document.documentElement.clientHeight - 200,
                    width: () => document.documentElement.clientWidth - 30,
                },
                row: {
                    len: maxLength,
                    height: 25,
                },
                col: {
                    len: maxWidth,
                    width: 100,
                    indexWidth: 60,
                    minWidth: 60,
                },
                style: {
                    bgcolor: '#ffffff',
                    align: 'left',
                    valign: 'middle',
                    textwrap: false,
                    strike: false,
                    underline: false,
                    color: '#0a0a0a',
                    font: {
                        name: 'Helvetica',
                        size: 10,
                        bold: false,
                        italic: false,
                    },
                },
            })



            s.on('cell-selected', (cell, ri, ci) => {
                console.log(s, 'cell-selected', cell, ri, ci)
            });
            s.on('cells-selected', (cell, { sri, sci, eri, eci }) => { });
            // edited on cell
            s.on('cell-edited', (text, ri, ci) => {
                console.log(s, 'cell-edited', text, ri, ci, s.cell(ri, ci, 0), s.cellStyle(ri, ci, 0))
                s.cell(ri, ci, 0)
                s.cellStyle(ri, ci, 0)

            });
            s.loadData(stox()) // load data
                .change(data => {
                    console.log(data.name, data.rows, Object.entries(data.rows));
                    const entries = Object.entries(data.rows);
                    const thingsToSave: any[] = [];
                    for (let index = 1; index < entries.length; index++) {
                        const thingToSave: any = {};
                        const element: any = entries[index];
                        if (element[0] !== 'len') {
                            //  console.log(element[1].cells)

                            if (data.name === 'Pipes') {
                                for (let index2 = 0; index2 < props.pipeFields.length; index2++) {
                                    const heading = props.pipeFields[index2];
                                    const cell = element[1].cells[index2];
                                    if (cell) {
                                        thingToSave[heading.fieldName] = element[1].cells[index2].text;
                                    } else {
                                        thingToSave[heading.fieldName] = ""
                                    }

                                }

                            } else if (data.name === 'Equipment') {
                                for (let index2 = 0; index2 < props.equipmentFields.length; index2++) {
                                    const heading = props.equipmentFields[index2];
                                    const cell = element[1].cells[index2];
                                    if (cell) {
                                        thingToSave[heading.fieldName] = element[1].cells[index2].text;
                                    } else {
                                        thingToSave[heading.fieldName] = ""
                                    }
                                }
                            }
                        }

                        thingsToSave.push(thingToSave)
                    }
                    // save data to db
                    if (data.name === 'Pipes') {
                        setPipeDataNew(thingsToSave)
                    } else if (data.name === 'Equipment') {
                        setEquipmentDataNew(thingsToSave)
                    }
                });
            //      var grid = s.spreadsheet(document.getElementById("gridctr"));
        }
    }, []);
    return <>

        <div ref={Ref} />
        <button type="button" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            console.log(s);
        }}>check</button>
        <button type="button" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            pipeDataNew && equipmentDataNew && props.onSave(pipeDataNew, equipmentDataNew);
        }}>Save</button>


    </>
};


export default SpreadSheetEditor