import { IAssignment } from "../interface/assignment";
import axiosInstance from "./instance";

export const RequestGetAssignments = (addNotification: any, data?: any): Promise<IAssignment[]> => {
    return new Promise<IAssignment[]>((resolve, reject) => {
        let url = `/assignments/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetAssignment = (addNotification: any, id: string): Promise<IAssignment> => {
    return new Promise<IAssignment>((resolve, reject) => {

        axiosInstance(addNotification).get(`/assignments/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewAssignment = (addNotification: any, thing: IAssignment): Promise<IAssignment> => {
    return new Promise<IAssignment>((resolve, reject) => {

        axiosInstance(addNotification).post(`/assignments/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteAssignment = (addNotification: any, thing: IAssignment): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/assignments/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateAssignment = (addNotification: any, user: IAssignment): Promise<IAssignment> => {
    return new Promise<IAssignment>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/assignments/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestAssignmentFunction = (addNotification: any, func:string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/assignments/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

export const RequestGetPublicAssignment =  (addNotification:any, id: string): Promise<IAssignment> => {
    return new Promise<IAssignment>((resolve, reject) => {

        axiosInstance(addNotification).get(`/publicAssignments/${id}`).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetPublicAssignments =  (addNotification:any, data?: any): Promise<IAssignment[]> => {
    return new Promise<IAssignment[]>((resolve, reject) => {
        let url = `/publicAssignments/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + ':' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
