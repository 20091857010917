import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import React, { useEffect, useState } from "react";
import { Accordion } from 'react-bootstrap';
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Helmet } from "react-helmet";
import { Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestGetClient, RequestNewClient, RequestUpdateClient } from "../../../api/client-requests";
import { RequestGetImpuritys } from '../../../api/impurity-requests';
import { RequestGetInspectionTypes } from "../../../api/inspection-type-requests";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestGetMechanisms } from '../../../api/mechanism-requests';
import { composeValidators, required } from "../../../components/FormStuff/validators";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import UploadImages from "../../../components/system/UploadImages/UploadImages";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { usePageTitle } from "../../../context/pageTitle";
import { IClient, IInspectionMatrix, IMatrix, IMatrixLevel } from "../../../interface/client";
import { IImpurity } from '../../../interface/impurity';
import { IInspectionType } from "../../../interface/inspection-type";
import { ELogType, ESeverity } from "../../../interface/log";
import { IMechanism } from '../../../interface/mechanism';

const SetMatrixSize = (values: IClient, generateMatrix: (current?: IMatrix | undefined) => IMatrix, form: any) => {
    return <div className="col-12">
        <div className="btn-group">
            <button className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                let matri = values.matrix;
                const ok = window.confirm("This will remove current matrix data and impact existing assignments. Are you sure?");
                if (ok) {
                    matri.size = '3';
                    matri = generateMatrix(matri);
                    form.change('matrix', matri);
                    form.change('name', '_' + values.name);
                    form.change('name', values.name.slice(1));
                }


            }}>3 X 3</button>
            <button className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                let matri = values.matrix;
                const ok = window.confirm("This will remove current matrix data and impact existing assignments. Are you sure?");
                if (ok) {
                    matri.size = '4';
                    matri = generateMatrix(matri);
                    form.change('matrix', matri);
                    form.change('name', '_' + values.name);
                    form.change('name', values.name.slice(1));
                }
            }}>4 X 4</button>
            <button className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                let matri = values.matrix;
                const ok = window.confirm("This will remove current matrix data and impact existing assignments. Are you sure?");
                if (ok) {
                    matri.size = '5';
                    matri = generateMatrix(matri);
                    form.change('matrix', matri);
                    form.change('name', '_' + values.name);
                    form.change('name', values.name.slice(1));
                }
            }}>5 X 5</button>
            <button className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                let matri = values.matrix;
                const ok = window.confirm("This will remove current matrix data and impact existing assignments. Are you sure?");
                if (ok) {
                    matri.size = '6';
                    matri = generateMatrix(matri);
                    form.change('matrix', matri);
                    form.change('name', '_' + values.name);
                    form.change('name', values.name.slice(1));
                }
            }}>6 X 6</button>
            <button className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                let matri = values.matrix;
                const ok = window.confirm("This will remove current matrix data and impact existing assignments. Are you sure?");
                if (ok) {
                    matri.size = '7';
                    matri = generateMatrix(matri);
                    form.change('matrix', matri);
                    form.change('name', '_' + values.name);
                    form.change('name', values.name.slice(1));
                }
            }}>7 X 7</button>
        </div>
    </div>;
}

interface IMLevels {
    values: IClient,
    form: any
}
const MatrixLevels = ({ values, form }: IMLevels) => {
    return <div className="col-12">


        <FieldArray name="matrix.levels">

            {({ fields }) => (<>
                <div className="row">
                    {fields.map((name, index) => {
                        return <div className="col-3">
                            <h2>{index}</h2>
                            <Field name={`${name}.level`}>
                                {({ input, meta }) => (
                                    <div>
                                        <label>Level</label>
                                        <input type="text" className="form-control" {...input} placeholder="...." />
                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field name={`${name}.color`}>
                                {({ input, meta }) => (
                                    <div>
                                        <label>Colour</label>
                                        <input type="text" className="form-control" {...input} placeholder="...." />
                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field name={`${name}.description`}>
                                {({ input, meta }) => (
                                    <div>
                                        <label>Description</label>
                                        <input type="text" className="form-control" {...input} placeholder="...." />
                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>

                            <button className="btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                                ev.preventDefault();

                                //  console.log("inspectionMatrix", values.matrix.levels[index], values.inspectionMatrix)
                                //    inspectionMatrix
                                const inspectionMatrix = values.inspectionMatrix.map(_dd => {
                                    const dd = { ..._dd };
                                    dd.levels = dd.levels.filter(lev => {
                                        if (lev.level !== values.matrix.levels[index].level) {
                                            return lev;
                                        }
                                    })
                                    return dd;
                                })
                                form.change('inspectionMatrix', inspectionMatrix)
                                fields.remove(index);
                                //  console.log("inspectionMatrix", values.matrix.levels[index], values.inspectionMatrix, inspectionMatrix)

                            }}><FontAwesomeIcon icon={faTrash} /></button>
                        </div>;
                    })}

                    <div className="col-3">
                        <button className="btn btn-success" type="button" onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            fields.push({ level: '', color: '', description: '' });

                        }}>Add Level</button>
                    </div>
                </div>
            </>)}

        </FieldArray>
    </div>;
}

function newFunction(values: IClient, cellToggle: (riskNo: number, severityNo: number) => void, doCellTheme: (riskNo: number, severityNo: number) => React.CSSProperties, doCellText: (riskNo: number, severityNo: number) => string) {
    return <FieldArray name="matrix.severity">
        {(sevProps: any) => (

            <FieldArray name="matrix.risk">
                {(riskProps: any) => (<>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>People Impact</th>
                                <th>Asset Impact</th>
                                <th>Environmental Impact</th>
                                <th>Financial Impact</th>
                                <th>Reputation Impact</th>
                                <th>Consequence</th>
                                {riskProps.fields.map((riskName: string, riskIndex: number) => {
                                    return <th>
                                        <Field name={`${riskName}.risk`}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <input type="text" className="form-control" {...input} placeholder="Likelihood Title" />
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name={`${riskName}.riskDescription`}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <textarea type="text" className="form-control" {...input} placeholder="Likelihood Description" />
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                    </th>;
                                })}
                            </tr>

                        </thead>

                        <tbody>
                            {sevProps.fields.map((severityName: string, severityIndex: number) => {
                                const actSeverity = values.matrix.severity[severityIndex].severityNumber;
                                return <tr key={severityIndex}>
                                    <td>{actSeverity}</td>
                                    <td><Field name={`${severityName}.peopleImpact`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <textarea type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field></td>
                                    <td><Field name={`${severityName}.assetImpact`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <textarea type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field></td>
                                    <td><Field name={`${severityName}.environmentalImpact`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <textarea type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field></td>
                                    <td><Field name={`${severityName}.financialImpact`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <textarea type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field></td>
                                    <td><Field name={`${severityName}.reputationImpact`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <textarea type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field></td>
                                    <td><Field name={`${severityName}.severity`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <input type="text" className="form-control" {...input} placeholder="Consequence Title" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field><Field name={`${severityName}.severityDescription`}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <textarea type="text" className="form-control" {...input} placeholder="Consequence Description:" />
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field></td>

                                    {riskProps.fields.map((riskName: string, riskIndex: number) => {
                                        const actRisk = values.matrix.risk[riskIndex].riskNo;
                                        return <td onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            cellToggle(actRisk, actSeverity);
                                        }} style={doCellTheme(actRisk, actSeverity)}>{doCellText(actRisk, actSeverity)}</td>;
                                    })}

                                </tr>;
                            })}
                        </tbody>
                    </table>
                </>
                )}
            </FieldArray>

        )}
    </FieldArray>;
}




const AdminClient = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingStarted, setLoadingStarted] = useState<boolean>(false);

    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IClient>();
    const [types, setTypes] = useState<IInspectionType[]>();
    const [impurities, setImpurities] = useState<IImpurity[]>();
    const [damageMechanisms, setDamageMechanisms] = useState<IMechanism[]>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();
    const { setPageTitle } = usePageTitle()!;


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/clients/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    const generateMatrix = (current?: IMatrix): IMatrix => {
        let matrix: IMatrix = {
            size: '5', risk: [], severity: [], severityRisk: [], levels: [
                {
                    "level": "VL",
                    "color": "#bbf7b2",
                    "description": "Very Low",
                    "lowInterval": 12,
                    "mediumInterval": 24,
                    "highInterval": 36
                },
                {
                    "level": "L",
                    "color": "green",
                    "description": "Low",
                    "lowInterval": 24,
                    "mediumInterval": 36,
                    "highInterval": 48
                },
                {
                    "level": "M",
                    "color": "orange",
                    "description": "Medium",
                    "lowInterval": 36,
                    "mediumInterval": 48,
                    "highInterval": 60
                },
                {
                    "level": "H",
                    "color": "red",
                    "description": "High",
                    "lowInterval": 48,
                    "mediumInterval": 60,
                    "highInterval": 72
                },
                {
                    "level": "VH",
                    "color": "#992400",
                    "description": "Very High",
                    "lowInterval": 60,
                    "mediumInterval": 72,
                    "highInterval": 120
                }]
        };
        if (current !== undefined) {
            matrix = current;
            matrix.risk = [];
            matrix.severity = [];
            matrix.severityRisk = [];
        }
        for (let index = 0; index < parseInt(matrix.size); index++) {
            const actSeverity = (parseInt(matrix.size) + 1) - (index + 1)
            matrix.risk.push({ riskNo: (index + 1), risk: '', riskDescription: '' })
            matrix.severity.push({ severityNumber: actSeverity, severityDescription: '', severity: '', peopleImpact: '', assetImpact: '', environmentalImpact: '', financialImpact: '', reputationImpact: '' })
            for (let riskIndex = 0; riskIndex < parseInt(matrix.size); riskIndex++) {

                matrix.severityRisk.push({ riskNo: (riskIndex + 1), severityNo: actSeverity, thing: 0 })
            }
        }
        console.log(matrix)

        return matrix;
    }

    const baseInspectionLevels: IMatrixLevel[] = [
        {
            "level": "VL",
            "color": "#bbf7b2",
            "description": "Very Low",
            "lowInterval": 12,
            "mediumInterval": 24,
            "highInterval": 36
        },
        {
            "level": "L",
            "color": "green",
            "description": "Low",
            "lowInterval": 24,
            "mediumInterval": 36,
            "highInterval": 48
        },
        {
            "level": "M",
            "color": "orange",
            "description": "Medium",
            "lowInterval": 36,
            "mediumInterval": 48,
            "highInterval": 60
        },
        {
            "level": "H",
            "color": "red",
            "description": "High",
            "lowInterval": 48,
            "mediumInterval": 60,
            "highInterval": 72
        },
        {
            "level": "VH",
            "color": "#992400",
            "description": "Very High",
            "lowInterval": 60,
            "mediumInterval": 72,
            "highInterval": 120
        }
    ];


    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);

            const newGetData = async () => {
                setLoadingStarted(true);

                const imps = await RequestGetImpuritys(addNotification);
                setImpurities(imps)


                const dms = await RequestGetMechanisms(addNotification);
                setDamageMechanisms(dms);
                if (id === 'new') {


                    const types = await RequestGetInspectionTypes(addNotification);
                    const inspecitonMatrixs: IInspectionMatrix[] = types.map(it => {
                        return { type: it.id, levels: [...baseInspectionLevels] }
                    })
                    setTypes(types);
                    setThing({ id: 'new', name: '', email: '', description: '', draDamageMechanisms: [], url: '', matrixMax: 5, matrixReverse: false, logoId: '', matrix: generateMatrix(), phone: '', inspectionMatrix: inspecitonMatrixs, draOverrides: {}, draImpurities: [] })
                    setLoading(false);
                    setPageTitle && setPageTitle('Clients / New', 'admin');




                } else {

                    const types = await RequestGetInspectionTypes(addNotification);

                    setTypes(types);




                    const client = await RequestGetClient(addNotification, id)



                    if (client.matrix === undefined) {
                        client.matrix = generateMatrix();
                    }
                    if (client.draOverrides === undefined) {
                        client.draOverrides = {};
                    }

                    const inspecitonMatrixs: IInspectionMatrix[] = types.map(it => {
                        //check if exists
                        const index = client.inspectionMatrix.findIndex(i => i.type === it.id);
                        if (index !== -1) {
                            return client.inspectionMatrix[index]
                        } else {
                            return { type: it.id, levels: [...baseInspectionLevels] }
                        }
                    })
                    client.inspectionMatrix = inspecitonMatrixs;
                    console.log("set");
                    setThing(client);
                    setLoading(false);
                    setPageTitle && setPageTitle('Clients / ' + client.name, 'admin');



                }

            }


            if (loading === true) {
                if (loadingStarted === false) {

                    newGetData()
                    {/*<!-- OPTIONAL 1 -->*/ }

                    {/*<!-- OPTIONAL END -->*/ }
                }

            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Client - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IClient>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Client Created!';
                ppp = RequestNewClient(addNotification, data);
            } else {
                notificationText = 'Client Saved!';
                ppp = RequestUpdateClient(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Client - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    console.log("render");

    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../clients" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Client / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL END -->*/}

            <Form <IClient>
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                onSubmit={onSubmit}
                initialValues={thing}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                    //  console.log("renderForm");

                    const doCellTheme = (riskNo: number, severityNo: number): React.CSSProperties => {
                        const severityRisk = values.matrix.severityRisk;
                        const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
                        //    console.log(index);


                        if (index !== -1) {
                            const thing = severityRisk[index];

                            //  console.log(thing)
                            const level = values.matrix.levels[thing.thing]


                            if (level !== undefined) {

                                return { "backgroundColor": level.color };
                            } else {
                                return {}
                            }



                        } else {
                            return {}
                        }



                    }
                    const doCellText = (riskNo: number, severityNo: number): string => {
                        const severityRisk = values.matrix.severityRisk;
                        const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
                        //   console.log(index);
                        const thing = severityRisk[index];
                        const level = values.matrix.levels[thing.thing]
                        if (level !== undefined) {
                            return level.level
                        } else {
                            return '';
                        }

                    }
                    const cellToggle = (riskNo: number, severityNo: number) => {
                        const severityRisk = values.matrix.severityRisk;
                        const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
                        //   

                        const thing = { ...severityRisk[index] };
                        const maxLevel = values.matrix.levels.length;
                        let level = thing.thing + 1;
                        if (level >= maxLevel) {
                            level = 0;
                        }

                        thing.thing = level
                        console.log(thing.thing);

                        severityRisk[index] = thing;

                        const matrix = { ...values.matrix, severityRisk: severityRisk }

                        form.change('matrix', matrix)

                    }



                    const updateAll = (levels: any[], currentIndex: number) => {
                        console.log("updateAll", levels, currentIndex)

                        const currentArray = [...values.inspectionMatrix];


                        for (let index = 0; index < currentArray.length; index++) {

                            if (index !== currentIndex) {
                                currentArray[index].levels = [...levels]
                            }

                        }

                        form.change("inspectionMatrix", currentArray)

                    }

                    const inspectionTypeMatrix = (type: IInspectionType) => {


                        let index = 0;
                        let levels: any[];
                        let data: any;

                        if (values.inspectionMatrix) {
                            index = values.inspectionMatrix.findIndex(t => t.type === type.id)
                            levels = values.inspectionMatrix[index].levels;
                        }

                        return <FieldArray name={`inspectionMatrix[${index}].levels`}>

                            {({ fields }) => {
                                return <>
                                    {/* {JSON.stringify(values.inspectionMatrix[index])} */}
                                    <h2>{type.name}</h2>
                                    <p>{type.description}</p>
                                    <table className="table">
                                        <tr>
                                            <th colSpan={1}>Intervals</th>
                                            <th colSpan={3}>Inspection Grade Confidence</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={1}>Risks</th>
                                            <th colSpan={1}>Low</th>
                                            <th colSpan={1}>Medium</th>
                                            <th colSpan={1}>High</th>
                                        </tr>
                                        {fields.map((name, index) => {
                                            return <tr>
                                                <td colSpan={1}>{values.matrix.levels[index] && values.matrix.levels[index].level}</td>
                                                <td colSpan={1}>
                                                    <Field name={`${name}.lowInterval`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseInt(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)
                                                            return parseInt(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </td>
                                                <td colSpan={1}>  <Field name={`${name}.mediumInterval`} type="number" parse={(value, name) => {
                                                    //  console.log("parse", value, name)
                                                    return parseInt(value);
                                                }}
                                                    format={(value, name) => {
                                                        //  console.log("format", value, name)
                                                        return parseInt(value);
                                                    }}>
                                                    {({ input, meta }) => (
                                                        <div>
                                                            <input className="form-control" {...input} placeholder="...." />
                                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field></td>
                                                <td colSpan={1}>  <Field name={`${name}.highInterval`} type="number" parse={(value, name) => {
                                                    //  console.log("parse", value, name)
                                                    return parseInt(value);
                                                }}
                                                    format={(value, name) => {
                                                        //  console.log("format", value, name)
                                                        return parseInt(value);
                                                    }}>
                                                    {({ input, meta }) => (
                                                        <div>
                                                            <input className="form-control" {...input} placeholder="...." />
                                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field></td>
                                            </tr>;
                                        })}

                                    </table>
                                    <button type="button" onClick={() => {
                                        updateAll(levels, index);
                                    }} className="btn btn-warning">Make all the same as this "{type.name}"</button>
                                </>;
                            }}
                        </FieldArray>;
                    }
                    return <form onSubmit={handleSubmit}>  <DebugButton data={values} />
                        <div className="row">
                            <div className="col-12">

                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>General</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">





                                                <div className="col-6">
                                                    <Field name="name" validate={composeValidators(required)}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Name</label>
                                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                    <Field name="description">
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Description</label>
                                                                <input type="text" className="form-control" {...input} placeholder="Description of client" />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name="email">
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Email</label>
                                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>


                                                <div className="col-6"> <Field name="logo" aspect="none" component={UploadImages} text="Set Logo" onChanged={(data: any) => {
                                                    console.log("changed", data)
                                                    form.change('logoId', data.id)
                                                }} />



                                                    {values.logo && <div className="w-100">
                                                        {values.logo.croppedSrcData ? <img src={values.logo.croppedSrcData} /> : <img className="img-fluid" src={values.logo.url} />}
                                                    </div>}
                                                </div>
                                            </div>





                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Risk Matrix</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className="col-12">


                                                    <h3>Risk Levels</h3>

                                                    <MatrixLevels values={values} form={form} />

                                                    {SetMatrixSize(values, generateMatrix, form)}

                                                    <h3>Matrix</h3>
                                                    {newFunction(values, cellToggle, doCellTheme, doCellText)}

                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Inspection Types</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className="col-12">

                                                    {types && types.map((type, index) => {
                                                        return <div key={index}>


                                                            {inspectionTypeMatrix(type)}
                                                        </div>
                                                    })}


                                                </div> </div>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>DRA Options</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2>Title Overrides</h2>
                                                    <p>When not empty these will override the display text for the specified dimension</p>
                                                    <div className='btn-group'>
                                                        <button type='button' className='btn btn-primary' onClick={() => {

                                                            const overrides = { ...values.draOverrides }
                                                            overrides.assets = 'System Subdivision';
                                                            overrides.impurities = 'Primary Cause';
                                                            overrides.damageMechanisms = 'Failure Mode';
                                                            overrides.operatingModes = 'Scenario';
                                                            overrides.impuritiesEnabled = true
                                                            overrides.additional = 'Secondary Cause'
                                                            overrides.additionalEnabled = true;
                                                            form.change('draOverrides', overrides)
                                                        }}>Parent FMECA</button>
                                                        <button type='button' className='btn btn-primary' onClick={() => {

                                                            const overrides = { ...values.draOverrides }
                                                            overrides.assets = 'Asset Section';
                                                            overrides.impurities = 'Impurities';
                                                            overrides.damageMechanisms = 'Damage Mechanisms';
                                                            overrides.operatingModes = 'Operating Modes';
                                                            overrides.impuritiesEnabled = true
                                                            overrides.additional = ''
                                                            overrides.additionalEnabled = false;
                                                            form.change('draOverrides', overrides)
                                                        }}>DRA</button>
                                                        <button type='button' className='btn btn-primary' onClick={() => {

                                                            const overrides = { ...values.draOverrides }
                                                            overrides.assets = 'FOW Section';

                                                            overrides.damageMechanisms = 'Corrosion Threat';
                                                            overrides.operatingModes = 'Stage';
                                                            overrides.impuritiesEnabled = false
                                                            overrides.impurities = '';
                                                            overrides.additional = ''
                                                            overrides.additionalEnabled = false;
                                                            form.change('draOverrides', overrides)
                                                        }}>FOW</button>

                                                        <button type='button' className='btn btn-primary' onClick={() => {

                                                            const overrides = { ...values.draOverrides }
                                                            overrides.assets = 'Part';
                                                            overrides.impurities = 'Dimension X';
                                                            overrides.damageMechanisms = 'Damage Mechanisms';
                                                            overrides.operatingModes = 'Stage';
                                                            overrides.impuritiesEnabled = true
                                                            overrides.additional = ''
                                                            overrides.additionalEnabled = false;
                                                            form.change('draOverrides', overrides)
                                                        }}>Generic</button>
                                                    </div>

                                                    <Field name="draOverrides.assets">
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>"Assets" Override</label>
                                                                <input type="text" className="form-control" {...input} />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name="draOverrides.operatingModes">
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>"Operating Modes" Override </label>
                                                                <input type="text" className="form-control" disabled={(values.draOverrides.oppModeDisabled)} {...input} />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                    <Field name="draOverrides.damageMechanisms">
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>"Damage Mechanisms" Override</label>
                                                                <input type="text" className="form-control" {...input} />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <button type="button" className='btn btn-warning' onClick={() => {
                                                        const overrides = { ...values.draOverrides }
                                                        if (overrides.impuritiesEnabled === undefined) {
                                                            overrides.impuritiesEnabled = true;
                                                        } else {
                                                            overrides.impuritiesEnabled = !overrides.impuritiesEnabled
                                                        }

                                                        form.change('draOverrides', overrides)
                                                    }}>Impurities {values.draOverrides.impuritiesEnabled && values.draOverrides.impuritiesEnabled ? 'enabled' : 'disabled'}</button>
                                                    {values.draOverrides.impuritiesEnabled && <>
                                                        <Field name="draOverrides.impurities">
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <label>"Impurities" Override</label>
                                                                    <input type="text" className="form-control" {...input} />
                                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                        <button type="button" className='btn btn-warning' onClick={() => {
                                                            const overrides = { ...values.draOverrides }
                                                            if (overrides.additionalEnabled === undefined) {
                                                                overrides.additionalEnabled = true;
                                                            } else {
                                                                overrides.additionalEnabled = !overrides.additionalEnabled
                                                            }

                                                            form.change('draOverrides', overrides)
                                                        }}>Additional {values.draOverrides.additionalEnabled ? 'enabled' : 'disabled'}</button>
                                                        {values.draOverrides.additionalEnabled && <Field name="draOverrides.additional">
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <label>"Additional" Override </label>
                                                                    <input type="text" className="form-control" {...input} />
                                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>}
                                                    </>}

                                                </div>
                                            </div>


                                        </Accordion.Body>
                                    </Accordion.Item>



                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>DRA Impurities / Damage Mechanisms</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Please note that editing these will effect any DRA's for this client, if you disable a used Impurity or Mechanism it will break the DRA forms effected,</p>
                                                </div>
                                                <div className="col-6">
                                                    <h2>Impurities</h2>


                                                    <button type='button' className='btn btn-success' onClick={() => {
                                                        const ids = impurities && impurities.map(imp => {
                                                            return imp.id
                                                        })
                                                        form.change("draImpurities", ids)
                                                    }}>Activate All</button>
                                                    {impurities && impurities.map((imp, indexImp) => {
                                                        return <div key={indexImp} className="d-flex justify-content-between">

                                                            <label className="">
                                                                <Field
                                                                    name="draImpurities"
                                                                    component="input"
                                                                    type="checkbox"
                                                                    value={imp.id}
                                                                />{' '}
                                                                {imp.name}
                                                            </label>
                                                        </div>
                                                    })}

                                                </div>
                                                <div className="col-6">
                                                    <h2>Damage Mechanisms</h2>
                                                    <button type='button' className='btn btn-success' onClick={() => {
                                                        const ids = damageMechanisms && damageMechanisms.map(imp => {
                                                            return imp.id
                                                        })
                                                        form.change("draDamageMechanisms", ids)
                                                    }}>Activate All</button>
                                                    {damageMechanisms && damageMechanisms.map((dm, indexImp) => {
                                                        return <div key={indexImp} className="d-flex justify-content-between">

                                                            <label className="">
                                                                <Field
                                                                    name="draDamageMechanisms"
                                                                    component="input"
                                                                    type="checkbox"
                                                                    value={dm.id}
                                                                />{' '}
                                                                {dm.name}
                                                            </label>
                                                        </div>
                                                    })}

                                                </div>
                                            </div>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>








                                <div className="col-12">
                                    <button className="btn btn-primary" type="submit">Save Client</button>
                                </div>
                            </div>
                        </div>

                    </form>



                }} />

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminClient;