import { faArrowAltCircleDown, faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMutators from "final-form-arrays";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { ProfileUpdate } from "../../api/profile-requests";
import { composeValidators, required } from "../../components/FormStuff/validators";
import { useAuth } from "../../context/auth";
import { ENotificationType, useNotifications } from "../../context/notifications";

const MyProfileHome = () => {
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { auth } = useAuth()!;
    const { addNotification } = useNotifications()!;

    const onSubmit = (data: any) => {
        ProfileUpdate(addNotification, data).then(response => {
            console.log(response);
            if (response.PasswordBoolean) {
                if (response.PasswordBoolean === true) {
                    addNotification && addNotification("Success", "you have successfully changed your password please remember it for next time you log in", ENotificationType.Success)
                } else {
                    addNotification && addNotification("Failed", "you entered your current password incorrectly", ENotificationType.Success)
                }
            }
            if (response.ProfileBoolean === true) {
                addNotification && addNotification("Success", "you have successfully changed your profile settings", ENotificationType.Success)
            }
        })
    }
    return (

        <>

            <div className="d-flex justify-content-between">
                <h3>Settings</h3>
                <button className="btn btn-link" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    setShowSettings(!showSettings)
                }} type="button">
                    {showSettings === false ? <FontAwesomeIcon icon={faArrowAltCircleDown} /> : <FontAwesomeIcon icon={faArrowAltCircleUp} />}
                </button>

            </div>

            {showSettings === true && auth && <Form
                initialValues={
                    {
                        firstName: auth && auth.user && auth.user.firstName,
                        lastName: auth && auth.user && auth.user.lastName,
                        email: auth && auth.user && auth.user.email,
                        mobile: auth && auth.user && auth.user.mobile,
                    }}
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators,
                }}
                render={({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>

                        <Field name="firstName">
                            {({ input, meta }) => (
                                <div>
                                    <label>First Name</label>
                                    <input type="text" className="form-control" {...input} placeholder="...." />
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>
                        <Field name="lastName">
                            {({ input, meta }) => (
                                <div>
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" {...input} placeholder="...." />
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>
                        <hr />
                        <Field name="email">
                            {({ input, meta }) => (
                                <div>
                                    <label>Email</label>
                                    <input type="text" className="form-control" {...input} placeholder="...." />
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>
                        <Field name="mobile">
                            {({ input, meta }) => (
                                <div>
                                    <label>Mobile</label>
                                    <input type="text" className="form-control" {...input} placeholder="...." />
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>
                        <button type="submit" className="btn btn-success">Update Profile</button>
                    </form>

                )} />}
            <div className="d-flex justify-content-between">
                <h3>Password</h3>
                <button className="btn btn-link" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    setShowPassword(!showPassword)
                }} type="button">
                    {showPassword === false ? <FontAwesomeIcon icon={faArrowAltCircleDown} /> : <FontAwesomeIcon icon={faArrowAltCircleUp} />}
                </button>
            </div>

            {showPassword === true && <Form
                initialValues={{}}
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators,
                }}
                validate={values => {
                    const errors: any = {};
                    console.log(errors);
                    if (values.newPassword !== values.newPassword2) {
                        errors.newPassword = 'Passwords do not match!'
                    }
                    return errors;
                }}
                render={({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>


                        <Field name="password" validate={composeValidators(required)}>
                            {({ input, meta }) => (
                                <div>
                                    <label>Current Password</label>
                                    <input type="password" className="form-control" {...input} placeholder="" />
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>
                        <Field name="newPassword" validate={composeValidators(required)}>
                            {({ input, meta }) => (
                                <div>
                                    <label>New Password</label>
                                    <input type="password" className="form-control" {...input} placeholder="" />
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>
                        <Field name="newPassword2" validate={composeValidators(required)}>
                            {({ input, meta }) => (
                                <div>
                                    <label>New Password (Again)</label>
                                    <input type="password" className="form-control" {...input} placeholder="" />
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>

                        <button type="submit" className="btn btn-success">Change Password</button>
                    </form>

                )} />}
        </>);
}

export default MyProfileHome;