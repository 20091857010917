import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RichText = ({ input, meta, label, ...rest }: any) => {
  //EditorState.createWithContent()
  //draftToHtml(convertToRaw(
  let thing: any;
  const [editorState, setEditorState] = useState(() => {
    if (input.value !== null && input.value !== undefined) {

      const why = input.value
      //  console.log("why", why)
      try {

        // const blocksFromHTML = convertFromRaw(why);
        const state = convertFromRaw(JSON.parse(why));
        // console.log("tging", state)
        return EditorState.createWithContent(state)

      } catch (err) {
        // console.log("error", err, thing);
        return EditorState.createEmpty();
      }
    } else {
      return EditorState.createEmpty();
    }
  });

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState)
    console.log(input, rest)
    rest.onChanged && rest.onChanged(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    return input.onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
  }

  return (<><label>{label}</label>
    <div className="editor">
      {(editorState !== undefined && editorState !== null) && <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
      />
      }
      {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
    </div>
  </>
  )
}


export default RichText;