import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { IAssignment } from "../../interface/assignment";
import { IClient, IMatrixRisk, IMatrixSeverity } from "../../interface/client";
import { IEquipment } from "../../interface/equipment";
import { IInstallation } from "../../interface/installation";
import { IPipe } from "../../interface/pipe";

interface IClientRiskTable {
    detailDisplay: boolean
    assignment?: IAssignment;
    installation?: IInstallation;
    client: IClient;
    pipes: IPipe[]
    equipments: IEquipment[]
    query?: any;
    setQuery?: (q: any, add: boolean) => void
}

const ClientRiskTable = ({ installation, pipes, equipments, client, query, assignment, setQuery, detailDisplay }: IClientRiskTable) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const doCellCss = (riskNo: number, severityNo: number): React.CSSProperties => {
        const severityRisk = client.matrix.severityRisk;
        const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
        //    console.log(index);
        const thing = severityRisk[index];


        const level = client.matrix.levels[thing.thing]




        return { "backgroundColor": level.color };


    }
    const doCellTheme = (riskNo: number, severityNo: number): string => {
        if (client !== undefined) {
            const severityRisk = client.matrix.severityRisk;
            const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
            //    console.log(index);
            //       const thing = severityRisk[index];



            return `tableHover`;
            // }
        } else {
            return '';
        }


    }
    const doCellText = (riskNo: number, severityNo: number): string => {

        if (client !== undefined) {

            const severityRisk = client.matrix.severityRisk;
            const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
            //   console.log(index);
            const thing = severityRisk[index];





            const level = client.matrix.levels[thing.thing]

            //  console.log(level)


            return level.level

        } else {
            return '';
        }
    }


    const getCount = (Severity: number, Likelihood: number): number => {

        let count: number = -1;
        if (assignment) {
            count = assignment.forms.filter(form => {
                return (form.Severity === Severity && form.Likelihood === Likelihood)
            }).length;
        }
        if (installation) {
            //pipes
            //  console.log(installation)
            if (pipes) {
                count = pipes.filter(form => {
                    return (form.combinedConsequence === Severity && form.likelihood === Likelihood)
                }).length;
            }
            if (equipments) {
                count = count + equipments.filter(form => {
                    return (form.combinedConsequence === Severity && form.likelihood === Likelihood)
                }).length;
            }

        }
        if (count !== -1) { return count } else {
            return 0;
        }
    }

    const inQuery = (Severity: number, Likelihood: number) => {

        let SeverityTrue: boolean = false;
        let LikelihoodTrue: boolean = false;
        if (assignment) {
            if (query && Array.isArray(query.Severity)) {
                if (query.Severity.includes(Severity)) {
                    SeverityTrue = true;
                }
            } else {
                if (query && query.Severity === Severity) {
                    SeverityTrue = true;
                }
            }

            if (query && Array.isArray(query.Likelihood)) {
                if (query && query.Likelihood.includes(Likelihood)) {
                    LikelihoodTrue = true;
                }
            } else {
                if (query && query.Likelihood === Likelihood) {
                    LikelihoodTrue = true;
                }
            }
        }
        if (installation) {
            if (query && Array.isArray(query.combinedConsequence)) {
                if (query && query.combinedConsequence.includes(Severity)) {
                    SeverityTrue = true;
                }
            } else {
                if (query && query.combinedConsequence === Severity) {
                    SeverityTrue = true;
                }
            }

            if (query && Array.isArray(query.likelihood)) {
                if (query && query.likelihood.includes(Likelihood)) {
                    LikelihoodTrue = true;
                }
            } else {
                if (query && query.likelihood === Likelihood) {
                    LikelihoodTrue = true;
                }
            }
        }

        if (SeverityTrue === true && LikelihoodTrue === true) {
            return true;
        } else {
            return false;
        }
    }


    return <><div ref={componentRef}>
        <span className="e2eClientRiskTable" style={{ display: 'none' }}>TestOk</span>
        <table className="riskTable center w-100">
            <tbody>
                <tr>
                    {detailDisplay === true && <>

                        <td colSpan={5}></td>


                    </>}
                    <td></td>

                    <td colSpan={client && client.matrix.risk.length}>
                        <h4>Risk profile</h4></td>
                </tr>
                <tr>
                    {detailDisplay === true && <>
                        <td colSpan={5}></td>
                    </>}
                    <td></td>

                    <td colSpan={client && client.matrix.risk.length}><b>Likelihood</b></td>
                </tr>
                <tr>
                    {detailDisplay === true && <>

                        <td className="text-left" style={{ width: '10%' }}>People Impact</td>
                        <td className="text-left" style={{ width: '10%' }}>Asset Impact</td>
                        <td className="text-left" style={{ width: '10%' }}>Environmental Impact</td>
                        <td className="text-left" style={{ width: '10%' }}>Financial Impact</td>
                        <td className="text-left" style={{ width: '10%' }}>Reputation Impact</td>

                    </>}
                    <td><b>Severity</b></td>
                    {client && client.matrix.risk.map((risk: IMatrixRisk, riskIndex: number) => {
                        return <td key={`${riskIndex}`} className="tableHover" onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            setQuery && setQuery({ Likelihood: risk.riskNo }, false)
                        }}>
                            <b>{risk.risk}</b>

                            {/* {risk.riskDescription !== '' && <p>{risk.riskDescription}</p>} */}
                        </td>
                    })}
                </tr>

                {client && client.matrix.severity.map((severity: IMatrixSeverity, severityIndex: number) => {
                    const actSeverity = severity.severityNumber
                    return <tr key={severityIndex}>

                        {detailDisplay === true && <>
                            <td className="text-left">{severity.peopleImpact}</td>
                            <td className="text-left">{severity.assetImpact}</td>
                            <td className="text-left">{severity.environmentalImpact}</td>
                            <td className="text-left">{severity.financialImpact}</td>
                            <td className="text-left">{severity.reputationImpact}</td>
                        </>}
                        <td className="tableHover" onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            setQuery && setQuery({ Severity: actSeverity }, false)
                        }}>
                            <br />
                            <b>{severity.severity}</b>

                            {/* {severity.severityDescription !== '' && <p>{severity.severityDescription}</p>} */}
                        </td>
                        {client && client.matrix.risk.map((risk: IMatrixRisk, riskIndex: number) => {
                            const actRisk = risk.riskNo;

                            const cellCount = getCount(actSeverity, actRisk)
                            return <td key={`${riskIndex}`} onClick={(ev: React.MouseEvent) => {
                                ev.preventDefault();
                                if (assignment) {
                                    setQuery && setQuery({ combinedConsequence: actSeverity, Likelihood: actRisk }, false)
                                }
                                if (installation) {
                                    setQuery && setQuery({ combinedConsequence: actSeverity, likelihood: actRisk }, false)
                                }

                            }} style={doCellCss(actRisk, actSeverity)} className={doCellTheme(actRisk, actSeverity)}>
                                <>
                                    {cellCount > 0 ? <b>{doCellText(actRisk, actSeverity)}<br />{cellCount}</b> : <>{doCellText(actRisk, actSeverity)}</>}
                                </>
                            </td>
                        })}

                    </tr>
                })}
            </tbody>
        </table>
    </div>
        {handlePrint && <button type="button" className="btn btn-info" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            handlePrint();

        }}>
            <FontAwesomeIcon icon={faPrint} />
        </button>}
    </>;
}

export default ClientRiskTable;