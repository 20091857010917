import { ISession } from "../interface/session";
import axiosInstance from "./instance";

export const RequestGetSessions = (addNotification: any, data?: any): Promise<ISession[]> => {
    return new Promise<ISession[]>((resolve, reject) => {
        let url = `/sessions/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetSession = (addNotification: any, id: string): Promise<ISession> => {
    return new Promise<ISession>((resolve, reject) => {

        axiosInstance(addNotification).get(`/sessions/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewSession = (addNotification: any, thing: ISession): Promise<ISession> => {
    return new Promise<ISession>((resolve, reject) => {

        axiosInstance(addNotification).post(`/sessions/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteSession = (addNotification: any, user: ISession): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/sessions/${user.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateSession = (addNotification: any, user: ISession): Promise<ISession> => {
    return new Promise<ISession>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/sessions/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }

    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }
