import { faArrowRight, faBug, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface IPopupFieldComparison {
    data: any;

}
export const PopupFieldComparison = ({ data }: IPopupFieldComparison) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    return <>
        <button type="button" name="e2eFieldComparisonShow" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            setShow(true);
        }} className="btn btn-success e2eDebugButton"><FontAwesomeIcon icon={faBug} /></button>

        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header bsPrefix="debug" closeButton>
                <Modal.Title>Field Compare</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-left">
                    <div className="row">
                        {data.fieldComparison.map((comp: any, indexdd: number) => {
                            return <div className={`col-4 ${comp.validation.ok === false && 'bg-warning'}`}>{comp.fname}<br />{comp.current} {!comp.eq === true ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faArrowRight} />} {comp.update}<br />{JSON.stringify(comp.validation)}</div>;
                        })}
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>

}