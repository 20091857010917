import axios, { AxiosInstance } from "axios";
import { STORAGEKEY } from "../context/auth";
import { ENotificationType } from "../context/notifications";

const axiosInstance = (newNote: any): AxiosInstance => {
    const localStorageStr = localStorage.getItem(STORAGEKEY);

    let token: string = 'unset';
    if (localStorageStr) {
        const parsed = JSON.parse(localStorageStr)
        console.log("ls+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++", parsed)
        token = parsed.token;
    }
    const instance: AxiosInstance = axios.create({
        baseURL: '/api',
        timeout: 30000,
        headers: { 'X-Custom-Header': token }
    });
    instance.interceptors.response.use((resp) => resp, (error) => {
        // whatever you want to do with the error

        console.log(error)
        newNote && newNote('Error', 'Api Error', ENotificationType.Danger)
    });

    return instance;
};

export default axiosInstance;