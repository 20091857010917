import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from "react-helmet";
import 'react-input-range/lib/css/index.css';
import { Route, RouteProps, Switch } from 'react-router-dom';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import EBoundary from './components/system/EBoundary';
import Error from './components/system/Error';
import { useAuth } from './context/auth';
import { NotificationProvider } from './context/notifications';
import { PageTitleProvider, usePageTitle } from './context/pageTitle';
import About from './routes/About';
import Admin from './routes/admin/Admin';
import CalcBase from './routes/Calc/Base.';
import Installations from './routes/ClientInstallations/Installations';
import Reports from './routes/ClientReports/Reports';
import Contact from './routes/Contact/Contact';
import Home from './routes/Home';
import Login from './routes/Login';
import MyProfile from './routes/MyProfile/MyProfile';
import Page from './routes/Page';
import SteelsBase from './routes/Steel/Base.';





export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: string | string[];
  errorText?: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const { setPageTitle } = usePageTitle()!;

  const { auth } = useAuth()!;

  let error = false;

  if (Array.isArray(props.isAuthenticated)) {

    if (!props.isAuthenticated.includes(auth.type)) {
      error = true;
    }

  } else {
    if (auth.type !== props.isAuthenticated) {
      error = true;
    }
  }


  if (error) {

    setPageTitle && setPageTitle('Access Denied', 'login');


    const renderComponent = () => { return <h1>{props.errorText ? props.errorText : 'Access Denied'}</h1> };
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};
const App = () => {
  // const { addNotification } = useNotifications()!;
  const { auth } = useAuth()!;
  // const [loading, setLoading] = useState<boolean>(false);


  // useEffect(() => {

  // }, [])


  const clientOnly: ProtectedRouteProps = {
    isAuthenticated: ['admin', 'client']
  };

  const adminOnly: ProtectedRouteProps = {
    isAuthenticated: 'admin'
  };

  return (<>
    <NotificationProvider>
      <PageTitleProvider>


        <>


          {auth.id !== 'new' ? <div className="App">
            <GoogleReCaptchaProvider reCaptchaKey={auth.metaData.recapture}>
              <h1 style={{ display: 'none' }}>
                / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}
              </h1>
              <Helmet>
                <title>/ {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
              </Helmet>

              <EBoundary title="header-boundary">

                <Navigation />

              </EBoundary>



              <section className="page-nace" id="nace-content-block_610124d47a9b3">
                <div className="container-fluid">
                  <div className="page-nace__wrapper">
                    <div className="row">




                      <EBoundary title="route-boundary">
                        <Switch>
                          <Route path="/about">
                            <About />
                          </Route>

                          <ProtectedRoute
                            {...adminOnly}
                            path='/admin'
                            component={Admin}
                          />

                          <ProtectedRoute
                            {...clientOnly}
                            path='/calculator'
                            component={CalcBase}
                          />

                          <ProtectedRoute
                            {...adminOnly}
                            path='/steels'
                            component={SteelsBase}
                          />
                          <ProtectedRoute
                            {...clientOnly}
                            path='/reports'
                            component={Reports}
                          />

                          <ProtectedRoute
                            {...clientOnly}
                            path='/installations'
                            component={Installations}
                          />

                          <ProtectedRoute
                            {...clientOnly}
                            path='/profile'
                            component={MyProfile}
                          />
                          <Route path="/contact">
                            <Contact />
                          </Route>
                          <Route path="/login">
                            <Login />
                          </Route>
                          <Route path="/" exact>
                            <Home />
                          </Route>
                          <Route path="/p/:slug">
                            <Page />
                          </Route>
                        </Switch>
                      </EBoundary>


                    </div>
                  </div>
                </div>
              </section>


              <div className="" style={{ paddingTop: "200px" }}></div>

              <Footer />
            </GoogleReCaptchaProvider>
          </div> : <><Error /> Sorry System Error please contact support</>}

        </>

      </PageTitleProvider>
    </NotificationProvider>

  </>);
}

export default App;
