//import './theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Metric } from 'web-vitals';
import App from './App';
import { AuthProvider } from './context/auth';
import './index.scss';
import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://b{auth.type === 'buyer' && it.ly/CRA-vitals
const sendToAnalytics = (metric: Metric) => {
  /*

   name: 'CLS' | 'FCP' | 'FID' | 'LCP' | 'TTFB';
    value: number;
    delta: number;
    id: string;
    isFinal: boolean;
    entries: PerformanceEntry[];

    */
  // const d: any = {
  //   name: metric.name,
  //   value: metric.value,
  //   delta: metric.delta,
  //   id: metric.id,
  //   isFinal: metric.isFinal,
  //   entries: metric.entries.map((aa: any) => {
  //     const dd: any = { ...aa.toJSON() };
  //     if (dd.element) dd.element = undefined;
  //     if (dd.target) dd.target = undefined;
  //     try {
  //       JSON.stringify(dd);
  //     } catch (e) {
  //       console.error(dd);
  //       console.error(e);
  //     }
  //     return dd;

  //   })
  // }


  // RequestNewLog(undefined, {
  //   type: ELogType.reactUse,
  //   requestId: '',
  //   sessionId: '',
  //   userId: '',
  //   payload: d,
  //   severity: ESeverity.Trivial,
  //   text: 'react use'
  // })
}

reportWebVitals(sendToAnalytics);
