import queryString from "query-string";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router";
import { RequestNewContactPublic } from "../../api/contact-requests";
import { composeValidators, mustBeEmail, required } from "../../components/FormStuff/validators";
import DebugButton from "../../components/system/DebugButton";
import Loading from "../../components/system/loading";
import { useNotifications } from "../../context/notifications";

const ContactForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const [other, setOther] = useState<any>({});
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { addNotification } = useNotifications()!;
    let location = useLocation();

    useEffect(() => {

        const parsed = queryString.parse(location.search);
        console.log(parsed)
        if (parsed) {
            setOther(parsed)
        } else {
            setOther({});
        }
    }, [location.search]);


    const onSubmit = (data: any) => {
        setLoading(true)
        executeRecaptcha && executeRecaptcha("contact_form").then(token => {
            data.id = 'new';
            data.recapture = token
            console.log(data);
            RequestNewContactPublic(addNotification, data).then(contact => {
                setFinished(true);
                setLoading(false)
            })
        }).catch(() => {
            setLoading(false)
        });
    }

    return (<>
        {loading === true && <Loading />}
        {finished === true ? <>thanks for your message, we will contract you shortly</> : <Form
            onSubmit={onSubmit}
            initialValues={{
                other
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => {

                return <form onSubmit={handleSubmit}>
                    <DebugButton data={values} />
                    <Field name="name" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                            <div className="pull-left">
                                <label>Your Name</label>
                                <input type="text" className="form-control" {...input} placeholder="...." />
                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                            </div>
                        )}
                    </Field><br></br>
                    <Field name="phone" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                            <div className="pull-left">
                                <label>Phone Number</label>
                                <input type="text" className="form-control" {...input} placeholder="...." />
                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                            </div>
                        )}
                    </Field><br></br>
                    <Field name="email" validate={composeValidators(required, mustBeEmail)}>
                        {({ input, meta }) => (
                            <div className="pull-left">
                                <label>Email</label>
                                <input type="text" className="form-control" {...input} placeholder="...." />
                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                            </div>
                        )}
                    </Field><br></br>
                    <Field name="message" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                            <div className="pull-left">
                                <label>Message</label>
                                <textarea type="text" className="form-control" {...input} placeholder="...." />
                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                            </div>
                        )}
                    </Field>
                    <Field
                        name="agree"
                        component="input"
                        type="checkbox"
                        className=""
                        id="agree"
                    />
                    <label htmlFor="agree">Agree to tnc</label>
                    <p>What happens next? we will check our part database and that of our partners and get back to you</p>
                    <button className="btn btn-primary" type="submit">Make Request</button>
                </form>

            }} />}

    </>);
}

export default ContactForm;