import { IMatterial } from "../interface/matterial";
import axiosInstance from "./instance";

export const RequestGetMatterials =  (addNotification:any, data?: any): Promise<IMatterial[]> => {
    return new Promise<IMatterial[]>((resolve, reject) => {
        let url = `/matterials/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + ':' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetMatterial =  (addNotification:any,id: string): Promise<IMatterial> => {
    return new Promise<IMatterial>((resolve, reject) => {

        axiosInstance(addNotification).get(`/matterials/${id}`).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewMatterial =  (addNotification:any,thing: IMatterial): Promise<IMatterial> => {
    return new Promise<IMatterial>((resolve, reject) => {

        axiosInstance(addNotification).post(`/matterials/`, thing).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteMatterial =  (addNotification:any, user: IMatterial): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/matterials/${user.id}`).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateMatterial =  (addNotification:any, user: IMatterial): Promise<IMatterial> => {
    return new Promise<IMatterial>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/matterials/${user.id}`, user).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

