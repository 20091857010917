import { faArrowDown, faArrowUp, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import DataTable from "../../components/system/DataTable";
import DateDisplay from "../../components/system/DateDisplay";
import DebugButton from "../../components/system/DebugButton";
import TextDisplay from "../../components/system/TextDisplay";
import { IInspection, IInspectionReported } from "../../interface/inspection";

interface IInspectionDisplay {
    inspections: IInspection[];
    equipmentId?: string;
    loopId?: string;
    pipeId?: string;
}

export const InspectionDisplay = ({ inspections, equipmentId, loopId, pipeId }: IInspectionDisplay) => {
    //const [toggle, setToggle] = useEffect<boolean>(false)

    const [toggle, setToggle] = useState<boolean>(true);
    const [mode, setMode] = useState<boolean>(true);


    inspections.sort((obj1: IInspection, obj2: IInspection) => {
        if (toggle === false) {
            return (obj1.dateOfReport < obj2.dateOfReport ? -1 : 1);
        } else {
            return (obj1.dateOfReport > obj2.dateOfReport ? -1 : 1)
        }
    });
    const inspectedT: any[] = [];
    inspections.map(insp => {

        const inspLess: IInspection = { ...insp };
        inspLess.reported = [];
        if (equipmentId) {
            insp.reported.filter(dd => dd.equipmentId === equipmentId).map(s => inspectedT.push({ ...s, inspection: inspLess }));
        } else if (loopId) {
            insp.reported.filter(dd => dd.loopId === loopId).map(s => inspectedT.push({ ...s, inspection: inspLess }));
        } else if (pipeId) {
            insp.reported.filter(dd => dd.pipeId === pipeId).map(s => inspectedT.push({ ...s, inspection: inspLess }));
        }
    });



    return <>
        <h2>Inspections</h2>

        {inspectedT.length > 0 ? <>
            <div className="d-flex justify-content-between">
                <div>

                </div>
                <div> <button className="btn btn-success" type="button" onClick={() => {
                    setMode(!mode);
                }}>{mode === true ? 'r' : 'table'}</button>
                    <button className="btn btn-success" type="button" onClick={() => {
                        setToggle(!toggle);
                    }}>{toggle === true ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}</button>
                </div>
            </div>
            {mode ? <VerticalTimeline>

                {inspections.map(insp => {
                    let inspected: IInspectionReported[]

                    if (equipmentId) {
                        inspected = insp.reported.filter(dd => dd.equipmentId === equipmentId);
                    } else if (loopId) {
                        inspected = insp.reported.filter(dd => dd.loopId === loopId);
                    } else if (pipeId) {
                        inspected = insp.reported.filter(dd => dd.pipeId === pipeId);
                    } else {
                        return <>Error</>;
                    }

                    return <VerticalTimelineElement
                        dateClassName={'text-dark'}
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date={format(new Date(insp.dateOfReport), "yyyy-MM-dd")}
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<FontAwesomeIcon icon={faSearch} size="lg" />}
                    >

                        {inspected && inspected.map((ins, index) => {
                            return <><h4 className="vertical-timeline-element-subtitle">{index + 1} :{ins.typeId} /  {ins && ins.tagNo}</h4><TextDisplay text={ins.findings} /></>;
                        })}


                        <p>

                            <Link className="btn btn-primary" to={`/installations/${insp.installId}/inspection/${insp.id}`}>Details</Link>

                        </p>
                    </VerticalTimelineElement>;
                })}


            </VerticalTimeline> : <>
                <DataTable

                    hideIndexCol={true}
                    colHeadings={[
                        { name: "Confidence", field: "confidence", sortable: true },
                        { name: "Type", field: "typeId", sortable: false },
                        {
                            name: "Date", field: "dateOfReport", r: (value: any, index) => {
                                return <DateDisplay format="YYYY-MM-DD" date={value.inspection.dateOfReport} />;
                            }, sortable: true
                        },
                        {
                            name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                return <div className="btn-group">
                                    <DebugButton data={value} />
                                    <Link className="btn btn-primary" to={`/installations/${value.inspection.installId}/inspection/${value.inspection.id}`}>Details</Link>
                                    {value.pipe && <Link className="btn btn-outline-primary" to={`/installations/${value.inspection.installId}/pipe/${value.pipeId}`}>Pipe: {value.pipe.refId}</Link>}
                                    {value.equipment && <Link className="btn btn-outline-primary" to={`/installations/${value.inspection.installId}/equipment/${value.equipmentId}`}>Equipment: {value.equipment.refId}</Link>}


                                </div>;
                            }, sortable: false
                        },

                    ]}

                    data={inspectedT}
                />
            </>}
        </> : <>
            <p>sorry no results</p>
        </>}



    </>;

}