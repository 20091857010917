import React, { useCallback, useEffect } from "react";
import { RequestSystem } from "../api/system-requests";
import { ISession } from "../interface/session";
import { useNotifications } from "./notifications";
export const STORAGEKEY = 'fuckyou'
const blankSession: ISession = { id: 'new', type: 'guest', valid: false, permissions: [] };

type AuthContextType = {
  auth: ISession;
  setAuth?: (value: ISession) => void;
  logout?: () => void;
  hasPermission?: (roles: string[]) => boolean;
};

export const AuthContext = React.createContext<AuthContextType | undefined>(
  {
    auth: { ...blankSession }
  }
);

type Props = {
  children: React.ReactNode;
};






export const AuthProvider = ({ children }: Props) => {
  const INTERVAL_TIME = 60000;
  const localStorageStr = localStorage.getItem(STORAGEKEY);
  //console.log(localStorageStr)
  const { addNotification } = useNotifications()!;

  let initSession: ISession = { ...blankSession };
  if (localStorageStr) {
    initSession = JSON.parse(localStorageStr);
  }

  const [init, setInit] = React.useState<boolean>(false);
  const [auth, setAuth] = React.useState<ISession>(initSession);

  let interval: any;
  const logout = () => {

    const newSession: ISession = { ...blankSession };
    console.log("auth:LOGOUT", newSession);
    localStorage.removeItem(STORAGEKEY);
    clearLoop();
    RequestSystem(undefined, 'fdsf').then(session => {
      console.log("auth:LOGOUT", session);
      setAuth(session);
      localStorage.setItem(STORAGEKEY, JSON.stringify(session));
      interval = setInterval(loop, INTERVAL_TIME)

    }).catch(() => {
      setAuth({ ...initSession })
    });
  }

  //auto save to context!
  useEffect(() => {
    console.log("auth auth:useEffect", auth)
    // localStorage.setItem(STORAGEKEY, JSON.stringify(auth));
  }, [auth]);

  const hasPermission = (roles: string[]): boolean => {
    console.log("hasPermission", auth.user?.permissions)
    let result = false;
    roles.push('**');
    for (let index = 0; index < roles.length; index++) {
      const requiredRole: string = roles[index];
      if (auth.user && auth.user.permissions) {
        console.log(requiredRole, auth.user.permissions.findIndex((dd: string) => dd === requiredRole))
        if (auth.user.permissions.findIndex((dd: string) => dd === requiredRole) >= 0) {
          result = true;
        }
      }
    }
    return result;
    //    if(result===true){
  }

  const loop = useCallback(() => {
    const fuck = localStorage.getItem(STORAGEKEY);
    if (fuck !== null) {
      const off = JSON.parse(fuck)
      console.log(off);
      let token = '';
      if (off && off.token) {
        token = off.token;
      }
      RequestSystem(undefined, token).then(session => {
        console.log("RequestSystemLoop", session);
        localStorage.setItem(STORAGEKEY, JSON.stringify(session));
        setAuth(session);
      }).catch(() => {
        setAuth({ ...initSession })
      });
    }

  }, [setAuth])

  const clearLoop = useCallback(() => {
    clearInterval(interval);
  }, [interval])

  useEffect(() => {
    return () => {
      clearLoop();
      console.log("auth cleaned up");
    };
  }, []);




  useEffect(() => {
    if (init === false) {
      setInit(true);
      console.log("auth auth:useEffect:init");
      let token = '';
      if (auth && auth.token) {
        token = auth.token;
      }
      RequestSystem(undefined, token).then(session => {
        console.log("RequestSystem", session);
        setAuth(session);
        localStorage.setItem(STORAGEKEY, JSON.stringify(session));
        interval = setInterval(loop, INTERVAL_TIME)
      }).catch(() => {
        setAuth({ ...initSession })
      });
    }

  });


  return (
    <AuthContext.Provider value={{ auth, setAuth, logout, hasPermission }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);