import { faCheck, faDatabase, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DebugButton from '../../../components/system/DebugButton';
import { PopupFieldComparison } from './_FieldComparisonPopup';

export const CSVPopupContents = ({ values, array, form }: any) => {
    if (array !== undefined) {


        // const fay: string = array.toString();



        return <div className="text-left">
            <div className="btn-group">
                <button className="btn btn-warning  e2eSelectAll" onClick={() => {
                    if (values[array]) {
                        let joy = [...values[array]]
                        joy = joy.map((dd, index) => {
                            if (!dd.error) {
                                dd.action = true;
                            }

                            return dd;
                        })


                        form.change(array, joy)
                    }
                }}>ALL TICK</button>


                <button className="btn btn-warning" onClick={() => {
                    if (values[array]) {
                        let joy = [...values[array]]
                        joy = joy.map((dd, index) => {
                            if (!dd.error) {
                                dd.action = false;
                            }

                            return dd;
                        })


                        form.change(array, joy)
                    }
                }}>ALL UNTICK</button>
            </div>
            <p className="bg-primary">Check each row and approve if everything is correct, any validation / lookup errors will appear. <br /> [once the + become ticks press submit, then the actual update of the actual database happens untill now its all virtual <br /> then it gets a bit less refied and just appends the created or updated object to each line, we can get rid of this maybe but need to see what happpens as it evovles] </p>
            <div className="row">
                {values[array] && values[array].map((equip: any, index: number) => {
                    return <div className="col-3" key={index}>
                        <h2>Row {index + 1}</h2>
                        <div className="d-flex justify-content-between">
                            {equip.error ? <>{equip.errorTxt}</> : <>
                                {equip.isNew && <FontAwesomeIcon icon={faDatabase} />}
                                <button className="btn btn-warning" type="button" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    const joy = [...values[array]]
                                    joy[index].action = !joy[index].action;


                                    form.change(array, joy)
                                }}>{equip.action ? <><FontAwesomeIcon icon={faCheck} /></> : <FontAwesomeIcon icon={faPlus} />}</button>

                                {equip.exception && <p className="bg-danger e2eFailedDB">Something went wrong, [{equip.exception}]</p>}

                                {equip.response && <p className="bg-success e2eUpDate">Update Success <DebugButton data={equip.response} /></p>}



                            </>}
                            <PopupFieldComparison data={equip} />

                        </div>





                    </div>
                })}
            </div>


        </div>;
    } else {
        return <>boo</>;
    }

}
