import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useRouteMatch } from "react-router-dom";
import { RequestGetAnomaly, RequestGetAnomalys } from "../../api/anomaly-requests";
import { downloadFile } from "../../api/file-download-request";
import DataTable from "../../components/system/DataTable";
import DateDisplay from "../../components/system/DateDisplay";
import DebugButton from "../../components/system/DebugButton";
import TextDisplay from "../../components/system/TextDisplay";
import { useAuth } from "../../context/auth";
import { useNotifications } from "../../context/notifications";
import { IAnomaly } from "../../interface/anomaly";
import { IInspection } from "../../interface/inspection";



const Anomaly = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [resultsCount, setResultsCount] = useState<number>(0);
    const [thing, setThing] = useState<IAnomaly>();
    const [related, setRelated] = useState<IAnomaly[]>();
    const [inspections, setInspections] = useState<IInspection[]>();
    const [query, setQueryObj] = useState<any>({});
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const topRef = useRef<any>()
    const tableRef = useRef<any>()
    const [detailDisplayTable, setDetailDisplayTable] = useState<boolean>(false)
    const { auth } = useAuth()!;
    useEffect(() => {

        const params: any = match.params;
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            // const report = (): Promise<any> => {
            console.log("get reports", params.pipeId);
            const prodCall = () => {
                RequestGetAnomaly(addNotification, params.id2)
                    .then((json: IAnomaly) => {
                        console.log(json);
                        setThing(json);

                        let ddd: IAnomaly[] = [];

                        RequestGetAnomalys(addNotification, { inspectionId: json.inspectionId })
                            .then((jsone: IAnomaly[]) => {

                                ddd = ddd.concat(jsone)
                                RequestGetAnomalys(addNotification, { pipeId: json.pipeId })
                                    .then((jsone3: IAnomaly[]) => {
                                        console.log(jsone3);
                                        for (let index = 0; index < jsone3.length; index++) {
                                            if (ddd.findIndex(ddd => ddd.id === jsone3[index].id) === -1) {
                                                ddd.push(jsone3[index])
                                            }

                                        }
                                        RequestGetAnomalys(addNotification, { equipmentId: json.equipmentId })
                                            .then((jsone4: IAnomaly[]) => {
                                                console.log(jsone4);
                                                for (let index = 0; index < jsone4.length; index++) {
                                                    if (ddd.findIndex(ddd => ddd.id === jsone4[index].id) === -1) {
                                                        ddd.push(jsone4[index])
                                                    }

                                                }
                                                setRelated(ddd)
                                                setLoading(false);
                                            })
                                    })

                            })





                    })
            }


            Promise.allSettled([prodCall()]).then(() => {
                setLoading(false)
            });


        }
    }, [])
    let ColHead: any[] = [
        { name: 'Type', field: 'typeId', sortable: true },
        { name: 'Tag No', field: 'tagNo', sortable: true },
        {
            name: "Pipe / Equipment", field: "id", r: (value: any, index: number) => {
                return <div className="btn-group">
                    <DebugButton data={value} />
                    <Link className="btn btn-primary" to={`/installations/${value.installId}/inspection/${value.id}`}>Details</Link>

                </div>;
            }, sortable: false
        }
    ]


    return (<>
        {(loading === false && thing && thing !== null) && <>
            <Helmet>
                <title>{thing.refId} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <div className="col-12" ref={topRef}>
                <div className="text-left">
                    <Link to={`/installations/${thing.installId}/home`}>{thing.installation && thing.installation.name}</Link>
                    {' >'} Anomaly : {thing.refId}
                </div>
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="Home">

                        <DebugButton data={thing} />

                        <div className="row">
                            <h2>Details</h2>

                            <div className="col-12 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Subject</b>
                                    <p>{thing.subject}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Status</b>
                                    <p>{thing.status}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>File</b>

                                    {thing.file && <p>{thing.file.fileName} :
                                        <button className="btn btn-primary" type="button" onClick={() => {
                                            thing.file && downloadFile(addNotification, thing.file);
                                        }}><FontAwesomeIcon icon={faFileDownload} /></button>

                                    </p>}
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Date</b>
                                    <p><DateDisplay date={thing.date} /></p>
                                </div>
                            </div>

                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Reference Id</b>
                                    <p>{thing.refId}</p>
                                </div>
                            </div>
                            <h2>Rased By</h2>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Inspector</b>
                                    <p>{thing.rasesByName}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Company</b>
                                    <p>{thing.rasesByCompany}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Date</b>
                                    <p><DateDisplay date={thing.rasesDate} /></p>
                                </div>
                            </div>

                            <div className="col-12 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Summary Of Findings</b>
                                    <p><TextDisplay text={thing.summaryOfFindings} /></p>
                                </div>
                            </div>

                            <h2>Action</h2>

                            <div className="col-12 border border-start">


                                <p><TextDisplay text={thing.action} /></p>

                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>By</b>
                                    <p>{thing.actionByCompany}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>By Company</b>
                                    <p>{thing.actionByCompany}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Date</b>
                                    <p><DateDisplay date={thing.actionByDate} /></p>
                                </div>
                            </div>



                            <h2>Work</h2>

                            <div className="col-12 border border-start">

                                <p><TextDisplay text={thing.work} /></p>


                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>By</b>
                                    <p>{thing.workByName}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>By Company</b>
                                    <p>{thing.workByCompany}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Date</b>
                                    <p><DateDisplay date={thing.workByDate} /></p>
                                </div>
                            </div>
                            <h2>Completed</h2>

                            <div className="col-12 border border-start">

                                <p><TextDisplay text={thing.completed} /></p>

                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>By</b>
                                    <p>{thing.completedByName}</p>
                                </div>
                            </div>
                            <div className="col-6 border border-start">
                                <div className="d-flex justify-content-between">
                                    <b>Date</b>
                                    <p><DateDisplay date={thing.completedByDate} /></p>
                                </div>
                            </div>
                            {/* <div className="col-12">
                                <div className="d-flex justify-content-between">
                                    <b>Closing</b>
                                    <p>{thing.closing}</p>
                                </div>
                            </div> */}



                        </div>
                    </Tab>

                    <Tab eventKey="related" title="Related Anomalies">
                        <h2>Related Anomalies</h2>
                        {related && <DataTable

                            hideIndexCol={true}
                            colHeadings={[
                                { name: "ID", field: "refId", sortable: true },
                                { name: "Subject", field: "subject", sortable: false },
                                { name: "Status", field: "status", sortable: false },
                                {
                                    name: "Date", field: "date", r: (value: any, index) => {
                                        return <DateDisplay date={value.date} />;
                                    }, sortable: true
                                },
                                {
                                    name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                        return <div className="btn-group">
                                            <DebugButton data={value} />
                                            {value.pipe && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.pipeId}`}>Pipe: {value.pipe.refId}</Link>}
                                            {value.equipment && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.equipmentId}`}>Equipment: {value.equipment.refId}</Link>}


                                        </div>;
                                    }, sortable: false
                                },

                            ]}

                            data={related}
                        />}
                    </Tab>


                </Tabs>

            </div>



        </>}

    </>
    );
}

export default Anomaly;