
interface IDText {
    text:string
}
const TextDisplay: React.FunctionComponent<IDText> = props => {

    return <>{props.text!== undefined && props.text.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
      })}</>
};


export default TextDisplay