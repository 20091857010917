import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { SocketProvider } from '../../context/socket';
import AdminAnomalys from './Admin.Anomalys/Admin.Anomalys';
import AdminAssignments from './Admin.Assignments/Admin.Assignments';
import AdminCalculators from './Admin.Calculators/Admin.Calculators';
import AdminClients from './Admin.Clients/Admin.Clients';
import AdminContacts from './Admin.Contacts/Admin.Contacts';
import AdminDebug from './Admin.Debug';
import AdminDrawings from './Admin.Drawings/Admin.Drawings';
import AdminEquipments from './Admin.Equipments/Admin.Equipments';
import AdminFiles from './Admin.Files/Admin.Files';
import AdminHelps from './Admin.Helps/Admin.Helps';
import AdminHome from './Admin.Home';
import AdminImages from './Admin.Images/Admin.Images';
import AdminImpuritys from './Admin.Impuritys/Admin.Impuritys';
import AdminInspecteds from './Admin.Inspecteds/Admin.Inspecteds';
import AdminInspections from './Admin.Inspections/Admin.Inspections';
import AdminInspectionTypes from './Admin.InspectionTypes/Admin.InspectionTypes';
import AdminInstallations from './Admin.Installations/Admin.Installations';
import AdminInstallationSystems from './Admin.InstallationSystems/Admin.InstallationSystems';
import AdminInstallationVersions from './Admin.InstallationVersions/Admin.InstallationVersions';
import AdminLogs from './Admin.Logs';
import AdminMatterials from './Admin.Matterials/Admin.Matterials';
import AdminMechanisms from './Admin.Mechanisms/Admin.Mechanisms';
import AdminPages from './Admin.Pages/Admin.Pages';
import AdminPipes from './Admin.Pipes/Admin.Pipes';
import AdminSessions from './Admin.Sessions/Admin.Sessions';
import AdminSteels from './Admin.Steels/Admin.Steels';
import AdminStores from './Admin.Stores/Admin.Stores';
import AdminSysNotifications from './Admin.SysNotifications/Admin.SysNotifications';
import AdminSysNotificationTypes from './Admin.SysNotificationTypes/Admin.SysNotificationTypes';
import AdminSysPermissionss from './Admin.SysPermissionss/Admin.SysPermissionss';
import AdminUsers from './Admin.Users/Admin.Users';
import AdminSystemCollectionsPage from './_Collections/Admin.Collections';
import AdminCollectionStatusPage from './_Collections/Admin.CollectionStatus';
import InstallationExportPage from './_Collections/Admin.InstallationExport';

interface IAdminProps {

}

const Admin = ({ }: IAdminProps) => {
    let match = useRouteMatch();
    const { hasPermission } = useAuth()!;

    let [loading, setLoading] = useState<boolean>(false);
    // let [user, setUser] = useState<IUserSellerProfile>();
    let [expanded, setExpanded] = useState<boolean>(true);
    useEffect(() => {
        //   console.log("get product types");
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {



        }
    }, [match])



    return (<div className="">

        <SocketProvider>

            <div className="text-left"><button
                className="btn btn-outline-success"
                type="button"
                onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    setExpanded(!expanded)
                }}><FontAwesomeIcon icon={faBars} /></button></div>
            <div className="row">
                {expanded && <div className="col-2 text-left">

                    <h4>Reports</h4>
                    <NavDropdown.Item as={Link} to="/admin/assignments">DRA</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/installations">Retriever</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/inspections">Retriever - Inspections</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/anomalys">Retriever - Anomalies</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/calculators">Alloyselect</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/steels">S355</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/clients">Clients</NavDropdown.Item>
                    {/* <NavDropdown.Item as={Link} to="/admin/contacts">Contacts</NavDropdown.Item> */}
                    <h4>Data</h4>
                    <NavDropdown.Item as={Link} to="/admin/matterials">Materials</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/impurities">Impurities</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/mechanisms">Damage Mechanisms</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/helps">Help</NavDropdown.Item>

                    {hasPermission && hasPermission(['**']) && <>
                        <h4>Retriever Configuration</h4>
                        <NavDropdown.Item as={Link} to="/admin/equipments">Equipment</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/pipes">Pipes</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/installationSystems">Installation Systems</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/installatlionLoops">Installation Loops</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/installationVersions">Installation Versions</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/inspected">Inspected</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/drawings">Drawings</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/inspectionTypes">Inspection Types</NavDropdown.Item>


                    </>}

                    <h4>System & User Configuration</h4>

                    <NavDropdown.Item as={Link} to="/admin/users">Users</NavDropdown.Item>
                    {hasPermission && hasPermission(['**']) && <>
                        <NavDropdown.Item as={Link} to="/admin/files">Files</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/permissions">Permissions</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/notifications">Notifications</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/notificationTypes">Notification Types</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/pages">Pages</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/sessions">Sessions</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/images">Images</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/logs">Logs</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/collections">Collections</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/backupMonitor">Backup Monitor</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/exportInstallation">Backup Installation</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/admin/debug">Debug</NavDropdown.Item>

                    </>}

                </div>}

                <div className={`${expanded === true ? 'col-10' : 'col-12'}`}>
                    {loading === false && <>
                        <Switch>
                            <Route path={`${match.path}/home`}>
                                <AdminHome />
                            </Route>
                            <Route path={`${match.path}/stores`}>
                                <AdminStores />
                            </Route>
                            <Route path={`${match.path}/logs`}>
                                <AdminLogs />
                            </Route>
                            <Route path={`${match.path}/clients`}>
                                <AdminClients />
                            </Route>
                            <Route path={`${match.path}/helps`}>
                                <AdminHelps />
                            </Route>
                            <Route path={`${match.path}/contacts`}>
                                <AdminContacts />
                            </Route>
                            <Route path={`${match.path}/installations`}>
                                <AdminInstallations />
                            </Route>
                            <Route path={`${match.path}/installationSystems`}>
                                <AdminInstallationSystems />
                            </Route>
                            <Route path={`${match.path}/installationVersions`}>
                                <AdminInstallationVersions />
                            </Route>
                            <Route path={`${match.path}/matterials`}>
                                <AdminMatterials />
                            </Route>
                            <Route path={`${match.path}/equipments`}>
                                <AdminEquipments />
                            </Route>
                            <Route path={`${match.path}/pipes`}>
                                <AdminPipes />
                            </Route>
                            <Route path={`${match.path}/anomalys`}>
                                <AdminAnomalys />
                            </Route>
                            <Route path={`${match.path}/inspections`}>
                                <AdminInspections />
                            </Route>
                            <Route path={`${match.path}/inspected`}>
                                <AdminInspecteds />
                            </Route>
                            <Route path={`${match.path}/pages`}>
                                <AdminPages />
                            </Route>
                            <Route path={`${match.path}/sessions`}>
                                <AdminSessions />
                            </Route>
                            <Route path={`${match.path}/images`}>
                                <AdminImages />
                            </Route>
                            <Route path={`${match.path}/impurities`}>
                                <AdminImpuritys />
                            </Route>
                            <Route path={`${match.path}/mechanisms`}>
                                <AdminMechanisms />
                            </Route>
                            <Route path={`${match.path}/assignments`}>
                                <AdminAssignments />
                            </Route>
                            <Route path={`${match.path}/steels`}>
                                <AdminSteels />
                            </Route>
                            <Route path={`${match.path}/stores`}>
                                <AdminStores />
                            </Route>
                            <Route path={`${match.path}/drawings`}>
                                <AdminDrawings />
                            </Route>
                            <Route path={`${match.path}/users`}>
                                <AdminUsers />
                            </Route>
                            <Route path={`${match.path}/files`}>
                                <AdminFiles />
                            </Route>
                            <Route path={`${match.path}/calculators`}>
                                <AdminCalculators />
                            </Route>
                            <Route path={`${match.path}/collections`}>
                                <AdminSystemCollectionsPage />
                            </Route>

                            <Route path={`${match.path}/permissions`}>
                                <AdminSysPermissionss />
                            </Route>
                            <Route path={`${match.path}/notifications`}>
                                <AdminSysNotifications />
                            </Route>
                            <Route path={`${match.path}/inspectionTypes`}>
                                <AdminInspectionTypes />
                            </Route>

                            <Route path={`${match.path}/notificationTypes`}>
                                <AdminSysNotificationTypes />
                            </Route>

                            <Route path={`${match.path}/backupMonitor`} exact>
                                <AdminCollectionStatusPage />
                            </Route>
                            <Route path={`${match.path}/debug`}>
                                <AdminDebug />
                            </Route>
                            <Route path={`${match.path}/exportInstallation`}>
                                <InstallationExportPage />
                            </Route>
                            <Route path={`${match.path}`} exact>
                                <AdminHome />
                            </Route>
                        </Switch>
                    </>}

                </div>

            </div>

        </SocketProvider>

    </div>);
}

export default Admin;


// const passwordHash = bcrypt.hashSync(req.body.password, 10);
// userD.password = passwordHash;