import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { RequestGetPublicAssignments } from "../../api/assignment-requests";
import DataTable from "../../components/system/DataTable";
import DebugButton from "../../components/system/DebugButton";
import { useAuth } from "../../context/auth";
import { useNotifications } from "../../context/notifications";
import { usePageTitle } from "../../context/pageTitle";
import { IAssignment } from "../../interface/assignment";
import Report from "./Report";
const Reports = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [reports, setReports] = useState<IAssignment[]>();
    const { addNotification } = useNotifications()!;
    let match = useRouteMatch();
    const { auth } = useAuth()!;
    const location = useLocation();



    const { setPageTitle } = usePageTitle()!;



    useEffect(() => {



        setPageTitle && setPageTitle('My DRA');

    }, [location])



    useEffect(() => {
        console.log("get reports");
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            RequestGetPublicAssignments(addNotification)
                .then(assignments => {
                    setReports(assignments)
                    setLoading(false);
                })

        }
    }, [])

    let ColHead: any[] = [
        { name: 'Name', field: 'name', sortable: true },
        { name: 'Revision', field: 'revision', sortable: true },


    ]


    if (auth.type === 'admin') {

        ColHead.push({
            name: "Client", field: "client", sortable: false, r: (value: any, inde: number) => {
                return <>{value.client && value.client.name}</>
            }
        })
    }

    ColHead.push({
        name: "Actions", align: "right", field: "id", r: (value: any, index: number) => {
            return <div className="btn-group">
                <DebugButton data={value} />
                <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>View Report</Link>

            </div>;
        }, sortable: false
    })
    return (<>
        {reports && <>
            <Helmet>
                <title>My Risk Reports / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <Switch>
                <Route path={`${match.path}`} exact>
                    <div className="col-12">
                        <DataTable colHeadings={ColHead} data={reports} />
                    </div>
                </Route>
                <Route path={`${match.path}/:id`}>
                    <Report />
                </Route>
            </Switch>

        </>}

    </>
    );
}

export default Reports;