import moment from 'moment';




export const parseFields = (fields: any[], index: number, ddd: any, current: any, possibleUpdate: any, values: any) => {
    const field: any = { ...fields[index] };
    const fname = field.field;
    const ftype = field.type;
    const validation = {
        ok: true, msg: '', echo: ''
    };
    let eq = false;
    const update = ddd[fname];
    console.log("______________PARSE FIELDS", field)
    //  field.currentValue = current[fname];
    // field.updateValue = ddd[fname]
    if (update !== undefined) {
        if (field.required) {
            if (update === '') {
                validation.ok = false;
                validation.msg = 'Required';
            }
        }
        switch (ftype) {
            case 'inspection-types':
                const targetField = field.targetField;
                const newArr: string[] = [];
                const split: string[] = update.split(',');
                split.map(str => {
                    if (newArr.findIndex(s => s === str) === -1) {
                        newArr.push(str)
                    }
                })
                ddd[targetField] = newArr;
                break;
            case 'boolean':
                console.log("_____________________________________________________________________________________________________________", update);

                if (update.toLowerCase() === 'true') {
                    ddd[fname] = true;
                } else if (fname.toLowerCase() === '1') {
                    ddd[fname] = true;
                } else if (update.toLowerCase() === 'yes') {
                    ddd[fname] = true;
                } else if (update.toLowerCase() === 'false') {
                    ddd[fname] = false;
                } else if (update.toLowerCase() === '0') {
                    ddd[fname] = false;
                } else if (update.toLowerCase() === 'no') {
                    ddd[fname] = false;
                } else {
                    ddd[fname] = false;
                }

                //   ddd[targetField] = Boolean(ddd[targetField])
                eq = (current[fname] === ddd[fname]);
                break;
            case 'date':
                eq = (current[fname] === update);


                const splitOne = update.split('-');
                const splitTwo = update.split('/');
                console.log("SSS", splitOne, splitTwo);
                if (splitOne.length === 3) {
                    const momented = moment(update, 'DD-MM-YYYY');
                    const targetField = field.targetField;
                    if (momented) {
                        ddd[targetField] = momented.toDate();
                        possibleUpdate.fieldComparison.push({ fname: targetField, current: current[targetField], update: momented.toDate().toString(), validation: { ok: true, msg: '' } });
                    } else {
                        //  validation.ok = false;
                        validation.msg = 'Parse failed (1)';
                    }
                } else if (splitTwo.length === 3) {
                    const momented = moment(update, 'DD-MM-YYYY');
                    const targetField = field.targetField;
                    if (momented) {
                        ddd[targetField] = momented.toDate();
                        possibleUpdate.fieldComparison.push({ fname: targetField, current: current[targetField], update: momented.toDate().toString(), validation: { ok: true, msg: '' } });
                    } else {
                        //  validation.ok = false;
                        validation.msg = 'Parse failed (2)';
                    }
                } else {
                    validation.msg = 'Parse failed (*)';
                }

                break;
            case 'text':
                eq = (current[fname] === update);
                break;
            case 'equipmentType':
                // eq = (current[fname] === update)
                const equipIndex = values.equipmentTypes.findIndex((dd: any) => dd.value === update.toString())
                if (equipIndex === -1) {
                    validation.ok = false;
                    validation.msg = 'Failed to find equipment type that matches'
                }
                break;
            case 'fluid':
                // eq = (current[fname] === update)
                const fluidIndex = values.fluids.findIndex((dd: any) => dd.value === update.toString())
                if (fluidIndex === -1) {
                    validation.ok = false;
                    validation.msg = 'Failed to find fluid that matches'
                }
                break;
            case 'matterial':
                // eq = (current[fname] === update)
                const matterialIndex = values.matterials.findIndex((dd: any) => dd.value === update.toString())
                console.log("MATTERIAL", matterialIndex)
                if (matterialIndex === -1) {
                    validation.ok = false;
                    validation.msg = 'Failed to find matterial that matches'
                }
                break;
            case 'AZ':
                eq = (current[fname] === update);

                console.log(fname, update);
                if (values.client.matrix && values.client.matrix.risk) {
                    const index = values.client.matrix.risk.findIndex((s: any) => s.risk === update.toUpperCase());
                    if (index !== -1) {
                        const targetField = field.targetField;
                        const int = parseInt(values.client.matrix.risk[index].riskNo);
                        ddd[targetField] = int;
                        possibleUpdate.fieldComparison.push({ fname: targetField, current: current[targetField], update: int, validation: { ok: true, msg: '' } });
                    } else {
                        validation.ok = false;
                        validation.echo = '[cant match]';
                    }
                } else {
                    validation.ok = false;
                    validation.echo = '[no client matrix]';
                }
                break;
            case 'AZ_LIKE':
                eq = (current[fname] === update);

                console.log(fname, update);
                if (values.client.matrix && values.client.matrix.risk) {
                    if (update.toUpperCase() !== '') {


                        const index = values.client.matrix.risk.findIndex((s: any) => s.risk === update.toUpperCase());
                        if (index !== -1) {
                            const targetField = field.targetField;
                            const int = parseInt(values.client.matrix.risk[index].riskNo);
                            ddd[targetField] = int;
                            possibleUpdate.fieldComparison.push({ fname: targetField, current: current[targetField], update: int, validation: { ok: true, msg: '' } });
                        } else {
                            validation.ok = false;
                            validation.echo = '[cant match]';
                        }
                    }
                } else {
                    validation.ok = false;
                    validation.echo = '[no client matrix]';
                }
                break;
            case 'AZ_CC':
                eq = (current[fname] === update);

                console.log(fname, update);
                if (values.client.matrix && values.client.matrix.severity) {
                    const index = values.client.matrix.severity.findIndex((s: any) => s.severity === update.toUpperCase());
                    if (index !== -1) {
                        const targetField = field.targetField;
                        const int = parseInt(values.client.matrix.severity[index].severityNumber);
                        ddd[targetField] = int;
                        possibleUpdate.fieldComparison.push({ fname: targetField, current: current[targetField], update: int, validation: { ok: true, msg: '' } });
                    } else {
                        validation.ok = false;
                        validation.echo = '[cant match]';
                    }
                } else {
                    validation.ok = false;
                    validation.echo = '[no client matrix]';
                }
                break;
            case 'float':
                if (isNaN(Number(update))) {
                    validation.ok = false;
                    validation.msg = 'Not a number';

                    eq = (current[fname] === update);
                }
                break;
            case 'int':
                if (isNaN(Number(update))) {
                    validation.ok = false;
                    validation.msg = 'Not a number';

                    eq = (current[fname] === update);
                }
                break;
            case 'number':
                if (isNaN(Number(update))) {
                    validation.ok = false;
                    validation.msg = 'Not a number';

                    eq = (current[fname] === update);
                }
                break;
            case 'system':
                eq = (current[fname] === ddd[fname]);
                const systemIndex = values.systems.findIndex((dd: any) => dd.refId === update.toString());
                if (systemIndex === -1) {
                    validation.ok = false;
                    validation.msg = 'Failed to find system with that refId';
                } else {
                    const id = values.systems[systemIndex].id;
                    ddd.systemId = id;
                    possibleUpdate.fieldComparison.push({ fname: 'systemId', current: current.systemId, update: id, validation: { ok: true, msg: '' } });
                }

                break;
            case 'loop':
                eq = (current[fname] === ddd[fname]);
                const loopIndex = values.loops.findIndex((dd: any) => dd.refId === update.toString());
                if (loopIndex === -1) {
                    validation.ok = false;
                    validation.msg = 'Failed to find loop with that refId';
                } else {
                    const id = values.loops[loopIndex].id;
                    ddd.loopId = id;
                    ddd.systemId = values.loops[loopIndex].systemId;
                    possibleUpdate.fieldComparison.push({ fname: 'loopId', current: current.loopId, update: id, validation: { ok: true, msg: '' } });
                }

                break;

            case 'LOF':

                if (isNaN(Number(update)) || update === 'N/A') {
                    if (update === 'N/A') {

                    } else {
                        const parsed = parseInt(update);
                        if ((parsed > 0) && (parsed <= values.client.matrixMax)) {
                            validation.ok = false;
                            validation.msg = `N/A or 1-${values.client.matrixMax}`;
                        }

                    }


                    // eq = (current[fname] === update)
                }
                break;
        }
        possibleUpdate.fieldComparison.push({ fname, eq, current: current[fname], update: ddd[fname], validation });
    } else {
        validation.echo = 'failed';
        console.log("FFS ", fname, ddd);
        possibleUpdate.fieldComparison.push({ fname, eq, current: current[fname], update: ddd[fname], validation });
    }
}

const validateFormInput = (thingddcdd: any, fields: any[]) => {
    const validationErrors: string[] = [];
    fields.map(field => {
        console.log(field);
        if (field.required) {
            if (field.targetField) {
                const fName = field.targetField;

                if (!thingddcdd[fName]) {

                    validationErrors.push(field.field);
                }
            } else if (field.field) {
                const fName = field.field;

                if (!thingddcdd[fName]) {
                    validationErrors.push(fName);
                }
            }
        }
    });
    return validationErrors;
}

