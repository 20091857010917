import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import DateField from '../../../components/FormStuff/date';
import { IInspectionType } from '../../../interface/inspection-type';
interface IInputFields {
    values: any;
    array: any[];
    form: any;
    targetObject: any;
    inspectionTypes: IInspectionType[] | undefined;
}
export const InputFields = ({ values, array, form, targetObject, inspectionTypes }: IInputFields) => {
    const pipes = values.pipes
    const equip = values.equipments;
    const client = values.client;
    console.log(client)

    const LOFMax = client.matrixMax;
    return <>

        {array && array.map((field: any, fIndex: number) => {
            const fieldName = field.field;
            let payload: any;
            if (values[targetObject]) {
                payload = values[targetObject][fieldName];
            }
            let validation = false;
            if (values.validationErrors) {
                const indexed = values.validationErrors.findIndex((m: string) =>
                    m === fieldName
                )
                if (indexed !== -1) {
                    validation = true;
                }
            }

            return <div key={fIndex}>
                {(field.type === 'text') && <Field name={`${targetObject}.${field.field}`}>
                    {({ input, meta }) => (
                        <div>
                            <label>{field.name}</label>
                            <input id={`inp_${field.field}`} type="text" className="form-control" {...input} placeholder="...." />
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}


                {field.type === 'inspection-types' && <>
                    <div>

                        <div className="row">
                            <div className="col-12">

                                {inspectionTypes && inspectionTypes.map((type, typeIndex) => {

                                    return <label className="col-4" key={typeIndex}>

                                        <Field
                                            name={`${targetObject}.inspectionTypeIds`}
                                            type="checkbox"
                                            value={type.id}
                                            component={({ input, meta }) => (<>
                                                <input type="checkbox" id={`inp_${field.field}_${type.id}`} {...input} />
                                                <div className={`${input.checked ? `inp_${field.field}_${type.id}_true` : `inp_${field.field}_${type.id}_false`}`}>
                                                    {type.name} ({type.type}) {"The value is" + input.checked}
                                                </div>


                                            </>
                                            )}
                                        />

                                    </label>;
                                })}

                            </div>

                        </div>
                    </div>

                </>}

                {(field.type === 'date') && <>


                    <Field name={`${targetObject}.${field.field}`} label={field.name} component={DateField} />
                    {validation && <span className="bg-warning">Required</span>}

                </>}
                {field.type === 'fromStr' && <>
                    <div>

                        <Field name={`${targetObject}.${field.field}`}>
                            {({ input, meta }) => (
                                <div>
                                    <label>{field.name}</label>
                                    <input id={`inp_${field.field}`} type="text" className="form-control" {...input} placeholder="...." readOnly={true} />
                                    {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>
                        {validation && <span className="bg-warning">Required</span>}
                        <div className="row">
                            <div className="col-6">

                                <Field name={`${targetObject}.fromPipeId`}>
                                    {({ input, meta }) => (
                                        <div>

                                            <label>Pipe</label>
                                            <select id={`inp_${field.field}PipeId`} className="form-control" {...input}>
                                                <option></option>
                                                {pipes && pipes.map((d: any, index: number) => {
                                                    return <option key={index} value={d.id}>{d.refId}</option>
                                                })}
                                            </select>
                                        </div>
                                    )}
                                </Field>

                                <OnChange name={`${targetObject}.fromPipeId`}>
                                    {(value, previous) => {
                                        // do something

                                        const fIndex = values.pipes.findIndex((ddd: any) => ddd.id === value);
                                        if (fIndex !== -1) {
                                            console.log(fIndex)

                                            const pipe = values.pipes[fIndex];
                                            form.change(`${targetObject}.${field.field}`, pipe.refId)
                                            //form.change(`${targetObject}.system_refId`, system.refId)
                                            //  form.change(`${targetObject}.system`, system)

                                            form.change(`${targetObject}.fromEquipment`, undefined)
                                            form.change(`${targetObject}.fromPipe`, pipe)
                                            form.change(`${targetObject}.fromEquipmentId`, undefined)
                                        }

                                    }}
                                </OnChange>
                            </div>
                            <div className="col-6">
                                <Field name={`${targetObject}.fromEquipmentId`}>
                                    {({ input, meta }) => (
                                        <div>

                                            <label>Equipment</label>
                                            <select id={`inp_${field.field}EquipmentId`} className="form-control" {...input}>
                                                <option></option>
                                                {equip && equip.map((d: any, index: number) => {
                                                    return <option key={index} value={d.id}>{d.refId}</option>
                                                })}
                                            </select>
                                        </div>
                                    )}
                                </Field>
                                <OnChange name={`${targetObject}.fromEquipmentId`}>
                                    {(value, previous) => {
                                        // do something

                                        const fIndex = values.equipments.findIndex((ddd: any) => ddd.id === value);
                                        if (fIndex !== -1) {
                                            console.log(fIndex)

                                            const eq = values.equipments[fIndex];
                                            form.change(`${targetObject}.${field.field}`, eq.refId)
                                            form.change(`${targetObject}.fromEquipment`, eq)
                                            form.change(`${targetObject}.fromPipe`, undefined)
                                            form.change(`${targetObject}.fromPipeId`, undefined)
                                            //  form.change(`${targetObject}.system`, system)
                                        }

                                    }}
                                </OnChange>
                            </div>
                        </div>
                    </div>

                </>}
                {field.type === 'toStr' && <>
                    <div>
                        <Field name={`${targetObject}.${field.field}`}>
                            {({ input, meta }) => (
                                <div>
                                    <label>{field.name}</label>
                                    <input id={`inp_${field.field}`} type="text" className="form-control" {...input} placeholder="...." readOnly={true} />
                                    {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>
                        {validation && <span className="bg-warning">Required</span>}
                        <div className="row">
                            <div className="col-6">

                                <Field name={`${targetObject}.toPipeId`}>
                                    {({ input, meta }) => (
                                        <div>

                                            <label>Pipe</label>
                                            <select id={`inp_${field.field}PipeId`} className="form-control" {...input}>
                                                <option></option>
                                                {pipes && pipes.map((d: any, index: number) => {
                                                    return <option key={index} value={d.id}>{d.refId}</option>
                                                })}
                                            </select>
                                            {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <OnChange name={`${targetObject}.toPipeId`}>
                                    {(value, previous) => {
                                        // do something

                                        const fIndex = values.pipes.findIndex((ddd: any) => ddd.id === value);
                                        if (fIndex !== -1) {
                                            console.log(fIndex)

                                            const pipe = values.pipes[fIndex];
                                            form.change(`${targetObject}.${field.field}`, pipe.refId)
                                            //form.change(`${targetObject}.system_refId`, system.refId)
                                            form.change(`${targetObject}.toEquipment`, undefined)
                                            form.change(`${targetObject}.toPipe`, pipe)
                                            form.change(`${targetObject}.toEquipmentId`, undefined)
                                        }

                                    }}
                                </OnChange>
                            </div>
                            <div className="col-6">
                                <Field name={`${targetObject}.toEquipmentId`}>
                                    {({ input, meta }) => (
                                        <div>

                                            <label>Equipment</label>
                                            <select id={`inp_${field.field}EquipmentId`} className="form-control" {...input}>
                                                <option></option>
                                                {equip && equip.map((d: any, index: number) => {
                                                    return <option key={index} value={d.id}>{d.refId}</option>
                                                })}
                                            </select>
                                            {meta && meta.error && <span className="bg-warning">{meta.error}</span>}

                                        </div>
                                    )}
                                </Field>

                                <OnChange name={`${targetObject}.toEquipmentId`}>
                                    {(value, previous) => {
                                        // do something

                                        const fIndex = values.equipments.findIndex((ddd: any) => ddd.id === value);
                                        if (fIndex !== -1) {
                                            console.log(fIndex)

                                            const eq = values.equipments[fIndex];
                                            form.change(`${targetObject}.${field.field}`, eq.refId)
                                            //form.change(`${targetObject}.system_refId`, system.refId)
                                            form.change(`${targetObject}.toEquipment`, eq)
                                            form.change(`${targetObject}.toPipe`, undefined)
                                            form.change(`${targetObject}.toPipeId`, undefined)
                                        }

                                    }}
                                </OnChange>
                            </div>
                        </div>
                    </div>

                </>}
                {field.type === 'AZ' && <Field name={`${targetObject}.${field.targetField}`} type="number" parse={(value, name) => {
                    //  console.log("parse", value, name)
                    return parseFloat(value);
                }} format={(value, name) => {
                    //  console.log("format", value, name)
                    return parseFloat(value);
                }}>
                    {({ input, meta }) => (
                        <div>

                            <label>{field.name}</label>
                            <select id={`inp_${field.field}`} className="form-control" {...input}>
                                <option></option>
                                {values.client && values.client.matrix && values.client.matrix.risk && values.client.matrix.risk.map((client: any, index: number) => {
                                    return <option key={index} value={client.riskNo}>{client.risk}</option>
                                })}
                            </select>
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}

                {field.type === 'AZ_LIKE' && <Field name={`${targetObject}.${field.targetField}`} type="number" parse={(value, name) => {
                    //  console.log("parse", value, name)
                    return parseFloat(value);
                }}
                    format={(value, name) => {
                        //  console.log("format", value, name)

                        return parseFloat(value);
                    }}>
                    {({ input, meta }) => (
                        <div>

                            <label>{field.name}</label>
                            <select id={`inp_${field.field}`} className="form-control" {...input}>
                                <option></option>
                                {values.client && values.client.matrix && values.client.matrix.risk && values.client.matrix.risk.map((client: any, index: number) => {
                                    return <option key={index} value={client.riskNo}>{client.risk}</option>
                                })}
                            </select>
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}
                {field.type === 'AZ_CC' && <Field name={`${targetObject}.${field.targetField}`} type="number" parse={(value, name) => {
                    //  console.log("parse", value, name)
                    return parseFloat(value);
                }}
                    format={(value, name) => {
                        //  console.log("format", value, name)

                        return parseFloat(value);
                    }}>
                    {({ input, meta }) => (
                        <div>

                            <label>{field.name}</label>
                            <select id={`inp_${field.field}`} className="form-control" {...input}>
                                <option></option>
                                {values.client && values.client.matrix && values.client.matrix.severity && values.client.matrix.severity.map((client: any, index: number) => {
                                    return <option key={index} value={client.severityNumber}>{client.severity}</option>
                                })}
                            </select>
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}

                {field.type === 'boolean' && <label htmlFor={`${targetObject}.${field.field}`}>
                    {field.name}<br />
                    <Field
                        name={`${targetObject}.${field.field}`}
                        id={`inp_${field.field}`}
                        component="input"
                        type="checkbox"
                    />
                </label>}
                {field.type === 'int' && <Field name={`${targetObject}.${field.field}`} type="number" parse={(value, name) => {
                    return parseInt(value);
                }}
                    format={(value, name) => {
                        return parseInt(value);
                    }}>
                    {({ input, meta }) => (
                        <div>
                            <label>{field.name}</label>
                            <input id={`inp_${field.field}`} type="number" className="form-control" {...input} placeholder="...." />
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}
                {field.type === 'number' && <Field name={`${targetObject}.${field.field}`} type="number" parse={(value, name) => {
                    //  console.log("parse", value, name)
                    return parseInt(value);
                }}
                    format={(value, name) => {
                        //  console.log("format", value, name)

                        return parseInt(value);
                    }}>
                    {({ input, meta }) => (
                        <div>
                            <label>{field.name}</label>
                            <input id={`inp_${field.field}`} type="number" className="form-control" {...input} placeholder="...." />
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}

                {field.type === 'LOF' && <Field name={`${targetObject}.${field.field}`} type="text" >
                    {({ input, meta }) => (
                        <div>
                            <label>{field.name}</label>
                            <input id={`inp_${field.field}`} type="text" className="form-control" {...input} placeholder="...." />
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}
                {field.type === 'float' && <Field name={`${targetObject}.${field.field}`} type="number" parse={(value, name) => {
                    //  console.log("parse", value, name)
                    return parseFloat(value);
                }}
                    format={(value, name) => {
                        //  console.log("format", value, name)

                        return parseFloat(value);
                    }}>
                    {({ input, meta }) => (
                        <div>
                            <label>{field.name}</label>
                            <input id={`inp_${field.field}`} type="number" className="form-control" {...input} placeholder="...." />
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}
                {field.type === 'system' && <>
                    <Field name={`${targetObject}.${field.targetField}`}>
                        {({ input, meta }) => (
                            <div>
                                <label>{field.name}</label>
                                <select id={`inp_${field.field}`} className="form-control" {...input}>
                                    <option></option>
                                    {values.systems && values.systems.map((client: any, index: number) => {
                                        return <option key={index} value={client.id}>{client.refId}</option>
                                    })}
                                </select>
                                {validation && <span className="bg-warning">Required</span>}
                            </div>
                        )}
                    </Field>


                    <OnChange name={`${targetObject}.${field.targetField}`}>
                        {(value, previous) => {
                            // do something

                            const fIndex = values.systems.findIndex((ddd: any) => ddd.id === value);
                            if (fIndex !== -1) {
                                console.log(fIndex)

                                const system = values.systems[fIndex];
                                form.change(`${targetObject}.systemId`, system.id)
                                form.change(`${targetObject}.system_refId`, system.refId)
                                form.change(`${targetObject}.system`, system)
                            }

                        }}
                    </OnChange>



                </>}
                {field.type === 'type' && <Field name={`${targetObject}.${field.field}`}>
                    {({ input, meta }) => (
                        <div>
                            <label>{field.name}</label>
                            <select id={`inp_${field.field}`} className="form-control" {...input}>
                                <option></option>
                                {values.equipmentTypes && values.equipmentTypes.map((client: any, index: number) => {
                                    return <option key={index} value={client.value}>{client.text}</option>
                                })}
                            </select>
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}
                {field.type === 'matterial' && <Field name={`${targetObject}.${field.field}`}>
                    {({ input, meta }) => (
                        <div>
                            <label>{field.name} {JSON.stringify(field.required)}</label>
                            <select id={`inp_${field.field}`} className="form-control" {...input}>
                                <option></option>
                                {values.matterials && values.matterials.map((client: any, index: number) => {
                                    return <option key={index} value={client.value}>{client.text}</option>
                                })}
                            </select>
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}
                {field.type === 'fluid' && <Field name={`${targetObject}.${field.field}`}>
                    {({ input, meta }) => (
                        <div>
                            <label>{field.name}</label>
                            <select id={`inp_${field.field}`} className="form-control" {...input}>
                                <option></option>
                                {values.fluids && values.fluids.map((client: any, index: number) => {
                                    return <option key={index} value={client.value}>{client.text}</option>
                                })}
                            </select>
                            {validation && <span className="bg-warning">Required</span>}
                        </div>
                    )}
                </Field>}
                {/*  */}{field.type === 'loop' && <>
                    <Field name={`${targetObject}.${field.targetField}`}>
                        {({ input, meta }) => (
                            <div>
                                <label>{field.name}</label>
                                <select id={`inp_${field.field}`} className="form-control" {...input}>
                                    <option></option>
                                    {values.loops && values.loops.map((client: any, index: number) => {
                                        return <option key={index} value={client.id}>{client.refId}</option>
                                    })}
                                </select>
                                {validation && <span className="bg-warning">Required</span>}
                            </div>
                        )}
                    </Field>

                    <OnChange name={`${targetObject}.${field.targetField}`}>
                        {(value, previous) => {
                            // do something      console.log(fIndex)

                            const fIndex = values.loops.findIndex((ddd: any) => ddd.id === value);
                            if (fIndex !== -1) {


                                const loop = values.loops[fIndex];
                                console.log(fIndex, loop)
                                form.change(`${targetObject}.loopId`, loop.id)
                                form.change(`${targetObject}.loop_refId`, loop.refId)
                                form.change(`${targetObject}.loop`, loop)
                                form.change(`${targetObject}.systemId`, loop.systemId)
                                form.change(`${targetObject}.system`, loop.system)
                                form.change(`${targetObject}.system_refId`, loop.system_refId)
                                form.change(`${targetObject}.fluidId`, loop.fluidId)
                            }

                        }}
                    </OnChange>
                </>}
            </div>
        })}

    </>
}
