import { IFile } from "../interface/file";
import axiosInstance from "./instance";

export const RequestFileDownload =  (addNotification:any,data:IFile): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        let url = '/file-download/'+data.id;
        const method = 'GET';
        axiosInstance(addNotification).request({

          url,
          method,
          responseType: 'blob', //important
  
        }).then(response => {
          //  console.log(response);
            resolve(response)
        }).catch(exc => {
            //console.log(exc);
            reject(exc);
        });
    })
}



export const downloadFile = (addNotification:any, file: IFile) => {


  let url = '/file-download/'+file.id;
  const method = 'GET';
  axiosInstance(addNotification).request({

    url,
    method,

  }) 
  .then((response) => {
      console.log(file);
      const linkSource = `data:${file.fileMime};base64,${response.data}`;
      const downloadLink = document.createElement("a");
    
  
      downloadLink.href = linkSource;
      downloadLink.download = file.fileName;
      downloadLink.click();
  })
}