import { IStore } from "../interface/store";
import axiosInstance from "./instance";

export const RequestGetCollections =  (addNotification:any, data?: any): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/collections/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + ':' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewCollectionBackup = (addNotification:any,thing:any): Promise<any> => {
    let url = `/collections/`;
    return new Promise<any>((resolve, reject) => {

        axiosInstance(addNotification).post(url, thing).then(response => {
            //   console.log(response);
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}