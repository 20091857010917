import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Redirect, useLocation } from "react-router-dom";
import { RequestGetHelps } from "../../api/help-requests";
import { useAuth } from "../../context/auth";
import { IHelp } from "../../interface/help";

interface IHelpButton {

  //location: string;
  // alwaysDisplay?: boolean
}
const HelpButton = ({ }: IHelpButton) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectTo, setRedirectTo] = useState<string>();
  const { auth, logout, hasPermission } = useAuth()!;
  const [help, setHelp] = useState<IHelp | null>();
  const [query, setQuery] = useState<string>();
  const location = useLocation();
  useEffect(() => {
    console.log("HelpEffect", location.pathname)
    setHelp(undefined);
    RequestGetHelps(undefined, { location: location.pathname }).then(dbHelps => {
      console.log(dbHelps)
      if (dbHelps.length === 1) {
        setHelp(dbHelps[0]);
      }
      setQuery(location.pathname);
    });
  }, [location])

  const createMarkup = () => {
    let mum = '';
    if (help && help.content) {
      mum = help.content;
    }
    return {
      __html: mum
    };
  };

  return (<>
    {redirect === true && redirectTo && <Redirect to={redirectTo} />}
    <div className="btn-group">
      {help && <button type="button" onClick={(ev: React.MouseEvent) => {
        ev.preventDefault();
        setShow(true);
      }} className="btn btn-success"><FontAwesomeIcon icon={faQuestionCircle} /></button>}
      {auth.type === 'admin' && <button type="button" onClick={(ev: React.MouseEvent) => {
        ev.preventDefault();
        if (!help) {
          setRedirectTo(`/admin/helps/new?location=${encodeURI(location.pathname)}`)
          setRedirect(true);
        } else {
          setRedirectTo(`/admin/helps/${help.id}`)
          setRedirect(true);
        }
      }} className="btn btn-warning"><FontAwesomeIcon icon={faQuestionCircle} />+</button>}
    </div>

    <Modal size="xl" show={show} onHide={handleClose}>
      {help && <> <Modal.Header closeButton>
        <Modal.Title>{help.name}</Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <div className="text-left">
            <div dangerouslySetInnerHTML={createMarkup()} />

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer></>}

    </Modal>




  </>);


}

export default HelpButton;