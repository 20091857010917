import { faCheck, faCross } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import JSONPretty from 'react-json-prettify';
import { RequestGetSteels } from '../../api/steel-requests';
import { composeValidators, required } from '../../components/FormStuff/validators';
import DataTable from '../../components/system/DataTable';
import DebugButton from '../../components/system/DebugButton';
import Loading from '../../components/system/loading';
import { useNotifications } from '../../context/notifications';
import { usePageTitle } from '../../context/pageTitle';
import { ISteel } from '../../interface/steel';




interface ISteelsResponse {
    metal: ISteel;
    list: ISteel[];
    yielded: any;
    tensiled: any;
    elongated: any;
    message?: string;
}

const SteelsBase = () => {
    const { setPageTitle } = usePageTitle()!;

    const [d, setD] = useState<any>({});
    const [refine, setRefine] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const { addNotification } = useNotifications()!;
    const [loaded, setLoaded] = useState<boolean>(false);
    const [response, setResponse] = useState<ISteelsResponse>();
    const [inspect, setInspect] = useState<ISteel[]>();
    const [types, setTypes] = useState<ISteel[]>();
    const [typesLst, setTypesLst] = useState<string[]>();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        // 



        setPageTitle && setPageTitle('S355');
        if (loading === false) {
            setLoading(true);
            // console.log()
            RequestGetSteels(addNotification, {}).then(steels => {
                setTypes(steels);
                const typesLst: string[] = steels.map(item => item.type).filter((value, index, self) => self.indexOf(value) === index);
                setTypesLst(typesLst);
                setLoading(false)
            })
        }
    }, [])
    const inspectThese = (d: ISteel[]) => {
        /// find current thing
        console.log(d)
        setInspect(d)
        setShow(true)
    }
    const refineResults = (d: any) => {
        /// find current thing
        console.log(d)
        setRefine(d)

    }
    const calculate = (d: any) => {
        /// find current thing
        console.log(d)
        setD(d)
        if (types) {
            const filtered = types.filter(dsd => dsd.type === d.standard)
            if (filtered.length > 0) {


                const mIndex = filtered.findIndex(dsdx => dsdx.name === d.namedSteel)
                const metal = { ...filtered[mIndex] }






                const yielded = metal.yieldRT.filter(dd => d.thickness >= dd.ll && d.thickness <= dd.ul)
                metal.yieldVal = yielded[0].value;

                const tensiled = metal.tensileRT.filter(dd => d.thickness >= dd.ll && d.thickness <= dd.ul)
                metal.tensileVal = tensiled[0].valuell;

                const elongated = metal.elongation.filter(dd => d.thickness >= dd.ll && d.thickness <= dd.ul)
                metal.elongationVal = elongated[0].value;


                const impacted = metal.impactEnergy.filter(dd => d.thickness >= dd.ll && d.thickness <= dd.ul)
                metal.impactFilter = impacted;
                console.log(yielded, tensiled, elongated)
                if (yielded.length > 0 && tensiled.length > 0 && elongated.length > 0) {

                    const checkedTypes = types.map(t => {
                        t.yieldOk = false;
                        t.tensileOk = false;
                        t.elongationOk = false;
                        t.impactOk = false;
                        t.matchOk = false;

                        const yielded2 = t.yieldRT.filter(dd => d.thickness >= dd.ll && d.thickness <= dd.ul)
                        if (yielded2.length > 0) {
                            t.yieldVal = yielded2[0].value;
                            if (yielded2[0].value >= yielded[0].value) {
                                t.yieldOk = true;
                            }
                        }



                        const tensiled2 = t.tensileRT.filter(dd => d.thickness >= dd.ll && d.thickness <= dd.ul)
                        if (tensiled2.length > 0) {
                            t.tensileVal = tensiled2[0].valuell
                            if (tensiled2[0].valuell >= tensiled[0].valuell) {
                                t.tensileOk = true;
                            }
                        }

                        const elongated2 = t.elongation.filter(dd => d.thickness >= dd.ll && d.thickness <= dd.ul && dd.test === 'Longitudinal')
                        if (elongated2.length > 0) {
                            t.elongationVal = elongated2[0].value;
                            if (elongated2[0].value >= elongated[0].value) {
                                t.elongationOk = true;
                            }
                        }

                        const impacted2 = t.impactEnergy.filter(dd => d.thickness >= dd.ll && d.thickness <= dd.ul)
                        if (elongated2.length > 0) {
                            t.impactFilter = impacted2
                        }


                        if (t.tensileOk === true
                            && t.yieldOk === true
                            && t.elongationOk === true) {
                            t.matchOk = true;
                        }
                        return t;
                    })
                    setResponse({ metal, yielded, tensiled, list: checkedTypes, elongated })
                } else {
                    setResponse({ metal, yielded, tensiled, list: [], elongated, message: 'Cant match current metal to your settings' })
                }

            }
        }
    }



    return <div className="col-12">

        {loaded === false ? <div>
            <Form
                onSubmit={calculate}
                initialValues={{}}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-3">


                                <Field name="standard" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Standard</label>
                                            <select className="form-control" {...input}>
                                                <option></option>
                                                {typesLst && typesLst.map((t, tIndex) => {
                                                    return <option key={tIndex} value={t}>{t}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>

                                <OnChange name={`standard`}>
                                    {(value, previous) => {
                                        // do something


                                        if (types && value !== '') {
                                            const typesLst: string[] = types.filter(val => val.type === value).map(item => item.name)
                                                .filter((value, index, self) => self.indexOf(value) === index);
                                            // const index = types.findIndex(d => d.name === value);
                                            // if (index !== -1) {
                                            //     form.change('standardObj', types[index])
                                            // }
                                            form.change('names', typesLst)
                                        }
                                    }}
                                </OnChange>
                            </div>


                            {values.names && <div className="col-3"><Field name="namedSteel" validate={composeValidators(required)}>
                                {({ input, meta }) => (
                                    <div>
                                        <label>Name</label>
                                        <select className="form-control" {...input}>
                                            <option></option>
                                            {values.names && values.names.map((t: any, tIndex: number) => {
                                                return <option key={tIndex} value={t}>{t}</option>
                                            })}
                                        </select>
                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                    </div>
                                )}
                            </Field></div>}

                            <div className="col-3">

                                <Field name={`thickness`}
                                    validate={composeValidators(required)}
                                    parse={(value, name) => {
                                        //  console.log("parse", value, name)
                                        if (value) return parseFloat(value);
                                    }}
                                    format={(value, name) => {
                                        //  console.log("format", value, name)

                                        if (value) return parseFloat(value);
                                    }}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Nominal Thickness</label>
                                            <input type="number" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="col-3">


                                <button className="btn btn-primary" type="submit">Search S355</button>

                            </div>

                        </div>

                    </form>

                }} />
            {response && <>

                <div className="row">
                    <h3>{response.metal.type} - {response.metal.name} <DebugButton data={response.metal} /></h3>
                    <div className="col-3">
                        Yield<br />
                        {response.metal.yieldVal}
                    </div>
                    <div className="col-3">
                        Tensile<br />
                        {response.metal.tensileVal}
                    </div>
                    <div className="col-3">
                        Elongation<br />
                        {response.metal.elongationVal}
                    </div>

                    <div className="col-3">
                        Impact<br />
                        {response.metal.elongationVal}
                    </div>
                </div>




                {response.message ? <div className="text-left"><p className="bg-warning">{response.message}</p>     <JSONPretty json={response.metal} /></div> : <><DataTable

                    doWithSelected={[{
                        name: 'compare', title: 'Compare', func: (data: any[]) => {
                            console.log(data);
                            inspectThese(data)
                        }
                    }]}

                    rowStyleFn={(d) => {
                        return (d.matchOk === true ? { 'background-color': 'green' } : { 'background-color': 'red' })
                    }}
                    colHeadings={[
                        { name: "Type", field: "type", sortable: true, queryable: true },
                        { name: "Name", field: "name", sortable: true },
                        {
                            name: "Yield", field: "yieldOk", sortable: true, r: (value: any, index) => {
                                return <>{value.yieldOk ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCross} />} {value.yieldVal}</>
                            }
                        },
                        {
                            name: "Tensile", field: "tensileOk", sortable: true, r: (value: any, index) => {
                                return <>{value.tensileOk ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCross} />} {value.tensileVal}</>
                            }
                        }, {
                            name: "Elongation", field: "elongationOk", sortable: true, r: (value: any, index) => {
                                return <>{value.elongationOk ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCross} />} {value.elongationVal}</>
                            }
                        },
                        {
                            name: "Total Ok", field: "matchOk", sortable: true, r: (value: any, index) => {
                                return <>{value.matchOk ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCross} />}</>
                            }
                        },
                        {
                            name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                return <div className="btn-group">
                                    <DebugButton data={value} />

                                </div>;
                            }, sortable: false
                        },
                    ]}

                    exportFileName={`risks`}
                    rowExpanded={(value: any, index) => {
                        return <>

                            {value.impactFilter && value.impactFilter.length > 0 ? <div className="w-100 row">
                                <div className="col-3">
                                    Impact Energy Filtered
                                </div>

                                {value.impactFilter && value.impactFilter.map((dd: any, index: number) => {
                                    return <div className="col-3">
                                        Test: {dd.test}<br />
                                        Value: {dd.value}<br />
                                        Temp: {dd.temp}<br />
                                    </div>
                                })}

                            </div> : <div className="w-100 row">
                                <div className="col-3">
                                    Impact Energy Raw
                                </div>

                                {value.impactEnergy && value.impactEnergy.map((dd: any, index: number) => {
                                    return <div className="col-3">

                                        Test: {dd.test}<br />

                                        {dd.ll && dd.ul ? <>Thickness: {dd.ll} - {dd.ul} </> : <>Unspecified thickness values <span className="text-danger">*</span></>}

                                        <br />
                                        Charpy: {dd.value}<br />
                                        Temp: {dd.temp}<br />
                                    </div>
                                })}

                            </div>}</>
                    }}

                    data={response.list.filter((steel) => {
                        console.log(refine)
                        let ok: boolean = false;
                        if (refine === undefined) {
                            return steel;
                        } else {
                            if (refine.standard !== '') {
                                if (steel.type === refine.standard) {
                                    return steel
                                }
                            }
                        }

                    })}
                />

                    <p> <span className="text-danger">*</span>, assumed for all thickness but should be tested for specific thickness value</p>
                    <Form
                        onSubmit={refineResults}
                        initialValues={{}}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {

                            return <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-4">
                                        <Field name="standard">
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Standard</label>
                                                    <select className="form-control" {...input}>
                                                        <option></option>
                                                        {typesLst && typesLst.map((t, tIndex) => {
                                                            return <option key={tIndex} value={t}>{t}</option>
                                                        })}
                                                    </select>
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>

                                        <OnChange name={`standard`}>
                                            {(value, previous) => {
                                                // do something


                                                if (types && value !== '') {
                                                    const typesLst: string[] = types.filter(val => val.type === value).map(item => item.name)
                                                        .filter((value, index, self) => self.indexOf(value) === index);
                                                    // const index = types.findIndex(d => d.name === value);
                                                    // if (index !== -1) {
                                                    //     form.change('standardObj', types[index])
                                                    // }
                                                    form.change('names', typesLst)
                                                }
                                            }}
                                        </OnChange>


                                    </div>
                                    {/* {values.names && <div className="col-4"><Field name="namedSteel">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Name</label>
                                                <select className="form-control" {...input}>
                                                    <option></option>
                                                    {values.names && values.names.map((t: any, tIndex: number) => {
                                                        return <option key={tIndex} value={t}>{t}</option>
                                                    })}
                                                </select>
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    </div>} */}
                                    <div className="col-4">
                                        <label></label>
                                        <button className="btn btn-primary" type="submit">Refine</button>
                                    </div>

                                </div>

                            </form>

                        }} />
                </>}
            </>}
        </div> : <Loading />}


        {show && <><h2>Compare Steels</h2>
            <div className="text-left table">
                <table>
                    <tbody>
                        <tr>
                            <th>Standard</th>
                            {inspect && inspect.map((steel, indexx) => {
                                return <td key={indexx + "+_"}>{steel.type}</td>
                            })}
                        </tr>
                        <tr>
                            <th>Name</th>
                            {inspect && inspect.map((steel, indexx) => {
                                return <td key={indexx + "+_"}>{steel.name}</td>
                            })}

                        </tr>

                        <tr>
                            <th>Yield</th>
                            {inspect && inspect.map((steel, indexx) => {
                                return <td key={indexx + "+_"}>{steel.yieldVal}</td>
                            })}

                        </tr>
                        <tr>
                            <th>Tensile</th>
                            {inspect && inspect.map((steel, indexx) => {
                                return <td key={indexx + "+_"}>{steel.tensileVal}</td>
                            })}

                        </tr>
                        <tr>
                            <th>Elongation</th>
                            {inspect && inspect.map((steel, indexx) => {
                                return <td key={indexx + "+_"}>{steel.elongationVal}</td>
                            })}

                        </tr>
                        <tr>
                            <th>Charpy Match</th>
                            {inspect && inspect.map((steel, indexx) => {
                                return <td key={indexx + "+_"}>{steel.impactFilter && steel.impactFilter.map((imp, iindex) => {
                                    return <div key={iindex}>
                                        Thickness: {imp.ll} {imp.ul}<br />
                                        Temp: {imp.temp}<br />
                                        Charpy: {imp.value} <br />
                                        {/* {JSON.stringify(imp)} */}
                                    </div>
                                })}</td>
                            })}

                        </tr>

                    </tbody>
                </table>
            </div>
        </>}

    </div>

}


export default SteelsBase