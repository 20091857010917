import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Area } from "@qiuz/react-image-map";
import { useRef } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import { Crop } from "react-image-crop";
import { v4 as uuid } from 'uuid';
import { composeValidators, minValue, mustBeNumber, required } from "../../../components/FormStuff/validators";
import Croppy from "../../../components/system/croppy";
import CroppyDisplay from "../../../components/system/croppy-display";
import UploadImages from "../../../components/system/UploadImages/UploadImages";
import { IClient } from "../../../interface/client";
import { IImpurity } from "../../../interface/impurity";
import { IMechanism } from "../../../interface/mechanism";

interface IPageOne {
    values: any,
    form: any,
    clients: IClient[] | undefined,
    impuritiesA: IImpurity[] | undefined, mechanismsA: IMechanism[] | undefined, mechanismsCA: string[] | undefined, errors: any,
    setImp: (imp: IImpurity[]) => void,
    setDm: (imp: IMechanism[]) => void
}




const PageOne = ({ values, form, clients, impuritiesA, mechanismsA, mechanismsCA, errors, setImp, setDm }: IPageOne) => {
    const assetsTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.assets ? values.client.draOverrides.assets : 'Assets');
    const impuritiesTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.impurities ? values.client.draOverrides.impurities : 'Impurities');
    const damageMechanismsTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.damageMechanisms ? values.client.draOverrides.damageMechanisms : 'Damage Mechanisms');
    const operatingModesTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.operatingModes ? values.client.draOverrides.operatingModes : 'Operating Modes');
    const impuritiesEnabled: boolean = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.impuritiesEnabled ? values.client.draOverrides.impuritiesEnabled : false);
    const additionalTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.operatingModes ? values.client.draOverrides.additional : 'Additional');
    const additionalEnabled: boolean = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.additionalEnabled ? values.client.draOverrides.additionalEnabled : false);



    const imgRef = useRef<HTMLImageElement>(null)
    return <div className="row">
        <div className="col-12">
            <h3>Assignment Info ({values.state})</h3>
            <div className="btn-group">
                <button type="button" className="btn btn-warning" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    form.change('state', 'complete');
                    alert('make sure you save assignment to make this change apply');
                }}>Set Complete</button>
                <button type="button" className="btn btn-warning" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    form.change('state', 'draft');
                    alert('make sure you save assignment to make this change apply');
                }}>Set Draft</button>
            </div>
            <Field name="name" validate={composeValidators(required)}>
                {({ input, meta }) => (
                    <div>
                        <label>Name</label>
                        <input data-testid="assignment-name" type="text" className="form-control" {...input} placeholder="...." />
                        {meta && meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                    </div>
                )}
            </Field>
            <Field
                name="revision"
                validate={composeValidators(required, mustBeNumber, minValue(1))}
                parse={(value, name) => {
                    //  console.log("parse", value, name)
                    if (value)
                        return parseInt(value);
                }}
                format={(value, name) => {
                    //  console.log("format", value, name)
                    if (value)
                        return parseInt(value);
                }}>
                {({ input, meta }) => (
                    <div>
                        <label>Revision Number</label>
                        <input data-testid="assignment-revision" type="number" className="form-control" {...input} placeholder="...." />
                        {meta && meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                    </div>
                )}
            </Field>
            <Field name="clientId" validate={composeValidators(required)}>
                {({ input, meta }) => (
                    <div>
                        <label>Client</label>
                        <select id="assignment-clientId" data-testid="assignment-clientId" className="form-control" {...input}>
                            <option></option>
                            {clients && clients.map((client, index) => {
                                return <option key={`AAA${index}`} value={client.id}>{client.name}</option>;
                            })}
                        </select>
                        {meta && meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                    </div>
                )}
            </Field>
            <OnChange name={`clientId`}>
                {(value, previous) => {
                    // do somevalues
                    if (clients) {
                        const ind = clients.findIndex(dd => dd.id === value);
                        if (ind !== -1) {
                            const newClient = clients[ind];
                            form.change('client', newClient);

                            if (newClient.impurities) {
                                setImp(newClient.impurities)
                            }
                            if (newClient.damageMechanisms) {
                                setDm(newClient.damageMechanisms)
                            }
                        }
                    }

                }}
            </OnChange>
            <Field name="description">
                {({ input, meta }) => (
                    <div>
                        <label>Description</label>
                        <input data-testid="assignment-description" type="text" className="form-control" {...input} placeholder="...." />
                        {meta && meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                    </div>
                )}
            </Field>



        </div>

        {values.clientId && <>
            {impuritiesEnabled && <div className="col-6">
                <h3>{impuritiesTitle}</h3>
                {errors.impurities && <span className="bg-warning">{errors.impurities}</span>}
                <FieldArray name="impurities">
                    {({ fields }) => (<>
                        <div className="row">
                            {fields.map((name, index) => {

                                const thing = values.impurities[index];
                                const usedCount = values.forms.filter((form: any) =>
                                    form.Impurities.includes(thing)
                                );





                                return (<div key={name} className="col-6">

                                    ({(index + 1)})
                                    {values.impurities[index]}

                                    <button disabled={usedCount.length > 0} data-testid={`impurity-remove-${thing}`} className="btn btn-outline-danger" type="button" onClick={() => fields.remove(index)}>
                                        Remove used {usedCount.length}</button>
                                </div>
                                )
                            })}
                        </div>
                        <div className="col-12">
                            {impuritiesA && impuritiesA.map((imp, impIndex) => {

                                if (values.impurities.findIndex((ddd: string) => ddd === imp.id) === -1) {
                                    return <button
                                        key={impIndex}
                                        data-testid={`impurity-add-${imp.id}`}
                                        className="btn btn-primary"
                                        type="button"

                                        onClick={() => {
                                            console.log(imp.id);
                                            fields.push(imp.id);
                                        }}
                                    >Add "{imp.name}"</button>;
                                }

                            })}
                        </div>

                    </>)}
                </FieldArray>
            </div>}
            <div className="col-6">
                <h3>{damageMechanismsTitle}</h3>
                {errors.damageMechanisms && <span className="bg-warning">{errors.damageMechanisms}</span>}
                <FieldArray name="damageMechanisms">
                    {({ fields }) => {
                        const inCategory = (cat: string) => {
                            let bool: boolean = false;
                            if (mechanismsA) {
                                const ids = mechanismsA.filter(c => c.category === cat).filter(asoo => {
                                    if (values.damageMechanisms.findIndex((ddd: string) => ddd === asoo.id) !== -1) {
                                        return asoo;
                                    }
                                });
                                console.log(ids);
                                return (ids.length !== 0 ? true : false);
                            } else {
                                return false;
                            }

                        };

                        const notInCategory = (cat: string) => {
                            let bool: boolean = false;
                            if (mechanismsA) {
                                const ids = mechanismsA.filter(c => c.category === cat).filter(asoo => {
                                    if (values.damageMechanisms.findIndex((ddd: string) => ddd === asoo.id) === -1) {
                                        return asoo;
                                    }
                                });
                                console.log(ids);
                                return (ids.length !== 0 ? true : false);
                            } else {
                                return false;
                            }
                        };

                        return <>

                            <div className="row">
                                <div className="col-12">
                                    {mechanismsCA && mechanismsCA.map((dd, ddIndex) => {

                                        if (mechanismsA) {

                                            mechanismsA.filter(cat => cat.category === dd);

                                            if (inCategory(dd)) {
                                                return <div key={ddIndex}>
                                                    <h3>{dd}</h3>
                                                    <button
                                                        className="btn btn-danger"
                                                        type="button"

                                                        onClick={() => {
                                                            if (mechanismsA) {
                                                                const ids = mechanismsA.filter(c => c.category === dd).filter(asoo => {
                                                                    if (values.damageMechanisms.findIndex((ddd: string) => ddd === asoo.id) !== -1) {
                                                                        return asoo;
                                                                    }
                                                                });

                                                                const dm = [...values.damageMechanisms];

                                                                for (let index = 0; index < ids.length; index++) {
                                                                    const element = ids[index];


                                                                    const indexsdd = dm.findIndex(ed => ed === element.id);
                                                                    dm.splice(indexsdd, 1);
                                                                }
                                                                form.change("damageMechanisms", dm);
                                                                console.log(dm);
                                                            }

                                                        }}> <FontAwesomeIcon icon={faTrash} />All</button>
                                                    <hr />
                                                    {fields.map((name, index) => {

                                                        const mech = values.damageMechanisms[index];
                                                        const merhInt = mechanismsA.findIndex(ddddddd => ddddddd.id === mech);
                                                        const object = mechanismsA[merhInt];


                                                        const usedCount = values.forms.filter((form: any) =>
                                                            form.DamageMechanism.includes(mech)
                                                        );
                                                        if (object !== undefined && object.category === dd) {
                                                            return <div key={name} className="col-6">
                                                                <button
                                                                    data-testid={`mechanism-remove-${object.id}`}
                                                                    disabled={usedCount.length > 0} className="btn btn-outline-danger" type="button" onClick={() => fields.remove(index)}>
                                                                    <FontAwesomeIcon icon={faTrash} />used: {usedCount.length}</button>
                                                                ({(index + 1)})
                                                                {object.name}


                                                            </div>;
                                                        }



                                                    })}
                                                </div>;
                                            }

                                        }
                                    })}
                                </div>

                            </div>
                            <div className="col-12">

                                {mechanismsCA && mechanismsCA.map((dd, ddIndex) => {
                                    if (notInCategory(dd)) {
                                        return <div key={ddIndex}>
                                            <h3> {dd}</h3>
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={() => {
                                                    if (mechanismsA) {
                                                        const ids = mechanismsA.filter(c => c.category === dd).filter(asoo => {
                                                            if (values.damageMechanisms.findIndex((ddd: string) => ddd === asoo.id) === -1) {
                                                                return asoo;
                                                            }
                                                        });
                                                        console.log(ids);

                                                        ids.map(ddddddd => {
                                                            fields.push(ddddddd.id);
                                                        });
                                                    }

                                                }}>All</button>
                                            <hr />
                                            {mechanismsA && mechanismsA.filter(cat => cat.category === dd).map((mech, mechIndex) => {

                                                if (values.damageMechanisms.findIndex((ddd: string) => ddd === mech.id) === -1) {
                                                    return <button
                                                        key={mechIndex}
                                                        data-testid={`mechanism-add-${mech.id}`}
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={() => {
                                                            console.log(mech.id);
                                                            fields.push(mech.id);
                                                        }}
                                                    >{mech.name}</button>;
                                                }

                                            })}

                                        </div>;
                                    }
                                })}





                            </div>
                        </>;
                    }}
                </FieldArray>
            </div>

            <div className="col-12">
                <h3>{assetsTitle}</h3>
                {/* {values.selectedAsset} */}
                {errors.assetsDetailed && <span className="bg-warning">{errors.assetsDetailed}</span>}
                <FieldArray name="assetsDetailed">
                    {({ fields }) => {

                        const mapArea: Area[] = [];
                        const crops: Crop[] = [];
                        for (let index = 0; index < values.assetsDetailed.length; index++) {
                            const element = values.assetsDetailed[index];


                            if (element.data) {
                                mapArea.push({
                                    left: `${element.data.x}%`,
                                    top: `${element.data.y}%`,
                                    height: `${element.data.height}%`,
                                    width: `${element.data.width}%`,
                                    style: { background: 'rgba(255, 0, 0, 0.5)' },
                                    onMouseOver: () => console.log('map onMouseOver')

                                });
                            }



                        }


                        const onMapClick = (area: any, index: number) => {
                            const tip = `click map${index + 1}`;
                            console.log(tip, area);
                            alert(tip);
                        }

                        const onCropped = (crop: any): void => {
                            console.log("onCropped", crop)
                            // const asset = { ...values.selectedAsset }
                            // asset.data = crop

                            console.log("assetIndex", values.selectedAsset)

                            // update the asset

                            const assets = [...values.assetsDetailed];
                            const assetIndex = assets.findIndex((d: any) => d.id === values.selectedAsset.id)
                            // console.log(assetIndex)
                            if (assetIndex !== -1) {
                                assets[assetIndex].data = crop;
                            }
                            form.change('selectedAsset', undefined)
                            form.change('assetsDetailed', assets)
                        }


                        return <div>


                            <h2>Installation Schematic</h2>

                            {values.image && <div className="row">

                                <div className="col-6">
                                    {values.selectedAsset !== undefined ? <Croppy image={values.image} asset={values.selectedAsset} onCropped={(c) => onCropped(c)} /> : 'Crop button'}
                                </div>

                                <div className="col-6">
                                    <CroppyDisplay image={values.image} editMode={true} assetsDetailed={values.assetsDetailed} onClick={(asset) => {
                                        console.log("click", asset)
                                    }} />
                                </div>
                            </div>}
                            <Field name="image" aspect="none" camera={false} component={UploadImages} text="Set Main Diagram Image" attachTo="assignment" attachId={values.id} onChanged={(data: any) => {
                                console.log("changed", data);
                                form.change('imageId', data.id);
                                form.change('image', data);
                            }} />

                            {values.assets.length > 0 && <h4>Migration Tools</h4>}
                            {values.assets.map((assetStr: any, index: number) => {
                                const usedCount = (assetStr === undefined ? values.forms.filter((form: any) => form.Additional.includes(assetStr)).length : 0);

                                return <p key={index}>
                                    {assetStr} <button type="button" className="btn btn-outline-warning" onClick={() => {
                                        const index = values.assetsDetailed.find((d: any) => d.name === assetStr)
                                        // console.log(index)
                                        if (index === undefined) {
                                            fields.push({ id: assetStr, name: assetStr })
                                        }

                                    }}>Migrate</button>
                                    <button disabled={usedCount > 0} type="button" className="btn btn-outline-warning" onClick={() => {
                                        const ffs = [...values.assets]
                                        ffs.splice(index, 1)
                                        form.change('assets', ffs);
                                    }}>Delete used:{usedCount}</button>
                                </p>
                            })}


                            {fields.map((name, index) => {

                                const thing = values.assetsDetailed[index];
                                const usedCount = values.forms.filter((form: any) =>
                                    form.AssetSection.includes(thing.id)
                                );

                                return <><div className="input-group mb-3" key={name}>
                                    <span className="input-group-text" id="basic-addon1">({(index + 1)})</span>

                                    <Field name={`${name}.name`} component="input">

                                        {({ input, meta }) => (
                                            <>

                                                <input data-testid={`asset-name-${index + 1}`} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta && meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </>
                                        )}
                                    </Field>
                                    {thing && thing.data && <button disabled={values.selectedAsset && values.selectedAsset.id === thing.id} className="btn btn-outline-warning" onClick={() => {
                                        const assets = [...values.assetsDetailed];
                                        const assetIndex = assets.findIndex((d: any) => d.id === thing.id)
                                        // console.log(assetIndex)
                                        if (assetIndex !== -1) {
                                            assets[assetIndex].data = undefined;
                                        }

                                        form.change('assetsDetailed', assets)
                                    }} type="button">reset-crop</button>}
                                    <button disabled={values.selectedAsset && values.selectedAsset.id === thing.id} className="btn btn-outline-success" onClick={() => form.change('selectedAsset', thing)} type="button">crop</button>
                                    <button data-testid={`asset-remove-${index + 1}`} disabled={usedCount.length > 0} className="btn btn-outline-danger" onClick={() => fields.remove(index)} type="button">Remove  used:{usedCount.length}</button>

                                </div></>

                            })}
                            <button
                                data-testid={`asset-add`}
                                className="btn btn-success"
                                type="button"
                                onClick={() => fields.push({ id: uuid(), name: '' })}
                            >Add {assetsTitle}</button>
                        </div>
                    }}
                </FieldArray>
            </div>
            <div className="col-6">
                <h3>{operatingModesTitle}</h3>

                {errors.modesDetailed && <span className="bg-warning">{errors.modesDetailed}</span>}

                <FieldArray name="modesDetailed">
                    {({ fields }) => (
                        <div>
                            {values.modes.length > 0 && <h4>Migration Tools</h4>}
                            {values.modes.map((modeStr: any, index: number) => {

                                const usedCount = (modeStr === undefined ? values.forms.filter((form: any) => form.Additional.includes(modeStr)).length : 0);
                                return <p key={index}>{modeStr}<button type="button" className="btn btn-outline-warning" onClick={() => {
                                    const index = values.modesDetailed.find((d: any) => d.name === modeStr)
                                    console.log(index)
                                    if (index === undefined) {
                                        fields.push({ id: modeStr, name: modeStr })
                                    }

                                }}>Migrate</button>


                                    <button type="button" className="btn btn-outline-warning" onClick={() => {

                                        const ffs = [...values.modes]
                                        ffs.splice(index, 1)
                                        form.change('modes', ffs)

                                    }}>Delete  used:{usedCount}</button>

                                </p>
                            })}

                            {fields.map((name, index) => {
                                const thing = values.modesDetailed[index];
                                const usedCount = values.forms.filter((form: any) =>
                                    form.OperatingMode.includes(thing.id)
                                );

                                return (<div className="input-group mb-3" key={name}>
                                    <span className="input-group-text" id="basic-addon1">({(index + 1)})</span>
                                    <Field name={`${name}.name`} component="input">

                                        {({ input, meta }) => (
                                            <>

                                                <input data-testid={`mode-name-${index + 1}`} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta && meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </>
                                        )}
                                    </Field>
                                    <button disabled={usedCount.length > 0} data-testid={`mode-remove-${index + 1}`} className="btn btn-outline-danger" onClick={() => fields.remove(index)} type="button">Remove used:{usedCount.length}</button>

                                </div>

                                )
                            })}
                            <button
                                data-testid={`mode-add`}
                                className="btn btn-success"
                                type="button"
                                onClick={() => fields.push({ id: uuid(), name: '' })}
                            >Add {operatingModesTitle}</button>
                        </div>
                    )}
                </FieldArray>
            </div>


            {additionalEnabled && <div className="col-6">
                <h3>{additionalTitle}</h3>

                {errors.additional && <span className="bg-warning">{errors.additional}</span>}

                <FieldArray name="additional">
                    {({ fields }) => (
                        <div>


                            {fields.map((name, index) => {
                                const thing = values.additional[index];

                                const usedCount = values.forms.filter((form: any) =>
                                    form.Additional.includes(thing.id)
                                );


                                return (<div className="input-group mb-3" key={name}>
                                    <span className="input-group-text" id="basic-addon1">({(index + 1)})</span>
                                    <Field name={`${name}.name`} component="input">
                                        {({ input, meta }) => (
                                            <>
                                                <input type="text" data-testid={`additional-name-${index + 1}`} className="form-control" {...input} placeholder="...." />
                                                {meta && meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </>
                                        )}
                                    </Field>
                                    <button disabled={usedCount.length > 0} data-testid={`additional-remove-${index + 1}`} className="btn btn-outline-danger" onClick={() => fields.remove(index)} type="button">Remove used:{usedCount.length}</button>
                                </div>

                                )
                            })}
                            <button
                                className="btn btn-success"
                                type="button"
                                data-testid={`additional-add`}
                                onClick={() => fields.push({ id: uuid(), name: '' })}
                            >Add {additionalTitle}</button>
                        </div>
                    )}
                </FieldArray>
            </div>}
        </>
        }


    </div >;
}
export default PageOne;