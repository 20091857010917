import { IInstallationVersion } from "../interface/installation-version";
import axiosInstance from "./instance";

export const RequestGetInstallationVersions = (addNotification: any, data?: any): Promise<IInstallationVersion[]> => {
    return new Promise<IInstallationVersion[]>((resolve, reject) => {
        let url = `/installationVersions/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetInstallationVersion = (addNotification: any, id: string): Promise<IInstallationVersion> => {
    return new Promise<IInstallationVersion>((resolve, reject) => {

        axiosInstance(addNotification).get(`/installationVersions/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewInstallationVersion = (addNotification: any, thing: IInstallationVersion): Promise<IInstallationVersion> => {
    return new Promise<IInstallationVersion>((resolve, reject) => {

        axiosInstance(addNotification).post(`/installationVersions/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteInstallationVersion = (addNotification: any, thing: IInstallationVersion): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/installationVersions/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateInstallationVersion = (addNotification: any, user: IInstallationVersion): Promise<IInstallationVersion> => {
    return new Promise<IInstallationVersion>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/installationVersions/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestInstallationVersionFunction = (addNotification: any, func:string, id: string): Promise<any> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/installationVersions/_func/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
