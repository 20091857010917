import { IImage } from "../interface/image";
import axiosInstance from "./instance";

export const RequestGetImages =  (addNotification:any, data?: any): Promise<IImage[]> => {
    return new Promise<IImage[]>((resolve, reject) => {
        let url = `/images/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetImage =  (addNotification:any,id: string): Promise<IImage> => {
    return new Promise<IImage>((resolve, reject) => {

        axiosInstance(addNotification).get(`/images/${id}`).then(response => {
            //   console.log(response);
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewImage =  (addNotification:any,thing: IImage): Promise<IImage> => {
    return new Promise<IImage>((resolve, reject) => {

        axiosInstance(addNotification).post(`/images/`, thing).then(response => {
            //   console.log(response);
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteImage =  (addNotification:any, user: IImage): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/images/${user.id}`).then(response => {
            //   console.log(response);
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateImage =  (addNotification:any, user: IImage): Promise<IImage> => {
    return new Promise<IImage>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/images/${user.id}`, user).then(response => {
            //   console.log(response);
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}


// eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }

    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }
