import { Area, ImageMap } from "@qiuz/react-image-map";
import { IImage } from "../../interface/image";


interface Cropy {
    image: IImage;
    assetsDetailed: any[];
    editMode: boolean;
    onClick: (thing: any) => void;
}

const CroppyDisplay = (params: Cropy) => {
    const mapArea: Area[] = [];

    for (let index = 0; index < params.assetsDetailed.length; index++) {
        const element = params.assetsDetailed[index];

        let style: any = {};
        if (params.editMode) {
            style = { background: 'rgba(255, 0, 0, 0.5)' }
        }

        if (element.data) {
            mapArea.push({
                left: `${element.data.x}%`,
                top: `${element.data.y}%`,
                height: `${element.data.height}%`,
                width: `${element.data.width}%`,
                style: style,
                onMouseOver: () => {
                    //     console.log('map onMouseOver')

                }

            });
        }



    }


    const onMapClick = (area: any, index: number) => {
        const tip = `click map${index + 1}`;
        console.log(tip, area);

        params.onClick(params.assetsDetailed[index])

        // alert(tip);
    }

    const src = params.image.croppedSrcData ? params.image.croppedSrcData : params.image.c1600

    return <div>{src && <ImageMap
        className="usage-map"
        src={src}
        map={mapArea}
        onMapClick={onMapClick}
    />}</div>
}


export default CroppyDisplay;