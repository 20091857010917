import { ICalculator } from "../interface/calculator";
import axiosInstance from "./instance";

export const RequestGetCalculators = (addNotification: any, data?: any): Promise<ICalculator[]> => {
    return new Promise<ICalculator[]>((resolve, reject) => {
        let url = `/calculators/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetCalculator = (addNotification: any, id: string): Promise<ICalculator> => {
    return new Promise<ICalculator>((resolve, reject) => {

        axiosInstance(addNotification).get(`/calculators/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewCalculator = (addNotification: any, thing: ICalculator): Promise<ICalculator> => {
    return new Promise<ICalculator>((resolve, reject) => {

        axiosInstance(addNotification).post(`/calculators/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteCalculator = (addNotification: any, thing: ICalculator): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/calculators/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateCalculator = (addNotification: any, user: ICalculator): Promise<ICalculator> => {
    return new Promise<ICalculator>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/calculators/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestCalculatorFunction = (addNotification: any, func:string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/calculators/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }
export const RequestCalcEvaluate = (addNotification: any, data:any): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/calc/evaluate`;
        
        axiosInstance(addNotification).post(url, data).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}
// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
