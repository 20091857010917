

export
    const equipmentFields = [
        { field: 'id', name: 'id', type: 'id' },
        { field: 'name', name: 'name', type: 'text', required: true },
        { field: 'description', name: 'description', type: 'text' },
        { field: 'refId', name: 'refId', type: 'text', required: true },
        { field: 'system_refId', name: 'system_refId', required: true, type: 'system', targetField: 'systemId' },
        { field: 'likelihoodOverrideAZ', name: 'Risk Likelihood (Override)', type: 'AZ_LIKE', targetField: 'likelihoodOverride' },
        { field: 'combinedConsequenceAZ', name: 'combinedConsequenceAZ', required: true, type: 'AZ_CC', targetField: 'combinedConsequence' },
        { field: 'inspectionTypeIdsStr', name: 'inspectionTypeIdsStr', type: 'inspection-types', targetField: 'inspectionTypeIds' },
        { field: 'materialId', name: 'materialId', type: 'matterial', required: true },
        { field: 'type', name: 'type', type: 'type', required: true },
        { field: 'serviceFluidId', name: 'serviceFluidId', type: 'fluid', required: true },
        { field: 'frmCommissionedDate', name: 'Commission Date', type: 'date', required: true, targetField: 'commissionedDate' },

        { field: 'operatingTemperatureMin', name: 'operatingTemperatureMin', type: 'number' },
        { field: 'operatingTemperatureMax', name: 'operatingTemperatureMax', type: 'number' },

        { field: 'operatingPressureMin', name: 'operatingPressureMin', type: 'number' },
        { field: 'operatingPressureMax', name: 'operatingPressureMax', type: 'number' },

        { field: 'designPressureMin', name: 'designPressureMin', type: 'number' },
        { field: 'designPressureMax', name: 'designPressureMax', type: 'number' },

        { field: 'designTemperatureMin', name: 'designTemperatureMin', type: 'number' },
        { field: 'designTemperatureMax', name: 'designTemperatureMax', type: 'number' },

        { field: 'flammableConsequenceAZ', name: 'flammableConsequenceAZ', type: 'AZ', targetField: 'flammableConsequence' },
        { field: 'toxicConsequenceAZ', name: 'toxicConsequenceAZ', type: 'AZ', targetField: 'toxicConsequence' },
        { field: 'materialSpecification', name: 'materialSpecification', type: 'text' },
        { field: 'materialGrade', name: 'materialGrade', type: 'text' },
        { field: 'diameter', name: 'diameter', type: 'number' },
        { field: 'originalWallThickness', name: 'originalWallThickness', type: 'text' },


        { field: 'criticalityRating', name: 'criticalityRating', type: 'text' },
        { field: 'corrosionType', name: 'corrosionType', type: 'text' },
        { field: 'intCorrosionRate', name: 'intCorrosionRate', type: 'number' },
        { field: 'intCorrosionProbability', name: 'intCorrosionProbability', type: 'number' },
        { field: 'extCorrosionRate', name: 'extCorrosionRate', type: 'number' },
        { field: 'externalCorrosionProbability', name: 'externalCorrosionProbability', type: 'number' },
        { field: 'otherDamageMechanisms', name: 'otherDamageMechanisms', type: 'text' },
        { field: 'otherDamageMechanismProbability', name: 'otherDamageMechanismProbability', type: 'number' },
        { field: 'combinedProbability', name: 'combinedProbability', type: 'number' },

        { field: 'testingBarg', name: 'testingBarg', type: 'number' },
        { field: 'testingMedium', name: 'testingMedium', type: 'text' },


        { field: 'intCorrosionInspectionPriority', name: 'intCorrosionInspectionPriority', type: 'number' },
        { field: 'extCorrosionInspectionPriority', name: 'extCorrosionInspectionPriority', type: 'number' },
        { field: 'otherDamageMechanismInspectionPriority', name: 'otherDamageMechanismInspectionPriority', type: 'number' },
        { field: 'environmentalCrackingInspectionPriority', name: 'environmentalCrackingInspectionPriority', type: 'number' },
        { field: 'combinedInspectionPriority', name: 'combinedInspectionPriority', type: 'number' },

        { field: 'itaIntCo2LOF', name: 'CO2 LOF', type: 'LOF', targetField: 'itaIntCo2LOF', required: true },
        { field: 'itaIntCo2Comments', name: 'Co2Comments', type: 'text' },
        { field: 'itaIntPWCLOF', name: 'PWC LOF', type: 'LOF', targetField: 'itaIntPWCLOF', required: true },
        { field: 'itaIntPWCComments', name: 'PWC Comments', type: 'text' },
        { field: 'itaIntErosionLOF', name: 'Erosion LOF', type: 'LOF', targetField: 'itaIntErosionLOF', required: true },
        { field: 'itaIntErosionComments', name: 'Erosion Comments', type: 'text' },
        { field: 'itaIntCreviceCorrosionLOF', name: 'Crevice Corrosion LOF', type: 'LOF', targetField: 'itaIntCreviceCorrosionLOF', required: true },
        { field: 'itaIntCreviceCorrosionComments', name: 'Crevice Corrosion Comments', type: 'text' },
        { field: 'itaIntUnderDepositCorrosionLOF', name: 'Under Deposit Corrosion LOF', type: 'LOF', targetField: 'itaIntUnderDepositCorrosionLOF', required: true },
        { field: 'itaIntUnderDepositCorrosionComments', name: 'Under Deposit Corrosion Comments', type: 'text' },
        { field: 'itaIntMICLOF', name: 'MIC LOF', type: 'LOF', targetField: 'itaIntMICLOF', required: true },
        { field: 'itaIntMICComments', name: 'MIC Comments', type: 'text' },
        { field: 'itaExtAtmosphericCorrosionLOF', name: 'Atmospheric Corrosion LOF', type: 'LOF', targetField: 'itaExtAtmosphericCorrosionLOF', required: true },
        { field: 'itaExtAtmosphericCorrosionComments', name: 'Atmospheric Corrosion Comments', type: 'text' },
        { field: 'itaExtCUILOF', name: 'External CUI LOF', type: 'LOF', targetField: 'itaExtCUILOF', required: true },
        { field: 'itaExtCUIComments', name: 'External CUI Comments', type: 'text' },
        { field: 'itaExtCorrosionUnderSupportLOF', name: 'External Corrosion Under Support LOF', type: 'LOF', targetField: 'itaExtCorrosionUnderSupportLOF', required: true },
        { field: 'itaExtCorrosionUnderSupportComments', name: 'External CorrosionUnder Support Comments', type: 'text' },
    ]
