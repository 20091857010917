import { faAngleDoubleDown, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMutators from 'final-form-arrays';
import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Form } from "react-final-form";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { RequestGetPublicAssignment } from "../../api/assignment-requests";
import { downloadFile } from "../../api/file-download-request";
import ListViewX from "../../components/ListView";
import CroppyDisplay from "../../components/system/croppy-display";
import DataTable from "../../components/system/DataTable";
import MultiSelectCreate from "../../components/system/MultiSelect";
import { useAuth } from "../../context/auth";
import { useNotifications } from "../../context/notifications";
import { usePageTitle } from "../../context/pageTitle";
import { IAssignment, IAssignmentForm, IAssignmentSubThing } from "../../interface/assignment";
import RiskTable from "./RiskTable";


export const findThing = (id: string, array: IAssignmentSubThing[]) => {

    const aaaa = array.find(dd => dd.id === id)
    //  console.log(id, array, aaaa)
    if (aaaa) {
        return aaaa.name
    } else {
        return 'error id=' + id;
    }

}


const Report = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [resultsCount, setResultsCount] = useState<number>(0);
    const [assignment, setAssignment] = useState<IAssignment>();
    const [query, setQueryObj] = useState<any>({ OperatingMode: [], AssetSection: [], Impurities: [], Additional: [] });
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const topRef = useRef<any>()
    const tableRef = useRef<any>()

    const { auth } = useAuth()!;


    const { setPageTitle } = usePageTitle()!;
    const assetsTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.assets ? assignment.client.draOverrides.assets : 'Assets');
    const impuritiesTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.impurities ? assignment.client.draOverrides.impurities : 'Impurities');
    const damageMechanismsTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.damageMechanisms ? assignment.client.draOverrides.damageMechanisms : 'Damage Mechanisms');
    const operatingModesTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.operatingModes ? assignment.client.draOverrides.operatingModes : 'Operating Modes');
    const impuritiesEnabled: boolean = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.impuritiesEnabled ? assignment.client.draOverrides.impuritiesEnabled : false);
    const additionalTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.operatingModes ? assignment.client.draOverrides.additional : 'Additional');
    const additionalEnabled: boolean = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.additionalEnabled ? assignment.client.draOverrides.additionalEnabled : false);


    const [startedLoading, setStartedLoading] = useState<boolean>(false);

    useEffect(() => {
        console.log("get reports");
        const params: any = match.params;
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true && startedLoading === false) {
            setStartedLoading(true)


            RequestGetPublicAssignment(addNotification, params.id)
                .then((json: IAssignment) => {
                    console.log(json);
                    setAssignment(json);
                    setLoading(false);
                    setPageTitle && setPageTitle('DRA ' + json.name);
                })
        }
    }, [])

    const setQuery = (toAdd: any, append?: boolean) => {
        console.log("toAdd", toAdd, "append", append)

        setQueryObj({ ...query, ...toAdd })
    }

    const lookupValueDesc = (thing: string, value: any) => {
        let str: string = value + '';

        console.log(thing, value)
        switch (thing) {
            case 'AssetSection':
                if (assignment && assignment.assetsDetailed) {
                    str = findThing(value, assignment.assetsDetailed)
                }

                break;
            case 'OperatingMode':
                if (assignment && assignment.modesDetailed) {
                    str = findThing(value, assignment.modesDetailed)
                }

                break;
            case 'Additional':
                if (assignment && assignment.additional) {
                    str = findThing(value, assignment.additional)
                }

                break;
            case 'Severity':
                if (assignment && assignment.client) {
                    const index = assignment.client.matrix.severity.findIndex(dd => dd.severityNumber === value)
                    str = assignment.client.matrix.severity[index].severity
                }
                break;
            case 'Likelihood':
                if (assignment && assignment.client) {
                    const index = assignment.client.matrix.risk.findIndex(dd => dd.riskNo === value)
                    str = assignment.client.matrix.risk[index].risk
                }
                break;
        }

        return str;
    }
    const queryDesc = () => {
        let ss: string = '';
        const keys = Object.keys(query)
        console.log("queryDesc", query)
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];

            if (Array.isArray(query[element])) {
                if (query[element].length > 0) {
                    switch (element) {
                        case "AssetSection":
                            ss += assetsTitle + ' ';
                            break;
                        case "Impurities":
                            ss += impuritiesTitle + ' ';
                            break;
                        case "OperatingMode":
                            ss += operatingModesTitle + ' ';
                            break;
                        case "Additional":
                            ss += additionalTitle + ' ';
                            break;
                        default:
                            ss += element + ' ';
                    }







                    //
                    ss += ' : (';
                    for (let index2 = 0; index2 < query[element].length; index2++) {
                        const elemented = query[element][index2];


                        ss += lookupValueDesc(element, elemented);
                        if (index2 !== query[element].length - 1) {
                            ss += ', ';
                        }

                    }
                    ss += ') '
                }
            } else {
                ss += element + ' : ' + lookupValueDesc(element, query[element]);
            }


            // if (index !== keys.length - 1) {
            //     ss += ', ';
            // }
        }
        return ss;
    }

    const formColumbs: any[] = [];

    if (assignment) {
        formColumbs.push({
            name: assetsTitle, field: "AssetSection", sortable: false,
            rsx: (value: any, index: number) => {
                return value.AssetSection.map((asset: any, aIndex: number) => {
                    console.log(value.AssetSection.length, aIndex, value.AssetSection.length !== (aIndex + 1))
                    return findThing(asset, assignment.assetsDetailed);
                })
            }, rpx: (value: any, index: number) => {
                return value.AssetSection.map((asset: any, aIndex: number) => {
                    console.log(value.AssetSection.length, aIndex, value.AssetSection.length !== (aIndex + 1))
                    return findThing(asset, assignment.assetsDetailed) + `${value.AssetSection.length !== (aIndex + 1) ? ' ,' : ''}`;
                })
            }, r: (value: any, index: number) => {
                return <>{assignment.assetsDetailed && value.AssetSection.map((asset: any, aIndex: number) => {
                    return <span key={aIndex}>{findThing(asset, assignment.assetsDetailed)}<br /></span>;
                })}</>
            }
        })
        formColumbs.push({
            name: operatingModesTitle, field: "OperatingMode", sortable: false,
            rsx: (value: any, index: number) => {
                return value.OperatingMode.map((asset: any, aIndex: number) => {
                    return findThing(asset, assignment.modesDetailed)
                })
            }, rpx: (value: any, index: number) => {
                return value.OperatingMode.map((asset: any, aIndex: number) => {
                    return findThing(asset, assignment.modesDetailed) + `${value.OperatingMode.length !== (aIndex + 1) ? ' ,' : ''}`
                })
            }, r: (value: any, index: number) => {

                return <>{assignment.modesDetailed && value.OperatingMode.map((asset: any, aIndex: number) => {
                    return <span key={aIndex}>{findThing(asset, assignment.modesDetailed)}<br /></span>;
                })}</>

            }
        })

        if (additionalEnabled === true) {

            formColumbs.push({
                name: additionalTitle, field: "Additional", sortable: false,
                rsx: (value: any, index: number) => {
                    return value.Additional.map((asset: any, aIndex: number) => {
                        return findThing(asset, assignment.additional)
                    })
                }, rpx: (value: any, index: number) => {
                    return value.Additional.map((asset: any, aIndex: number) => {
                        return findThing(asset, assignment.additional) + `${value.Additional.length !== (aIndex + 1) ? ' ,' : ''}`
                    })
                }, r: (value: any, index: number) => {

                    return <>{assignment.additional && value.Additional.map((asset: any, aIndex: number) => {
                        return <span key={aIndex}>{findThing(asset, assignment.additional)}<br /></span>;
                    })}</>

                }
            })
        }



    }




    if (impuritiesEnabled === true) {
        formColumbs.push(
            {
                name: impuritiesTitle, field: "Impurities", sortable: false,
                rsx: (value: any, index: number) => {
                    console.log("Impurities", value)
                    return value.Impurities.map((asset: any, aIndex: number) => {
                        return asset
                    })
                }, rpx: (value: any, index: number) => {
                    console.log("Impurities", value)
                    return value.Impurities.map((asset: any, aIndex: number) => {
                        return asset + `${value.Impurities.length !== (aIndex + 1) ? ' ,' : ''}`
                    })
                }, r: (value: any, index: number) => {
                    return <ListViewX list={value.Impurities} />;
                }
            })

    }




    formColumbs.push({
        name: damageMechanismsTitle, field: "DamageMechanism", sortable: false,
        rsx: (value: any, index: number) => {
            return value._DM.map((asset: any, aIndex: number) => {
                return asset.name
            })
        }, rpx: (value: any, index: number) => {
            return value._DM.map((asset: any, aIndex: number) => {
                return asset.name + `${value._DM.length !== (aIndex + 1) ? ' ,' : ''}`
            })
        }, r: (value: any, index: number) => {
            return <ListViewX list={value._DM} />;
        }
    },
        { name: "Severity", queryable: false, field: "Severity", sortable: true },
        { name: "Likelihood", queryable: false, field: "Likelihood", sortable: true },
        { name: "Risk", queryable: false, field: "Risk", sortable: true },
        { name: "Res Severity", queryable: false, field: "ResSeverity", sortable: true },
        { name: "Res Likelihood", queryable: false, field: "ResLikelihood", sortable: true },
        { name: "Res Risk", queryable: false, field: "ResRisk", sortable: true },

    )


    return (<>
        {(loading === false && assignment && assignment !== null) && <>
            <Helmet>
                <title>{assignment.name} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <div className="row" ref={topRef}>

                <Form
                    initialValues={query}
                    onSubmit={(data: any) => {
                        console.log("onSubmit", data);
                        const d: any = {};

                        d.AssetSection = data.AssetSection;
                        d.Impurities = data.Impurities;
                        d.OperatingMode = data.OperatingMode;
                        if (data.Additional) {
                            d.Additional = data.Additional;
                        }
                        setQueryObj(d)
                    }}
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}

                    render={({ form, handleSubmit, pristine, invalid, values }) => {
                        return <div className="row">
                            {/* <DebugButton data={values} /> */}
                            <div className={`text-center  col-sm-12 col-lg-2`}>
                                {assignment.client && <RiskTable client={assignment.client} query={query} assignment={assignment} setQuery={setQuery} ></RiskTable>}
                            </div>


                            <div className="col-sm-6 col-lg-2">

                                <MultiSelectCreate data={assignment.assetsDetailed} title={assetsTitle} fieldName="AssetSection" form={form} values={values} enableAdd={false}>
                                    <>
                                        {assignment.image && <div>
                                            <CroppyDisplay editMode={false} image={assignment.image} assetsDetailed={assignment.assetsDetailed} onClick={(asset) => {

                                                const AssetSection: string[] = [...values.AssetSection]
                                                console.log("click", asset, AssetSection)
                                                if (AssetSection.includes(asset.id)) {
                                                    const index = AssetSection.indexOf(asset.id);
                                                    AssetSection.splice(index, 1)
                                                } else {

                                                    AssetSection.push(asset.id)
                                                }

                                                form.change('AssetSection', AssetSection)
                                                handleSubmit()

                                            }} />
                                        </div>}
                                    </>
                                </MultiSelectCreate>
                            </div>

                            {impuritiesEnabled === true && <div className="col-sm-6 col-lg-2">
                                <MultiSelectCreate data={assignment.impuritiesDetailed} title={impuritiesTitle} fieldName="Impurities" form={form} values={values} enableAdd={false} />
                            </div>}

                            <div className="col-sm-6 col-lg-2">
                                <MultiSelectCreate data={assignment.modesDetailed} title={operatingModesTitle} fieldName="OperatingMode" form={form} values={values} enableAdd={false} />
                            </div>

                            {additionalEnabled === true && <div className="col-sm-6 col-lg-2">
                                <MultiSelectCreate data={assignment.additional} title={additionalTitle} fieldName="Additional" form={form} values={values} enableAdd={false} />
                            </div>}

                            <div className="col-12">
                                <div className="btn-group">
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary">Refine</button>
                                </div>
                            </div>
                        </div>
                    }} />



                <div className="col-12">
                    <div className="d-flex justify-content-between">
                        <h2>{assignment.name && assignment.name} rev {assignment.revision && assignment.revision}</h2>

                        <div>
                            {Object.keys(query).length > 0 && <div className="d-flex justify-content-between">
                                <div>{queryDesc()}</div>
                                <button type="button" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    setQueryObj({ OperatingMode: [], AssetSection: [], Impurities: [] });
                                }} className="btn btn-warning">Reset</button>
                            </div>}
                        </div>
                        <div>
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id={`tooltip`}>See Results</Tooltip>
                                }>
                                <button className="viewResultsLink btn btn-primary" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    tableRef.current.scrollIntoView()
                                }}><FontAwesomeIcon icon={faAngleDoubleDown} /></button>
                            </OverlayTrigger>

                        </div>
                    </div>

                </div>


            </div>
            <div className="row">
                <div className="col-12">


                    <div ref={tableRef}>


                        <DataTable <IAssignmentForm>
                            enablePrint={true}
                            exportFileName={`risks`}
                            topRef={topRef}
                            topText="View Search"
                            hideIndexCol={false}
                            query={query}
                            addColHeadings={[
                                { name: 'Quant Method', field: 'QuantMethod' },
                                { name: 'Existing Safeguards', field: 'ExistingSafeguards' },
                                { name: 'Risk Description', field: 'RiskDescription' },
                                { name: 'Mitigation Measures', field: 'MitigationMeasures' },
                                { name: 'Specific Actions', field: 'SpecificActions' },
                            ]}
                            rowExpanded={(value: any, index) => {
                                return <div className="row">
                                    <div className="col-2">
                                        <h4>Quant Method</h4>
                                        {value.QuantMethod}
                                    </div>
                                    <div className="col-2">
                                        <h4>Existing Safeguards</h4>
                                        {value.ExistingSafeguards}
                                    </div>
                                    <div className="col-2">
                                        <h4>Risk Description</h4>
                                        {value.RiskDescription}
                                    </div>

                                    <div className="col-2">
                                        <h4>Mitigation Measures</h4>
                                        {value.MitigationMeasures}
                                    </div>
                                    <div className="col-2">
                                        <h4>Specific Actions</h4>
                                        {value.SpecificActions}
                                    </div>
                                    <div className="col-2">
                                        <h4>Files</h4>
                                        {value.files && value.files.map((file: any, index: number) => (<div key={`sdjkhfsdkjhf${index}`}>
                                            <div key={index} className="col-4">

                                                <FontAwesomeIcon size="5x" className="w-50" icon={faFile} /><br />
                                                {file.fileName}
                                                <br />
                                                <button className="btn btn-success" type="button" onClick={(ev: React.MouseEvent) => {
                                                    downloadFile(addNotification, file);
                                                }}>Download</button>
                                            </div>
                                        </div>))}
                                    </div>
                                </div>
                            }}
                            colHeadings={formColumbs}
                            data={assignment.forms} />
                    </div>
                </div>
            </div>



        </>}

    </>
    );
}

export default Report;