import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMutators from 'final-form-arrays';
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from 'react-final-form-listeners';
import { composeValidators, maxValue, minValue, mustBeNumber, required } from "../../../components/FormStuff/validators";
import DebugButton from "../../../components/system/DebugButton";
import UploadFiles from "../../../components/system/UploadFiles";
import { useNotifications } from "../../../context/notifications";
import { IAssignment, IAssignmentSubThing } from "../../../interface/assignment";
import { IFile } from "../../../interface/file";
import { IImpurity } from "../../../interface/impurity";
import { IMechanism } from "../../../interface/mechanism";

interface IAdminReportForm {
    addSubForm: (report: any) => void;
    assignment: IAssignment;
    assignmentId: string;
    data: any;
    visible: boolean;
    assetsA: IAssignmentSubThing[];
    opsModesA: IAssignmentSubThing[];
    additionalA: IAssignmentSubThing[];
    impuritiesA: IImpurity[];
    damageMechanismsA: IMechanism[];
    possibleDamageMechanisms: IMechanism[];

}
const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const blankFile: IFile = { id: 'new', name: '', description: '', fileExt: '', fileHash: '', fileMime: '', fileName: '', fileSize: 0, assignmentId: '', otherAssignments: [], };
const AdminReportForm = ({ data, visible, addSubForm, assetsA, opsModesA, impuritiesA, damageMechanismsA, assignmentId, assignment, possibleDamageMechanisms, additionalA }: IAdminReportForm) => {
    //   const [data, setData] = useState({});
    const [show, setShow] = useState(false);
    const { addNotification } = useNotifications()!;
    const [fileData, setFileData] = useState<IFile>({ ...blankFile });
    let hookIntoForm: any;
    const [filesShow, setFilesShow] = useState(false);
    useEffect(() => {
        console.log("dddd", data);
        setShow(visible);
    }, [data, visible])

    const assetsTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.assets ? assignment.client.draOverrides.assets : 'Assets');
    const impuritiesTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.impurities ? assignment.client.draOverrides.impurities : 'Impurities');
    const damageMechanismsTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.damageMechanisms ? assignment.client.draOverrides.damageMechanisms : 'Damage Mechanisms');
    const operatingModesTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.operatingModes ? assignment.client.draOverrides.operatingModes : 'Operating Modes');
    const impuritiesEnabled: boolean = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.impuritiesEnabled ? assignment.client.draOverrides.impuritiesEnabled : false);
    const additionalTitle: string = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.operatingModes ? assignment.client.draOverrides.additional : 'Additional');
    const additionalEnabled: boolean = (assignment !== undefined && assignment !== null && assignment.client !== undefined && assignment.client.draOverrides && assignment.client.draOverrides.additionalEnabled ? assignment.client.draOverrides.additionalEnabled : false);


    const doCellTheme = (riskNo: number, severityNo: number): string => {
        if (assignment !== undefined && assignment.client !== undefined) {
            const severityRisk = assignment.client.matrix.severityRisk;
            const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
            //    console.log(index);
            const thing = severityRisk[index];
            if (index >= 0) {
                const level = assignment.client.matrix.levels[thing.thing]




                return level.color
            } else {
                return '';
            }
        } else {
            return '';
        }


    }
    const doCellText = (riskNo: number, severityNo: number): string => {
        if (assignment !== undefined && assignment.client !== undefined) {

            const severityRisk = assignment.client.matrix.severityRisk;
            const index = severityRisk.findIndex(thing => thing.riskNo === riskNo && thing.severityNo === severityNo)
            //   console.log(index);    
            if (index >= 0) {
                const thing = severityRisk[index];

                const level = assignment.client.matrix.levels[thing.thing]

                return level.level
            } else {
                return "Unknown";
            }

        } else {
            return '';
        }
    }
    return (<>{show ? <div>
        {/* {JSON.stringify(assignment)} */}
        <Form
            initialValues={data}
            onSubmit={(data: any) => {
                //  setData({})
                let errors: any = {}
                const impuritiesEnabled: boolean = (data !== undefined && data !== null && data.client !== undefined && data.client.draOverrides && data.client.draOverrides.impuritiesEnabled ? data.client.draOverrides.impuritiesEnabled : false);
                const additionalEnabled: boolean = (data !== undefined && data !== null && data.client !== undefined && data.client.draOverrides && data.client.draOverrides.additionalEnabled ? data.client.draOverrides.additionalEnabled : false);

                let err = false;
                if (!data.OperatingMode || data.OperatingMode.length === 0) {
                    errors.OperatingMode = `${operatingModesTitle} is empty`;
                    err = true;
                }
                if (!data.AssetSection || data.AssetSection.length === 0) {
                    errors.AssetSection = `${assetsTitle} Section is too short`;
                    err = true;
                }
                if (impuritiesEnabled) {
                    if (!data.Impurities || data.Impurities.length === 0) {
                        errors.Impurities = `${impuritiesTitle} Section is too short`;
                        err = true;
                    }
                }
                if (additionalEnabled) {
                    if (!data.Additional || data.Additional.length === 0) {
                        errors.Additional = `${additionalTitle} Section is too short`;
                        err = true;
                    }
                }

                if (!data.DamageMechanism || data.DamageMechanism.length === 0) {
                    errors.DamageMechanism = `${damageMechanismsTitle} Section is too short`;
                    err = true;
                }
                console.log(errors);
                if (err === false) {
                    //  setShow(false)
                    addSubForm(data)
                } else {
                    alert("missing something")
                }

            }}
            mutators={{
                // potentially other mutators could be merged here
                ...arrayMutators
            }}

            render={({ form, handleSubmit, pristine, invalid, values, valid }) => {

                hookIntoForm = (file: IFile) => {
                    let array: any[] = [];

                    if (values.files) {
                        array = [...values.files];
                    }

                    array.push(file);
                    form.change('files', array);
                    setFileData({ ...blankFile });

                };

                return <>
                    <form onSubmit={handleSubmit} data-testid="subform">

                        <DebugButton data={JSON.parse(JSON.stringify(values))}></DebugButton>
                        <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); setShow(!show) }}>Toggle Form</button>
                        <div className="row">

                            <div className="col-6">


                                <h3>{assetsTitle}</h3>
                                <div className="row">
                                    {assetsA && assetsA.map((asset, assetIndex) => {

                                        return <label className="col-4" key={assetIndex}>
                                            <Field
                                                name="AssetSection"
                                                component="input"
                                                type="checkbox"
                                                value={asset.id}
                                                data-testid={`risk-asset-${asset.name}`}
                                            />{' '}
                                            {asset.name}


                                            {/* {JSON.stringify(asset)} */}
                                        </label>;
                                    })}

                                </div>
                                {assignment.assets.length > 0 && <>Migrate {values.AssetSection.map((d: any, index: number) => {
                                    return <button className="btn btn-outline-primary"

                                        data-testid={`risk-remove-asset-${d.id}`}
                                        type="button" onClick={() => {
                                            const ffs = [...values.AssetSection]
                                            ffs.splice(index, 1)
                                            form.change('AssetSection', ffs)
                                        }}>Remove {d}</button>
                                })}</>}
                            </div>
                            <div className="col-6">
                                <h3>{operatingModesTitle}</h3>
                                <div className="row">
                                    {opsModesA && opsModesA.map((mode, modeIndex) => {

                                        return <label className="col-4" key={modeIndex}>
                                            <Field

                                                data-testid={`risk-mode-${mode.name}`}
                                                name="OperatingMode"
                                                component="input"
                                                type="checkbox"
                                                value={mode.id}
                                            />{' '}
                                            {mode.name}


                                            {/* {JSON.stringify(mode)} */}
                                        </label>;
                                    })}

                                </div>



                                {assignment.modes.length > 0 && <>Migrate {values.OperatingMode.map((d: any, index: number) => {
                                    return <button className="btn btn-outline-primary" data-testid={`risk-mode-remove-${d.id}`} type="button" onClick={() => {

                                        const ffs = [...values.OperatingMode]
                                        ffs.splice(index, 1)
                                        form.change('OperatingMode', ffs)


                                    }}>Remove {d}</button>
                                })}</>}


                            </div>




                            {impuritiesEnabled && <div className="col-6">
                                <h3>{impuritiesTitle}</h3>

                                <div className="row">
                                    {impuritiesA && impuritiesA.map((imp: any, impIndex) => {

                                        return <label className="col-6" key={impIndex}>
                                            <Field
                                                data-testid={`risk-impurity-${imp}`}
                                                name="Impurities"
                                                component="input"
                                                type="checkbox"
                                                value={imp}
                                            />{' '}
                                            {imp.replace(/_/g, ' ')}
                                        </label>;
                                    })}

                                </div>
                            </div>}
                            <div className="col-6">
                                <h3>{damageMechanismsTitle}</h3>
                                <div className="row">

                                    <OnChange name="DamageMechanism">
                                        {(value: any[], previous) => {
                                            // do something
                                            console.log(value)

                                            const newPopulatedDM = [];
                                            for (let index = 0; index < value.length; index++) {
                                                const valued = value[index];
                                                const foundDM = possibleDamageMechanisms.find((dd) => dd.id === valued);
                                                if (foundDM !== undefined) {
                                                    newPopulatedDM.push(foundDM);
                                                }

                                            }

                                            form.change('_DM', newPopulatedDM)
                                        }}
                                    </OnChange>
                                    {damageMechanismsA && damageMechanismsA.map((asset: any, assetIndex) => {
                                        const foundDM = possibleDamageMechanisms.find((dd) => dd.id === asset);
                                        return <label className="col-6" key={assetIndex}>
                                            <Field
                                                name="DamageMechanism"
                                                component="input"
                                                type="checkbox"
                                                value={asset}
                                                data-testid={`risk-mechanism-${foundDM && foundDM.id}`}
                                            />{' '}

                                            {foundDM && foundDM.name}
                                        </label>;
                                    })}

                                </div>
                            </div>
                            {additionalEnabled && <div className="col-6">
                                <h3>{additionalTitle}</h3>
                                <div className="row">
                                    {additionalA && additionalA.map((mode, modeIndex) => {

                                        return <label className="col-4" key={modeIndex}>
                                            <Field
                                                name="Additional"
                                                component="input"
                                                type="checkbox"
                                                value={mode.id}
                                                data-testid={`risk-additional-${mode.name}`}
                                            />{' '}
                                            {mode.name}


                                            {/* {JSON.stringify(mode)} */}
                                        </label>;
                                    })}

                                </div>
                            </div>}


                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6">
                                        <Field
                                            name="ExistingSafeguards"
                                        >
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Existing Safeguards</label>
                                                    <textarea data-testid={`risk-existing-safeguards`} spellCheck={true} type="text" className="form-control" {...input} placeholder="" />
                                                    {meta && meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                        <OnChange name="ExistingSafeguards">
                                            {(value, previous) => {
                                                // do something

                                                if (value !== null) {
                                                    const val = capitalize(value);
                                                    form.change('ExistingSafeguards', val)
                                                }
                                            }}
                                        </OnChange>
                                    </div>
                                    <div className="col-6">
                                        <Field
                                            name="RiskDescription"
                                        >
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Risk Description</label>
                                                    <textarea data-testid={`risk-description`} spellCheck={true} type="text" className="form-control" {...input} placeholder="" />
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="col-6">
                                        <Field
                                            name="QuantMethod"
                                        >
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Quant Method</label>
                                                    <textarea data-testid={`risk-quant-method`} spellCheck={true} type="text" className="form-control" {...input} placeholder="" />
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>





                                    <div className="col-2">
                                        <Field
                                            name="Severity"
                                            validate={composeValidators(required)}
                                            parse={(value, name) => {
                                                //  console.log("parse", value, name)
                                                if (value) return parseInt(value);
                                            }}
                                            format={(value, name) => {
                                                //  console.log("format", value, name)

                                                if (value) return parseInt(value);
                                            }}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Severity</label>
                                                    <select data-testid={`risk-severity`} id={`risk-severity`} className="form-control" {...input}>
                                                        <option></option>
                                                        {assignment.client && assignment.client.matrix.severity.map(sev => (
                                                            <option value={sev.severityNumber}>{sev.severity}</option>

                                                        ))}
                                                    </select>
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>

                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-2">

                                        <Field
                                            name="Likelihood"
                                            validate={composeValidators(required)}
                                            parse={(value, name) => {
                                                //  console.log("parse", value, name)
                                                if (value) return parseInt(value);
                                            }}
                                            format={(value, name) => {
                                                //  console.log("format", value, name)

                                                if (value) return parseInt(value);
                                            }}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Likelihood</label>
                                                    <select data-testid={`risk-likelihood`} id={`risk-likelihood`} className="form-control" {...input}>
                                                        <option></option>
                                                        {assignment.client && assignment.client.matrix.risk.map(sev => (
                                                            <option value={sev.riskNo}>{sev.risk}</option>

                                                        ))}
                                                    </select>
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                        <OnChange name="Severity">
                                            {(value, previous) => {
                                                // do something

                                                if (values.Likelihood && value !== null) {
                                                    form.change('Risk', doCellText(values.Likelihood, value))
                                                }
                                            }}
                                        </OnChange>
                                        <OnChange name="Likelihood">
                                            {(value, previous) => {
                                                if (values.Severity && value !== null) {
                                                    form.change('Risk', doCellText(value, values.Severity))
                                                }
                                            }}
                                        </OnChange>
                                    </div>
                                    <div className="col-2">
                                        <div>
                                            <label>Risk</label>

                                            <p data-testid={`risk-risk`} className={doCellTheme(values.Likelihood, values.Severity)}>{doCellText(values.Likelihood, values.Severity)}</p>
                                        </div>
                                    </div>

                                    <div className="col-2">
                                        <Field
                                            name="ResSeverity"
                                            validate={composeValidators(required, mustBeNumber, minValue(0), maxValue(5))}
                                            parse={(value, name) => {
                                                //  console.log("parse", value, name)
                                                if (value) return parseInt(value);
                                            }}
                                            format={(value, name) => {
                                                //  console.log("format", value, name)

                                                if (value) return parseInt(value);
                                            }}
                                        >
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Res Severity</label>
                                                    <select data-testid={`risk-res-severity`} id={`risk-res-severity`} className="form-control" {...input}>
                                                        <option></option>
                                                        {assignment.client && assignment.client.matrix.severity.map(sev => (
                                                            <option value={sev.severityNumber}>{sev.severity}</option>

                                                        ))}
                                                    </select>
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>

                                    </div>
                                    <div className="col-2">
                                        <Field
                                            name="ResLikelihood"
                                            validate={composeValidators(required, mustBeNumber, minValue(0), maxValue(5))}
                                            parse={(value, name) => {
                                                //  console.log("parse", value, name)
                                                if (value) return parseInt(value);
                                            }}
                                            format={(value, name) => {
                                                //  console.log("format", value, name)

                                                if (value) return parseInt(value);
                                            }}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Res Likelihood</label>
                                                    <select data-testid={`risk-res-likelihood`} id={`risk-res-likelihood`} className="form-control" {...input}>
                                                        <option></option>
                                                        {assignment.client && assignment.client.matrix.risk.map(sev => (
                                                            <option value={sev.riskNo}>{sev.risk}</option>

                                                        ))}
                                                    </select>
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-2">
                                        <div>
                                            <label>Res Risk</label>
                                            <OnChange name="ResSeverity">
                                                {(value, previous) => {
                                                    // do something

                                                    if (values.ResLikelihood && value !== null) {
                                                        form.change('ResRisk', doCellText(values.ResLikelihood, value))
                                                    }
                                                }}
                                            </OnChange>
                                            <OnChange name="ResLikelihood">
                                                {(value, previous) => {
                                                    if (values.ResSeverity && value !== null) {
                                                        form.change('ResRisk', doCellText(value, values.ResSeverity))
                                                    }
                                                }}
                                            </OnChange>
                                            <p className={doCellTheme(values.ResLikelihood, values.ResSeverity)}>{doCellText(values.ResLikelihood, values.ResSeverity)}</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <Field
                                            name="SpecificActions">
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Specific Actions</label>
                                                    <textarea data-testid={`risk-res-specific-actions`} spellCheck={true} type="text" className="form-control" {...input} placeholder="" />
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>



                                </div>
                            </div>



                            <div className="col-12">
                                <h2>Supporting Info</h2>
                                <FieldArray name="files">
                                    {({ fields }) => (
                                        <div className="row"><div className="col-12">

                                            <Field name="file" otherAssignments={[{ toType: 'assignment', toId: assignmentId }, { toType: 'client', toId: values.clientId }]} component={UploadFiles} onChanged={(event: any) => {
                                                //   fields.push(event)
                                                fields.push(event)
                                                console.log(event)
                                            }} />

                                        </div>

                                            {fields.map((name, index) => (<div key={`sdjkhfsdkjhf${index}`}>
                                                {values.files && values.files[index] && <div key={index} className="col-4">

                                                    <FontAwesomeIcon className="w-50" icon={faFile} /><br />
                                                    {values.files[index].fileName}
                                                    <br />
                                                    <button type="button" className="btn btn-danger" onClick={() => fields.remove(index)}> Remove</button>
                                                </div>}
                                            </div>))}

                                        </div>
                                    )}
                                </FieldArray>
                            </div>
                            <div className="col-12">
                                {valid === true && <div className="btn-group">
                                    <button data-testid="risk-submit" type="submit" className="btn btn-primary">SAVE RISK</button>
                                </div>}

                            </div>
                        </div>
                    </form>
                </>
            }} />


    </div> : <></>
    }</>);
};

export default AdminReportForm;