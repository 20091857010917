import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMutators from "final-form-arrays";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Helmet } from "react-helmet";
import { Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestGetSteel, RequestNewSteel, RequestUpdateSteel } from "../../../api/steel-requests";
import { composeValidators, required } from "../../../components/FormStuff/validators";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { usePageTitle } from "../../../context/pageTitle";
import { ELogType, ESeverity } from "../../../interface/log";
import { ISteel } from "../../../interface/steel";

const AdminSteel = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<ISteel>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();
    const { setPageTitle } = usePageTitle()!;


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/steels/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            const parsed = queryString.parse(location.search);
            console.log(id, location, parsed);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
                    if (parsed.duplicate) {
                        let id = '';
                        id = parsed.duplicate.toString();
                        const prodCall = () => {
                            return RequestGetSteel(addNotification, id).then(thing => {
                                thing.id = 'new';
                                delete thing._id;
                                setThing(thing);
                            })
                        }

                        Promise.allSettled([prodCall()]).then(() => {
                            setLoading(false)
                            setPageTitle && setPageTitle('S355 / New', 'admin');

                        });
                    } else {
                        setThing({ id: 'new', name: '', type: '', variant: '', letter: '', number: '', additionalTerms: '', form: '', yieldRT: [], tensileRT: [], elongation: [], impactEnergy: [] })
                        Promise.allSettled([]).then(() => {
                            setLoading(false)
                        });
                    }

                } else {
                    const prodCall = () => {
                        return RequestGetSteel(addNotification, id).then(thing => {
                            setThing(thing);
                            setPageTitle && setPageTitle('S355 / ' + thing.name, 'admin');
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Steel - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<ISteel>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Steel Created!';
                ppp = RequestNewSteel(addNotification, data);
            } else {
                notificationText = 'Steel Saved!';
                ppp = RequestUpdateSteel(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Steel - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../steels" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Steel / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}

            <Form <ISteel>
                onSubmit={(d) => {
                    if (d.id === 'new') {
                        // d.id = d.type + '_' + d.name;


                        // d.id  = d.id.replace(/[^a-zA-Z0-9-_]/g, '');
                    }
                    onSubmit(d)
                }}
                initialValues={thing}
                mutators={{
                    ...arrayMutators,
                    setValue: ([field, value], state, { changeValue }) => {
                        console.log(state, field, value)
                        changeValue(state, field, () => value)
                    }
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    //do if altered browse notifcation
                    //  console.log(submitting, pristine)
                    setFormChanged(!pristine)
                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />
                                <div className="row">
                                    <Field name="type" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div className="col-3">
                                                <label>Standard</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="name" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div className="col-3">
                                                <label>Name</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="variant">
                                        {({ input, meta }) => (
                                            <div className="col-3">
                                                <label>Variant</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="letter">
                                        {({ input, meta }) => (
                                            <div className="col-3">
                                                <label>Letter</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="number">
                                        {({ input, meta }) => (
                                            <div className="col-3">
                                                <label>Number</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="additionalTerms">
                                        {({ input, meta }) => (
                                            <div className="col-3">
                                                <label>Additional Terms</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="form">
                                        {({ input, meta }) => (
                                            <div className="col-3">
                                                <label>Form</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>


                                </div>
                                <h2>Yield RT</h2>

                                <FieldArray name="yieldRT">
                                    {({ fields }) => (
                                        <div>
                                            {fields.map((name, index) => (

                                                <div className="row" key={name}>
                                                    <div className="col-1">
                                                        <button className="pl-10 btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            fields.remove(index)
                                                        }}><FontAwesomeIcon icon={faTrash} /></button>
                                                    </div>
                                                    <Field name={`${name}.ll`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseFloat(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseFloat(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Min Limit Thickness (MM)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.ul`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseFloat(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseFloat(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Upper Limit Thickness (MM)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.value`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseInt(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseInt(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Value (MPa)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                </div>

                                            ))}
                                            <button
                                                className="btn btn-success"
                                                type="button"
                                                onClick={() => fields.push({})}
                                            >Add Value</button>
                                        </div>
                                    )}
                                </FieldArray>

                                <h2>Tensile RT</h2>

                                <FieldArray name="tensileRT">
                                    {({ fields }) => (
                                        <div>
                                            {fields.map((name, index) => (

                                                <div className="row" key={name}>
                                                    <div className="col-1">
                                                        <button className="pl-10 btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            fields.remove(index)
                                                        }}><FontAwesomeIcon icon={faTrash} /></button>
                                                    </div>
                                                    <Field name={`${name}.ll`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseFloat(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseFloat(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Min Limit Thickness (MM)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.ul`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseFloat(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseFloat(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Upper Limit Thickness (MM)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.valuell`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseInt(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseInt(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Value - Lower (MPa)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.valueul`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseInt(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseInt(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Value - Upper (MPa)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                </div>

                                            ))}
                                            <button
                                                className=" col-2 btn btn-success"
                                                type="button"
                                                onClick={() => fields.push({})}
                                            >Add Value</button>
                                        </div>
                                    )}
                                </FieldArray>





                                <h2>Elongation</h2>

                                <FieldArray name="elongation">
                                    {({ fields }) => (
                                        <div>
                                            {fields.map((name, index) => (

                                                <div className="row" key={name}>
                                                    <div className="col-1">
                                                        <button className="pl-10 btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            fields.remove(index)
                                                        }}><FontAwesomeIcon icon={faTrash} /></button>
                                                    </div>
                                                    <Field name={`${name}.test`}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">
                                                                <label>Type</label>
                                                                <select className="form-control" {...input}>
                                                                    <option></option>
                                                                    <option value="Longitudinal">Longitudinal</option>
                                                                    <option value="Transverse">Transverse</option>
                                                                </select>
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.ll`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseFloat(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseFloat(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Min Limit Thickness (MM)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.ul`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseFloat(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseFloat(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Upper Limit Thickness (MM)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.value`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseInt(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseInt(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Value (J)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                </div>

                                            ))}
                                            <button
                                                className="btn btn-success"
                                                type="button"
                                                onClick={() => fields.push({})}
                                            >Add Value</button>
                                        </div>
                                    )}
                                </FieldArray>
                                <h2>Impact Energy</h2>

                                <FieldArray name="impactEnergy">
                                    {({ fields }) => (
                                        <div>
                                            {fields.map((name, index) => (

                                                <div className="row" key={name}>
                                                    <div className="col-1">
                                                        <button className="pl-10 btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            fields.remove(index)
                                                        }}><FontAwesomeIcon icon={faTrash} /></button>
                                                    </div>
                                                    <Field name={`${name}.test`}>
                                                        {({ input, meta }) => (
                                                            <div className="col-2">

                                                                <label>Test</label>
                                                                <select className="form-control" {...input}>
                                                                    <option></option>
                                                                    <option value="Longitudinal">Longitudinal</option>
                                                                    <option value="Transverse">Transverse</option>
                                                                </select>
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.ll`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseFloat(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseFloat(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Min Limit Thickness (MM)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.ul`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseFloat(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseFloat(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Upper Limit Thickness (MM)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.value`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseInt(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseInt(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Value (MPa)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={`${name}.temp`} type="number" parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseInt(value);
                                                    }}
                                                        format={(value, name) => {
                                                            //  console.log("format", value, name)

                                                            return parseInt(value);
                                                        }}>
                                                        {({ input, meta }) => (
                                                            <div className="col-3">

                                                                <label>Temp (C)</label>
                                                                <input className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                </div>

                                            ))}
                                            <button
                                                className="btn btn-success"
                                                type="button"
                                                onClick={() => fields.push({})}
                                            >Add Value</button>
                                        </div>
                                    )}
                                </FieldArray>

                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </form>

                }} />

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminSteel;