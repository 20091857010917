import { faInfo, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import async from 'async';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CSVReader from 'react-csv-reader';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { Helmet } from 'react-helmet';
import { QRCode } from 'react-qrcode-logo';
import { Link, Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { RequestGetClients } from '../../../api/client-requests';
import { RequestDeleteEquipment, RequestGetEquipments, RequestNewEquipment, RequestUpdateEquipment } from '../../../api/equipment-requests';
import { RequestGetInspectionTypes } from '../../../api/inspection-type-requests';
import { RequestDeleteInstallationLoop, RequestGetInstallationLoops, RequestNewInstallationLoop, RequestUpdateInstallationLoop } from '../../../api/installation-loop-requests';
import {
    RequestGetInstallation,
    RequestNewInstallation,
    RequestUpdateInstallation
} from '../../../api/installation-requests';
import { RequestDeleteInstallationSystem, RequestGetInstallationSystems, RequestNewInstallationSystem, RequestUpdateInstallationSystem } from '../../../api/installation-system-requests';
import { RequestNewLog } from '../../../api/log-requests';
import { RequestDeletePipe, RequestGetPipes, RequestNewPipe, RequestUpdatePipe } from '../../../api/pipe-requests';
import { composeValidators, required } from '../../../components/FormStuff/validators';
import DataTable from '../../../components/system/DataTable';
import DebugButton from '../../../components/system/DebugButton';
import Loading from '../../../components/system/loading';
import UploadImages from '../../../components/system/UploadImages/UploadImages';
import Wizard from '../../../components/system/Wizard';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { usePageTitle } from '../../../context/pageTitle';
import { IClient } from '../../../interface/client';
import { IInspectionType } from '../../../interface/inspection-type';
import { IInstallation } from '../../../interface/installation';
import { IInstallationVersion } from '../../../interface/installation-version';
import { ELogType, ESeverity } from '../../../interface/log';
import { filesAttach } from '../addons';
import { CSVPopupContents } from './_CSVPopupContents';
import { equipmentFields } from './_equipFields';
import { getRisk } from './_getRisk';
import { InputFields } from './_InputFields';
import { loopFields } from './_loopFields';
import { parseCSV } from './_parseCSV';
import { pipeFields } from './_pipeFields';
import { systemFields } from './_systemfields';
import { validateFormInput } from './_validateFormInput';



/*


*/

const AdminInstallation = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IInstallation>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();

    const [clients, setClients] = useState<IClient[]>();

    const [inspectionTypes, setInspectionTypes] = useState<IInspectionType[]>();




    const [versions, setVersions] = useState<IInstallationVersion[]>();

    {/*<!-- OPTIONAL END -->*/ }
    const { setPageTitle } = usePageTitle()!;
    const papaparseOptions = {
        header: true,
        // dynamicTyping: true,
        skipEmptyLines: true,
        // transformHeader: (header: any) =>
        //     header
        //         .toLowerCase()
        //         .replace(/\W/g, '_')
    }


    const match: any = useRouteMatch("/admin/installations/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {
                setLoading(false)

                {/*<!-- OPTIONAL 2 -->*/ }
                const gClients = (): Promise<any> => {
                    return RequestGetClients(addNotification).then(clients => {
                        setClients(clients);
                    })
                }
                const gInspectTypes = (): Promise<any> => {
                    return RequestGetInspectionTypes(addNotification).then(iTypes => {
                        setInspectionTypes(iTypes)
                    })
                }
                if (id === 'new') {
                    setThing({
                        clientId: '', installationVersionId: '',
                        pipes: [],
                        contractors: [],
                        damageMechanism: [],
                        id: 'new', name: '', description: '', imageId: '',
                        equipments: [],
                        loops: [],
                        systems: [],
                        files: [],
                        fluids: [],
                        equipmentTypes: [],
                        matterials: [
                            { value: 'CS', text: 'CS' },
                            { value: 'LTCS', text: 'LTCS' },
                            { value: 'SS', text: 'SS' },
                            { value: 'DS', text: 'DS' },
                            { value: 'HDGCS', text: 'HDGCS' },

                        ]
                    })
                    Promise.allSettled([gClients(), gInspectTypes()]).then(() => {
                        setLoaded(true)
                        setPageTitle && setPageTitle('Retriever / New', 'admin');
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetInstallation(addNotification, id).then(thing => {
                            RequestGetInstallationLoops(addNotification, { installId: id }).then(loops => {
                                RequestGetInstallationSystems(addNotification, { installId: id }).then(systems => {
                                    RequestGetEquipments(addNotification, { installId: id }).then(equipment => {
                                        RequestGetPipes(addNotification, { installId: id }).then(pipes => {
                                            // console.log(thing, equipment)
                                            thing.equipments = equipment;
                                            thing.uuid = uuidv4()
                                            thing.pipes = pipes;
                                            thing.loops = loops;
                                            thing.systems = systems;
                                            setThing(thing);
                                            setPageTitle && setPageTitle('Retriever / ' + thing.name, 'admin');
                                        })
                                    })
                                })
                            });


                        })
                    }



                    Promise.allSettled([prodCall(), gClients(), gInspectTypes()]).then(() => {
                        setLoaded(true)

                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Installation - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IInstallation>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Installation Created!';
                ppp = RequestNewInstallation(addNotification, data);
            } else {
                notificationText = 'Installation Saved!';
                ppp = RequestUpdateInstallation(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Installation - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }



    //   const 

    {/*<!-- OPTIONAL END -->*/ }


    const SYSTEM_EXTERNAL_URL = auth.metaData['SYSTEM_EXTERNAL_URL']





    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="/admin/installations" />}

        {(loaded === true && thing) ? <>
            <span className="e2ePageAdminInstallation" style={{ display: 'none' }}>TestOk</span>
            <Helmet>
                <title>Installation / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}

            <div className="row">
                <div className="col-12">
                    <Wizard
                        pageMenu={true}
                        initialValues={thing}
                        onSubmit={(data: any) => {
                            console.log("onSubmit", data);
                            setRedirect(true);
                        }}
                        onPageChange={(data: any) => {
                            console.log("onPageChange", data);
                            // setWizardPage(data.page);
                            // onSave(data.values);
                        }}>

                        <Wizard.Page n="start" nt="Installation Details" onSubmit={(values: any, next: any) => {
                            const newD: any = { ...values };
                            newD.client = undefined;
                            newD.systemns = undefined;
                            newD.loops = undefined;
                            newD.equipments = undefined;
                            newD.pipes = undefined;
                            if (values.id === 'new') {


                                RequestNewInstallation(addNotification, newD).then(installation => {
                                    values = { ...values, ...installation };
                                    values.id = installation.id;
                                    next(values)
                                })
                            } else {

                                RequestUpdateInstallation(addNotification, newD).then(installation => {
                                    values = { ...values, ...installation };

                                    next(values)
                                })
                            }
                        }} render={(values: any, push: any, pop: any, form: any) => {


                            return <div className="row">
                                <span className="e2ePageAdminInstallationHome" style={{ display: 'none' }}>TestOk</span>
                                <div className="col-12">

                                    <h2>Details</h2>
                                    {values.id !== 'new' && <Link to={`/installations/${values.id}/home`} className="btn btn-warning" target="_blank">Client View</Link>}

                                    <Field name="name" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Name</label>
                                                <input id={`inp_${input.name}`} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>



                                    <Field name="description" >
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Description</label>
                                                <textarea id={`inp_${input.name}`} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="clientId" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Client</label>
                                                <select id={`inp_${input.name}`} className="form-control" {...input}>
                                                    <option></option>
                                                    {clients && clients.map((client, index) => {
                                                        return <option key={index} value={client.id}>{client.name}</option>
                                                    })}
                                                </select>
                                                {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <OnChange name={`clientId`}>
                                        {(value, previous) => {
                                            // do something
                                            if (clients) {
                                                const ind = clients.findIndex(dd => dd.id === value);
                                                if (ind !== -1) {
                                                    form.change('client', clients[ind])
                                                }
                                            }

                                        }}
                                    </OnChange>


                                </div>


                            </div>
                        }} />
                        <Wizard.Page n="detailed" nt="More Info" onSubmit={(values: any, next: any) => {
                            const newD: any = { ...values };
                            newD.client = undefined;
                            newD.systemns = undefined;
                            newD.loops = undefined;
                            newD.equipments = undefined;
                            newD.pipes = undefined;
                            if (values.id === 'new') {


                                RequestNewInstallation(addNotification, newD).then(installation => {
                                    values = { ...values, ...installation };

                                    next(values)
                                })
                            } else {

                                RequestUpdateInstallation(addNotification, newD).then(installation => {
                                    values = { ...values, ...installation };

                                    next(values)
                                })
                            }
                        }} render={(values: any, push: any, pop: any, form: any) => {





                            return <div className="row">
                                <div className="col-12">
                                    <span className="e2ePageAdminInstallationDetails" style={{ display: 'none' }}>TestOk</span>
                                    <span className="e2eInstallId" style={{ display: 'none' }}>{values.id}</span>
                                    <h2>More Info</h2>
                                    {values.id !== 'new' && <Link to={`/installations/${values.id}/home`} className="btn btn-warning" target="_blank">Client View</Link>}
                                    <h2>Installation Schematic</h2>



                                    {values.image && <div className="col-6 offset-2">
                                        <img src={values.image.croppedSrcData ? values.image.croppedSrcData : values.image.c1600} className="img-fluid" />
                                    </div>}
                                    <Field name="image" aspect="none" camera={false} component={UploadImages} text="Set Main Diagram Image" attachTo="installation" attachId={values.id} onChanged={(data: any) => {
                                        console.log("changed", data)
                                        form.change('imageId', data.id)
                                        form.change('image', data)
                                    }} />



                                </div>


                                {filesAttach("files", "fileIds", values, [{ toType: 'client', toId: values.clientId }, { toType: 'install', toId: values.installId }], addNotification, form)}



                                <div className="col-6">
                                    <h2>Materials</h2>
                                    <FieldArray name="matterials">
                                        {({ fields }) => (
                                            <div className="row">
                                                {fields.map((name, index) => (

                                                    <div className="row" key={name}>
                                                        <div className="col-5">
                                                            <Field name={`${name}.value`} component="input" validate={composeValidators(required)}>

                                                                {({ input, meta }) => (
                                                                    <>
                                                                        <label>Value</label>
                                                                        <input id={`inp_matterials_${index}_value`} type="text" className="form-control" {...input} placeholder="...." />
                                                                        {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-6">
                                                            <Field name={`${name}.text`} component="input" validate={composeValidators(required)}>

                                                                {({ input, meta }) => (
                                                                    <>
                                                                        <label>Text</label>
                                                                        <input id={`inp_matterials_${index}_value`} type="text" className="form-control" {...input} placeholder="...." />
                                                                        {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-1">
                                                            <button id={`btn_matterials_${index}_delete`} className="btn btn-outline-danger" onClick={() => fields.remove(index)} type="button" ><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </div>

                                                ))}
                                                <button
                                                    id={`inp_matterials_add`}
                                                    className="btn btn-outline-success"
                                                    type="button"
                                                    onClick={() => fields.push({ value: '', text: '' })}
                                                >Add Material</button>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                                <div className="col-6">
                                    <h2>Fluids</h2>
                                    <FieldArray name="fluids">
                                        {({ fields }) => (
                                            <div className="row">
                                                {fields.map((name, index) => (

                                                    <div className="row" key={name}>
                                                        <div className="col-5">
                                                            <Field name={`${name}.value`} component="input" validate={composeValidators(required)}>

                                                                {({ input, meta }) => (
                                                                    <>
                                                                        <label>Value</label>
                                                                        <input id={`inp_fluids_${index}_value`} type="text" className="form-control" {...input} placeholder="...." />
                                                                        {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-6">
                                                            <Field name={`${name}.text`} component="input" validate={composeValidators(required)}>

                                                                {({ input, meta }) => (
                                                                    <>
                                                                        <label>Text</label>
                                                                        <input id={`inp_fluids_${index}_text`} type="text" className="form-control" {...input} placeholder="...." />
                                                                        {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-1">
                                                            <button id={`inp_fluids_${index}_delete`} className="btn btn-outline-danger" onClick={() => fields.remove(index)} type="button" ><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </div>

                                                ))}
                                                <button
                                                    id={`inp_fluids_add`}
                                                    className="btn btn-outline-success inp_fluids_add"
                                                    type="button"
                                                    onClick={() => fields.push({ value: '', text: '' })}
                                                >Add Fluid</button>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                                <div className="col-6">
                                    <h2>Equipment Types</h2>
                                    <FieldArray name="equipmentTypes">
                                        {({ fields }) => (
                                            <div className="row">
                                                {fields.map((name, index) => (

                                                    <div className="row" key={name}>
                                                        <div className="col-5">
                                                            <Field name={`${name}.value`} component="input" validate={composeValidators(required)}>

                                                                {({ input, meta }) => (
                                                                    <>
                                                                        <label>Value</label>
                                                                        <input id={`inp_equipmentTypes_${index}_value`} type="text" className="form-control" {...input} placeholder="...." />
                                                                        {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-6">
                                                            <Field name={`${name}.text`} component="input" validate={composeValidators(required)}>

                                                                {({ input, meta }) => (
                                                                    <>
                                                                        <label>Text</label>
                                                                        <input id={`inp_equipmentTypes_${index}_text`} type="text" className="form-control" {...input} placeholder="...." />
                                                                        {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-1">
                                                            <button className="btn btn-outline-danger" id={`btn_equipmentTypes_${index}_delete`} onClick={() => fields.remove(index)} type="button" >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </div>
                                                    </div>

                                                ))}
                                                <button
                                                    id={`inp_equipmentTypes_add`}
                                                    className="btn btn-outline-success"
                                                    type="button"
                                                    onClick={() => fields.push({ value: '', text: '' })}
                                                >Add Equipment Type </button>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>


                                <div className="col-6">
                                    <h2>Contractor</h2>
                                    <FieldArray name="contractors">
                                        {({ fields }) => (
                                            <div className="row">
                                                {fields.map((name, index) => {

                                                    return <div className="row" key={name}>


                                                        <div className="col-5 row">
                                                            <div className="col-6">
                                                                <Field name={`${name}.id`} component="input" validate={composeValidators(required)}>

                                                                    {({ input, meta }) => (
                                                                        <>
                                                                            <label>Id</label>
                                                                            <input id={`inp_contractors_${index}_id`} type="text" className="form-control" {...input} placeholder="...." />
                                                                            {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-6">
                                                                <Field name={`${name}.name`} component="input" validate={composeValidators(required)}>

                                                                    {({ input, meta }) => (
                                                                        <>
                                                                            <label>Name</label>
                                                                            <input id={`inp_contractors_${index}_name`} type="text" className="form-control" {...input} placeholder="...." />
                                                                            {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 row">
                                                            <FieldArray name={`${name}.people`}>
                                                                {(something: any) => (
                                                                    <div className="row">
                                                                        {something.fields.map((nameA: string, indexA: number) => (

                                                                            <div className="row" key={`${nameA} ${indexA}`}>
                                                                                <div className="col-5">
                                                                                    <Field name={`${nameA}.id`} component="input" validate={composeValidators(required)}>

                                                                                        {({ input, meta }) => (
                                                                                            <>
                                                                                                <label>Id</label>
                                                                                                <input id={`inp_contractors_${index}_${indexA}_id`} type="text" className="form-control" {...input} placeholder="...." />
                                                                                                {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                                            </>
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <Field name={`${nameA}.name`} component="input" validate={composeValidators(required)}>

                                                                                        {({ input, meta }) => (
                                                                                            <>
                                                                                                <label>Name</label>
                                                                                                <input id={`inp_contractors_${index}_${indexA}_name`} type="text" className="form-control" {...input} placeholder="...." />
                                                                                                {meta && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                                            </>
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <button className="btn btn-outline-danger"
                                                                                        id={`btn_contractors_${index}_${indexA}_delete`}
                                                                                        onClick={() => something.fields.remove(index)} type="button" >
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </button>
                                                                                </div>
                                                                            </div>

                                                                        ))}
                                                                        <button
                                                                            id={`btn_contractors_${index}_addPerson`}
                                                                            className="btn btn-outline-success"
                                                                            type="button"
                                                                            onClick={() => something.fields.push({ id: '', name: '' })}
                                                                        >Add Person</button>
                                                                        <br />
                                                                    </div>
                                                                )}
                                                            </FieldArray>

                                                        </div>


                                                        <div className="col-1">
                                                            <div className="btn-group">
                                                                <button id={`btn_contractors_${index}_delete`} className="btn btn-outline-danger" onClick={() => fields.remove(index)} type="button" >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                                <DebugButton data={values.contractors[index]} />
                                                            </div>



                                                        </div>
                                                    </div>

                                                })}
                                                <button
                                                    id={`inp_contractors_add`}
                                                    className="btn btn-outline-success"
                                                    type="button"
                                                    onClick={() => fields.push({ value: '', text: '' })}
                                                >Add Contractor</button>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>
                        }} />
                        <Wizard.Page n="systems" nt="Systems" render={(values: any, push: any, pop: any, form: any) => {


                            return <div className="col-12">
                                <span className="e2ePageAdminInstallationSystems" style={{ display: 'none' }}>TestOk</span>
                                <span className="e2eInstallId" style={{ display: 'none' }}>{values.id}</span>
                                {values.id !== 'new' && <Link to={`/installations/${values.id}/systems`} className="btn btn-warning" target="_blank">Client View</Link>}

                                <h2>Systems</h2>

                                {values.newSystem ? <>

                                    <InputFields values={values} form={form} array={systemFields} targetObject="newSystem" inspectionTypes={inspectionTypes} />

                                    <div className="btn-group">
                                        <DebugButton data={values.newSystem} />
                                        <button className="btn btn-primary" id={`btnCancelNew`} onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            form.change('newSystem', undefined)
                                            form.change('____LOCK', undefined)
                                            form.change('validationErrors', undefined)
                                        }}>Cancel</button>
                                        <button className="btn btn-primary" id={`btnSubmitNew`} onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            if (values.newSystem) {
                                                let validationOK: boolean = true;
                                                let validationErrors: any[] = [];

                                                validationErrors = validateFormInput(values.newSystem, systemFields);
                                                if (validationErrors.length > 0) {
                                                    validationOK = false;
                                                }
                                                if (validationOK) {
                                                    if (values.newSystem.id === 'new') {
                                                        RequestNewInstallationSystem(addNotification, values.newSystem).then(nSys => {
                                                            const d = [...values.systems]
                                                            d.push(nSys);
                                                            form.change('systems', d)
                                                            form.change('newSystem', undefined)
                                                            form.change('____LOCK', undefined)
                                                            form.change('validationErrors', undefined)
                                                        });
                                                    } else {
                                                        RequestUpdateInstallationSystem(addNotification, values.newSystem).then(nSys => {
                                                            const d = [...values.systems]
                                                            const index = d.findIndex(dd => dd.id === nSys.id)
                                                            d[index] = nSys;
                                                            form.change('systems', d)
                                                            form.change('newSystem', undefined)
                                                            form.change('____LOCK', undefined)
                                                            form.change('validationErrors', undefined)
                                                        });
                                                    }
                                                } else {
                                                    form.change('validationErrors', validationErrors)
                                                }

                                            }
                                        }}>Save</button>
                                    </div>
                                </> : <> <button className="btn btn-primary" id={`btnNew`} onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    form.change('newSystem', { id: 'new', installId: values.id, clientId: values.clientId })
                                    form.change('____LOCK', true)
                                }}>New</button>  <DataTable

                                        exportFields={systemFields.map(d => { return { field: d.field, name: d.name } })}
                                        exportFileName={'system'}

                                        colHeadings={[
                                            { name: "RefId", field: "refId", sortable: false },
                                            { name: "Name", field: "name", sortable: false },
                                            { name: "Description", field: "description", sortable: false },
                                            {
                                                name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                                    return <div className="btn-group">
                                                        <DebugButton data={value} />
                                                        <Link target="_new" className={`btn btn-warning e2e_${index - 1}_directLink`} to={`/installations/${values.id}/system/${value.id}`}><FontAwesomeIcon icon={faInfo} /></Link>
                                                        <button className="btn btn-primary" id={`btnEdit_${index - 1}`} onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            form.change('newSystem', value);
                                                            form.change('____LOCK', true)
                                                        }}>Edit</button>
                                                        <button className="btn btn-danger" id={`btnDelete_${index - 1}`} onClick={(ev: React.MouseEvent) => {

                                                            ev.preventDefault();
                                                            const conf = window.confirm('Are you sure you want to do this?')
                                                            if (conf) {
                                                                RequestDeleteInstallationSystem(addNotification, value).then(dd => {
                                                                    const d = [...values.systems]

                                                                    d.splice(index - 1, 1);
                                                                    form.change('systems', d)
                                                                }).catch(ex => {
                                                                    console.log(ex);

                                                                })
                                                            }
                                                        }}>Delete</button>
                                                    </div>;
                                                }, sortable: false
                                            },

                                        ]}

                                        data={values.systems}
                                    />
                                    <Modal size="xl" show={values.systemUpload !== undefined ? true : false} onHide={() => {
                                        form.change('systemUpload', undefined)
                                    }}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>CSV Import</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <span className="e2ePageSystemPopup" style={{ display: 'none' }}>TestOk</span>
                                            <CSVPopupContents values={values} array="systemUpload" form={form} />

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-warning e2eCSVSubmit" type="button" onClick={(ev: React.MouseEvent) => {
                                                ev.preventDefault();
                                                const upload = values.systemUpload.filter((some: any) => some.action === true);

                                                console.log(upload)


                                                const d = [...values.systems]
                                                const uEquipmentUpload: any[] = [];

                                                async.each(upload, (toUpload: any, cb: any) => {
                                                    console.log("toUpload", toUpload.object);


                                                    if (toUpload.isNew) {

                                                        RequestNewInstallationSystem(addNotification, { ...toUpload.object, installId: values.id, clientId: values.clientId, id: 'new' }).then(nSys => {

                                                            d.push(nSys);
                                                            toUpload.response = nSys;
                                                            uEquipmentUpload.push(toUpload)
                                                            cb();
                                                            //      form.change('newEquipment', undefined)
                                                        }).catch(ex => {
                                                            console.log(ex);
                                                            toUpload.exception = "something failed here.";
                                                            uEquipmentUpload.push(toUpload);
                                                            cb();
                                                        });
                                                    } else {
                                                        const OBJECT = { ...toUpload.object }

                                                        OBJECT.id = toUpload.original.id;
                                                        OBJECT._id = toUpload.original._id;
                                                        OBJECT.installId = values.id;
                                                        OBJECT.clientId = values.clientId
                                                        RequestUpdateInstallationSystem(addNotification, OBJECT).then(nSys => {
                                                            console.log("____________________", nSys)
                                                            const index = d.findIndex(dd => dd.id === nSys.id)
                                                            d[index] = nSys;
                                                            toUpload.response = nSys;
                                                            uEquipmentUpload.push(toUpload)


                                                            cb()
                                                            //     form.change('newEquipment', undefined)
                                                        }).catch(ex => {
                                                            toUpload.exception = ex;
                                                            uEquipmentUpload.push(toUpload)
                                                            console.log("____________________", ex);
                                                            cb()
                                                        });
                                                    }
                                                    //const index = values.equipments.findIndex((ddd:any)=>ddd.id === toUpload.id);

                                                }, (err: any) => {
                                                    console.log("xxxx", d)
                                                    form.change('systems', d)
                                                    form.change('systemUpload', uEquipmentUpload)
                                                });
                                            }}>Action Selected</button>
                                            <Button variant="secondary" className="e2eClose" onClick={() => {
                                                form.change('systemUpload', undefined)
                                            }}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {values.systemUpload === undefined && <CSVReader
                                        cssClass="csv-reader-input"
                                        label="Upload systems.csv"
                                        onFileLoaded={(dataA: any[]) => {
                                            // form.change('uuid', uuidv4())
                                            //equipmentField

                                            parseCSV(dataA, 'systems', systemFields, 'systemUpload', values, form, false);

                                        }}
                                        onError={(error: any) => {
                                            // form.change('uuid', uuidv4())
                                            console.log(error)
                                        }}
                                        parserOptions={papaparseOptions}
                                        inputId="ObiWan"
                                        inputName="ObiWan"
                                        inputStyle={{ color: 'red' }}
                                    />

                                    }
                                </>}



                            </div>
                        }} />
                        <Wizard.Page n="loops" nt="Loops" render={(values: any, push: any, pop: any, form: any, validationErrors: any) => {


                            return <div className="col-12">
                                <span className="e2ePageAdminInstallationLoops" style={{ display: 'none' }}>TestOk</span>
                                <h2>Loops</h2>
                                {values.id !== 'new' && <Link to={`/installations/${values.id}/loops`} className="btn btn-warning" target="_blank">Client View</Link>}

                                {values.newLoop ? <>
                                    <InputFields values={values} form={form} array={loopFields} targetObject="newLoop" inspectionTypes={inspectionTypes} />

                                    <div className="btn-group">
                                        <DebugButton data={values.newLoop} />
                                        <button className="btn btn-primary" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            form.change('newLoop', undefined)
                                            form.change('____LOCK', undefined)
                                            form.change('validationErrors', undefined)
                                        }}>Cancel</button>

                                        <button className="btn btn-primary" id="btnSubmitNew" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            if (values.newLoop) {
                                                const thingddcdd: any = { ...values.newLoop };
                                                console.log("argh", thingddcdd, thingddcdd.likelihood, thingddcdd.combinedConsequence)
                                                const risk = getRisk(thingddcdd.likelihood, thingddcdd.combinedConsequence, values)
                                                thingddcdd.risk = risk;

                                                if (thingddcdd.frmPlannedDate !== '') {
                                                    const myMomentObject = moment(thingddcdd.frmPlannedDate, 'DD-MM-YYYY').toDate()
                                                    //    console.log(value, myMomentObject)
                                                    thingddcdd.plannedDate = myMomentObject;
                                                }
                                                if (thingddcdd.frmCommissionedDate !== '') {

                                                    const myMomentObject = moment(thingddcdd.frmCommissionedDate, 'DD-MM-YYYY').toDate()
                                                    //    console.log(value, myMomentObject)
                                                    thingddcdd.commissionedDate = myMomentObject;
                                                }
                                                //  form.change(`${targetObject}.${field.field}`, myMomentObject.toString())
                                                let validationOK: boolean = true;
                                                let validationErrors: any[] = [];

                                                validationErrors = validateFormInput(thingddcdd, loopFields);
                                                if (validationErrors.length > 0) {
                                                    validationOK = false;
                                                }


                                                /// why me? - validation 

                                                if (validationOK === true) {
                                                    //console.log("argh", thingddcdd, thingddcdd.likelihood, thingddcdd.combinedConsequence)
                                                    if (values.newLoop.id === 'new') {
                                                        RequestNewInstallationLoop(addNotification, thingddcdd).then(nSys => {
                                                            form.change('loops', [])
                                                            const d = [...values.loops]
                                                            d.push(nSys);
                                                            form.change('loops', d)
                                                            form.change('newLoop', undefined)
                                                            form.change('____LOCK', undefined)
                                                            form.change('validationErrors', undefined)
                                                        });
                                                    } else {
                                                        RequestUpdateInstallationLoop(addNotification, thingddcdd).then(nSys => {
                                                            const d = [...values.loops]
                                                            const index = d.findIndex(dd => dd.id === nSys.id)
                                                            d[index] = nSys;
                                                            form.change('loops', d)
                                                            form.change('newLoop', undefined)
                                                            form.change('____LOCK', undefined)
                                                            form.change('validationErrors', undefined)
                                                        });
                                                    }
                                                } else {
                                                    form.change('validationErrors', validationErrors)
                                                }



                                            }
                                        }}>Save</button></div>
                                </> : <> <button className="btn btn-primary" id="btnNew" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    form.change('newLoop', { id: 'new', installId: values.id, clientId: values.clientId })
                                    form.change('____LOCK', true)
                                }}>New</button>
                                    <DataTable


                                        exportFields={loopFields.map(d => { return { field: d.field, name: d.name } })}
                                        /*
            materialSpecification	materialGrade	diameter	originalWallThickness
            
            
                                        */
                                        exportFileName={'loops'}
                                        colHeadings={[
                                            { name: "RefId", field: "refId", sortable: false },
                                            { name: "Name", field: "name", sortable: false },

                                            { name: "Consequence", field: "combinedConsequenceAZ", sortable: false },
                                            { name: "Likelihood", field: "likelihoodAZ", sortable: false },
                                            { name: "LOF Combined", field: "LOFCombinedAZ", sortable: false },
                                            { name: "Risk", field: "riskAZ", sortable: false },
                                            { name: "Fluid", field: "fluidName", sortable: true, queryable: true },
                                            {
                                                name: "Material", field: "materialName", sortable: true,
                                            },

                                            {
                                                name: "System", field: "systemId", sortable: false, r: (value: any, index) => {
                                                    return <>{value.system && value.system.refId}</>
                                                }
                                            },
                                            {
                                                name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                                    return <div className="btn-group">
                                                        <DebugButton data={value} />
                                                        <Link target="_new" className={`btn btn-warning e2e_${index - 1}_directLink`} to={`/installations/${values.id}/loop/${value.id}`}><FontAwesomeIcon icon={faInfo} /></Link>
                                                        <button className="btn btn-primary" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            form.change('newLoop', value);
                                                            form.change('____LOCK', true)
                                                        }}>Edit</button>
                                                        <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            const conf = window.confirm('Are you sure you want to do this?')
                                                            if (conf) {
                                                                RequestDeleteInstallationLoop(addNotification, value).then(dd => {
                                                                    const d = [...values.loops]

                                                                    d.splice(index - 1, 1);
                                                                    form.change('loops', d)
                                                                }).catch(ex => {
                                                                    console.log(ex);

                                                                })
                                                            }
                                                        }}>Delete</button>
                                                    </div>;
                                                }, sortable: false
                                            },

                                        ]}

                                        data={values.loops}
                                    />
                                    <Modal size="xl" show={values.loopUpload !== undefined ? true : false} onHide={() => {
                                        form.change('loopUpload', undefined)
                                    }}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>CSV Import</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <span className="e2ePageLoopPopup" style={{ display: 'none' }}>TestOk</span>
                                            <CSVPopupContents values={values} array="loopUpload" form={form} />

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-warning e2eCSVSubmit" type="button" onClick={(ev: React.MouseEvent) => {
                                                ev.preventDefault();
                                                const upload = values.loopUpload.filter((some: any) => some.action === true);

                                                console.log(upload)


                                                const d = [...values.loops]
                                                const uEquipmentUpload: any[] = [];

                                                async.each(upload, (toUpload: any, cb: any) => {
                                                    console.log("toUpload", toUpload.object);
                                                    if (toUpload.isNew) {

                                                        RequestNewInstallationLoop(addNotification, { ...toUpload.object, installId: values.id, clientId: values.clientId, id: 'new' }).then(nSys => {

                                                            d.push(nSys);
                                                            toUpload.response = nSys;
                                                            uEquipmentUpload.push(toUpload)
                                                            cb();

                                                        }).catch(ex => {
                                                            console.log(ex);
                                                            toUpload.exception = "something failed here.";
                                                            uEquipmentUpload.push(toUpload);
                                                            cb();
                                                        });
                                                    } else {
                                                        const OBJECT = { ...toUpload.object }

                                                        OBJECT.id = toUpload.original.id;
                                                        OBJECT._id = toUpload.original._id;
                                                        OBJECT.installId = values.id;
                                                        OBJECT.clientId = values.clientId
                                                        RequestUpdateInstallationLoop(addNotification, OBJECT).then(nSys => {
                                                            console.log("____________________", nSys)
                                                            const index = d.findIndex(dd => dd.id === nSys.id)
                                                            d[index] = nSys;
                                                            toUpload.response = nSys;
                                                            uEquipmentUpload.push(toUpload)


                                                            cb()

                                                        }).catch(ex => {
                                                            toUpload.exception = "something failed here.";
                                                            uEquipmentUpload.push(toUpload)
                                                            console.log("____________________", ex);

                                                            cb()
                                                        });
                                                    }


                                                }, (err: any) => {
                                                    console.log("xxxx", d)
                                                    form.change('loops', d)
                                                    form.change('loopUpload', uEquipmentUpload)
                                                });
                                            }}>Action Selected</button>
                                            <Button variant="secondary" className="e2eClose" onClick={() => {
                                                form.change('loopUpload', undefined)
                                            }}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {values.loopUpload === undefined && <CSVReader
                                        cssClass="csv-reader-input"
                                        label="Upload loops.csv"
                                        onFileLoaded={(dataA: any[]) => {

                                            //equipmentField

                                            parseCSV(dataA, 'loops', loopFields, 'loopUpload', values, form, true);

                                        }}
                                        onError={(error: any) => {
                                            console.log(error)
                                        }}
                                        parserOptions={papaparseOptions}
                                        inputId="ObiWan"
                                        inputName="ObiWan"
                                        inputStyle={{ color: 'red' }}
                                    />}

                                </>}


                            </div>
                        }} />


                        <Wizard.Page n="Equip" nt="Equipments" render={(values: any, push: any, pop: any, form: any) => {


                            return <div className="col-12">
                                <span className="e2ePageAdminInstallationEquipments" style={{ display: 'none' }}>TestOk</span>
                                <h2>Equipments</h2>
                                {values.id !== 'new' && <Link to={`/installations/${values.id}/equipment`} className="btn btn-warning" target="_blank">Client View</Link>}

                                {values.newEquipment ? <>
                                    {values.newEquipment.id && <> {values.newEquipment.id} <br /><QRCode value={`${SYSTEM_EXTERNAL_URL}installations/tfarm/equipment/${values.newEquipment.id}`} size={500} logoImage={`/ocg.png`} /></>}
                                    <InputFields values={values} form={form} array={equipmentFields} targetObject="newEquipment" inspectionTypes={inspectionTypes} />



                                    <div className="btn-group">
                                        <DebugButton data={values.newEquipment} />
                                        <button className="btn btn-primary" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            form.change('newEquipment', undefined)
                                            form.change('____LOCK', undefined)
                                            form.change('validationErrors', undefined)
                                        }}>Cancel</button>

                                        <button className="btn btn-primary" id="btnSubmitNew" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            if (values.newEquipment) {
                                                console.log("dddddddddddddddddddddddddddddddddddddddddddddddddd", values)
                                                const thingddcdd: any = { ...values.newEquipment };
                                                const risk = getRisk(thingddcdd.likelihood, thingddcdd.combinedConsequence, values)
                                                thingddcdd.risk = risk;

                                                if (thingddcdd.frmPlannedDate !== '') {

                                                    const myMomentObject = moment(thingddcdd.frmPlannedDate, 'DD-MM-YYYY').toDate()
                                                    //    console.log(value, myMomentObject)


                                                    thingddcdd.plannedDate = myMomentObject;
                                                }
                                                if (thingddcdd.frmCommissionedDate !== '') {

                                                    const myMomentObject = moment(thingddcdd.frmCommissionedDate, 'DD-MM-YYYY').toDate()
                                                    //    console.log(value, myMomentObject)
                                                    thingddcdd.commissionedDate = myMomentObject;
                                                }

                                                let validationOK: boolean = true;
                                                let validationErrors: any[] = [];

                                                validationErrors = validateFormInput(thingddcdd, equipmentFields);
                                                if (validationErrors.length > 0) {
                                                    validationOK = false;
                                                }


                                                if (validationOK) {
                                                    if (values.newEquipment.id === 'new') {
                                                        RequestNewEquipment(addNotification, thingddcdd).then(nSys => {
                                                            const d = [...values.equipments]
                                                            d.push(nSys);
                                                            form.change('equipments', d)
                                                            form.change('newEquipment', undefined)
                                                            form.change('____LOCK', undefined)
                                                            form.change('validationErrors', undefined)
                                                        });
                                                    } else {
                                                        RequestUpdateEquipment(addNotification, thingddcdd).then(nSys => {
                                                            const d = [...values.equipments]
                                                            const index = d.findIndex(dd => dd.id === nSys.id)
                                                            d[index] = nSys;
                                                            form.change('equipments', d)
                                                            form.change('newEquipment', undefined)
                                                            form.change('____LOCK', undefined)
                                                            form.change('validationErrors', undefined)

                                                        });
                                                    }
                                                } else {
                                                    form.change('validationErrors', validationErrors)
                                                }

                                            }
                                        }}>Save</button></div>
                                </> : <> <button className="btn btn-primary" id="btnNew" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    form.change('newEquipment', { id: 'new', installId: values.id, clientId: values.clientId })
                                    form.change('____LOCK', true)
                                }}>New</button>

                                    <DataTable

                                        exportFields={equipmentFields.map(d => { return { field: d.field, name: d.name } })}
                                        /*
materialSpecification	materialGrade	diameter	originalWallThickness


                                        */
                                        exportFileName={'equipment'}
                                        colHeadings={[
                                            { name: "RefId", field: "refId", sortable: false },
                                            {
                                                name: "System", field: "systemId", sortable: false, r: (value: any, index) => {
                                                    return <>{value.system && value.system.refId}</>
                                                }
                                            },
                                            { name: "Name", field: "name", sortable: false },


                                            { name: "Type", field: "typeName", sortable: true },
                                            { name: "Service Fluid", field: "serviceFluidName", sortable: true, queryable: true },
                                            {
                                                name: "Material", field: "materialName", sortable: true,
                                            },

                                            { name: "Consequence", field: "combinedConsequenceAZ", sortable: false },
                                            { name: "Likelihood", field: "likelihoodAZ", sortable: false },
                                            { name: "LOF Combined", field: "LOFCombinedAZ", sortable: false },
                                            { name: "Risk", field: "riskAZ", sortable: false },


                                            // { name: "Description", field: "description", sortable: false },

                                            {
                                                name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                                    return <div className="btn-group">
                                                        <DebugButton data={value} />
                                                        <Link target="_new" className={`btn btn-warning e2e_${index - 1}_directLink`} to={`/installations/${values.id}/equipment/${value.id}`}><FontAwesomeIcon icon={faInfo} /></Link>
                                                        <button className="btn btn-primary" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            form.change('newEquipment', value);
                                                            form.change('____LOCK', true)
                                                        }}>Edit</button>
                                                        <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            const conf = window.confirm('Are you sure you want to do this?')
                                                            if (conf) {
                                                                RequestDeleteEquipment(addNotification, value).then(dd => {
                                                                    const d = [...values.equipments]

                                                                    d.splice(index - 1, 1);
                                                                    form.change('equipments', d)
                                                                }).catch(ex => {
                                                                    console.log(ex);

                                                                })
                                                            }
                                                        }}>Delete</button>
                                                    </div>;
                                                }, sortable: false
                                            },

                                        ]}

                                        data={values.equipments}
                                    />
                                    <Modal size="xl" show={values.equipmentUpload !== undefined ? true : false} onHide={() => {
                                        form.change('equipmentUpload', undefined)
                                    }}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>CSV Import</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <span className="e2ePageEquipmentPopup" style={{ display: 'none' }}>TestOk</span>
                                            <CSVPopupContents values={values} array="equipmentUpload" form={form} targetObject="newPipe" />

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-warning e2eCSVSubmit" type="button" onClick={(ev: React.MouseEvent) => {
                                                ev.preventDefault();
                                                const upload = values.equipmentUpload.filter((some: any) => some.action === true);

                                                console.log(upload)


                                                const d = [...values.equipments]
                                                const uEquipmentUpload: any[] = [];

                                                async.each(upload, (toUpload: any, cb: any) => {
                                                    console.log("toUpload", toUpload.object);


                                                    if (toUpload.isNew) {
                                                        RequestNewEquipment(addNotification, { ...toUpload.object, installId: values.id, clientId: values.clientId, id: 'new' }).then(nSys => {

                                                            d.push(nSys);
                                                            toUpload.response = nSys;
                                                            uEquipmentUpload.push(toUpload)
                                                            cb();
                                                            //      form.change('newEquipment', undefined)
                                                        }).catch(ex => {
                                                            console.log("fouck_________________________________________________________", ex);
                                                            toUpload.exception = "something failed here.";
                                                            uEquipmentUpload.push(toUpload);
                                                            cb();
                                                        });
                                                    } else {
                                                        const OBJECT = { ...toUpload.object }



                                                        OBJECT.id = toUpload.original.id;
                                                        OBJECT._id = toUpload.original._id;
                                                        OBJECT.installId = values.id;
                                                        OBJECT.clientId = values.clientId
                                                        RequestUpdateEquipment(addNotification, OBJECT).then(nSys => {
                                                            console.log("____________________", nSys)
                                                            const index = d.findIndex(dd => dd.id === nSys.id)
                                                            d[index] = nSys;
                                                            toUpload.response = nSys;
                                                            uEquipmentUpload.push(toUpload)


                                                            cb()
                                                        }).catch(ex => {
                                                            console.log("____________________", ex);
                                                            toUpload.exception = "something failed here.";
                                                            uEquipmentUpload.push(toUpload)

                                                            cb()
                                                        });
                                                    }
                                                    //const index = values.equipments.findIndex((ddd:any)=>ddd.id === toUpload.id);

                                                }, (err: any) => {
                                                    console.log("xxxx", d)
                                                    form.change('equipments', d)
                                                    form.change('equipmentUpload', uEquipmentUpload)
                                                });
                                            }}>Action Selected</button>
                                            <Button variant="secondary" className="e2eClose" onClick={() => {
                                                form.change('equipmentUpload', undefined)
                                            }}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {values.equipmentUpload === undefined && <CSVReader
                                        cssClass="csv-reader-input"
                                        label="Upload equipmennt.csv"
                                        onFileLoaded={(dataA: any[]) => {
                                            //equipmentField
                                            parseCSV(dataA, 'equipments', equipmentFields, 'equipmentUpload', values, form, false);
                                        }}
                                        onError={(error: any) => {
                                            console.log(error)
                                        }}
                                        parserOptions={papaparseOptions}
                                        inputId="ObiWan"
                                        inputName="ObiWan"
                                        inputStyle={{ color: 'red' }}
                                    />}
                                </>}

                            </div>
                        }} />
                        <Wizard.Page n="pipe" nt="Pipes" render={(values: any, push: any, pop: any, form: any) => {


                            return <div className="col-12">
                                <span className="e2ePageAdminInstallationPipes" style={{ display: 'none' }}>TestOk</span>
                                <h2>Pipes</h2>
                                {values.id !== 'new' && <Link to={`/installations/${values.id}/pipes`} className="btn btn-warning" target="_blank">Client View</Link>}

                                {values.newPipe ? <>
                                    {values.newPipe.id && <> {values.newPipe.id} <br /><QRCode value={`${SYSTEM_EXTERNAL_URL}installations/tfarm/pipe/${values.newPipe.id}`} size={500} logoImage={`/ocg.png`} /></>}
                                    <InputFields values={values} form={form} array={pipeFields} targetObject="newPipe" inspectionTypes={inspectionTypes} />


                                    <div className="btn-group">
                                        <DebugButton data={values.newPipe} />
                                        <button className="btn btn-primary" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            form.change('newPipe', undefined)
                                            form.change('____LOCK', undefined)
                                            form.change('validationErrors', undefined)
                                        }}>Cancel</button>

                                        <button className="btn btn-primary" id="btnSubmitNew" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            if (values.newPipe) {


                                                const actSend = { ...values.newPipe }
                                                console.log("why u peace of shit", actSend)
                                                let validationOK: boolean = true;
                                                let validationErrors: any[] = [];

                                                validationErrors = validateFormInput(actSend, pipeFields);
                                                if (validationErrors.length > 0) {
                                                    validationOK = false;
                                                }



                                                if (validationOK) {
                                                    if (values.newPipe.id === 'new') {
                                                        RequestNewPipe(addNotification, actSend).then(nSys => {
                                                            const d = [...values.pipes]
                                                            d.push(nSys);
                                                            form.change('pipes', d)
                                                            form.change('newPipe', undefined)
                                                            form.change('____LOCK', undefined)
                                                            form.change('validationErrors', undefined)

                                                        });
                                                    } else {
                                                        RequestUpdatePipe(addNotification, actSend).then(nSys => {
                                                            const d = [...values.pipes]
                                                            const index = d.findIndex(dd => dd.id === nSys.id)
                                                            d[index] = nSys;
                                                            form.change('pipes', d)
                                                            form.change('newPipe', undefined)
                                                            form.change('____LOCK', undefined)
                                                            form.change('validationErrors', undefined)

                                                        });
                                                    }
                                                } else {
                                                    form.change('validationErrors', validationErrors)

                                                }

                                            }
                                        }}>Save</button></div>
                                </> : <> <button className="btn btn-primary" id="btnNew" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    form.change('newPipe', { id: 'new', installId: values.id, clientId: values.clientId })
                                    form.change('____LOCK', true)
                                }}>New</button>
                                    <DataTable

                                        exportFields={pipeFields.map(d => { return { field: d.field, name: d.name } })

                                        }
                                        /*
materialSpecification	materialGrade	diameter	originalWallThickness


                                        */
                                        exportFileName={'pipes'}
                                        colHeadings={[
                                            { name: "ID", field: "refId", sortable: true },
                                            { name: "Name", field: "name", sortable: true },
                                            {
                                                name: "Loop", field: "loopId", sortable: false, r: (value: any, index) => {
                                                    return <>{value.loop && value.loop.refId}</>
                                                }
                                            },
                                            {
                                                name: "System", field: "systemId", sortable: false, r: (value: any, index) => {
                                                    return <>{value.system && value.system.refId}</>
                                                }
                                            },
                                            { name: "Service Fluid", field: "serviceFluidName", sortable: true, queryable: true },
                                            {
                                                name: "Material", field: "materialName", sortable: true,
                                            },
                                            { name: "size", field: "size", sortable: false, queryable: true },
                                            {
                                                name: "From", field: "fromStr", r: (value: any, index, addToQuery) => {
                                                    return <>{value.fromStr}
                                                        {value.fromEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.fromEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                                        {value.fromPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.fromPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                                    </>
                                                }, sortable: false
                                            },
                                            {
                                                name: "To", field: "toStr", r: (value: any, index, addToQuery) => {
                                                    return <>{value.toStr}
                                                        {value.toEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.toEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                                        {value.toPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.toPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}

                                                    </>

                                                }, sortable: false
                                            },
                                            {
                                                name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                                    return <div className="btn-group">
                                                        <DebugButton data={value} />
                                                        <Link target="_new" className={`btn btn-warning e2e_${index - 1}_directLink`} to={`/installations/${values.id}/pipe/${value.id}`}><FontAwesomeIcon icon={faInfo} /></Link>
                                                        <button className="btn btn-primary" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            form.change('newPipe', value);
                                                            form.change('____LOCK', true)
                                                        }}>Edit</button>
                                                        <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            const conf = window.confirm('Are you sure you want to do this?')
                                                            if (conf) {
                                                                RequestDeletePipe(addNotification, value).then(dd => {
                                                                    const d = [...values.pipes]
                                                                    d.splice(index - 1, 1);
                                                                    form.change('pipes', d)
                                                                }).catch(ex => {
                                                                    console.log(ex);

                                                                })
                                                            }
                                                        }}>Delete</button>
                                                    </div>;
                                                }, sortable: false
                                            },
                                        ]}

                                        data={values.pipes}
                                    />

                                    <Modal size="xl" show={values.pipeUpload !== undefined ? true : false} onHide={() => {
                                        form.change('pipeUpload', undefined)
                                    }}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>CSV Import</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <span className="e2ePagePipePopup" style={{ display: 'none' }}>TestOk</span>
                                            <CSVPopupContents values={values} array="pipeUpload" form={form} />

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-warning e2eCSVSubmit" type="button" onClick={(ev: React.MouseEvent) => {
                                                ev.preventDefault();
                                                const upload = values.pipeUpload.filter((some: any) => some.action === true);

                                                //console.log(upload)
                                                const d = [...values.pipes]
                                                const uEquipmentUpload: any[] = [];

                                                async.each(upload, (toUpload: any, cb: any) => {
                                                    console.log("toUpload", toUpload.object);

                                                    if (toUpload.isNew) {
                                                        RequestNewPipe(addNotification, { ...toUpload.object, installId: values.id, clientId: values.clientId, id: 'new' }).then(nSys => {

                                                            d.push(nSys);
                                                            toUpload.response = nSys;
                                                            uEquipmentUpload.push(toUpload)
                                                            cb();
                                                            //      form.change('newEquipment', undefined)
                                                        }).catch(ex => {
                                                            console.log(ex);
                                                            toUpload.exception = "something failed here.";
                                                            uEquipmentUpload.push(toUpload);
                                                            cb();
                                                        });
                                                    } else {
                                                        const OBJECT = { ...toUpload.object }

                                                        OBJECT.id = toUpload.original.id;
                                                        OBJECT._id = toUpload.original._id;
                                                        OBJECT.installId = values.id;
                                                        OBJECT.clientId = values.clientId
                                                        RequestUpdatePipe(addNotification, OBJECT).then(nSys => {
                                                            console.log("____________________", nSys)
                                                            const index = d.findIndex(dd => dd.id === nSys.id)
                                                            d[index] = nSys;
                                                            toUpload.response = nSys;
                                                            uEquipmentUpload.push(toUpload)


                                                            cb()
                                                            //     form.change('newEquipment', undefined)
                                                        }).catch(ex => {
                                                            toUpload.exception = "something failed here.";
                                                            uEquipmentUpload.push(toUpload)
                                                            console.log("____________________", ex);
                                                            cb()
                                                        });
                                                    }
                                                    //const index = values.equipments.findIndex((ddd:any)=>ddd.id === toUpload.id);

                                                }, (err: any) => {
                                                    console.log("starting accociate find", d)
                                                    form.change('pipes', d)
                                                    const u2EquipUpload: any[] = [];
                                                    async.each(uEquipmentUpload, (toUpload: any, cb: any) => {
                                                        console.log("toAccociate", toUpload.object);

                                                        const response = toUpload.response;
                                                        if (response !== undefined) {
                                                            const currentFromPipeId = response.fromPipeId;
                                                            const currentFromEquipmentId = response.fromEquipmentId;
                                                            const currentToPipeId = response.toPipeId;
                                                            // const currentFromPipeId = response.fromEquipmentId;
                                                            // const currentToPipeId = response.toEquipmentId;
                                                            const fromStr = response.fromStr;
                                                            const toStr = response.toStr;

                                                            response.fromPipeId = undefined;
                                                            response.fromEquipmentId = undefined;
                                                            response.toPipeId = undefined;
                                                            response.toEquipmentId = undefined;

                                                            const equipIndexF = values.equipments.findIndex((dd: any) => dd.refId === fromStr)
                                                            if (equipIndexF !== -1) {
                                                                console.log("found equip from", equipIndexF)
                                                                response.fromEquipmentId = values.equipments[equipIndexF].id;


                                                            } else {
                                                                const pipeIndexF = values.pipes.findIndex((dd: any) => dd.refId === fromStr)
                                                                if (pipeIndexF !== -1) {
                                                                    console.log("found pupe] from", pipeIndexF)
                                                                    response.fromPipeId = values.pipes[pipeIndexF].id;
                                                                }
                                                            }


                                                            console.log("from resule ", fromStr, response.fromPipeId, response.fromEquipmentId)
                                                            console.log("to", toStr);

                                                            const equipIndexT = values.equipments.findIndex((dd: any) => dd.refId === toStr)
                                                            if (equipIndexT !== -1) {
                                                                console.log("found equip to", values.equipments[equipIndexT].id)
                                                                response.toEquipmentId = values.equipments[equipIndexT].id;


                                                            } else {
                                                                const pipeIndexT = values.pipes.findIndex((dd: any) => dd.refId === toStr)
                                                                if (pipeIndexT !== -1) {
                                                                    console.log("found pipe to", values.pipes[pipeIndexT].id)
                                                                    response.toPipeId = values.pipes[pipeIndexT].id;
                                                                }
                                                            }


                                                            console.log("to resule ", toStr, response.toPipeId, response.toEquipmentId)



                                                            RequestUpdatePipe(addNotification, response).then(nSys => {
                                                                console.log("____________________", nSys)
                                                                const index = d.findIndex(dd => dd.id === nSys.id)
                                                                d[index] = nSys;
                                                                toUpload.response = nSys;
                                                                u2EquipUpload.push(toUpload)


                                                                cb()
                                                                //     form.change('newEquipment', undefined)
                                                            }).catch(ex => {
                                                                toUpload.exception = "something failed here.";
                                                                u2EquipUpload.push(toUpload)
                                                                console.log("____________________", ex);
                                                                cb()
                                                            });

                                                        } else {
                                                            cb();
                                                        }



                                                        //const index = values.equipments.findIndex((ddd:any)=>ddd.id === toUpload.id);

                                                    }, (err: any) => {
                                                        console.log("xxxx", d)


                                                        //additional step to do from / to





                                                        form.change('pipes', d)
                                                        form.change('pipeUpload', u2EquipUpload)
                                                    });

                                                    //additional step to do from / to





                                                    // form.change('pipes', d)
                                                    // form.change('pipeUpload', uEquipmentUpload)
                                                });
                                            }}>Action Selected</button>
                                            <Button variant="secondary" className="e2eClose" onClick={() => {
                                                form.change('pipeUpload', undefined)
                                            }}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {values.pipeUpload === undefined && <CSVReader
                                        cssClass="csv-reader-input"
                                        label="Upload pipe.csv"
                                        onFileLoaded={(dataA: any[]) => {

                                            //equipmentField

                                            parseCSV(dataA, 'pipes', pipeFields, 'pipeUpload', values, form, true);

                                        }}
                                        onError={(error: any) => {
                                            console.log(error)
                                        }}
                                        parserOptions={papaparseOptions}
                                        inputId="ObiWan"
                                        inputName="ObiWan"
                                        inputStyle={{ color: 'red' }}
                                    />}
                                </>}

                            </div>
                        }} />
                    </Wizard>
                </div>
            </div>

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminInstallation;
