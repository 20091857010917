import arrayMutators from "final-form-arrays";
import PropTypes from "prop-types";
import React, { createRef } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Form } from "react-final-form";
import DebugButton from "./DebugButton";

interface IWizardProps {

  initialValues: any;
  onPageChange?(data: any): void;
  onSubmit(data: any): void;

  menuBottom?: boolean;
  pageMenu?: boolean;
  startIndex?: number;
}
interface IWizardState {
  values: any;
  page: number;
  pageMenu: boolean;
  menuBottom: boolean;
  startIndex: number;
  loading: boolean;
}
class Wizard extends React.Component<IWizardProps, IWizardState> {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  public values: any;
  public form: any;
  static Page = ({ children }: any) => children;
  public myRef: any;

  constructor(props: any) {
    super(props);
    this.myRef = createRef();
    let menuBottom: boolean = true;
    if (props.menuBottom !== undefined) {
      menuBottom = props.menuBottom;
    }
    let pageMenu: boolean = false;
    if (props.pageMenu !== undefined) {
      pageMenu = props.pageMenu;
    }
    let startIndex: number = 0;
    if (props.startIndex !== undefined) {
      startIndex = props.startIndex;
    }
    this.state = {
      loading: false,
      page: startIndex,
      pageMenu: pageMenu,
      menuBottom: menuBottom,
      startIndex: startIndex,
      values: props.initialValues || {},
    };
  }

  next = (values: any, goto?: string) => {

    if (values.____LOCK === true) {
      alert("please finish what you are doing first.")
    } else {
      if (goto === 'SUBMIT_NOW') {
        this.props.onSubmit(values);
      } else {
        const children = React.Children.toArray(this.props.children);
        console.log("Wizard:next", values, goto, children);
        if (children !== undefined && children !== null) {
          if (goto !== undefined) {
            let ind: number;
            if (goto === null) {
              ind = this.state.page
            } else {
              ind = children.findIndex((something: any) => {
                return something.props.n === goto;
              });
            }

            //  console.log(ind);
            this.props.onPageChange && this.props.onPageChange({ page: ind, values: values })
            this.setState((state: any) => ({
              loading: false,
              page: ind,
              values,
            }));
            if (this.myRef !== null) this.myRef.current.scrollIntoView()
          } else {
            this.props.onPageChange && this.props.onPageChange({ page: this.state.page + 1, values: values })
            this.setState((state: any) => ({
              page: Math.min(state.page + 1, children.length - 1),
              loading: false,
              values,
            }));
            if (this.myRef !== null && this.myRef.current !== null) this.myRef.current.scrollIntoView()
          }
        }
      }
    }


  };

  gotoPage = (goto: string) => {
    if (this.values.____LOCK === true) {
      alert("please finish what you are doing first.")
    } else {
      const children = React.Children.toArray(this.props.children);
      const ind = children.findIndex((something: any) => {
        return something.props.n === goto;
      });
      this.setState((state: any) => ({
        page: ind,
      }));
      if (this.myRef !== null) this.myRef.current.scrollIntoView()
    }
  }
  previous = () => {
    if (this.values.____LOCK === true) {
      alert("please finish what you are doing first.")
    } else {
      this.setState((state: any) => ({
        page: Math.max(state.page - 1, 0),
      }));
      this.props.onPageChange && this.props.onPageChange({ page: this.state.page - 1 })
      if (this.myRef !== null) this.myRef.current.scrollIntoView()
    }
  }
  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values: any) => {
    const activePage: any = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    if (activePage !== undefined && activePage !== null) {
      return activePage.props.validate ? activePage.props.validate(values) : {};
    }
  };

  handleSubmit = (values: any) => {
    //  console.log("Wizard:handelSubmit", values.SUBMITNOW);
    const activePage: any = React.Children.toArray(this.props.children)[
      this.state.page
    ];

    if (activePage !== undefined && activePage !== null) {
      // if theres a custom on submit
      if (activePage.props.onSubmit !== undefined) {
        this.setState({ loading: true });
        activePage.props.onSubmit(values, (valuesS: any, goto?: string) => {
          values = valuesS;



          const { children, onSubmit } = this.props;
          const { page } = this.state;
          let isLastPage = page === React.Children.count(children) - 1;


          if (isLastPage) {
            return onSubmit(values);
          } else {
            this.next(values, goto);
          }
        });
      } else {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        let isLastPage = page === React.Children.count(children) - 1;
        if (values.service === "numberPort") {
          isLastPage = page === React.Children.count(children) - 2;
        }

        if (isLastPage) {
          return onSubmit(values);
        } else {
          this.next(values);
        }
      }
    }
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage: any = React.Children.toArray(children)[page];
    //  console.log(activePage);
    let r: any = null;
    if (activePage.props.render) {
      r = activePage.props.render;
    }

    let isLastPage = page === React.Children.count(children) - 1;


    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({
          handleSubmit,
          form: {
            mutators: { push, pop },
          }, // injected from final-form-arrays above
          pristine,
          form,
          submitting,
          values,
          errors,
        }) => {
          this.values = { ...values };
          this.form = form;
          const something: any[] = React.Children.toArray(this.props.children).map((u: any) => {
            //  console.log(u);
            let n: any = {};
            if (u !== null && u !== undefined && u.props !== undefined) {
              if (u.props.n !== undefined) {
                n.n = u.props.n
              }
              if (u.props.nt !== undefined) {
                n.nt = u.props.nt
              }
            }

            return n;
          })
          //    console.log(something)




          return (
            <form onSubmit={handleSubmit}>
              <div ref={this.myRef}>



                {this.state.loading === true ? <>Loading..</> : <>
                  {this.state.menuBottom === false && <><div className="d-flex flex-rowd-flex justify-content-between">
                    {page > 0 ? (
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={this.previous}
                      >
                        « Previous
                      </button>
                    ) : (
                      <div></div>
                    )}
                    {this.state.pageMenu === true ? (<ButtonGroup>{something.map((i: any, index: number) => {



                      if (index !== this.state.page) {
                        return (<Button key={index} variant="outline-primary" onClick={(e: any) => {
                          e.preventDefault();
                          this.gotoPage(i.n) // gotopage
                        }}>{i.nt}</Button>)
                      } else {
                        return (<Button key={index} variant="secondary">{i.nt}</Button>)
                      }

                    }
                    )}</ButtonGroup>) : (<></>)}
                    {!isLastPage ? (
                      <button className="btn btn-success e2eWizardNext" id="e2eWizardNext" type="submit">
                        Next »
                      </button>
                    ) : (
                      <button
                        className="btn btn-success e2eWizardSubmit e2eWizardNext"
                        type="submit" id="e2eWizardNext"
                        disabled={submitting}
                      >
                        Submit
                      </button>
                    )}
                  </div></>}
                  <DebugButton data={values} />
                  {r !== null ? <>{r(values, push, pop, form, this, errors)}</> : <>{activePage}</>}

                  {this.state.menuBottom === true && <><div className="d-flex flex-rowd-flex justify-content-between">
                    {page > 0 ? (
                      <button
                        type="button"
                        className="btn btn-success "
                        onClick={this.previous}
                      >
                        « Previous
                      </button>
                    ) : (
                      <div></div>
                    )}
                    {this.state.pageMenu === true ? (<ButtonGroup>{something.map((i: any, index: number) => {



                      if (index !== this.state.page) {
                        return (<Button key={index} variant="outline-primary" onClick={(e: any) => {
                          e.preventDefault();
                          //   this.gotoPage(i.n) // gotopage
                        }}>{i.nt}</Button>)
                      } else {
                        return (<Button key={index} variant="secondary">{i.nt}</Button>)
                      }

                    }
                    )}</ButtonGroup>) : (<></>)}
                    {!isLastPage ? (
                      <button className="btn btn-success e2eWizardNext" id="e2eWizardNext" type="submit">
                        Next »
                      </button>
                    ) : (
                      <button
                        className="btn btn-success e2eWizardNext" id="e2eWizardNext"
                        type="submit"
                        disabled={submitting}
                      >
                        Submit
                      </button>
                    )}
                  </div></>}



                </>}


              </div>
            </form>
          )
        }}
      </Form>
    );
  }
}

export default Wizard;
