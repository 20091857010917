import { IPipe } from "../interface/pipe";
import axiosInstance from "./instance";

export const RequestGetPipes = (addNotification: any, data?: any): Promise<IPipe[]> => {
    return new Promise<IPipe[]>((resolve, reject) => {
        let url = `/pipes/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetPipe = (addNotification: any, id: string): Promise<IPipe> => {
    return new Promise<IPipe>((resolve, reject) => {

        axiosInstance(addNotification).get(`/pipes/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewPipe = (addNotification: any, thing: IPipe): Promise<IPipe> => {
    return new Promise<IPipe>((resolve, reject) => {

        axiosInstance(addNotification).post(`/pipes/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeletePipe = (addNotification: any, thing: IPipe): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/pipes/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdatePipe = (addNotification: any, user: IPipe): Promise<IPipe> => {
    return new Promise<IPipe>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/pipes/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestPipeFunction = (addNotification: any, func: string, id: string): Promise<any> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/pipes/_func/${func}/${id}`;

        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
