import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { RequestGetInspectionTypes } from '../../api/inspection-type-requests';
import DataTable from '../../components/system/DataTable';
import DateDisplay from '../../components/system/DateDisplay';
import DebugButton from '../../components/system/DebugButton';
import Loading from '../../components/system/loading';
import { useNotifications } from '../../context/notifications';
import { IEquipment } from '../../interface/equipment';
import { IInspectionType } from '../../interface/inspection-type';
import { IInstallation } from '../../interface/installation';
import { IInstallationLoop } from '../../interface/installation-loop';



interface IISchedule {
    data: IInstallation,
    loops: IInstallationLoop[];
    equipment: IEquipment[];
}
interface IISYear {
    year: number;
    loops: any[];
}
const InstalaltionInspectionSchedule = ({ data, loops, equipment }: IISchedule) => {
    const detailDisplayTable = false;
    const [inspectionTypes, setInspectionTypes] = useState<IInspectionType[]>();
    const { addNotification } = useNotifications()!;
    const [startedLoading, setStartedLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        console.log("get reports");
        // const params: any = match.params;
        //console.log("get report",);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {
            // const report = (): Promise<any> => {
            if (startedLoading === false) {
                setStartedLoading(true)
                RequestGetInspectionTypes(addNotification).then(iTypes => {
                    setInspectionTypes(iTypes)
                    setLoading(false);
                })

            }



        }
    });


    const [search, setSearch] = useState<any>({ loopId: '*', equipmentId: '*', typeId: '*' })

    const onSearch = (data: any) => {
        setSearch(data);
    }


    if (loading) {
        return <Loading />
    } else {


        let things: any[] = [];
        // equipment.map((loop, loopIndex) => {
        //     console.log(loop)
        //     return loop.inspectionSchedule && loop.inspectionSchedule.map((sthing, sthingindex) => {
        //         things.push({ refId: loop.refId, systemId: loop.systemId, system: loop.system, risk: loop.risk, riskAZ: loop.riskAZ, id: loop.id, name: loop.name, ttype: 'equipment', ...sthing });
        //     })
        // })



        loops.map((loop, loopIndex) => {
            console.log("loop", loop.id, loop.inspectionSchedule)
            return loop.inspectionSchedule && loop.inspectionSchedule.map && loop.inspectionSchedule.map((sthing, sthingindex) => {
                //  console.log("YOU COCK SUCKER", sthing)


                const aThing: any = { ...sthing, installId: data.id };
                aThing.ttype = 'loop';
                if (aThing.isDeferred === true) {
                    const startTime = moment(aThing.inspectionDue);
                    const endTime = moment(aThing.deferredDate);
                    const duration = moment.duration(endTime.diff(startTime));
                    // inspectionType.defMonths = duration.asMonths();
                    aThing.deferralMonths = duration.asMonths();
                }




                things.push({
                    ...aThing
                });
            })
        })


        equipment.map((loop, loopIndex) => {
            console.log("loop", loop.id, loop.inspectionSchedule)
            return loop.inspectionSchedule && loop.inspectionSchedule.map && loop.inspectionSchedule.map((sthing, sthingindex) => {
                console.log("JOY", sthing)


                const aThing: any = { ...sthing, installId: data.id };
                aThing.ttype = 'equipment';
                if (aThing.isDeferred === true) {
                    const startTime = moment(aThing.inspectionDue);
                    const endTime = moment(aThing.deferredDate);
                    const duration = moment.duration(endTime.diff(startTime));
                    // inspectionType.defMonths = duration.asMonths();
                    aThing.deferralMonths = duration.asMonths();
                }




                things.push({

                    ...aThing
                });
            })
        });

        things = things.filter((inspect, index) => {
            //console.log(inspect, search)
            if (!search) return true
            if (search.typeId === '*') return true;
            if (search.typeId === inspect.type) return true;
        });
        things = things.filter((inspect, index) => {
            //    console.log(inspect, search)
            if (!search) return true
            if (search.loopId === '*') return true;
            if (search.loopId === inspect.thingId) return true;
        });

        things = things.filter((inspect, index) => {
            //      console.log(inspect, search)
            if (!search) return true
            if (search.equipmentId === '*') return true;
            if (search.equipmentId === inspect.thingId) return true;
        });
        things.sort((one, two) => (new Date(one.inspectionDue) > new Date(two.inspectionDue) ? -1 : 1));
        const thisYear = new Date(Date.now()).getFullYear() - 100;
        const maxYear = new Date(Date.now()).getFullYear() + 100;
        console.log({ thisYear, maxYear })
        let years: IISYear[] = [];

        for (let index = thisYear; index < maxYear + 1; index++) {

            const year = index;

            const pipesx = things.filter(pipe => {
                //    console.log(pipe, pipe.inspectionDue, new Date(pipe.inspectionDue).getFullYear(), year)
                if (pipe.inspectionYear === year) {
                    return pipe
                }
            });
            // console.log(pipesx)
            if (pipesx.length > 0) {
                console.log("__________________________-", pipesx)
                console.log(year, pipesx)
                years.push({ year: year, loops: pipesx })
            }

        }

        return <div><Form
            onSubmit={onSearch}
            initialValues={search}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
                //do if altered browse notifcation
                //console.log(submitting, pristine)

                return <form onSubmit={handleSubmit}>
                    <div className="input-group">

                        <Field name={`typeId`}>
                            {({ input, meta }) => (
                                <div>

                                    <select className="form-control" {...input}>
                                        <option value="*">Type</option>
                                        {inspectionTypes && inspectionTypes.map((inst, inde) => {
                                            return <option value={inst.id} key={inde}>{inst.name}</option>
                                        })}
                                    </select>
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>
                        <Field name={`loopId`}>
                            {({ input, meta }) => (
                                <div>

                                    <select className="form-control" {...input}>
                                        <option value="*">Loop</option>
                                        {loops && loops.map((inst, inde) => {
                                            return <option value={inst.id} key={inde}>{inst.name}</option>
                                        })}
                                    </select>
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>

                        <Field name={`equipmentId`}>
                            {({ input, meta }) => (
                                <div>

                                    <select className="form-control" {...input}>
                                        <option value="*">Equipment</option>
                                        {equipment && equipment.map((inst, inde) => {
                                            return <option value={inst.id} key={inde}>{inst.name}</option>
                                        })}
                                    </select>
                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                </div>
                            )}
                        </Field>

                        <button className="btn btn-primary" type="submit">Refine <FontAwesomeIcon icon={faSearch} /></button>
                        {(search.loopId !== '*' || search.equipmentId !== '*' || search.typeId !== '*') && <button className="btn btn-danger" type="button" onClick={() => {
                            setSearch({ loopId: '*', equipmentId: '*', typeId: '*' })
                        }}>Reset</button>}
                    </div>
                </form>
            }} />
            <div className="row">

                {/* {things && things.map(thing => {
                return <>{JSON.stringify(thing)}<hr /></>
            })}
            {things.length} */}
                {years.map((year, yIndex) => {
                    return <div key={yIndex} className="col-12"><h2>{year.year}</h2>

                        <DebugButton data={year} />

                        <DataTable

                            exportFileName={`${year.year}-inspection-schedule`}
                            hideIndexCol={true}
                            colHeadings={[
                                // { width: "5%", name: "ID", field: "refId", sortable: true },
                                { name: "Inspection", width: "10%", field: "type", sortable: true, queryable: true },
                                { width: "5%", name: "Name", field: "name", sortable: true },
                                { width: "5%", name: "Months", field: "inspectionMonths", sortable: true },
                                {
                                    width: "5%", name: "Confidence", field: "inspectedConfidence", sortable: true, r: (value: any, index) => {
                                        return <>
                                            {value.inspectedConfidence === 0 && <>Low</>}
                                            {value.inspectedConfidence === 1 && <>Medium</>}
                                            {value.inspectedConfidence === 2 && <>High</>}
                                        </>;
                                    }
                                },









                                {
                                    name: "Last Inspection", width: "10%", field: "systemId", r: (value: any, index) => {
                                        return <>{value.lastInspected ? <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/inspection/${value.lastInspected.inspectionId}`}><FontAwesomeIcon icon={faSearch} /></Link> : <>No Inspection</>}</>;
                                    }, sortable: true
                                },
                                {
                                    name: "Deferral Info", width: "10%", field: "isDeferred", r: (value: any, index) => {
                                        return <>{value.isDeferred === true ? <>Deferred to: <DateDisplay format="YYYY-MM-DD" date={value.deferredDate} /> ({value.deferralMonths.toFixed(0)} months)</> : 'Not Deferred'}</>;
                                    }, sortable: true
                                },

                                // {name: "Inspection Type", width: "10%", field: "type", sortable: true, queryable: true },
                                //

                                // {width: "10%", name: "Risk", field: "riskAZ", sortable: true },

                                {
                                    name: "Last Inspected", field: "id", r: (value: any, index) => {
                                        return <div>
                                            {value.lastInspected && value.lastInspected.date && <DateDisplay format="YYYY-MM-DD" date={value.lastInspected.date} />}
                                        </div>;
                                    }, sortable: false
                                },
                                {
                                    name: "Inspection Scheduled", field: "id", r: (value: any, index) => {
                                        return <div>
                                            {value.inspectionDue && <DateDisplay format="YYYY-MM-DD" date={value.inspectionDue} />}
                                        </div>;
                                    }, sortable: false
                                },

                                {
                                    name: "Actions", align: "right", width: "10%", field: "id", r: (value: any, index) => {
                                        return <div className="btn-group">
                                            <DebugButton data={value} />
                                            {value.ttype === 'loop' && <Link className="btn btn-primary" to={`/installations/${value.installId}/loop/${value.id}`}>Loop Details</Link>}
                                            {value.ttype === 'equipment' && <Link className="btn btn-primary" to={`/installations/${value.installId}/equipment/${value.id}`}>Equipment Details</Link>}
                                        </div>;
                                    }, sortable: true
                                },

                            ]}

                            data={year.loops}
                        />

                    </div>;
                })}

            </div>
        </div>;
    }
}

export default InstalaltionInspectionSchedule;