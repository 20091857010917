import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestGetSession, RequestNewSession, RequestUpdateSession } from "../../../api/session-requests";
import DebugButton from "../../../components/system/DebugButton";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { ELogType, ESeverity } from "../../../interface/log";
import { ISession } from "../../../interface/session";



interface IAdminSessionProp {
    //   types: IProductCategory[];
    //   manufacturers: IManufacturer[];
}
const AdminSession = ({ }: IAdminSessionProp) => {
    let location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<ISession>();
    const { addNotification } = useNotifications()!;

    //const [stores, setSessions] = useState<ISession[]>();



    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/sessions/:id");

    useEffect(() => {
        return function cleanup() {
            console.log("cleanup", loading, thing)
            setLoading(false);
            setThing(undefined);
        }
    }, []);

    useEffect(() => {
        try {
            const id = match.params.id;

            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                const prodCall = () => {
                    return RequestGetSession(addNotification, id).then(thing => {
                        setThing(thing);
                    })
                }

                Promise.allSettled([prodCall()]).then(() => {
                    setLoading(false)
                });


                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Session - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            let ppp: Promise<ISession>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Session Created!';
                ppp = RequestNewSession(addNotification, data);
            } else {
                notificationText = 'Session Saved!';
                ppp = RequestUpdateSession(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
            })
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Session - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        {redirect && <Redirect to="../sessions" />}
        {loading === false && thing && <>


            {/*<!-- OPTIONAL 1 -->*/}
            <Form
                onSubmit={onSubmit}
                initialValues={thing}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />
                                <Field name="name">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                {/* 

                                <Field name="id">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>ID</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>


                                <Field name="clientId">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Client</label>
                                            <select className="form-control" {...input} disabled={values.id !== 'new'}>
                                                <option></option>
                                                {clients && clients.map((client, index) => {
                                                    return <option key={index} value={client.id}>{client.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>*/}
                                <Field name="description">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Description</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>

                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </form>

                }} />

            {/*<!-- OPTIONAL END -->*/}
        </>}

    </>
    );
}

export default AdminSession;