import { faArrowDown, faArrowUp, faChevronCircleDown, faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormApi } from "final-form";
import { Field } from "react-final-form";
import { useNotifications } from "../../context/notifications";

interface IMultiSelectCreate {
    data: any[] | undefined;
    setData?: (d: any) => void;
    saveData?: (addNotification: any, d: any) => Promise<any>;
    title: string;
    description?: string;
    fieldName: string;
    form: FormApi<any, Partial<any>>;
    values: any;
    enableAdd?: boolean
}
export const MultiSelectCreate: React.FC<IMultiSelectCreate> = ({ data, title, fieldName, values, saveData, setData, form, enableAdd, description, children }) => {

    const { addNotification } = useNotifications()!;
    //  const { auth } = useAuth()!;
    //const titleShort = title.substring(0, title.length - 1);
    const thingTMPIndex = `${fieldName}TMP`;
    const thingORDERIndex = `${fieldName}ORDER`;
    const thingSearch = `${fieldName}SEARCH`;
    const NEWTHING = () => {
        if (data) {

            const dClone = [...data];

            const thingName = values[thingSearch].toUpperCase();


            //check if exsts already

            const thingFindIndex = data.findIndex(d => d.name.toLowerCase() === thingName.toLowerCase())
            if (thingFindIndex === -1) {
                const thing: any = { id: 'new', name: thingName };
                saveData && saveData(addNotification, thing).then((newData: any) => {
                    console.log("thingName", thingName, newData, values[fieldName])
                    dClone.push(newData)
                    setData && setData(dClone)

                    let something: string[]

                    if (values[fieldName] !== undefined) {
                        something = [...values[fieldName]];
                    } else {
                        something = []
                    }

                    something.push(newData.id);
                    form.change(thingSearch, '')
                    setTimeout(() => form.change(fieldName, something), 20)


                }).catch(ex => {
                    console.log("ex", ex)
                })
            } else {
                alert(`Already Exists`);
                form.change(thingSearch, '')
            }

        }

    }
    const all = (values[thingTMPIndex] === true ? true : false);
    const order = (values[thingORDERIndex] === true ? true : false);
    const filter = (value: any) => {

        //  console.log(values)


        if (all === true) {
            return value;
        }
        const search = values[thingSearch]

        if (value.id) {
            const fieldData = values[fieldName];

            if (fieldData && fieldData.indexOf(value.id) !== -1) {
                return value
            }
        }

        if (search && search.length >= 0) {
            const uppercase = value.name.toUpperCase()
            const searchcase = search.toUpperCase();


            if (uppercase.indexOf(searchcase) !== -1) {
                return value;
            }

            // if (value.name.toUpperCase().contains(search.toUpperCase())) {
            //     return value;
            // }

        }


    }
    if (data) {
        data = data.filter(filter)
    }

    return <div>
        <label>{title}</label>
        {children && <>{children}</>}
        {description !== undefined && <p className="text-secondary">{description}</p>}
        <div className="row">
            <div className="row">
                <div className="col-12">
                    <Field name={`${fieldName}SEARCH`}>
                        {({ input, meta }) => (
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <button type="button" className="btn btn-outline-primary" onClick={() => {
                                        form.change(thingTMPIndex, !all)
                                    }}>{all === true ? <FontAwesomeIcon icon={faChevronCircleUp} /> : <FontAwesomeIcon icon={faChevronCircleDown} />}</button>
                                    <input type="text" className="form-control" {...input} placeholder="Refine" />
                                    {enableAdd === true && <button type="button" className="btn btn-outline-primary" onClick={() => {
                                        NEWTHING();
                                    }}>Add {title}</button>}
                                </div>

                            </div>


                        )}
                    </Field>
                </div>


                {data && data.length > 0 && <div className="row">
                    <div className="col-10 text-left">
                        {data && data

                            .sort((a, b) => {

                                if (order === true) {
                                    return b.name.localeCompare(a.name)
                                } else {
                                    return a.name.localeCompare(b.name)
                                }
                            })
                            .map(d => {

                                return <div className="col-12" key={`${fieldName}${d.id}`}><Field
                                    name={fieldName}
                                    component="input"
                                    type="checkbox"
                                    value={d.id}
                                />{' '}{d.name}</div>
                            })}
                    </div>
                    <div className="col-2">
                        {data && data.length > 1 && <button type="button" className="btn btn-outline-primary" onClick={() => {
                            form.change(thingORDERIndex, !order)
                        }}>{order === true ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</button>}
                    </div>
                </div>}


            </div>

        </div>
    </div >;
}
export default MultiSelectCreate