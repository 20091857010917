import { faFileDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { downloadFile } from "../../api/file-download-request";
import UploadFiles from '../../components/system/UploadFiles';
import { ENotificationType } from "../../context/notifications";
import { IOtherAssignments } from "../../interface/_modelAll";



export const filesAttach = (name: string, idFileIds: string, values: any, otherAssignments: IOtherAssignments[], addNotification: ((title: string, payload: string, type: ENotificationType) => void) | undefined, form: any) => {
    return <div className="col-6">
        <h2>Additional Files</h2>
        {name}
        <FieldArray name={name}>

            {({ fields }) => (
                <div>

                    {fields.map((name, index) => {
                        return <>


                            {values.files && values.files[index] && <>
                                <span className="spanFileName">{values.files[index].fileName}</span>
                                <button className="btn btn-primary" type="button" onClick={() => {
                                    if (values.files) {
                                        values.files[index] && downloadFile(addNotification, values.files[index]);
                                    }
                                }}><FontAwesomeIcon icon={faFileDownload} /></button>

                                <button className="btn btn-danger" type="button" onClick={() => {
                                    fields.remove(index);
                                }}><FontAwesomeIcon icon={faTrash} /></button>
                                <br /></>}
                        </>;
                    })}



                    <Field name={`additionalFile`} otherAssignments={otherAssignments} component={UploadFiles} onChanged={(event: any) => {
                        //console.log("ev", evemt)
                        console.log("DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDW", event);
                        let fileanomalyCreateIds: string[] = [];
                        if (values.fileIds !== undefined) {
                            fileanomalyCreateIds = [...values.fileIds];
                        }


                        fileanomalyCreateIds.push(event.id);


                        form.change(`${idFileIds}`, fileanomalyCreateIds)
                        fields.push(event);
                    }} />
                </div>
            )}
        </FieldArray>

    </div>;
}


