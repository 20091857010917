import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Helmet } from "react-helmet";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { RequestDeleteInspection, RequestGetInspections } from '../../../api/inspection-requests';
import { RequestGetInstallations } from "../../../api/installation-requests";
import { RequestNewLog } from "../../../api/log-requests";
import DataTable from "../../../components/system/DataTable";
import DateDisplay from "../../../components/system/DateDisplay";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { usePageTitle } from "../../../context/pageTitle";
import { IInspection } from "../../../interface/inspection";
import { IInstallation } from "../../../interface/installation";
import { ELogType, ESeverity } from "../../../interface/log";
import AdminInspection from './Admin.Inspection';


const AdminInspectionsPge = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IInspection[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;


    useEffect(() => {
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react stores onunload'
        });
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }
    const [installs, setInstalls] = useState<IInstallation[]>()
    const [search, setSearch] = useState<any>()

    const onSearch = (data: any) => {
        setSearch(data);
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        try {
            if (loading === true) {
                // eslint-disable-next-line
                {/*<!-- OPTIONAL 2 -->*/ }
                RequestGetInspections(addNotification).then(thing => {
                    //   console.log(stores)
                    setThings(thing);
                    RequestGetInstallations(addNotification).then(thing => {
                        //   console.log(stores)
                        setInstalls(thing);
                        setLoading(false);
                    })
                })
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Inspections - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading, addNotification]);

    const onDelete = (value: IInspection) => {
        try {

            const conf = window.confirm("Delete?");
            if (conf) {
                setLoading(true);
                RequestDeleteInspection(addNotification, value).then(res => {
                    //  console.log(res);
                    let thingsD: IInspection[] = [];
                    if (things) {
                        thingsD = [...things];
                    }
                    const index = thingsD.findIndex(thing => thing._id === value._id);
                    thingsD.splice(index, 1);

                    setThings(thingsD);
                    setLoading(false);
                    addNotification && addNotification('Success', 'Inspection Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Inspections - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    const { setPageTitle } = usePageTitle()!;

    const location = useLocation();

    useEffect(() => {
        setPageTitle && setPageTitle('Retriever / Inspections', 'admin');
    }, [location])



    return (<>
        {loading === true && <Loading />}
        {things && <div className="col-12">
            <span className="e2eAdminInspections" style={{ display: 'none' }}>TestOk</span>
            <Helmet>
                <title>Inspections / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>


            {/*<!-- OPTIONAL 3 -->*/}
            <div className="d-flex justify-content-between">
                <Link className="btn btn-success" to="./inspections/new">New</Link>

                <Form
                    onSubmit={onSearch}
                    initialValues={search}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        //do if altered browse notifcation
                        //console.log(submitting, pristine)

                        return <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <Field name={`installId`}>
                                    {({ input, meta }) => (
                                        <div>

                                            <select className="form-control" {...input}>
                                                <option>*</option>
                                                {installs && installs.map((inst, inde) => {
                                                    return <option value={inst.id} key={inde}>{inst.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <button className="btn btn-primary" type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                            </div>
                        </form>
                    }} />
            </div>

            <DataTable


                colHeadings={[
                    { name: "Subject", field: "subject", sortable: true },
                    {
                        name: "Client", field: "client", sortable: false, r: (value: any, index) => {
                            return <>{value.client && value.client.name}</>
                        }
                    }, {
                        name: "Installation", field: "installId", sortable: true, r: (value: any, index) => {
                            return <>{value.installation && value.installation.name}</>
                        }
                    },
                    {
                        name: "Date", field: "dateOfReport", r: (value: any, index) => {
                            return <DateDisplay date={value.dateOfReport} />;
                        }, sortable: true
                    },
                    {
                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} />
                                <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    onDelete(value);
                                }}>Delete</button>
                            </div>;
                        }, sortable: false
                    },

                ]}

                data={things.filter((inspect, index) => {
                    if (!search) return true
                    if (search.installId === '*') return true;
                    if (search.installId === inspect.installId) return true;
                })}
            />

            {/*<!-- OPTIONAL END -->*/}
        </div>}
    </>
    );
};
const AdminInspections = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminInspection />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminInspectionsPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminInspections;