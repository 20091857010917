import React, { useState } from "react";
import ImageMapper from 'react-img-mapper';
import { Map } from "react-img-mapper/dist/types";
import { IImage } from "../../interface/image";
interface IDImage {
    image: IImage;
    className?: string;
    onClick?: (tag: any) => void;

}
//https://www.npmjs.com/package/react-img-mapper
const ImageDisplay: React.FunctionComponent<IDImage> = props => {
    const call = (area: any, index: number) => {
        props.onClick && props.onClick(props.image.mapRegions[index]);
    }
    const [map, setMap] = useState<Map>({
        name: 'my-map',
        // GET JSON FROM BELOW URL AS AN EXAMPLE
        areas: [],
    })
    const [dimensions, setDimensions] = useState<any>({ h: 0, w: 0 });

    const onLoaded = (imageRef: any, parentDimensions: any) => {
        console.log(parentDimensions)
        setDimensions(parentDimensions)
        setMap({
            ...map, areas: props.image.mapRegions.map((region: any, rIndex: number) => {
                //region.pixelX, 

                region.pixelH = region.h * parentDimensions.height;
                region.pixelW = region.w * parentDimensions.width;

                region.pixelX = region.x * parentDimensions.width;
                region.pixelY = region.y * parentDimensions.height;

                let area: any = { id: rIndex.toString(), shape: 'rect', coords: [], active: true, disabled: false, href: '', fillColor: `${region.color}25`, strokeColor: 'black', lineWidth: 4, preFillColor: '' }
                area.coords.push(region.pixelX)
                area.coords.push(region.pixelY)
                area.coords.push(region.pixelX + region.pixelW)
                area.coords.push(region.pixelY + region.pixelH)
                return area
            })
        })
    }
    let src = '';

    if (props.image && props.image.c1600) {
        src = props.image.c1600
    }
    //    console.log(image);
    if (props.image && props.image.croppedSrcData) {
        src = props.image.croppedSrcData;
    }

    return <>
        {props.image !== undefined && <>
            {props.image.mapRegions && props.image.mapRegions.length > 0 ? <>

                {/* <DataTable 
                    colHeadings={[
                        { name: "Type", field: "cls", sortable: false },
                    { name: "Type", field: "type", sortable: false },
                    { name: "X", field: "x", sortable: false },
                    { name: "Y", field: "y", sortable: false },
                    { name: "W", field: "w", sortable: false },
                    { name: "H", field: "h", sortable: false },
                    
                    { name: "pixelX", field: "pixelX", sortable: false },
                    { name: "pixelY", field: "pixelY", sortable: false },
                    { name: "pixelW", field: "pixelW", sortable: false },
                    { name: "pixelH", field: "pixelH", sortable: false },
                    {
                        name: "Actions",  align:"right", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} />
                              
                            </div>;
                        }, sortable: false
                    },

                ]}
                
                data={props.image.mapRegions}
            /> */}
                {props.image.c1600 && <ImageMapper

                    src={props.image.c1600}
                    onLoad={onLoaded}
                    map={map}
                    onClick={call}

                />}

            </> : <img className={`${props.className ? props.className : 'img-fluid w-100'}`} src={props.image.c800} alt={props.image.name} />}
        </>}


    </>
};


export default ImageDisplay