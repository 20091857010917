import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ComingSoon = () => {

    return <div className="jumbotron col-12">
        <FontAwesomeIcon icon={faCogs} size="5x" />
        <h3>Coming Soon</h3>
    </div>;
};

export default ComingSoon;