import { useEffect, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { IImage } from "../../interface/image";


interface Cropy {
    image: IImage;
    asset: any;
    onCropped: (crop: any) => void
}

const Croppy = (params: Cropy) => {
    const [crop, setCrop] = useState<Crop>()
    // const imgRef = useRef<HTMLImageElement>(null)
    useEffect(() => {
        if (params.asset.data) {
            setCrop(params.asset.data)
        } else {
            setCrop({})
        }
    }, [params.asset])

    const src = params.image.croppedSrcData ? params.image.croppedSrcData : params.image.c1600

    return <div>{src && <><ReactCrop

        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        src={src}

    >

    </ReactCrop>
        <br />
        {crop && <button type="button" className="btn btn-primary" onClick={() => {
            params.onCropped(crop)
        }}>Apply Crop</button>}</>}</div>
}


export default Croppy;