import { faEdit, faFile, faPaperclip, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestGetAssignment, RequestNewAssignment, RequestUpdateAssignment } from "../../../api/assignment-requests";
import { RequestGetClients } from "../../../api/client-requests";
import { downloadFile } from "../../../api/file-download-request";
import { RequestNewLog } from "../../../api/log-requests";
import ListViewX from "../../../components/ListView";
import DataTable from "../../../components/system/DataTable";
import DebugButton from "../../../components/system/DebugButton";
import EBoundary from "../../../components/system/EBoundary";
import Loading from "../../../components/system/loading";
import Wizard from "../../../components/system/Wizard";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { usePageTitle } from "../../../context/pageTitle";
import { EAssignmentSate, IAssignment } from "../../../interface/assignment";
import { IClient } from "../../../interface/client";
import { IImpurity } from "../../../interface/impurity";
import { ELogType, ESeverity } from "../../../interface/log";
import { IMechanism } from "../../../interface/mechanism";
import { findThing } from "../../ClientReports/Report";
import AdminReportForm from "./Admin.ReportForm";
import PageOne from "./page-one";




const arrayMax = (arr: number[]) => {
    if (arr.length === 0) {
        return 1;
    }
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}


let f = (a: any, b: any) => [].concat(...a.map((a: any) => b.map((b: any) => [].concat(a, b))));
const cartesian: any = (a: any, b: any, ...c: any) => b ? cartesian(f(a, b), ...c) : a;





const AdminAssignment = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [startedLoading, setStartedLoading] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IAssignment>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    const [showSubForm, setShowSubForm] = useState<boolean>(false);
    const [subformBuildData, setSubformBuildData] = useState({});
    const [subformData, setSubformData] = useState({});
    const [clients, setClients] = useState<IClient[]>();
    const [wizardPage, setWizardPage] = useState<number>(0);
    // const [subRep, setSubRep] = useState<ISubReport>();
    // const [opsModesA, setOpsMode] = useState<string[]>();

    const [mechanismsA, setMechanisms] = useState<IMechanism[]>();
    const [impuritiesA, setImpurities] = useState<IImpurity[]>();

    const [mechanismsCA, setMechanismsC] = useState<string[]>();


    const wizardRef = useRef<any>()
    const formRef = useRef<any>()


    {/*<!-- OPTIONAL END -->*/ }
    const [assignmentId, setAssignmentId] = useState<string>('');
    const match: any = useRouteMatch("/admin/assignments/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }
    const { setPageTitle } = usePageTitle()!;


    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);

            const load = async () => {
                setStartedLoading(true)

                const clients = await RequestGetClients(addNotification)
                setClients(clients);

                //   const mechanisms = await RequestGetMechanisms(addNotification);






                if (id === 'new') {
                    setThing({ parentId: '', revision: 1, state: EAssignmentSate.Draft, clientId: '', id: 'new', name: '', description: '', assets: [], assetsDetailed: [], modesDetailed: [], modes: [], impurities: [], damageMechanisms: [], riskMatrix: [], forms: [], additional: [] })

                    setLoading(false)
                    setPageTitle && setPageTitle('New DRA', 'admin');

                } else {

                    const parsed = queryString.parse(location.search);
                    console.log("parsed", parsed);
                    const aThing = await RequestGetAssignment(addNotification, id);
                    // const impurities = await RequestGetImpuritys(addNotification);
                    if (aThing !== undefined && aThing.client !== undefined && aThing.client.impurities) { setImpurities(aThing.client.impurities) }

                    if (aThing !== undefined && aThing.client !== undefined && aThing.client.damageMechanisms) {
                        setMechanisms(aThing.client.damageMechanisms);
                        let ddd = aThing.client.damageMechanisms.map(dd => {
                            return dd.category;
                        })

                        ddd = ddd.filter((item, index) => ddd.indexOf(item) === index);
                        setMechanismsC(ddd)
                    }



                    if (parsed.duplicate === 'true') {
                        aThing.parentId = aThing.id;
                        aThing.revision = aThing.revision + 1
                        aThing.id = 'new';
                        delete aThing._id;
                    }
                    console.log("parsed", aThing);

                    setThing(aThing);
                    setLoading(false)
                    aThing && setPageTitle && setPageTitle('DRA ' + aThing.name, 'admin');

                }
            }


            if (loading === true && startedLoading === false) {
                load()
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Assignment - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any, final?: boolean) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IAssignment>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Assignment Created!';
                ppp = RequestNewAssignment(addNotification, data);
            } else {
                notificationText = 'Assignment Saved!';
                ppp = RequestUpdateAssignment(addNotification, data);
            }

            ppp.then(values => {
                console.log(values);

                // setThing(values);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Assignment - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }


    return (<div>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="/admin/assignments" />}

        {(loading === false && thing && thing !== null) ? <>
            <Helmet>
                <title>Assignment / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 3 -->*/}
            <EBoundary title="assignment-form">
                <Wizard
                    startIndex={wizardPage}
                    ref={wizardRef}
                    initialValues={thing}
                    onSubmit={(data: any) => {
                        console.log("onSubmit", data);
                        onSubmit(data, true);
                    }}
                    onPageChange={(data: any) => {
                        console.log("onPageChange", data);
                        setWizardPage(data.page);
                        //  onSave(data.values);
                    }}>

                    <Wizard.Page onSubmit={(values: any, next: any) => {
                        const newD: any = { ...values };
                        values.riskMatrix = [];
                        values.loading = false;
                        values.showCurrent = true;
                        values.showMatrix = false;

                        if (values.id === 'new') {
                            RequestNewAssignment(addNotification, newD).then(installation => {
                                values = { ...values, ...installation };

                                next(values)
                            })


                        } else {

                            RequestUpdateAssignment(addNotification, newD).then(installation => {
                                values = { ...values, ...installation };
                                next(values)
                            })
                        }
                    }} validate={(values: any) => {
                        console.log("Validate", values)
                        const errored: any = {};
                        const impuritiesEnabled: boolean = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.impuritiesEnabled ? values.client.draOverrides.impuritiesEnabled : false);
                        const additionalEnabled: boolean = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.additionalEnabled ? values.client.draOverrides.additionalEnabled : false);

                        if (!(values.assetsDetailed.length >= 1)) {
                            errored.assetsDetailed = 'Must select one or more'
                        } else {
                            //make sure all have a name

                            //  const bullshit = values.assetsDetailed.filter((d: any) => d.name === '')
                            // console.log(bullshit)
                            // if (bullshit.length > 0) {
                            //     errored['assetsDetailed'] = 'all names must be filled in'
                            // }
                        }
                        if (!(values.modesDetailed.length >= 1)) {
                            errored.modesDetailed = 'Must select one or more'
                        } else {
                            //make sure all have a name

                            // for (let index = 0; index < values.modesDetailed.length; index++) {
                            //     const element = values.modesDetailed[index];
                            //     if (element.name === '') {
                            //         errored.modesDetailed = 'all names must be filled in'
                            //     }
                            // }
                        }

                        if (!(values.damageMechanisms.length >= 1)) {
                            errored.damageMechanisms = 'Must select one or more'
                        }
                        if (impuritiesEnabled === true) {
                            if (!(values.impurities.length >= 1)) {
                                errored.impurities = 'Must select one or more'
                            }
                        }

                        if (additionalEnabled === true) {
                            if (!(values.additional.length >= 1)) {
                                errored.additional = 'Must select one or more'
                            } else {
                                //make sure all have a name

                                // for (let index = 0; index < values.additional.length; index++) {
                                //     const element = values.additional[index];
                                //     if (element.name === '') {
                                //         errored.additional = 'all names must be filled in'
                                //     }
                                // }
                            }
                        }



                        return errored
                    }}
                        render={(values: any, push: any, pop: any, form: any, formThis: any, errors: any) => {
                            return <PageOne values={values} form={form} clients={clients} impuritiesA={impuritiesA} mechanismsA={mechanismsA} mechanismsCA={mechanismsCA} errors={errors} setImp={(imp) => {
                                setImpurities(imp)
                            }} setDm={(dm) => {
                                setMechanisms(dm);
                                let ddd = dm.map(dd => {
                                    return dd.category;
                                })

                                ddd = ddd.filter((item, index) => ddd.indexOf(item) === index);
                                setMechanismsC(ddd)
                            }} />;
                        }} />

                    <Wizard.Page render={(values: any, push: any, pop: any, form: any) => {

                        const assetsTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.assets ? values.client.draOverrides.assets : 'Assets');
                        const impuritiesTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.impurities ? values.client.draOverrides.impurities : 'Impurities');
                        const damageMechanismsTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.damageMechanisms ? values.client.draOverrides.damageMechanisms : 'Damage Mechanisms');
                        const operatingModesTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.operatingModes ? values.client.draOverrides.operatingModes : 'Operating Modes');
                        const impuritiesEnabled: boolean = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.impuritiesEnabled ? values.client.draOverrides.impuritiesEnabled : false);
                        const additionalTitle: string = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.operatingModes ? values.client.draOverrides.additional : 'Additional');
                        const additionalEnabled: boolean = (values !== undefined && values !== null && values.client !== undefined && values.client.draOverrides && values.client.draOverrides.additionalEnabled ? values.client.draOverrides.additionalEnabled : false);


                        const callMatrixCheckAsync = async () => {
                            console.log("callMatrixCheckAsync");
                            const assets = values.assetsDetailed.map((asset: any) => asset.id);
                            const modes = values.modesDetailed.map((mode: any) => mode.id);
                            values.riskMatrix = [];
                            let output: any;
                            if (impuritiesEnabled === false) {
                                output = cartesian(modes, assets, values.damageMechanisms.map((dm: any) => dm));
                            } else {
                                if (additionalEnabled === true) {
                                    output = cartesian(modes, assets, values.damageMechanisms.map((dm: any) => dm), values.impurities.map((imp: any) => imp), values.additional.map((mode: any) => mode.id));
                                } else {
                                    output = cartesian(modes, assets, values.damageMechanisms.map((dm: any) => dm), values.impurities.map((imp: any) => imp));
                                }
                            }

                            // console.log(output);
                            const forms = values.forms;

                            const matrix = output.map((out: any) => {

                                let index

                                let thing: any
                                if (impuritiesEnabled === false) {
                                    thing = { mode: out[0], asset: out[1], mechanism: out[2] }
                                    index = forms.findIndex((form: any) => (
                                        form.AssetSection.indexOf(thing.asset) !== -1)
                                        && form.OperatingMode.indexOf(thing.mode) !== -1
                                        && form.DamageMechanism.indexOf(thing.mechanism) !== -1
                                    )
                                } else {
                                    if (additionalEnabled === true) {
                                        thing = { mode: out[0], asset: out[1], mechanism: out[2], impurity: out[3], additional: out[4] }
                                        index = forms.findIndex((form: any) => (
                                            form.AssetSection.indexOf(thing.asset) !== -1)
                                            && form.Impurities.indexOf(thing.impurity) !== -1
                                            && form.OperatingMode.indexOf(thing.mode) !== -1
                                            && form.DamageMechanism.indexOf(thing.mechanism) !== -1
                                            && form.Additional.indexOf(thing.additional) !== -1
                                        )
                                    } else {
                                        thing = { mode: out[0], asset: out[1], mechanism: out[2], impurity: out[3] }
                                        index = forms.findIndex((form: any) => (
                                            form.AssetSection.indexOf(thing.asset) !== -1)
                                            && form.Impurities.indexOf(thing.impurity) !== -1
                                            && form.OperatingMode.indexOf(thing.mode) !== -1
                                            && form.DamageMechanism.indexOf(thing.mechanism) !== -1
                                        )
                                    }

                                }

                                console.log(index)
                                if (index >= 0) {
                                    thing.ok = true
                                } else {
                                    thing.ok = false
                                }


                                return thing;
                            })

                            form.change('riskMatrix', matrix);


                        }

                        const riskMatrixCols: any[] = [{
                            name: "MaKE", field: "OK", sortable: false, r: (value: any, index: number) => {
                                return <div className="btn-group">
                                    <DebugButton data={value} />
                                    <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        formRef.current.scrollIntoView()
                                        setSubformData({
                                            AssetSection: [
                                                value.asset
                                            ],
                                            OperatingMode: [
                                                value.mode
                                            ],
                                            Impurities: [
                                                value.impurity
                                            ],
                                            DamageMechanism: [
                                                value.mechanism
                                            ],
                                            Additional: [
                                                value.additional
                                            ]
                                        });
                                        setShowSubForm(true);


                                    }}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        //  formRef.current.scrollIntoView()
                                        let thing: any = { ...subformBuildData };
                                        if (thing.AssetSection) {
                                            if (thing.AssetSection.findIndex((dd: string) => dd === value.asset) === -1) {
                                                thing.AssetSection.push(value.asset)
                                            }

                                        } else {
                                            thing.AssetSection = [value.asset]
                                        }
                                        if (thing.OperatingMode) {
                                            if (thing.OperatingMode.findIndex((dd: string) => dd === value.mode) === -1) {
                                                thing.OperatingMode.push(value.mode)
                                            }
                                        } else {
                                            thing.OperatingMode = [value.mode]
                                        }
                                        if (thing.Impurities) {
                                            if (thing.Impurities.findIndex((dd: string) => dd === value.impurity) === -1) {
                                                thing.Impurities.push(value.impurity)
                                            }
                                        } else {
                                            thing.Impurities = [value.impurity]
                                        }
                                        if (thing.DamageMechanism) {
                                            if (thing.DamageMechanism.findIndex((dd: string) => dd === value.mechanism) === -1) {
                                                thing.DamageMechanism.push(value.mechanism)
                                            }
                                        } else {
                                            thing.DamageMechanism = [value.mechanism]
                                        }
                                        setSubformBuildData(thing);
                                        setShowSubForm(true);
                                    }}>
                                        <FontAwesomeIcon icon={faPaperclip} />
                                    </button>
                                </div>
                            }
                        },
                        {
                            name: assetsTitle, field: "asset", sortable: true, queryable: true, r: (value: any, index: number) => {
                                return <span>{values.assetsDetailed && values.assetsDetailed.find((d: any) => d.id === value.asset).name}</span>;
                            }
                        },
                        {
                            name: operatingModesTitle, field: "mode", sortable: true, queryable: true, r: (value: any, index: number) => {
                                return <span>{values.modesDetailed && values.modesDetailed.find((d: any) => d.id === value.mode).name}</span>;
                            }
                        },
                        { name: damageMechanismsTitle, field: "mechanism", sortable: true, queryable: true },];

                        if (impuritiesEnabled === true) {
                            riskMatrixCols.push({ name: impuritiesTitle, field: "impurity", sortable: true, queryable: true })
                        }
                        if (additionalEnabled === true) {
                            riskMatrixCols.push({
                                name: additionalTitle, field: "additional", sortable: true, queryable: true, r: (value: any, index: number) => {
                                    return <span>{values.additional && values.additional.find((d: any) => d.id === value.additional).name}</span>;
                                }
                            })
                        }
                        riskMatrixCols.push({ name: "Check", field: "ok", sortable: true, queryable: true, r: (value: any, index: number) => { return <>{JSON.stringify(value.ok)}</> } });



                        const formColumbs: any[] = [{
                            name: "Record", field: "Record", isButtonField: true, sortable: false, r: (value: any, index: number) => {
                                return <>
                                    {value.___index}<br />

                                    <div className="btn-group">
                                        <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            formRef.current.scrollIntoView()
                                            setSubformData(value);
                                            setShowSubForm(true);


                                        }}><FontAwesomeIcon icon={faEdit} /></button>

                                        <button type="button" className="btn btn-warning" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            //props.deleteFn && props.deleteFn(val)
                                            let forms: any[] = [];
                                            if (wizardRef.current.values.forms) {
                                                forms = [...wizardRef.current.values.forms]
                                            }

                                            const index = forms.findIndex(dd => dd.____index === value.____index);
                                            forms.splice(index, 1)
                                            form.change('forms', forms)

                                        }}><FontAwesomeIcon icon={faTrash} /></button>
                                    </div>



                                </>;
                            }
                        },
                        {
                            name: assetsTitle, field: "AssetSection", sortable: false, r: (value: any, index: number) => {
                                return <>{values.assetsDetailed && value.AssetSection.map((asset: any, aIndex: number) => {
                                    return <span key={aIndex}>{findThing(asset, values.assetsDetailed)}<br /></span>;
                                })}
                                </>;
                            }
                        },
                        {
                            name: operatingModesTitle, field: "OperatingMode", sortable: false, r: (value: any, index: number) => {
                                return <>{values.modesDetailed && value.OperatingMode.map((mode: any, mIndex: number) => {
                                    return <span key={mIndex}>{findThing(mode, values.modesDetailed)}<br /></span>;
                                })}</>;

                            }
                        },];


                        if (impuritiesEnabled === true) {
                            formColumbs.push({
                                name: impuritiesTitle, field: "Impurities", sortable: false, r: (value: any, index: number) => {
                                    return <ListViewX list={value.Impurities} />;
                                }
                            });

                        }
                        if (additionalEnabled === true) {
                            formColumbs.push({
                                name: additionalTitle, field: "Additional", sortable: false, r: (value: any, index: number) => {
                                    return <>{values.additional && value.Additional.map((mode: any, mIndex: number) => {
                                        return <span key={mIndex}>{findThing(mode, values.additional)}<br /></span>;
                                    })}</>;
                                }
                            });
                        }
                        formColumbs.push({
                            name: damageMechanismsTitle, field: "DamageMechanism", sortable: false, r: (value: any, index: number) => {
                                return <ListViewX list={value._DM} />;
                            }
                        });


                        formColumbs.push(
                            { name: "Existing Safeguards", field: "ExistingSafeguards", sortable: false, queryable: false },
                            { name: "Risk Description", field: "RiskDescription", sortable: false, queryable: false },
                            { name: "Quant Method", field: "QuantMethod", sortable: true, queryable: true },
                            { name: "Severity", field: "Severity", sortable: true, queryable: true },
                            { name: "Likelihood", field: "Likelihood", sortable: true, queryable: true },
                            { name: "Risk", field: "Risk", sortable: true, queryable: true },
                            { name: "Res Severity", field: "ResSeverity", sortable: true, queryable: true },
                            { name: "Res Likelihood", field: "ResLikelihood", sortable: true, queryable: true },
                            { name: "Res Risk", field: "ResRisk", sortable: true, queryable: true },
                            { name: "Specific Actions", field: "SpecificActions", sortable: true, queryable: true },

                        )


                        return <>
                            {values !== undefined && values.loading === true ? <>Loading</> : <div data-testid="matrix">
                                {values.riskMatrix && values.riskMatrix.length > 0 && <>

                                    <h2>To complete Matrix (<span data-testid={`riskMatrix_complete`}>{values.riskMatrix.filter((ddd: any) => ddd.ok === true).length}</span> / <span data-testid={`riskMatrix_toComplete`}>{values.riskMatrix.length}</span>)</h2>

                                    {values.showMatrix === true && <>

                                        {Object.keys(subformBuildData).length > 0 && <>
                                            <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                                ev.preventDefault();
                                                formRef.current.scrollIntoView()
                                                setSubformData(subformBuildData);
                                                setSubformBuildData({});
                                                setShowSubForm(true);
                                            }}><FontAwesomeIcon icon={faEdit} /></button>
                                        </>}
                                        <DataTable exportFileName={`riskMatrix`} colHeadings={riskMatrixCols} data={values.riskMatrix} />

                                    </>}





                                </>}


                                {values.showCurrent === true && <>

                                    <DataTable
                                        enablePrint={true}
                                        exportFileName={`risks`}
                                        rowExpanded={(value: any, index) => {
                                            return <div className="col-12">

                                                {value.files && value.files.map((file: any, index: number) => (<div key={`sdjkhfsdkjhf${index}`}>
                                                    <div key={index} className="col-4">

                                                        <FontAwesomeIcon size="5x" className="w-50" icon={faFile} /><br />
                                                        {file.fileName}
                                                        <br />
                                                        <button className="btn btn-success" type="button" onClick={(ev: React.MouseEvent) => {
                                                            downloadFile(addNotification, file);
                                                        }}>Download</button>
                                                    </div>
                                                </div>))}
                                            </div>
                                        }}
                                        hideIndexCol={true} colHeadings={formColumbs} data={values.forms} />

                                    <br />
                                    {/* <ReportTable assignment={values} /> */}


                                </>}

                                <div className="btn-group">
                                    {values.riskMatrix && values.riskMatrix.length > 0 && <button type="button" className="btn btn-primary" onClick={(ev) => {
                                        form.change('showMatrix', !values.showMatrix);
                                    }}>{!values.showMatrix ? 'Show' : 'Hide '} Risk Matrix</button>}
                                    <button className="btn btn-secondary" type="button" onClick={(ev) => {
                                        form.change('showCurrent', !values.showCurrent);
                                    }}>{!values.showCurrent ? 'Show' : 'Hide '}  Risks</button>
                                    <button data-testid="check-matrix" className="btn btn-primary" type="button" onClick={(ev => {

                                        callMatrixCheckAsync();
                                    })}>Check Matrix!</button>
                                </div>
                                {values.riskMatrix && values.riskMatrix.length > 0 && <>

                                    <h2>To complete Matrix ({values.riskMatrix.filter((ddd: any) => ddd.ok === true).length} / {values.riskMatrix.length})</h2>
                                </>}

                            </div>}

                        </>;
                    }} onSubmit={(values: any, next: any) => {
                        let goto: any = undefined;

                        next(values, goto);
                    }} />

                </Wizard>
            </EBoundary>


            {(wizardPage === 1 || wizardPage === 2) && <div className="col-12" ref={formRef}>
                {wizardPage}
                <EBoundary title="add-risk-boundary">
                    {wizardRef.current !== null && wizardRef.current.values.assets &&
                        wizardRef.current.values.modes &&
                        wizardRef.current.values.impurities &&
                        wizardRef.current.values.damageMechanisms &&
                        mechanismsA !== undefined
                        && <>
                            <button data-testid="add-risk" type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); setShowSubForm(true); setSubformData({}) }}>Add Risk</button>
                            <AdminReportForm assignment={wizardRef.current.values} assignmentId={assignmentId} visible={showSubForm} data={subformData} addSubForm={(d) => {
                                console.log(d)
                                wizardRef.current.form.change('loading', true)
                                let forms: any[] = [];
                                if (wizardRef.current.values.forms) {
                                    forms = [...wizardRef.current.values.forms]
                                }


                                if (d.____index) {
                                    // const index = forms.findIndex(dd => dd.____index === d.____index);
                                    forms[(d.____index - 1)] = d;
                                } else {
                                    const ids: number[] = forms.map(ss => parseInt(ss.____index, 10));
                                    let number: number = arrayMax(ids);
                                    number++;
                                    d.____index = number;
                                    forms.push(d);
                                }


                                //form.change('subForm', { aproved: false, Risk: 0, "RiskDescription": '', Likelihood: 0, MitigationMeasures: '', SpecificActions: '', "AssetSection": [], "OperatingMode": [], "Impurities": [], "DamageMechanism": [], id: 'new', reportId: '', ExistingSafeguards: '', ResLikelihood: 0, ResRisk: 0, ResSeverity: 0, QuantMethod: '', Severity: 0, })
                                wizardRef.current.form.change('forms', forms)

                                setTimeout(() => {
                                    wizardRef.current.form.change('loading', false)
                                }, 20);


                                setSubformData({});
                                setShowSubForm(!showSubForm)

                            }}
                                additionalA={wizardRef.current.values.additional}
                                assetsA={wizardRef.current.values.assetsDetailed}
                                opsModesA={wizardRef.current.values.modesDetailed}
                                impuritiesA={wizardRef.current.values.impurities}
                                damageMechanismsA={wizardRef.current.values.damageMechanisms}
                                possibleDamageMechanisms={mechanismsA} />
                        </>}
                </EBoundary></div>}
            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </div>
    );
}

export default AdminAssignment;
