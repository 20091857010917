import arrayMutators from "final-form-arrays";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Helmet } from "react-helmet";
import { Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestGetDrawing, RequestNewDrawing, RequestUpdateDrawing } from "../../../api/drawing-requests";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestGetPipes } from "../../../api/pipe-requests";
import DownshiftInput from "../../../components/FormStuff/downshiftInput";
import { composeValidators, required } from "../../../components/FormStuff/validators";
import DebugButton from "../../../components/system/DebugButton";
import ImageDisplay from "../../../components/system/ImageDisplay";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { IDrawing } from "../../../interface/drawing";
import { ELogType, ESeverity } from "../../../interface/log";
import { IPipe } from "../../../interface/pipe";


const AdminDrawing = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IDrawing>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    const [pipes, setPipes] = useState<IPipe[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/drawings/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
                    setThing({ id: 'new', name: '', description: '', imageId: '', classify: [], installationId: '', pipeId: '', equipmentId: '' })
                    Promise.allSettled([]).then(() => {
                        setLoading(false)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetDrawing(addNotification, id).then(thing => {
                            setThing(thing);
                            setLoading(false);

                            RequestGetPipes(addNotification, { installId: thing.installationId }).then(pipes => {
                                setPipes(pipes)
                            })
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Drawing - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IDrawing>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Drawing Created!';
                ppp = RequestNewDrawing(addNotification, data);
            } else {
                notificationText = 'Drawing Saved!';
                ppp = RequestUpdateDrawing(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Drawing - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../drawings" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Drawing / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}
            {thing.image && <><ImageDisplay image={thing.image} />{thing.image.name}</>}
            <Form <IDrawing>
                onSubmit={onSubmit}
                initialValues={thing}


                mutators={{
                    ...arrayMutators,
                    setValue: ([field, value], state, { changeValue }) => {
                        console.log(state, field, value)
                        changeValue(state, field, () => value)
                    }
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    //do if altered browse notifcation
                    //  console.log(submitting, pristine)
                    setFormChanged(!pristine)
                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />
                                <Field name="name" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>

                                {values.pipeId === '' ? <> {pipes && <Field
                                    name={`pipeId`}
                                    items={pipes}
                                    component={DownshiftInput}
                                    placeholder="Pipe Id"
                                    keys={['refId', 'fluidId']}
                                    label="Find Pipe"
                                />}</> : <><button type="button" className="btn btn-warning" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    form.change('pipeId', '');

                                }}>Reset Pipe</button></>}

                                <Field name="description">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Description</label>
                                            <textarea type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>

                                <FieldArray name="classify">
                                    {({ fields }) => (<>
                                        <div className="row">
                                            {fields.map((name, index) => (
                                                <div key={name} className="col-6">
                                                    <Field name={`${name}.txt`}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>{values.classify[index].id}</label>

                                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                {values.classify[index].orig && <span className="bg-primary">  Original:{values.classify[index].orig}</span>}
                                                            </div>
                                                        )}
                                                    </Field>



                                                </div>
                                            ))}
                                        </div>
                                    </>)}
                                </FieldArray>


                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </form>

                }} />

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminDrawing;