import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactForm from './ContactForm';
const Contact = () => {
    return (
        <>
            <br></br>
            <h1 className="pull-left">Get in touch</h1>
            <p className="pull-left">You are important to us so we would love to hear back from you, please use the form below to leave us a message and we will get back to you as soon as possible </p>
            <p> Don't be shy, you obviously want to contact us back , so use the form below</p>
            <p>Your needs is our business, please use the form blow to get in touch with us</p> <br></br>

            <div className="row grid-divider">
                <div className="col-6">
                    <h2 className="pull-left">Contact Form</h2>
                    <ContactForm />
                </div>
                <div className="col-6">

                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">... or alternatively you can contqct us by</div>
                            <div className="card-text">
                                <p className="pull-left"><FontAwesomeIcon icon={faEnvelope} size="5x" /></p>
                                <p className="pull-left"><FontAwesomeIcon icon={faPhone} size="5x" /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;