import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import ReactImageAnnotate from "react-image-annotate";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestGetImage, RequestNewImage, RequestUpdateImage } from "../../../api/image-requests";
import { RequestNewLog } from "../../../api/log-requests";
import DebugButton from "../../../components/system/DebugButton";
import ImageDisplay from "../../../components/system/ImageDisplay";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { IImage } from "../../../interface/image";
import { ELogType, ESeverity } from "../../../interface/log";



interface IAdminImageProp {
    //   types: IProductCategory[];
    //   manufacturers: IManufacturer[];
}
const AdminImage = ({ }: IAdminImageProp) => {
    let location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IImage>();
    const { addNotification } = useNotifications()!;

    //const [stores, setImages] = useState<IImage[]>();



    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/images/:id");

    useEffect(() => {
        return function cleanup() {
            console.log("cleanup", loading, thing)
            setLoading(false);
            setThing(undefined);
        }
    }, []);

    useEffect(() => {
        try {
            const id = match.params.id;

            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
                    setThing({ requestId: '', page: 0, tag: '', mapMeta: {}, mapRegions: [], otherAssignments: [], id: 'new', name: '', description: '', ourl: '', url: '', path: '', originalPath: '', assigned: false, height: 0, width: 0 })
                    Promise.allSettled([]).then(() => {
                        setLoading(false)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetImage(addNotification, id).then(thing => {
                            setThing(thing);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Image - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            let ppp: Promise<IImage>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Image Created!';
                ppp = RequestNewImage(addNotification, data);
            } else {
                notificationText = 'Image Saved!';
                ppp = RequestUpdateImage(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
            })
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Image - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        {redirect && <Redirect to="../images" />}
        {loading === false && thing && <>


            {/*<!-- OPTIONAL 1 -->*/}



            <Form
                onSubmit={onSubmit}
                initialValues={thing}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />
                                <Field name="name">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                {/* 

                                <Field name="id">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>ID</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>


                                <Field name="clientId">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Client</label>
                                            <select className="form-control" {...input} disabled={values.id !== 'new'}>
                                                <option></option>
                                                {clients && clients.map((client, index) => {
                                                    return <option key={index} value={client.id}>{client.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>*/}
                                <Field name="description">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Description</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>

                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </form>

                }} />
            <div className="row">
                <div className="col-6">
                    {thing.url && <ImageDisplay image={thing} />}
                </div>
                <div className="col-6">
                    {thing.url && <ReactImageAnnotate

                        selectedImage={thing.url}
                        taskDescription="tag things to be linked."
                        images={[{ src: thing.url, name: "Image 1", regions: thing.mapRegions }]}
                        regionClsList={["Dog", "Cat", "Mouse"]}
                        enabledTools={["create-box"]}
                        onExit={(dd: any) => {
                            const image = dd.images[0];
                            console.log(image.pixelSize);
                            const width = image.pixelSize.w;
                            const height = image.pixelSize.h;

                            const regions = image.regions.map((reg: any) => {
                                let region = { ...reg }

                                region.pixelH = reg.h * height;
                                region.pixelW = reg.w * width;

                                region.pixelX = reg.x * width;
                                region.pixelY = reg.y * height;

                                console.log(region);
                                return region;

                            })
                            const newThing = { ...thing, mapMeta: image, mapRegions: regions };

                            RequestUpdateImage(addNotification, newThing).then(ddd => {
                                RequestGetImage(addNotification, thing.id).then(image => {
                                    setLoading(true);
                                    setThing(thing);
                                })
                            })
                        }} />}
                </div>
            </div>



            {/*<!-- OPTIONAL END -->*/}
        </>}

    </>
    );
}

export default AdminImage;