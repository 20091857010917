import { faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { IImage } from "../../interface/image";
interface IPopupImage {
  data: IImage;
  alwaysDisplay?: boolean
}
const PopupImage = ({ data, alwaysDisplay }: IPopupImage) => {
  const [show, setShow] = useState(false);
  const [big, setBig] = useState(false);
  const handleClose = () => setShow(false);
  return (<>

    <button type="button" onClick={(ev: React.MouseEvent) => {
      ev.preventDefault();
      setShow(true);
    }} className="btn btn-success"><FontAwesomeIcon icon={faExpandArrowsAlt} /></button>

    <Modal size="xl" show={show} onHide={handleClose}>

      <Modal.Body>
        {big === true ? <div className="imageViewer"><img src={data.c1600} /></div> : <div className="imageViewer"><img src={data.c400} /></div>}
        <button type="button" onClick={(ev: React.MouseEvent) => {
          ev.preventDefault();
          setBig(!big);
        }} className="btn btn-success"><FontAwesomeIcon icon={faExpandArrowsAlt} /></button>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </>);


}

export default PopupImage;