

export const loopFields = [
    { field: 'id', name: 'Unique ID', type: 'id' },
    { field: 'name', name: 'Loop Name', required: true, type: 'text' },
    { field: 'description', name: 'description', type: 'text' },
    { field: 'refId', name: 'Loop Reference (refId)', type: 'text', required: true },
    { field: 'system_refId', name: 'System reference', required: true, type: 'system', targetField: 'systemId' },
    { field: 'likelihoodOverrideAZ', name: 'Risk Likelihood (Override)', type: 'AZ_LIKE', targetField: 'likelihoodOverride' },
    { field: 'combinedConsequenceAZ', name: 'Risk Consequence', required: true, type: 'AZ_CC', targetField: 'combinedConsequence' },
    { field: 'inspectionTypeIdsStr', name: 'inspectionTypeIdsStr', type: 'inspection-types', targetField: 'inspectionTypeIds' },
    { field: 'materialId', name: 'materialId', type: 'matterial', required: true, },
    { field: 'corrosionAllowance', name: 'corrosionAllowance', type: 'number' },
    { field: 'designCode', name: 'designCode', type: 'text' },
    { field: 'serviceFluidId', name: 'serviceFluidId', required: true, type: 'fluid' },
    { field: 'frmCommissionedDate', name: 'Commission Date', type: 'date', required: true, targetField: 'commissionedDate' },
    { field: 'externalCoating', name: 'externalCoating', type: 'text' },
    { field: 'insulation', name: 'insulation', type: 'boolean' },
    { field: 'itaIntCo2LOF', name: 'CO2 LOF', type: 'LOF', targetField: 'itaIntCo2LOF', required: true },
    { field: 'itaIntCo2Comments', name: 'Co2Comments', type: 'text' },
    { field: 'itaIntPWCLOF', name: 'PWC LOF', type: 'LOF', targetField: 'itaIntPWCLOF', required: true },
    { field: 'itaIntPWCComments', name: 'PWC Comments', type: 'text' },
    { field: 'itaIntErosionLOF', name: 'Erosion LOF', type: 'LOF', targetField: 'itaIntErosionLOF', required: true },
    { field: 'itaIntErosionComments', name: 'Erosion Comments', type: 'text' },
    { field: 'itaIntCreviceCorrosionLOF', name: 'Crevice Corrosion LOF', type: 'LOF', targetField: 'itaIntCreviceCorrosionLOF', required: true },
    { field: 'itaIntCreviceCorrosionComments', name: 'Crevice Corrosion Comments', type: 'text' },
    { field: 'itaIntUnderDepositCorrosionLOF', name: 'Under Deposit Corrosion LOF', type: 'LOF', targetField: 'itaIntUnderDepositCorrosionLOF', required: true },
    { field: 'itaIntUnderDepositCorrosionComments', name: 'Under Deposit Corrosion Comments', type: 'text' },
    { field: 'itaIntMICLOF', name: 'Under Deposit Corrosion LOF', type: 'LOF', targetField: 'itaIntMICLOF', required: true },
    { field: 'itaIntMICComments', name: 'Under Deposit Corrosion Comments', type: 'text' },
    { field: 'itaExtAtmosphericCorrosionLOF', name: 'Atmospheric Corrosion LOF', type: 'LOF', targetField: 'itaExtAtmosphericCorrosionLOF', required: true },
    { field: 'itaExtAtmosphericCorrosionComments', name: 'Atmospheric Corrosion Comments', type: 'text' },
    { field: 'itaExtCUILOF', name: 'External CUI LOF', type: 'LOF', targetField: 'itaExtCUILOF', required: true },
    { field: 'itaExtCUIComments', name: 'External CUI Comments', type: 'text' },
    { field: 'itaExtCorrosionUnderSupportLOF', name: 'External Corrosion Under Support LOF', type: 'LOF', targetField: 'itaExtCorrosionUnderSupportLOF', required: true },
    { field: 'itaExtCorrosionUnderSupportComments', name: 'External CorrosionUnder Support Comments', type: 'text' },
]