import { IInspectionType } from "../interface/inspection-type";
import axiosInstance from "./instance";

export const RequestGetInspectionTypes = (addNotification: any, data?: any): Promise<IInspectionType[]> => {
    return new Promise<IInspectionType[]>((resolve, reject) => {
        let url = `/inspectionTypes/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)
        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetInspectionType = (addNotification: any, id: string): Promise<IInspectionType> => {
    return new Promise<IInspectionType>((resolve, reject) => {

        axiosInstance(addNotification).get(`/inspectionTypes/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewInspectionType = (addNotification: any, thing: IInspectionType): Promise<IInspectionType> => {
    return new Promise<IInspectionType>((resolve, reject) => {

        axiosInstance(addNotification).post(`/inspectionTypes/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteInspectionType = (addNotification: any, thing: IInspectionType): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/inspectionTypes/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateInspectionType = (addNotification: any, user: IInspectionType): Promise<IInspectionType> => {
    return new Promise<IInspectionType>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/inspectionTypes/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestInspectionTypeFunction = (addNotification: any, func:string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/inspectionTypes/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
