
import React from 'react';



interface IPageTitle {
    title: string;
    class: string;
}

type PageTitleContextType = {
    d: IPageTitle;

    setPageTitle?: (t: string, c?: string) => void;
};
export const PageTitleContext = React.createContext<PageTitleContextType | undefined>({
    d: { title: '', class: 'normal' }
}
);
type Props = {
    children: React.ReactNode;
};
export const PageTitleProvider = ({ children }: Props) => {
    const [d, setPageTitlex] = React.useState<IPageTitle>({ title: '', class: '' });

    const setPageTitle = (title: string, classd?: string) => {

        if (d.title !== title || d.class !== classd) {
            const c = classd ? classd : 'normal';
            setPageTitlex({ title, class: c })
        }

    }

    return (
        <PageTitleContext.Provider value={{ d, setPageTitle }}>
            {children}
        </PageTitleContext.Provider>
    );
};

export const usePageTitle = () => React.useContext(PageTitleContext);