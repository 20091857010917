import moment from 'moment';
interface IDDate {
    date: string | Date | undefined;
    format?: string;
    ago?: boolean;
}
const DateDisplay: React.FunctionComponent<IDDate> = props => {
    const format: string = props.format ? props.format : 'DD/MM/YYYY'
    // console.log(props.date)
    return <>
        {props.date !== undefined && props.date !== null && <>

            {moment(props.date).format(format)}

            {props.ago === true && <> - {moment(props.date).fromNow()}</>}

        </>}


    </>
};


export default DateDisplay