import { faFileDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import { downloadFile } from "../../api/file-download-request";
import DataTable from "../../components/system/DataTable";
import DateDisplay from "../../components/system/DateDisplay";
import DebugButton from "../../components/system/DebugButton";
import { useNotifications } from "../../context/notifications";
import { IAnomaly } from "../../interface/anomaly";
import { IEquipment } from "../../interface/equipment";
import { IInstallation } from "../../interface/installation";
import { IPipe } from "../../interface/pipe";
import ClientRiskTable from "./ClientRiskTable";

interface IHome {
    data: IInstallation
    pipesAll: IPipe[]
    equipmentsAll: IEquipment[]
    anomalys: IAnomaly[]
}

const InstallationHome = ({ data, pipesAll, equipmentsAll, anomalys }: IHome) => {

    const [detailDisplayTable, setDetailDisplayTable] = useState<boolean>(false);
    const [pipes, setPipes] = useState<IPipe[]>();
    const [equipment, setEquipment] = useState<IEquipment[]>();
    const [query, setQueryed] = useState<any>();
    const { addNotification } = useNotifications()!;



    const resetQuery = () => {
        setQueryed(undefined);
        setPipes([]);
        setEquipment([])
    }
    const setQuery = (query: any) => {
        console.log(query)
        setQueryed(query)
        setPipes(pipesAll && pipesAll.filter(val => (val.combinedConsequence === query.combinedConsequence && val.likelihood === query.likelihood)))
        setEquipment(equipmentsAll && equipmentsAll.filter(val => (val.combinedConsequence === query.combinedConsequence && val.likelihood === query.likelihood)))
    }


    return <div className="row">
        <div className={`text-center ${detailDisplayTable === true ? "col-12" : "col-7"}`}>
            {data.client && <ClientRiskTable pipes={pipesAll} equipments={equipmentsAll} client={data.client} setQuery={setQuery} query={query} detailDisplay={detailDisplayTable} installation={data}></ClientRiskTable>}

            <div className="btn-group">
                {query && <button className="btn btn-warning" type="button" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    resetQuery();
                }}>Reset</button>}
                <button className="btn btn-success" type="button" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    setDetailDisplayTable(!detailDisplayTable);
                }}>View Matrix Details</button>
            </div>
        </div>


        {pipes && pipes.length > 0 && <div className="col-6">
            <h2>Pipes</h2>
            {pipes && <DataTable
                hideIndexCol={true}
                colHeadings={[
                    { name: "Nane", field: "name", sortable: true },
                    {
                        name: "Fluid", field: "fluidId", sortable: true, r: (value: any, index) => {
                            return <>
                                {value.fluidName}
                            </>;
                        }
                    },
                    { name: "size", field: "size", sortable: false, queryable: true },
                    {
                        name: "From", field: "fromStr", r: (value: any, index, addToQuery) => {
                            return <>{value.fromStr}
                                {value.fromEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.fromEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                {value.fromPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/pipe/${value.fromPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                            </>
                        }, sortable: false
                    },
                    {
                        name: "To", field: "toStr", r: (value: any, index, addToQuery) => {
                            return <>{value.toStr}
                                {value.toEquipmentId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.toEquipmentId}`}><FontAwesomeIcon icon={faPlus} /></Link>}
                                {value.toPipeId && <Link className="btn btn-outline-primary" to={`/installations/${value.installId}/equipment/${value.toPipeId}`}><FontAwesomeIcon icon={faPlus} /></Link>}

                            </>

                        }, sortable: false
                    },

                    {
                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} />
                                <Link className="btn btn-primary" to={`/installations/${value.installId}/pipe/${value.id}`}>Details</Link>

                            </div>;
                        }, sortable: false
                    },

                ]}

                data={pipes}
            />
            }

            <button className="btn btn-warning" type="button" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                resetQuery();
            }}>Reset</button>
        </div>}
        {equipment && equipment.length > 0 && <div className="col-6">
            <h2>Equipment</h2>
            {equipment && <DataTable

                hideIndexCol={true}
                colHeadings={[
                    { name: "Name", field: "name", sortable: false },
                    { name: "ID", field: "refId", sortable: true },
                    {
                        name: "System", field: "systemId", sortable: true, r: (value: any, index) => {
                            return <>
                                {value.system.name}
                            </>;
                        }
                    },
                    {
                        name: "Fluid", field: "fluidId", sortable: true, r: (value: any, index) => {
                            return <>
                                {value.fluidName}
                            </>;
                        }
                    },
                    { name: "ID", field: "refId", sortable: true, },
                    { name: "Type", field: "typeName", sortable: false },
                    {
                        name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} />
                                <Link className="btn btn-primary" to={`/installations/${value.installId}/equipment/${value.id}`}>Details</Link>

                            </div>;
                        }, sortable: false
                    },

                ]}

                data={equipment}
            />}

        </div>}
        {data.files && data.files.length > 0 && <div className="col-1">
            <h2>Files</h2>
            {data.files.map((file, indddex) => {
                return <div key={indddex} className="col-12">

                    {file.fileName}

                    <button className="btn btn-primary" type="button" onClick={() => {
                        file && downloadFile(addNotification, file);
                    }}><FontAwesomeIcon icon={faFileDownload} /></button>
                </div>
            })}

        </div>}

        <div className="col-5">
            <h2>Open Anomalies</h2>
            <span className="e2ePageClientInstallationDetails" style={{ display: 'none' }}>TestOk</span>
            {anomalys && <>
                {anomalys.filter(ddd => ddd.status === 'new').length > 0 ? <DataTable

                    hideIndexCol={true}
                    colHeadings={[
                        { name: "ID", field: "refId", sortable: true },
                        { name: "Subject", field: "subject", sortable: false },
                        { name: "Status", field: "status", sortable: false },
                        {
                            name: "Date", field: "date", r: (value: any, index) => {
                                return <DateDisplay format="YYYY-MM-DD" date={value.date} />;
                            }, sortable: true
                        },
                        {
                            name: "Actions", align: "right", field: "id", r: (value: any, index) => {
                                return <div className="btn-group">
                                    <DebugButton data={value} />
                                    <Link className="btn btn-primary" to={`/installations/${value.installId}/anomaly/${value.id}`}>Details</Link>


                                </div>;
                            }, sortable: false
                        },

                    ]}

                    data={anomalys}
                /> : <>
                    No Open Anomalies
                </>}
            </>}
        </div>

        {data.image && <div className="col-12">
            <h2>Asset Overview</h2>
            <img src={data.image.c1600} className="img-fluid" />
        </div>}
    </div>;
}

export default InstallationHome;