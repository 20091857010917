import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { IAnomaly } from '../../../interface/anomaly';
import { IInstallationLoop } from '../../../interface/installation-loop';





export const selectEquipmentA = (bool: boolean, equipment: any, pipe: any, name: string, values: IAnomaly, loops: IInstallationLoop[] | undefined, index: number, form: any) => {
    return <div className="row">
        {bool === true ? <>
            <div className="col-6">
                <Field name={`${name}.equipmentId`}>
                    {({ input, meta }) => (
                        <div>
                            <label>Equipment</label>
                            <select className="form-control" {...input}>
                                <option></option>
                                {values.system && values.system.equipment && values.system.equipment.map((p, pi) => {

                                    return <option value={p.id} key={pi}>{p.refId}</option>;
                                })}
                            </select>
                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                        </div>
                    )}
                </Field>
            </div>
            <div className="col-6">

                <Field name={`${name}.loopId`}>
                    {({ input, meta }) => (
                        <div>
                            <label>Loop</label>
                            <select className="form-control" {...input}>
                                <option></option>

                                {loops && loops.map((l, li) => {
                                    return <option value={l.id}>{l.refId}</option>;
                                })}
                            </select>
                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                        </div>
                    )}
                </Field>

                {values.loop && <>
                    <Field name={`${name}.pipeId`}>
                        {({ input, meta }) => (
                            <div>
                                <label>Pipe</label>
                                <select className="form-control" {...input}>
                                    <option></option>
                                    <option value="_ALL">all</option>
                                    {values.loop && values.loop.pipes && values.loop.pipes.map((p, pi) => {

                                        return <option value={p.id} key={pi}>{p.serviceFluidId} {p.refId}</option>;
                                    })}
                                </select>
                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                            </div>
                        )}
                    </Field>
                </>}

            </div>




        </> : <div className="col-12">
            {values.equipmentId ? <p>Equipment: {values.equipment?.refId} - {values.equipment?.name} </p> : <p>

                {values.loopId && <>Loop: {values.loop?.refId} -  {values.loop?.name}</>}
                /
                {values.pipeId && <>Pipe: {values.pipe?.refId} -  {values.loop?.name}</>}
            </p>}


            <button className="btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();

                form.change('pipe', undefined);
                form.change('equipment', undefined);
                form.change('loop', undefined);
                form.change('equipmentId', '');
                form.change('loopId', '');
                form.change('pipeId', '');
                form.change('results', []);
            }}>Clear Selection</button>
        </div>}
        <OnChange name={`${name}.loopId`}>
            {(value, previous) => {
                if (value !== '' && loops) {
                    // /reported
                    const loopIndex = loops.findIndex(d => d.id === value);
                    const loop = loops[loopIndex];

                    form.change('results', []);
                    form.change('loop', loop);
                }
            }}
        </OnChange>
        <OnChange name={`${name}.equipmentId`}>
            {(value, previous) => {
                // do something
                //find the equipment obj
                if (values.system) {
                    const ssss = values.system && values.system.equipment && values.system.equipment.find((ddd => ddd.id === value));
                    console.log("sdfsdfd", ssss);

                    if (ssss) {

                        form.change('equipment', ssss);
                        form.change('systemId', ssss.systemId);
                        form.change('materialId', ssss.materialId);
                        form.change('results', []);

                    }


                }
            }}
        </OnChange>


        <OnChange name={`${name}.pipeId`}>
            {(value, previous) => {
                // do something
                console.log("sdfsdfd");



                const ssss = values.loop !== undefined && values.loop.pipes !== undefined && values.loop.pipes.find((ddd => ddd.id === value));
                console.log("sdfsdfd", ssss);

                if (ssss) {
                    form.change('pipe', ssss);
                    form.change('loopId', ssss.loopId);
                    form.change('systemId', ssss.systemId);
                    form.change('results', []);


                }


            }}
        </OnChange>

    </div>;
}

