import { useState } from "react";

interface IProps {
    list: any[]
}

const ListViewX: React.FunctionComponent<IProps> = props => {
    const [expanded, setExpanded] = useState(false);
    if (props.list && Array.isArray(props.list)) {
        return <>{props.list && props.list.length > 3 ? <>
            {expanded === true ? <>{props.list && props.list.map((vv: any, dindex: number) => {
                if (typeof vv === 'string') {
                    return <p key={dindex}>{vv}</p>;
                } else {
                    return <p key={dindex}>{vv && vv.name && vv.name}</p>;
                }

            })}<button type="button" className="btn btn-info" onClick={() => {
                setExpanded(!expanded);
            }}>...</button></> : <>{props.list && props.list.slice(0, 3).map((vv: any, dindex: number) => {
                if (typeof vv === 'string') {
                    return <p key={dindex}>{vv}</p>;
                } else {
                    return <p key={dindex}>{vv && vv.name && vv.name}</p>;
                }

            })}<button type="button" className="btn btn-info" onClick={() => {
                setExpanded(!expanded);
            }}>...</button></>}

        </> : <>{props.list && props.list.map((vv: any, dindex: number) => {
            if (typeof vv === 'string') {
                return <p key={dindex}>{vv}</p>;
            } else {
                return <p key={dindex}>{vv && vv.name && vv.name}</p>;
            }

        })}</>}</>;
    } else {
        return <></>
    }
};

export default ListViewX