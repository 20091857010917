import DateDisplay from "../../components/system/DateDisplay"



interface IAttributeBlob {
    data: any;
    field: string;
    title: string;
    special?: string;
}
export const AttributeBlob = ({ data, field, title, special }: IAttributeBlob) => {


    return <>
        {data[field] && data[field] !== '' ? <div className="col-3 border border-start">
            <div className="d-flex justify-content-between">
                <b>{title}</b>
                <p className={`e2e_${field}`}>{special === 'date' ? <><DateDisplay format="YYYY-MM-DD" date={data[field]} /></> : <>{data[field]}</>}</p>
            </div>
        </div> : <div className="col-3 border border-start">
            <div className="d-flex justify-content-between">
                <b>{title}</b>
                <p className={`e2e_NO_${field}`}>-</p>
            </div>
        </div>}


    </>
}