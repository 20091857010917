import { Toast } from "react-bootstrap";
import { useNotifications } from "../../context/notifications";



const Notifications = () => {

  const { notifications, remove } = useNotifications()!;
  return (<>
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: 'relative',

      }}
    ><div style={{
      position: 'absolute',
      top: 0,
      right: 0,
    }}>{notifications.map((note, index) => {
      // console.log(note)
      //const class:string = ENotificationType[note.type];
      return <Toast className={note.type} key={index} onClose={() => remove && remove(note.id)} show={true} delay={3000} autohide>
        <Toast.Header>
          <strong className="mr-auto">{note.title}</strong>
        </Toast.Header>
        <Toast.Body>{note.payload}</Toast.Body>
      </Toast>
    })}

      </div>
    </div>
  </>);
}

export default Notifications;