import { IEquipment } from "../interface/equipment";
import axiosInstance from "./instance";

export const RequestGetEquipments = (addNotification: any, data?: any): Promise<IEquipment[]> => {
    return new Promise<IEquipment[]>((resolve, reject) => {
        let url = `/equipments/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetEquipment = (addNotification: any, id: string): Promise<IEquipment> => {
    return new Promise<IEquipment>((resolve, reject) => {

        axiosInstance(addNotification).get(`/equipments/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewEquipment = (addNotification: any, thing: IEquipment): Promise<IEquipment> => {
    return new Promise<IEquipment>((resolve, reject) => {

        axiosInstance(addNotification).post(`/equipments/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteEquipment = (addNotification: any, thing: IEquipment): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/equipments/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateEquipment = (addNotification: any, user: IEquipment): Promise<IEquipment> => {
    return new Promise<IEquipment>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/equipments/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestEquipmentFunction = (addNotification: any, func: string, id: string): Promise<any> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/equipments/_func/${func}/${id}`;
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
