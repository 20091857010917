import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import queryString from "query-string";
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { Helmet } from 'react-helmet';
import { Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { downloadFile } from '../../../api/file-download-request';
import {
    RequestGetInspection, RequestNewInspection,
    RequestUpdateInspection
} from '../../../api/inspection-requests';
import { RequestGetInspectionTypes } from '../../../api/inspection-type-requests';
import { RequestGetInstallationLoops } from '../../../api/installation-loop-requests';
import { RequestGetInstallations, RequestUpdateInstallation } from '../../../api/installation-requests';
import { RequestGetInstallationSystems } from '../../../api/installation-system-requests';
import { RequestNewLog } from '../../../api/log-requests';
import DateField from '../../../components/FormStuff/date';
import { composeValidators, required } from '../../../components/FormStuff/validators';
import DebugButton from '../../../components/system/DebugButton';
import Loading from '../../../components/system/loading';
import UploadFiles from '../../../components/system/UploadFiles';
import UploadImages from '../../../components/system/UploadImages/UploadImages';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { usePageTitle } from '../../../context/pageTitle';
import { IInspection } from '../../../interface/inspection';
import { IInspectionType } from '../../../interface/inspection-type';
import { IInstallation } from '../../../interface/installation';
import { IInstallationLoop } from '../../../interface/installation-loop';
import { IInstallationSystem } from '../../../interface/installation-system';
import { ELogType, ESeverity } from '../../../interface/log';
import { filesAttach } from '../addons';
import { internalApproval, selectEquipment } from './addons';
import { anythingBy } from './anythingBy';
const AdminInspection = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [startedLoading, setStartedLoading] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [redirectTo, setRedirectTo] = useState<string>('');
    const [newId, setNewId] = useState<string>('');
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IInspection>();
    const { addNotification } = useNotifications()!;
    const { auth, hasPermission } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();

    const [installations, setInstallations] = useState<IInstallation[]>();
    const [loops, setLoops] = useState<IInstallationLoop[]>()
    const [inspectionTypes, setInspectionTypes] = useState<IInspectionType[]>()
    const [systems, setSystems] = useState<IInstallationSystem[]>();

    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/inspections/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }



    const parsed = queryString.parse(location.search);


    const { setPageTitle } = usePageTitle()!;


    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true && startedLoading === false) {
                setStartedLoading(true);


                {/*<!-- OPTIONAL 2 -->*/ }

                RequestGetInspectionTypes(addNotification).then(iTypes => {
                    setInspectionTypes(iTypes)
                    RequestGetInstallations(addNotification).then(installations => {

                        setInstallations(installations);
                        if (id === 'new') {
                            const aThing: any = {
                                clientId: '', loopId: '', systemId: '', altRefId: '', fileIds: [],
                                refId: '', closing: '', approvedByName: '', formApprovedByDate: '', approvedByUserId: '',
                                formAssessedDate: '', id: 'new', fileId: '', reported: [], inspectionTypeId: 'inspection',
                                inspectionByCompany: '', inspectionByName: '', inspectionDate: '', formInspectionDate: '',
                                assessedByName: '', assessedByCompany: '', assessedDate: '',
                                subject: '', summaryOfFindings: '', installId: '',

                                dateOfReport: new Date(Date.now()).toString()
                            }



                            setThing(aThing);

                            setLoading(false)
                            setPageTitle && setPageTitle('Retriever / Inspection / New', 'admin');
                        } else {


                            RequestGetInspection(addNotification, id).then(thing => {
                                console.log("dd", installations)

                                if (thing.assessedDate) {
                                    thing.formAssessedDate = format(new Date(thing.assessedDate), "dd-MM-yyyy")
                                }

                                if (thing.inspectionDate) {
                                    thing.formInspectionDate = format(new Date(thing.inspectionDate), "dd-MM-yyyy")
                                }

                                if (thing.approvedByDate) {
                                    thing.formApprovedByDate = format(new Date(thing.approvedByDate), "dd-MM-yyyy")
                                }

                                if (thing.dateOfReport) {
                                    thing.formDate = format(new Date(thing.dateOfReport), "dd-MM-yyyy")
                                }

                                if (thing.installId) {
                                    const ins = installations.find(dd => dd.id === thing.installId)
                                    thing.installation = ins
                                }

                                if (thing.assessedByCompany && thing.installation) {
                                    const iii = thing.installation.contractors.findIndex(ddd => ddd.id === thing.assessedByCompany)
                                    if (iii !== -1) {

                                        thing.assessedByCompanyObj = thing.installation.contractors[iii]
                                    }
                                }

                                RequestGetInstallationSystems(addNotification, { installId: thing.installId }).then(syss => {
                                    setSystems(syss)
                                    if (thing.systemId !== '') {
                                        RequestGetInstallationLoops(addNotification, { systemId: thing.systemId }).then(loooy => {
                                            setLoops(loooy)
                                            setThing(thing);
                                            setLoading(false)
                                            //  setPageTitle && setPageTitle(thing.refId, 'admin');
                                            setPageTitle && setPageTitle('Retriever / Inspection / ' + thing.refId, 'admin');
                                        }).catch(ds => {

                                        })
                                    } else {
                                        setThing(thing);
                                        setLoading(false)

                                    }
                                }).catch(ds => {

                                })
                            })
                        }
                    })
                })




                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Inspection - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);


    {/*<!-- OPTIONAL 3 -->*/ }






    const onSubmit2 = (data: any) => {
        data.fileIds = [];
        console.log("onSubmit2", data)
        data.fileIds = [];
        if (data.files) {
            for (let index = 0; index < data.files.length; index++) {
                const element = data.files[index];
                data.fileIds.push(element.id)
            }
        }


        for (let index = 0; index < data.reported.length; index++) {
            const reported = data.reported[index];
            data.reported[index].fileIds = [];
            if (reported.files) {
                for (let index = 0; index < reported.files.length; index++) {
                    const elemented = reported.files[index];
                    data.reported[index].fileIds.push(elemented.id)
                }
            }
        }

        try {

            setLoading(true)
            setFormChanged(false)


            const doMainBit = () => {

                let ppp: Promise<IInspection>;
                let notificationText: string;
                if (!data._id) {
                    notificationText = 'Inspection Created!';
                    ppp = RequestNewInspection(addNotification, data);
                } else {
                    notificationText = 'Inspection Saved!';
                    ppp = RequestUpdateInspection(addNotification, data);
                }

                ppp.then(thing => {
                    console.log(thing);
                    setThing(thing);
                    addNotification && addNotification('Success', notificationText, ENotificationType.Primary);

                    if (parsed.test) {
                        // setRedirectTo('/admin/inspections/' + thing.id)
                        setNewId(thing.id);
                    } else {
                        setRedirectTo('/admin/inspections')
                        setRedirect(true);
                    }



                    setLoading(false)
                })
            }



            if (data.installation && data.installation._reqSave === true) {
                const ddd = { ...data.installation }
                console.log(ddd)
                RequestUpdateInstallation(addNotification, data.installation).then(ddd => {
                    doMainBit();
                }).catch(ex => {
                    console.log("ex", ex)
                })
            } else {
                doMainBit();
            }






        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Inspection - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }

    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to={redirectTo} />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Inspection / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <span className="e2ePageAdminInspection" style={{ display: 'none' }}>TestOk</span>
            {newId && newId !== '' && <span className="e2eNewId" style={{ display: 'none' }}>{newId}</span>}
            {/*<!-- OPTIONAL 4 -->*/}
            <div className="btn-group">

            </div>
            <Form <IInspection>
                onSubmit={(ddd) => {
                    //delete ddd.installation;
                    onSubmit2(ddd)
                }}
                validate={(data: any) => {
                    console.log("data", data)
                    return {}
                }}
                initialValues={{ ...thing, assessedByCompanyFind: '', assessedByNameFind: '', inspectionByNameFind: '', inspectionByCompanyFind: '' }}
                mutators={{
                    ...arrayMutators,
                    setValue: ([field, value], state, { changeValue }) => {
                        console.log(state, field, value)
                        changeValue(state, field, () => value)
                    }
                }}
                render={({ handleSubmit, form, submitting, pristine, values, valid, validating, errors }) => {

                    return <form id="inspectionForm" onSubmit={handleSubmit} autoComplete="off">

                        <div className="row">

                            <div className="col-12 d-flex justify-content-between">
                                <DebugButton data={values} alwaysDisplay={true} />

                            </div>

                            <div className="col-12">
                                <Field name="installId">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Installation</label>
                                            <select type id={`inp_${input.name}`} className="form-control" {...input} onChange={(ev: React.FormEvent) => {
                                                ev.preventDefault();
                                                input.onChange(ev);
                                                console.log(values.installId)

                                            }}>
                                                <option></option>
                                                {installations && installations.map((client, index) => {
                                                    return <option key={index} value={client.id}>{client.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <OnChange name="installId">
                                    {(value, previous) => {
                                        // do something

                                        if (installations && values.installId && value !== null) {
                                            const install = installations.find(dd => dd.id === values.installId)
                                            form.change('installation', install)
                                            form.change('clientId', install?.clientId)

                                            RequestGetInstallationSystems(addNotification, { installId: values.installId }).then(loooyd => {
                                                console.log(loooyd)
                                                setSystems(loooyd)

                                            }).catch(ds => {

                                            })


                                        }
                                    }}
                                </OnChange>
                            </div>
                            {values.installation && <>

                                <div id="e2eInstallationDetails" className="col-12">
                                    Installation Details - location
                                </div>


                                <div className="col-6">
                                    <Field name="refId" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Report Number</label>
                                                <input id={`inp_${input.name}`} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div className="col-6">
                                    <Field name="altRefId">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>
                                                    Project identification</label>
                                                <input id={`inp_${input.name}`} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-6">
                                    <label>File</label><br />
                                    {values.file && <span id="spanFileName">{values.file.fileName}
                                        <button className="btn btn-primary" type="button" onClick={() => {
                                            values.file && downloadFile(addNotification, values.file);
                                        }}><FontAwesomeIcon icon={faFileDownload} /></button>

                                    </span>}
                                    <Field name="file" validate={composeValidators(required)} otherAssignments={[{ toType: 'install', toId: values.installId }, { toType: 'client', toId: values.clientId }]} component={UploadFiles} onChanged={(event: any) => {
                                        //   fields.push(event)
                                        form.change('fileId', event.id)
                                        console.log(event)
                                    }}>


                                    </Field>
                                </div>
                                {filesAttach("files", "fileIds", values, [{ toType: 'client', toId: values.clientId }, { toType: 'install', toId: values.installId }], addNotification, form)}
                                <div className="col-6">
                                    <Field name="subject" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Subject</label>
                                                <input id={`inp_${input.name}`} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-6">
                                    <OnChange name={`formDate`}>
                                        {(value, previous) => {
                                            // do something


                                            const myMomentObject = moment(value, 'DD-MM-YYYY').toDate()
                                            console.log(value, myMomentObject)
                                            form.change('dateOfReport', myMomentObject.toString())
                                        }}
                                    </OnChange>
                                    <Field name="formDate" validate={composeValidators(required)} component={DateField} />
                                </div>

                                <div className="col-12">
                                    <Field name="summaryOfFindings" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Summary of Findings</label>
                                                <textarea id={`inp_${input.name}`} spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-12">
                                    <Field name={`systemId`}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>System</label>
                                                <select id={`inp_${input.name}`} type className="form-control" {...input}>
                                                    <option></option>
                                                    {systems && systems.map((l, li) => {
                                                        return <option value={l.id}>{l.refId}</option>
                                                    })}
                                                </select>
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <OnChange name="systemId">
                                        {(value, previous) => {
                                            if (value !== '' && systems) {
                                                RequestGetInstallationLoops(addNotification, { systemId: value }).then(loooy => {
                                                    console.log("WWWWWW", loooy)
                                                    setLoops(loooy)
                                                }).catch(ds => {
                                                    console.log("WWWWWW_err", ds)
                                                })
                                                const index = systems.findIndex(d => d.id === value);

                                                const loop = systems[index];
                                                form.change('system', loop)
                                            }
                                        }}
                                    </OnChange>

                                </div>

                                {values.systemId !== '' ? <div className="col-12">
                                    <h2>Inspected Item</h2>
                                    <FieldArray name="reported">

                                        {({ fields }) => (
                                            <div>

                                                {fields.map((name, index) => {
                                                    const reported = values.reported[index];
                                                    const equipment = values.reported[index].equipmentId;
                                                    const pipe = values.reported[index].pipeId;
                                                    //  const loop = values.reported[index].loop;

                                                    console.log("reported", reported)
                                                    const display: boolean = ((equipment !== null && equipment !== undefined && equipment !== '') || (pipe !== null && pipe !== undefined && pipe !== '') ? false : true)
                                                    return <div className="text-left" key={name}>
                                                        <br />
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h2>Inspection {index + 1}</h2>
                                                                {values.system ? <>
                                                                    <Field name={`${name}.typeId`}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Type {reported.typeId}</label>
                                                                                <select id={`inp_${index}typeId`} type className="form-control" {...input}>
                                                                                    <option></option>

                                                                                    {inspectionTypes && inspectionTypes.map((vv, index) => {
                                                                                        return <option key={index} value={vv.id}>{vv.name} - {vv.type}</option>
                                                                                    })}
                                                                                </select>
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                    <p>System: {values.system && values.system.refId}</p>

                                                                    <DebugButton data={JSON.parse(JSON.stringify(reported))} />
                                                                    {selectEquipment(display, equipment, pipe, name, values, loops, index, reported, form)}



                                                                    <OnChange name={`${name}.typeId`}>
                                                                        {(value, previous) => {
                                                                            // do something
                                                                            console.log("sdfsdfd", values.reported[index]);

                                                                            const rrr = [...values.reported];
                                                                            //find the equipment obj
                                                                            if (inspectionTypes) {
                                                                                const ssss = inspectionTypes.find((ddd => ddd.id === value));
                                                                                console.log("sdfsdfd", ssss);

                                                                                if (ssss) {
                                                                                    rrr[index].inspectionType = ssss;
                                                                                }
                                                                                console.log(value, rrr);
                                                                                form.change('reported', rrr);
                                                                            }

                                                                        }}
                                                                    </OnChange>

                                                                    {/* {reported.typeId === 'UT' || reported.typeId === 'RT' || reported.typeId === 'AUT' || reported.typeId === 'PAUT' && <> */}
                                                                    {(reported.inspectionType && reported.inspectionType.isMeasurement === true) && <FieldArray name={`${name}.results`}>

                                                                        {(resultsFrm: any) => {

                                                                            return <>
                                                                                <h4>Wall Thickness Readings</h4>
                                                                                {resultsFrm.fields.map((name2: string, index2: number) => {
                                                                                    const thing = values.reported[index].results[index2];
                                                                                    return <div key={index2} className="row">
                                                                                        <div className="col-4">
                                                                                            <Field name={`${name2}.point`}>
                                                                                                {({ input, meta }) => (
                                                                                                    <div>
                                                                                                        <label>Point</label>
                                                                                                        <input id={`inp_${input.name}`} type="text" className="form-control" {...input} placeholder="...." />
                                                                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <Field name={`${name2}.value`}
                                                                                                parse={(value, name) => {
                                                                                                    //  console.log("parse", value, name)
                                                                                                    if (value) return parseFloat(value);
                                                                                                }}
                                                                                                format={(value, name) => {
                                                                                                    //  console.log("format", value, name)

                                                                                                    if (value) return parseFloat(value);
                                                                                                }}>
                                                                                                {({ input, meta }) => (
                                                                                                    <div>
                                                                                                        <label>AVE</label>
                                                                                                        <input id={`inp_${input.name}`} type="number" className="form-control" {...input} placeholder="...." />
                                                                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <Field name={`${name2}.min`}
                                                                                                parse={(value, name) => {
                                                                                                    //  console.log("parse", value, name)
                                                                                                    if (value) return parseFloat(value);
                                                                                                }}
                                                                                                format={(value, name) => {
                                                                                                    //  console.log("format", value, name)

                                                                                                    if (value) return parseFloat(value);
                                                                                                }}>
                                                                                                {({ input, meta }) => (
                                                                                                    <div>
                                                                                                        <label>MIN</label>
                                                                                                        <input id={`inp_${input.name}`} type="number" className="form-control" {...input} placeholder="...." />
                                                                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <OnChange name={`${name2}.value`}>
                                                                                            {(value, previous) => {
                                                                                                // do something
                                                                                                if (value) {
                                                                                                    console.log(value, thing)

                                                                                                    if (thing.original && value > thing.original) {
                                                                                                        //  window.alert('Thats above the original thinkness')
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        </OnChange>

                                                                                        <div className="col-4">
                                                                                            <button className="btn btn-outline-danger" onClick={() => resultsFrm.fields.remove(index2)} type="button" >Remove Result</button>
                                                                                        </div>
                                                                                    </div>
                                                                                })}

                                                                                <button
                                                                                    className="btn btn-success"
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        console.log(reported.pipe)
                                                                                        resultsFrm.fields.push({})
                                                                                    }}
                                                                                >Add Result</button>
                                                                            </>;

                                                                        }}
                                                                    </FieldArray>}




                                                                    {reported.image && <div className="col-6 offset-2 e2eImageDisplay">
                                                                        <img id={`e2e_image_${index}`} src={reported.image.croppedSrcData ? reported.image.croppedSrcData : reported.image.c1600} className="img-fluid" />
                                                                        <button type="button" onClick={() => {

                                                                            const rrr = [...values.reported];
                                                                            //find the equipment obj
                                                                            const r = rrr[index]
                                                                            r.imageId = '';
                                                                            r.image = undefined;
                                                                            rrr[index] = r;
                                                                            form.change('reported', rrr);

                                                                        }} className="btn btn-warning">Remove</button>
                                                                    </div>}
                                                                    <br />
                                                                    <Field name={`image_${index}`} aspect="none" camera={false} component={UploadImages} text="Image" attachTo="installation" attachId={values.id} onChanged={(data: any) => {
                                                                        console.log("changed", data)


                                                                        const rrr = [...values.reported];
                                                                        //find the equipment obj
                                                                        const r = rrr[index]
                                                                        r.imageId = data.id;
                                                                        r.image = data;
                                                                        rrr[index] = r;
                                                                        form.change('reported', rrr);


                                                                        // form.change('imageId', data.id)
                                                                        // form.change('image', data)
                                                                    }} />

                                                                    {filesAttach(`${name}.files`, `${name}.fileIds`, reported, [{ toType: 'client', toId: values.clientId }, { toType: 'install', toId: values.id }], addNotification, form)}
                                                                    <Field name={`${name}.confidence`} validate={composeValidators(required)}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Confidence</label>
                                                                                <select id={`inp_${index}confidence`} type className="form-control" {...input}>
                                                                                    <option></option>
                                                                                    <option value="0">Low</option>
                                                                                    <option value="1">Medium</option>
                                                                                    <option value="2">High</option>

                                                                                </select>
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>

                                                                    <Field name={`${name}.tagNo`}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Tag No</label>
                                                                                <input id={`inp_${index}tagNo`} type="text" className="form-control" {...input} placeholder="...." />
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>

                                                                    <Field name={`${name}.findings`}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Inspection Findings</label>
                                                                                <textarea id={`inp_${index}findings`} spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                    <Field name={`${name}.engineering`}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Engineering Assessment</label>
                                                                                <textarea id={`inp_${index}engineering`} spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>

                                                                    <Field name={`${name}.materialId`}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Material</label>
                                                                                <select id={`inp_${index}matterialId`} type className="form-control" {...input}>
                                                                                    <option></option>
                                                                                    {values.installation && values.installation.matterials && values.installation.matterials.map((mat, mIndex) => {
                                                                                        return <option value={mat.value}>{mat.text}</option>
                                                                                    })}
                                                                                </select>
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                    <div className="form-group">
                                                                        <Field
                                                                            id={`inp_${index}anomalyCreate`}
                                                                            name={`${name}.anomalyCreate`}
                                                                            component="input"
                                                                            type="checkbox"
                                                                        />{' '}Open an anomaly

                                                                    </div>
                                                                    {reported.anomalyCreate === true && <Field name={`${name}.mitigationMeasures`}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Mitigation Measures</label>
                                                                                <textarea id={`inp_${index}mitigationMeasures`} spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>}
                                                                </> : <>Oops maybe this is a old record? <br /> <DebugButton alwaysDisplay={true} data={JSON.parse(JSON.stringify(reported))} />
                                                                </>}


                                                                <br />

                                                                <button className="btn btn-outline-danger" onClick={() => fields.remove(index)} type="button" >Remove Inspection</button>



                                                            </div>

                                                        </div>

                                                    </div>
                                                })}
                                                <button
                                                    id="btnAddInspectedItem"
                                                    className="btn btn-success"
                                                    type="button"
                                                    onClick={() => fields.push({ equipmentId: '', pipeId: '', anomalyId: '', files: [], fileIds: [] })}
                                                >Add Inspected Item</button>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div> :
                                    <div className="col-12">
                                        Make sure System is selected!
                                    </div>}


                                {anythingBy("Inspection", "inspection", form, values)}
                                {anythingBy("Assessed", "assessed", form, values)}





                                <div className="col-12"><h2>Closing</h2></div>

                                <div className="col-12">

                                    <Field name="closing" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Closing Summary</label>
                                                <textarea id={`inp_${input.name}`} spellCheck={true} type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                </div>
                                {internalApproval(values, hasPermission, setLoading, addNotification, thing, setThing)}




                            </>}
                            <div className="col-12">
                                <button id="btnInspectionSubmit" className="btn btn-primary" type="submit">Save</button>
                            </div>
                        </div>



                    </form>

                }} />

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminInspection;


