import queryString from "query-string";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link, Redirect, useLocation } from "react-router-dom";
import { RequestChangeLostPassword, RequestLogin, RequestLoginLostPassword } from "../api/auth-requests";
import { composeValidators, required } from "../components/FormStuff/validators";
import Loading from "../components/system/loading";
import { STORAGEKEY, useAuth } from "../context/auth";
import { ENotificationType, useNotifications } from "../context/notifications";
import { usePageTitle } from "../context/pageTitle";
const Login = () => {
    const { auth, setAuth } = useAuth()!;
    const { addNotification } = useNotifications()!
    const { executeRecaptcha } = useGoogleReCaptcha();


    let location = useLocation();
    const [data] = useState<any>({ user: '', password: '', lostPasswordMode: false });

    const [done, setDone] = useState<boolean>(false);
    const [recover, setRecover] = useState<string>('');
    const [redirect, setRedirect] = useState<boolean>(false);
    const [redirectTo, setRedirectTo] = useState<string>('/');
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {

        const parsed = queryString.parse(location.search);
        console.log(parsed)
        if (parsed.recover) {
            setRecover(parsed.recover.toString())
        } else {
            setRecover('');
        }
    }, [location.search]);

    const { setPageTitle } = usePageTitle()!;
    useEffect(() => {

        setPageTitle && setPageTitle('AUTHENTICATION', 'login');

    }, [])


    const login = (data: any) => {
        setLoading(true)
        let recaptureType = 'login';
        if (data.lostPasswordMode === true) {
            recaptureType = 'lostpassword';
        }
        executeRecaptcha && executeRecaptcha(recaptureType).then(token => {
            // data.id = 'new';
            data.recapture = token



            if (data.lostPasswordMode === false) {
                //normal login
                const login: any = {
                    user: data.user,
                    password: data.password,
                    recapture: token
                }
                RequestLogin(addNotification, login).then(response => {
                    console.log(response);
                    if (response.user) {
                        //   addNotification && addNotification('Login', 'You have successfully logged in!', ENotificationType.Success)
                        setAuth && setAuth(response);
                        localStorage.setItem(STORAGEKEY, JSON.stringify(response));
                        setRedirect(true)
                    } else {
                        addNotification && addNotification('Login', 'Something wasn\'t right there please try again!', ENotificationType.Warning)
                    }
                    setLoading(false)


                }).catch(loginExecption => {
                    console.log(loginExecption)
                    addNotification && addNotification('Error', 'Api Error', ENotificationType.Danger)
                    setLoading(false)
                });

            } else {
                const login: any = {
                    user: data.user,
                    recapture: token
                }
                RequestLoginLostPassword(addNotification, login).then(response => {
                    console.log(response);
                    if (response) {
                        addNotification && addNotification('Login', 'Please await email for recovery info!', ENotificationType.Success)
                        localStorage.setItem('auth2', JSON.stringify(response));
                    } else {
                        addNotification && addNotification('Login', 'Something wasn\'t right there please try again!', ENotificationType.Warning)
                    }
                    setLoading(false)
                    setDone(true);
                }).catch(loginExecption => {
                    console.log(loginExecption)
                    addNotification && addNotification('Error', 'Api Error', ENotificationType.Danger)
                    setLoading(false)
                });
            }
        });

    }

    const recoverSetNewPassword = (data: any) => {

        executeRecaptcha && executeRecaptcha("lostpasswordreset").then(token => {

            data.recapture = token;
            RequestChangeLostPassword(addNotification, data).then(dd => {
                if (dd.lostPasswordUpdate === true) {
                    addNotification && addNotification('Password Reset', 'Password Changed!', ENotificationType.Success)
                    setDone(true);
                }
            })

        });
    }
    return (
        <div>

            {redirect === true && <Redirect to={redirectTo} />}
            {loading === true && <Loading />}

            {recover === '' ? <>
                <h1>Login</h1>
                {auth.type === 'guest' ? <>
                    <Form
                        onSubmit={login}
                        initialValues={data}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {

                            return <form onSubmit={handleSubmit}>
                                <span className="e2ePageLoginForm" style={{ display: 'none' }}>TestOk</span>
                                {done === true ? <div className="col-12">please await a email to you account address this will contain instructions to update your password</div> : <div className="col-12">
                                    <Field name="user" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Username</label>
                                                <input id="inpLoginUser" type="text" className="form-control" {...input} placeholder="" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    {values.lostPasswordMode === false && <Field name="password">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Password</label>
                                                <input id="inpLoginPass" type="password" className="form-control" {...input} placeholder="" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>}
                                    <div className="btn-group">
                                        <button type="button" id="btnLostPassword" className="btn btn-warning" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            form.change('lostPasswordMode', !values.lostPasswordMode)
                                        }}>{values.lostPasswordMode === false ? "Lost Password" : "Return to login"}</button>
                                        <button id="btnLoginSubmit" type="submit" className="btn btn-success">
                                            {values.lostPasswordMode === false ? "Login" : "Recover Password"}
                                        </button>
                                    </div>

                                </div>}
                            </form>
                        }} /></> : <>Your already logged in</>}
            </> : <>


                <h1>Set New Password</h1>

                {auth.type === 'guest' ? <><Form
                    onSubmit={recoverSetNewPassword}
                    initialValues={{ ...data, recoverId: recover }}
                    validate={values => {
                        const errors: any = {};
                        console.log(errors);
                        if (values.newPassword !== values.newPassword2) {
                            errors.newPassword = 'Passwords do not match!'
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {

                        return <form onSubmit={handleSubmit}>
                            <span className="e2eLoginRecoverPasswordForm" style={{ display: 'none' }}>TestOk</span>
                            {done === true ? <div className="col-12">please login with your reset password <Link to="/login">Login</Link></div> : <div className="col-12">

                                <div className="col-12">
                                    <Field name="newPassword" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>New Password</label>
                                                <input id="inpLoginRecoverPass" type="password" className="form-control" {...input} placeholder="" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="newPassword2" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Password Confirm</label>
                                                <input id="inpLoginRecoverPass2" type="password" className="form-control" {...input} placeholder="" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <div className="btn-group">

                                        <button type="submit" id="btnRecoverPasswordSubmit" className="btn btn-success">Update Password</button>
                                    </div>

                                </div>
                            </div>}
                        </form>
                    }} /></> : <>Your already logged in</>}
            </>}


        </div>
    );
}

export default Login;